import axiosInstance from "../AxiosInstance";

const RegisterEmpresa = async (body, set) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("auth/empresa/criar-cadastro/", body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default RegisterEmpresa;
