import React from "react";
import ButtonDark from "../ButtonDark";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import PasswordInput from "../passwordInput";
import { useNavigate } from "react-router-dom";
import VerifyEmailParceiro from "../../services/Register/VerifyEmailParceiro";
import VerifyTokenParceiro from "../../services/Register/VerifyTokenParceiro";
import Input from "../Input";
import RegisterParceiro from "../../services/Register/RegisterParceiro";
import checkValidate from "../../assets/check-validate.svg";

const ParceiroPassword = (props) => {
  let { complete } = props;

  const navigate = useNavigate();
  const [step, setStep] = useState("email");

  const [inputs, setInputs] = useState({
    email: "",
    confirmarEmail: "",
    token: "",
    senha: "",
    confirmarSenha: "",
    termos: false,
  });

  const [buttonState, setButtonState] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  const [pass, setPass] = useState(true);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const confirmToken = (e) => {
    e.preventDefault();

    setButtonState({ ...buttonState, step2: true });

    if (inputs.token === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step2: false });
      return;
    }

    VerifyTokenParceiro(inputs.email.toLowerCase(), inputs.token)
      .then((resp) => {
        setButtonState({ ...buttonState, step2: false });
        setStep("password");
      })
      .catch((e) => {
        setModal({
          text: e.data.message,
          alertType: "fail",
          active: true,
        });
        setButtonState({ ...buttonState, step1: false });
      });
  };

  const funcConfirmBtn = (e) => {
    e.preventDefault();

    setButtonState({ ...buttonState, step1: true });

    if (inputs.email === "" || inputs.confirmarEmail === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    } else if (inputs.email !== inputs.confirmarEmail) {
      setModal({
        text: "os emails não conferem!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    VerifyEmailParceiro(inputs.email.toLowerCase())
      .then((resp) => {
        setStep("validando");
        setButtonState({ ...buttonState, step1: false });
      })
      .catch((e) => {
        setModal({
          text: e.data.message,
          alertType: "fail",
          active: true,
        });
        setButtonState({ ...buttonState, step1: false });
      });
  };

  const setPassword = (e) => {
    e.preventDefault();

    setButtonState({ ...buttonState, step3: true });

    const parceiroDados = localStorage.getItem("parceiro-information-register")
      ? JSON.parse(localStorage.getItem("parceiro-information-register"))
      : null;

    const espacoDados = localStorage.getItem("parceiro-espaco-register")
      ? JSON.parse(localStorage.getItem("parceiro-espaco-register"))
      : null;

    const responsavelDados = localStorage.getItem(
      "parceiro-responsavel-register"
    )
      ? JSON.parse(localStorage.getItem("parceiro-responsavel-register"))
      : null;

    let cnpj = parceiroDados.cnpj;
    cnpj = cnpj.replace(/[^\d]+/g, "");
    let cpf_responsavel = responsavelDados.cpf;
    cpf_responsavel = cpf_responsavel.replace(/[^\d]+/g, "");

    RegisterParceiro({
      email: inputs.email.toLowerCase(),
      password1: inputs.senha,
      password2: inputs.confirmarSenha,
      razao_social: parceiroDados.razao_social,
      cnpj: cnpj,
      site: parceiroDados.site,
      telefone: parceiroDados.telefone,
      endereco: espacoDados,
      responsavel: { ...responsavelDados, cpf: cpf_responsavel },
    })
      .then((resp) => {
        setButtonState({ ...buttonState, step3: false });
        setStep("concluido");
      })
      .catch((e) => {
        if (Array.isArray(e.response.data)) {
          setModal({
            text: e.response.data[0],
            alertType: "fail",
            active: true,
          });
          setButtonState({ ...buttonState, step1: false });
          return;
        }
        setModal({
          text: e.response.data.data,
          alertType: "fail",
          active: true,
        });
        setButtonState({ ...buttonState, step1: false });
      });
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full flex flex-col lg:justify-center lg:px-12">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "email" && (
        <div className="flex flex-col items-start justify-start h-full w-full relative">
          <p className="textos regular pb-4 self-center">Etapa 1 de 3</p>
          <p className="body medium text-primaria4 pb-4">Cadastre seu email</p>
          <p className="button regular text-secundaria4">
            Ele será usado para acessar a administração do seu coworking.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-8 flex-wrap"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <Input
              background="terciaria2"
              placer="E-mail"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <Input
              background="terciaria2"
              placer="Confirmar E-mail"
              name="confirmarEmail"
              value={inputs.confirmarEmail}
              onChange={(e) =>
                setInputs({ ...inputs, confirmarEmail: e.target.value })
              }
            />
            <div className="w-full flex items-center justify-center px-6 mt-4 h-10">
              <ButtonDark legend="CONFIRMAR" loading={buttonState.step1} />
            </div>
          </form>
        </div>
      )}
      {step === "validando" && (
        <div className="flex flex-col items-start justify-start h-full w-full relative">
          <p className="textos regular pb-4 self-center">Etapa 2 de 3</p>
          <p className="body medium text-primaria4 pb-4">Validar e-mail</p>
          <p className="button regular text-secundaria4">
            Enviamos o cógido para {inputs.email} Se não encontrá-lo, veja a
            caixa de spam.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-8 flex-wrap"
            onSubmit={(e) => confirmToken(e)}
          >
            <Input
              background="terciaria2"
              placer="Token de validação"
              name="token"
              value={inputs.token}
              onChange={(e) => setInputs({ ...inputs, token: e.target.value })}
            />
            <div className="w-full flex items-center justify-center px-6 mt-4 h-10">
              <ButtonDark legend="CONFIRMAR" loading={buttonState.step2} />
            </div>
            <p
              className="button bold self-center text-center mt-4 cursor-pointer"
              onClick={() => setStep("email")}
            >
              Voltar
            </p>
          </form>
        </div>
      )}
      {step === "password" && (
        <div className="flex flex-col items-start justify-start h-full w-full relative">
          <p className="textos regular pb-4 self-center">Etapa 3 de 3</p>
          <p className="body medium text-primaria4 pb-4">Criar senha</p>
          <p className="button regular text-secundaria4">
            Digite uma senha, ela será usada para realizar o seu login.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-8 flex-wrap"
            onSubmit={(e) => setPassword(e)}
          >
            <div className="h-12 lg:w-9/12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Senha"
                name="senha"
                value={inputs.senha}
                onChange={(e) =>
                  setInputs({ ...inputs, senha: e.target.value })
                }
                pass={pass}
                setPass={setPass}
              />
            </div>
            <div className="h-12 lg:w-9/12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Confirmar Senha"
                name="confirmarSenha"
                value={inputs.confirmarSenha}
                pass={pass}
                setPass={setPass}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    confirmarSenha: e.target.value,
                  })
                }
              />
            </div>
            <div className="lg:w-11/12 w-full flex flex-row gap-4 mt-4">
              <input
                type="checkbox"
                className="w-[10px]"
                onChange={() =>
                  setInputs({
                    ...inputs,
                    termos: !inputs.termos,
                  })
                }
              ></input>
              <p className="button regular text-secundaria4">
                aceito os termos de{" "}
                <a
                  href={`https://onworkingpass.com/termos`}
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  política
                </a>{" "}
                e{" "}
                <a
                  href={`https://onworkingpass.com/privacidade`}
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  privacidade
                </a>
              </p>
            </div>
            <div
              className={
                inputs.termos
                  ? "w-full flex items-center justify-center px-6 mt-2 h-10"
                  : "w-full flex items-center justify-center px-6 mt-2 h-10 opacity-20 pointer-events-none"
              }
            >
              <ButtonDark legend="CONFIRMAR" loading={buttonState.step3} />
            </div>
          </form>
        </div>
      )}
      {!complete.password && step === "concluido" && (
        <div className="flex items-center justify-center flex-col gap-10">
          <img
            src={checkValidate}
            alt="E-mail validado com sucesso"
            className="w-[120px]"
          />
          <p className="body medium text-primaria4 text-center">
            Seu pré cadastro foi realizado com sucesso!
          </p>
          <p className="textos caption text-justify text-detalhes1">
            Iremos analisar e caso haja necessidade a equipe da ON entrará em
            contato para solicitar informações adicionais, para acompanhar a
            liberação, acesse sua conta pré cadastrada.
          </p>
          <div className="w-10/12 flex items-center justify-center h-11">
            <ButtonDark
              legend="ACESSAR CONTA"
              func={() => navigate("/login")}
              id="finaliza-preenchimento-dados"
            />
          </div>
        </div>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ParceiroPassword;
