import React from "react";
import ButtonLight from "../components/ButtonLight";
import profile from "../assets/profile-login.svg";
import padlock from "../assets/padlock.svg";
import Input from "../components/Input";
import ButtonDark from "../components/ButtonDark";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../hooks/AuthContext";
import ModalAlert from "../components/ModalAlert";
import LoggedHeaderContext from "../hooks/LoogedHeaderContext";
import imagemLogin from "../assets/Image-esquerdo.svg";
import { useNavigate } from "react-router-dom";
import closeEye from "../assets/closeEye.svg";
import openedEye from "../assets/openedEye.svg";

const Login = () => {
  const [pass, setPass] = useState(true);
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Login");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  return (
    <section className="p-6 bg-terciariafundo pb-20 lg:flex lg:items-center lg:justify-center">
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
      <div className=" lg:border-primaria5 lg:flex-row lg:flex lg:w-10/12 lg:rounded-xl lg:mt-8">
        <div className="lg:w-6/12 lg:bg-primaria5 lg:rounded-l-lg pr-4 gc-login-img hidden lg:flex">
          <img
            src={imagemLogin}
            alt="Imagem de um escritório com pessoas trabalhando"
          />{" "}
        </div>
        <div className="lg:w-6/12 p-4 lg:flex lg:items-center lg:justify-center lg:flex-col bg-terciaria2 lg:rounded-r-xl">
          <form
            className="w-full border-primaria5 lg:border-0 border-[3px] py-8 rounded-xl mt-6 px-4 lg:px-12 flex flex-row flex-wrap items-center justify-center lg:w-10/12 lg:mt-2"
            onSubmit={(e) => loginUser(e, setModal)}
          >
            <p className="subtitle medium text-secundaria5 relative left-0">
              Faça seu login!
            </p>
            <div className="w-full px-4 mt-6 gap-4 flex flex-col flex-wrap">
              <div className="w-full relative flex flex-row items-center justify-center gap-2">
                <img
                  src={profile}
                  alt="Login icone"
                  className="icon-shadow-login"
                />
                <Input
                  background="secundaria1"
                  placer="Email"
                  name="login"
                ></Input>
              </div>
              <div className="w-full relative flex flex-row items-center justify-center gap-2">
                <img
                  src={padlock}
                  alt="Login icone"
                  className="icon-shadow-login"
                />
                <div className="relative w-full shadow-3xl h-9 rounded-lg">
                  <input
                    className="w-full justify-center rounded-lg h-full pl-5 items-center placeholder:text-secundaria3 text-secundaria3 bg-terciariafundo"
                    placeholder="Senha"
                    type={pass ? "password" : "text"}
                    name="password"
                  ></input>
                  <img
                    src={pass ? openedEye : closeEye}
                    alt=""
                    className="absolute w-6 h-auto top-[9px] right-5 cursor-pointer opacity-50"
                    onClick={() => setPass(!pass)}
                  />
                </div>
              </div>
              <div className="w-full relative flex flex-row items-center justify-start gap-2">
                <input type="checkbox" className="ml-2 rounded-full" />
                <p className="body medium text-secundaria3 opacity-80">
                  Matenha-me conectado
                </p>
              </div>
            </div>
            <div className="w-11/12 mt-8 h-11">
              <ButtonDark legend="ENTRAR" shadow="buttonDark" />
            </div>
            <div className="w-11/12 mt-4 h-11">
              <ButtonLight
                legend="CRIAR CONTA"
                onClick={(e) => navigate("/cadastro")}
              />
            </div>
            <div className="divisao-lp-line w-9/12 my-8" />
            <p className="button regular text-detalhes3 cursor-pointer">
              Esqueceu sua senha?
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
