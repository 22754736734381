import React, { useContext, useEffect, useState } from "react";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";
import contato from "../../assets/telaContatoImg.jpg";
import FormContato from "../../services/LadingPage/FormContato";
import ModalAlert from "../../components/ModalAlert";

const Contato = () => {
  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [inputs, setInputs] = useState({
    nome: "",
    email: "",
    telefone: "",
    assunto: "",
    descricao: "",
  });

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Contato");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    if (
      inputs.nome &&
      inputs.email &&
      inputs.telefone &&
      inputs.assunto &&
      inputs.descricao
    ) {
      FormContato(inputs)
        .then((resp) => {
          setModal({
            text: "Formulário enviado com sucesso!",
            alertType: "ok",
            active: true,
          });
          setInputs({
            nome: "",
            email: "",
            telefone: "",
            assunto: "",
            descricao: "",
          });
        })
        .catch((e) => {
          setModal({
            text: e.response.data.message,
            alertType: "fail",
            active: true,
          });
        });
    } else {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
    }
  };

  return (
    <section className="bg-terciaria2 py-8 w-full flex flex-col xl:flex-row items-center justify-center xl:px-[180px] px-[15px]">
      <div className="xl:w-6/12 w-full max-w-[650px] xl:px-0 xl:max-w-none xl:pr-8 flex flex-col xl:h-[720px] h-auto justify-between mt-4 xl:mt-0">
        <p className="argentum-medium text-secundaria5">Fale conosco!</p>
        <p className="argentum-medium-16 text-secundaria5 mt-4 xl:mt-0">
          Encontre o espaço de coworking perfeito para você! Entre em contato
          conosco agora mesmo e encontre o local de trabalho ideal para suas
          necessidades
        </p>
        <img
          src={contato}
          alt="imagem de contato"
          className="w-full h-[540px] rounded-md object-cover hidden xl:flex"
        />
      </div>
      <div className="w-full xl:w-6/12 xl:max-w-none max-w-[650px] xl:pl-8 mt-8 xl:mt-0">
        <form
          className="w-full xl:px-12 px-4 xl:h-[720px] h-auto py-8 xl:py-0 rounded-md shadow-shadowContato xl:pt-16"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex flex-col items-center justify-center w-full gap-[32px]">
            <input
              value={inputs.nome}
              onChange={(e) => setInputs({ ...inputs, nome: e.target.value })}
              className="w-full bg-detalhes7 rounded-sm pl-6 h-[56px]"
              placeholder="Nome"
            />
            <input
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              className="w-full bg-detalhes7 rounded-sm pl-6 h-[56px]"
              placeholder="Email"
            />
            <input
              value={inputs.telefone}
              onChange={(e) =>
                setInputs({ ...inputs, telefone: e.target.value })
              }
              className="w-full bg-detalhes7 rounded-sm pl-6 h-[56px]"
              placeholder="Telefone"
              type="number"
            />
          </div>
          <p className="argentum-regular py-6">Assunto:</p>
          <div className="flex flex-row justify-between">
            <button
              className={
                inputs.assunto === "Reclamação"
                  ? "text-detalhes1 border border-primaria4 px-2 py-1 rounded-sm"
                  : "text-secundaria3 px-2 py-1 border border-terciaria2"
              }
              onClick={() => setInputs({ ...inputs, assunto: "Reclamação" })}
            >
              Reclamação
            </button>
            <button
              className={
                inputs.assunto === "Sugestão"
                  ? "text-detalhes1 border border-primaria4 px-2 py-1 rounded-sm"
                  : "text-secundaria3 px-2 py-1 border border-terciaria2"
              }
              onClick={() => setInputs({ ...inputs, assunto: "Sugestão" })}
            >
              Sugestão
            </button>
            <button
              className={
                inputs.assunto === "Dúvidas"
                  ? "text-detalhes1 border border-primaria4 px-2 py-1 rounded-sm"
                  : "text-secundaria3 px-2 py-1 border border-terciaria2"
              }
              onClick={() => setInputs({ ...inputs, assunto: "Dúvidas" })}
            >
              Dúvidas
            </button>
            <button
              className={
                inputs.assunto === "Outros"
                  ? "text-detalhes1 border border-primaria4 px-2 py-1 rounded-sm"
                  : "text-secundaria3 px-2 py-1 border border-terciaria2"
              }
              onClick={() => setInputs({ ...inputs, assunto: "Outros" })}
            >
              Outros
            </button>
          </div>
          <textarea
            className="w-full h-[150px] bg-detalhes7 mt-8 pl-5 pt-3 resize-none"
            placeholder="Descrição"
            onChange={(e) =>
              setInputs({ ...inputs, descricao: e.target.value })
            }
            value={inputs.descricao}
          />
          <button
            className="w-full h-[60px] mt-6 bg-primaria4 rounded-md"
            onClick={(e) => submitForm(e)}
          >
            <p className="argentum-normal text-terciaria2">ENVIAR</p>
          </button>
        </form>
      </div>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default Contato;
