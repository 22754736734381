import React from "react";
import LogoOn from "../assets/logo-on.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import mail from "../assets/mail.svg";

const FooterCommingSoon = () => {
  return (
    <section>
      <div className="absolute bottom-0 h-auto py-6 px-4 w-full footer-bg lg:hidden flex flex-row flex-wrap justify-around align-middle gap-4 transition-all">
        <img
          alt="Logo Onworking"
          src={LogoOn}
          className="w-36 h-auto object-center pb-2"
        />
        <div className="flex flex-col flex-wrap justify-around align-middle gap-1">
          <div className="flex flex-row justify-between gap-4">
            <img
              src={instagram}
              alt="Link para o instagram"
              className="w-7 h-auto"
            />
            <img
              src={twitter}
              alt="Link para o twitter"
              className="w-7 h-auto"
            />
            <img
              src={facebook}
              alt="Link para o facebook"
              className="w-7 h-auto"
            />
            <img src={mail} alt="Link para o mail" className="w-7 h-auto" />
          </div>
          <p className="subtitle bold text-primaria4 text-center">
            (11) 0.0000-0000
          </p>
        </div>
        <div className="w-full h-fit text-center">
          <p className="subtitle regular text-secundaria3 w-full h-auto">
            © Copyright 2022 - Todos os direitos reservados.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FooterCommingSoon;
