import { useState, useEffect } from "react";
import "./CountdownTimer.css";
import { getRemainingTimeUntilMsTimestamp } from "../utils/CountDownUtils";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  return (
    <div className="countdown-timer">
      <div className="columns-upcomming-div">
        <h2 className="upcomming-num">{remainingTime.days}</h2>
        <p className="upcomming-legend">Dias</p>
      </div>
      <p className="upcomming-num">:</p>
      <div className="columns-upcomming-div">
        <h2 className="upcomming-num">{remainingTime.hours}</h2>
        <p className="upcomming-legend">Horas</p>
      </div>
      <p className="upcomming-num">:</p>
      <div className="columns-upcomming-div">
        <h2 className="upcomming-num">{remainingTime.minutes}</h2>
        <p className="upcomming-legend">Minutos</p>
      </div>
      <p className="upcomming-num">:</p>
      <div className="columns-upcomming-div">
        <h2 className="upcomming-num">{remainingTime.seconds}</h2>
        <p className="upcomming-legend">Segundos</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
