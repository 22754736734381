import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import maskDoc from "../../utils/maskDoc";
import ModalAlert from "../ModalAlert";
import validateCPF from "../../utils/validateCPF";

const ParceiroResponsavel = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    nome: "",
    cpf: "",
    email: "",
    telefone: "",
  });

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    let cpf = validateCPF(e.target.cpf.value);
    if (
      inputs.nome === "" ||
      inputs.cpf === "" ||
      inputs.email === "" ||
      inputs.telefone === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!cpf) {
      setModal({
        text: "CPF inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      funcChangeStep("password", "", "responsavel");
      localStorage.setItem(
        "parceiro-responsavel-register",
        JSON.stringify(inputs)
      );
    }
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      <p className="textos regular pb-4 self-center">Etapa 1 de 1</p>
      <p className="body medium text-primaria4">Dados do responsável</p>
      <form
        className="w-full px-1 items-center justify-center gap-6 flex flex-col mt-8"
        onSubmit={(e) => funcConfirmBtn(e)}
      >
        <Input
          background="terciaria2"
          placer="Nome completo"
          name="nome"
          value={inputs.nome}
          onChange={(e) => setInputs({ ...inputs, nome: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="CPF"
          name="cpf"
          value={inputs.cpf}
          onChange={(e) =>
            setInputs({ ...inputs, cpf: maskDoc(e.target.value, "cpf") })
          }
        />
        <Input
          background="terciaria2"
          placer="E-mail"
          name="email"
          value={inputs.email}
          onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="Telefone"
          name="telefone"
          value={inputs.telefone}
          onChange={(e) =>
            setInputs({
              ...inputs,
              telefone: maskDoc(e.target.value, "telefone"),
            })
          }
        />
        <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
          <ButtonDark legend="CONFIRMAR" />
        </div>
      </form>
      {!complete.responsavel && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("responsavel", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ParceiroResponsavel;
