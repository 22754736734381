import React from "react";
import plus from "../assets/plus.svg";
import minus from "../assets/minus.svg";

const ButtonCounter = (props) => {
  let { dados, setDados } = props;

  const setVisits = (action) => {
    if (action === "plus") {
      setDados(dados + 1);
      return;
    } else if (action === "minus") {
      if (dados === 0) return;
      setDados(dados - 1);
    }
  };

  const handleChange = (changeV) => {
    const v = parseInt(changeV);
    if (typeof v !== "number") {
      setDados(0);
      return;
    }
    if (isNaN(v)) {
      setDados(0);
      return;
    }
    setDados(v);
  };

  return (
    <div className="flex gap-2 flex-col">
      <div className="flex gap-2 flex-row">
        <div
          className="h-8 w-14 bg-terciariafundo shadow-counterBtn rounded-lg cursor-pointer flex items-center justify-center"
          onClick={() => setVisits("minus")}
        >
          <img src={minus} alt="Diminuir visitas" />
        </div>
        <input
          className="h-8 w-14 bg-terciariafundo shadow-counterView rounded-lg flex items-center justify-center text-center textos medium text-secundaria6"
          value={dados}
          onChange={(e) => handleChange(e.target.value)}
        ></input>
        <div
          className="h-8 w-14 bg-terciariafundo shadow-counterBtn rounded-lg cursor-pointer flex items-center justify-center"
          onClick={() => setVisits("plus")}
        >
          <img src={plus} alt="Aumentar visitas" />
        </div>
      </div>
      {/* <div className="mt-4">
        <ButtonLight
          legend="CONFIRMAR"
          onClick={() => setDados({ ...dados, espacos: valorChange })}
        />
      </div> */}
    </div>
  );
};

export default ButtonCounter;
