import axiosInstance from "../AxiosInstance";

const GetSingleColab = async (email) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`empresas/dados-colaborador/?email=${email}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({
          message: error.response.data,
          status: 400,
        });
      });
  });
};

export default GetSingleColab;
