import React, { useEffect, useState } from "react";
import check from "../../assets/checkH.svg";
import ButtonLight from "../../components/ButtonLight";
import ConfirmarSolicitacao from "../../services/Parceiro/ConfirmarSolicitacao";
import ListarSolicitacoes from "../../services/Parceiro/ListarSolicitacoes";
import getParamsFromUrl from "../../utils/getParamsFromUrl";

const Solicitacoes = () => {
  const [solicitacoes, setSolicitacoes] = useState();
  const [load, setLoad] = useState(false);
  const [nextPage, setNextPage] = useState("");

  const addItens = async (arr, itens) => {
    return new Promise(function (resolve, reject) {
      itens.map((iten) => {
        let newDate = new Date(iten.hora_inicio);
        iten.hora_inicio = newDate;
        let newDate2 = new Date(iten.hora_fim);
        iten.hora_fim = newDate2;
        arr.push(iten);
        return true;
      });
      resolve(arr);
    });
  };

  useEffect(() => {
    getSolicitacoes();
    // eslint-disable-next-line
  }, []);

  const handleSeeMore = () => {
    ListarSolicitacoes(nextPage)
      .then((resp) => {
        if (resp?.data?.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }
        let resultados = resp.data.results;
        let newArr = solicitacoes;
        addItens(newArr, resultados).then((resp) => {
          setSolicitacoes(resp);
          setNextPage(resp?.data?.next);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSolicitacoes = () => {
    ListarSolicitacoes()
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        if (resp.data.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }

        addItens(newArr, resultados).then((resp) => {
          setSolicitacoes(resp);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleConfirm = (uuid) => {
    ConfirmarSolicitacao({
      reserva_uuid: uuid,
      confirmar: true,
    })
      .then((resp) => {
        getSolicitacoes();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleRefuse = (uuid) => {
    ConfirmarSolicitacao({
      reserva_uuid: uuid,
      confirmar: false,
    })
      .then((resp) => {
        getSolicitacoes();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <section className="p-6 lg:p-4 flex items-center justify-start bg-terciariafundo min-h-screen flex-col">
      <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-4 w-full lg:w-10/12 my-4 flex items-center justify-center flex-col">
        <div className="flex flex-row gap-2 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25 w-full">
          <img src={check} alt="check-in" />
          <p className="textos semibold text-secundaria4 pt-1">SOLICITAÇÕES</p>
        </div>
        <div className="lg:py-8 py-4 flex lg:flex-row flex-col items-center justify-center lg:flex-wrap gap-8 w-full">
          {load &&
            solicitacoes.map((solicitacao) => {
              return (
                <div
                  key={solicitacao.uuid}
                  className="bg-terciaria2 gap-4 lg:border border-secundaria2 rounded-t-lg rounded-b-lg border flex flex-col py-4 lg:w-5/12 lg:px-0 px-4"
                >
                  <p className="textos semibold text-secundaria4 lg:pl-6">
                    Dados da solicitação
                  </p>
                  <div className="w-full flex items-center justify-center flex-col gap-4 lg:flex-row lg:flex-wrap">
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {solicitacao.nome_colaborador}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {solicitacao.email}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12">
                      <p className="textos regular text-secundaria3">
                        {solicitacao.data_agendamento}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full lg:w-5/12">
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {solicitacao.hora_inicio.getHours()}:
                          {solicitacao.hora_inicio.getMinutes()}h
                        </p>
                      </div>
                      <p className="textos regular text-secundaria3">às</p>
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {solicitacao.hora_fim.getHours()}:
                          {solicitacao.hora_fim.getMinutes()}h
                        </p>
                      </div>
                    </div>
                    <div className="w-10/12 lg:w-6/12 mt-8 flex items-center flex-col gap-4">
                      <ButtonLight
                        legend="CONFIRMAR"
                        onClick={() => handleConfirm(solicitacao.uuid)}
                      />
                      <p
                        className="textos semibold text-secundaria4 cursor-pointer"
                        onClick={() => handleRefuse(solicitacao.uuid)}
                      >
                        Recusar
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          {load && solicitacoes.length === 0 && (
            <p className="textos semibold text-secundaria4 mt-8">
              Não há solicitações pendentes.
            </p>
          )}
        </div>
        {nextPage && (
          <p
            className="textos semibold text-secundaria4 mt-2"
            onClick={() => handleSeeMore()}
          >
            Ver mais
          </p>
        )}
      </div>
    </section>
  );
};

export default Solicitacoes;
