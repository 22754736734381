import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState, useEffect } from "react";
import maskDoc from "../../utils/maskDoc";
import ModalAlert from "../ModalAlert";
import { FileUploader } from "react-drag-drop-files";
import upload from "../../assets/upload.svg";
import ButtonLight from "../../components/ButtonLight";
import ParceiroDoc from "../../services/Register/ParceiroDoc";
import InputEdit from "../InputEdit";
import axios from "axios";

const fileTypes = [
  "JPG",
  "PNG",
  "GIF",
  "PDF",
  "JPEG",
  "MP4",
  "MOV",
  "WMV",
  "AVI",
];

const ParceiroEspaco = (props) => {
  let { funcChangeStep, complete } = props;
  const [file, setFile] = useState({
    espaco_file: null,
    espaco_status: "vazio",
    tabela_file: null,
    tabela_status: "vazio",
  });

  const [buttonState, setButtonState] = useState({
    step2: false,
    step3: false,
  });

  const handleChangeEspaco = (arq) => {
    setFile({ ...file, espaco_file: arq, espaco_status: "adicionado" });
    return;
  };

  const handleChangeTabela = (arq) => {
    setFile({ ...file, tabela_file: arq, tabela_status: "adicionado" });
    return;
  };

  const [inputsStatus, setInputsStatus] = useState({
    logradouro: false,
    numero: false,
    cep: false,
    bairro: false,
    municipio: false,
    estado: false,
  });

  // eslint-disable-next-line
  const [dados, setDados] = useState(
    localStorage.getItem("parceiro-information-register")
      ? JSON.parse(localStorage.getItem("parceiro-information-register"))
      : null
  );

  useEffect(() => {
    setDados(
      localStorage.getItem("parceiro-information-register")
        ? JSON.parse(localStorage.getItem("parceiro-information-register"))
        : null
    );
  }, [props.complete]);

  const [inputs, setInputs] = useState({
    logradouro: "",
    numero: "",
    cep: "",
    bairro: "",
    municipio: "",
    estado: "",
  });

  const [step, setStep] = useState("dados");

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    if (
      inputs.logradouro === "" ||
      inputs.numero === "" ||
      inputs.cep === "" ||
      inputs.bairro === "" ||
      inputs.municipio === "" ||
      inputs.estado === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (e.target.cep.value.length !== 8) {
      setModal({
        text: "CEP inválido",
        alertType: "fail",
        active: true,
      });
      return;
    }

    localStorage.setItem("parceiro-espaco-register", JSON.stringify(inputs));
    setStep("espaco");
  };

  const handleEdit = (e) => {
    e.preventDefault();
    let cep = inputs.cep;
    cep = cep.toString();
    if (
      inputs.logradouro === "" ||
      inputs.numero === "" ||
      inputs.cep === "" ||
      inputs.bairro === "" ||
      inputs.municipio === "" ||
      inputs.estado === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (cep.length !== 8) {
      setModal({
        text: "CEP inválido",
        alertType: "fail",
        active: true,
      });
      return;
    }

    localStorage.setItem("parceiro-espaco-register", JSON.stringify(inputs));

    if (file.espaco_file) {
      handleEditDoc("espaco")
        .then((resp) => {
          if (file.tabela_file) {
            handleEditDoc("tabela-preco")
              .then((resp) => {
                setModal({
                  text: "Alterações registradas com sucesso!",
                  alertType: "ok",
                  active: true,
                });
                localStorage.setItem(
                  "parceiro-espaco-register",
                  JSON.stringify(inputs)
                );
                setTimeout(() => {
                  if (complete.responsavel) {
                    funcChangeStep("password", "", "espaco");
                    return;
                  }
                  funcChangeStep("responsavel", "", "espaco");
                  return;
                }, 1000);
              })
              .catch((e) =>
                setModal({
                  text: "Não foi possível enviar o seu arquivo de Tabela de Preços, tente outro",
                  alertType: "fail",
                  active: true,
                })
              );
            return;
          }
          localStorage.setItem(
            "parceiro-espaco-register",
            JSON.stringify(inputs)
          );
          if (complete.responsavel) {
            funcChangeStep("password", "", "espaco");
            return;
          }
          funcChangeStep("responsavel", "", "espaco");
          return;
        })
        .catch((e) => {
          setModal({
            text: "Não foi possível enviar o seu arquivo de Apresentação, tente outro",
            alertType: "fail",
            active: true,
          });
          return;
        });
    }
    return;
  };

  const handleEditDoc = async (tipo) => {
    return new Promise(function (resolve, reject) {
      const formData = new FormData();
      if (tipo === "espaco") {
        let cnpj = dados.cnpj;
        cnpj = cnpj.replace(/[^\d]+/g, "");
        formData.append("file", file.espaco_file);
        formData.append("tipo_documento", "espaco");
        formData.append("cnpj", cnpj);
        ParceiroDoc(formData)
          .then((resp) => {
            setStep("tabela-preco");
            resolve("ok");
            return;
          })
          .catch((e) => {
            setFile({ ...file, cnpj_status: "falhou" });
            reject(e);
            return;
          });
        return;
      }

      if (tipo === "tabela-preco") {
        if (!file.tabela_file) {
          resolve("ok");
        }
        let cnpj = dados.cnpj;
        cnpj = cnpj.replace(/[^\d]+/g, "");
        formData.append("file", file.tabela_file);
        formData.append("tipo_documento", "tabela-preco");
        formData.append("cnpj", cnpj);
        ParceiroDoc(formData)
          .then((resp) => {
            resolve("ok");
            return;
          })
          .catch((e) => {
            setFile({ ...file, contrato_status: "falhou" });
            reject(e);
            return;
          });
      }
    });
  };

  const setCep = (e) => {
    if (inputs?.cep?.length > 8) {
      return;
    }
    setInputs({
      ...inputs,
      cep: maskDoc(e.target.value, "cep"),
    });
  };

  useEffect(() => {
    if (inputs?.cep?.length === 8) {
      axios.get(`https://viacep.com.br/ws/${inputs.cep}/json/`).then((resp) => {
        setInputs({
          logradouro: resp.data.logradouro,
          bairro: resp.data.bairro,
          municipio: resp.data.localidade,
          estado: resp.data.uf,
          numero: "",
          cep: inputs.cep,
        });
      });
    }

    if (inputs?.cep?.length === 0) {
      setInputs({
        logradouro: "",
        numero: "",
        cep: "",
        bairro: "",
        municipio: "",
        estado: "",
      });
    }
  }, [inputs.cep]);

  const handleSendDoc = (tipo) => {
    const formData = new FormData();
    if (tipo === "espaco") {
      setButtonState({ ...buttonState, step2: true });
      let cnpj = dados.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");
      formData.append("file", file.espaco_file);
      formData.append("tipo_documento", "espaco");
      formData.append("cnpj", cnpj);
      ParceiroDoc(formData)
        .then((resp) => {
          setModal({
            text: "Documento enviado com sucesso!",
            alertType: "ok",
            active: true,
          });
          setButtonState({ ...buttonState, step2: false });
          setStep("tabela-preco");
          return;
        })
        .catch((e) => {
          setFile({ ...file, espaco_status: "falhou" });
          return;
        });
      return;
    }

    if (tipo === "tabela-preco") {
      if (!file.tabela_file) {
        funcChangeStep("password", "", "espaco");
        setButtonState({ ...buttonState, step3: false });
        return;
      }
      setButtonState({ ...buttonState, step3: true });
      let cnpj = dados.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");
      formData.append("file", file.tabela_file);
      formData.append("tipo_documento", "tabela-preco");
      formData.append("cnpj", cnpj);
      ParceiroDoc(formData)
        .then((resp) => {
          setModal({
            text: "Documento enviado com sucesso!",
            alertType: "ok",
            active: true,
          });
          setTimeout(() => {
            if (complete.responsavel) {
              funcChangeStep("password", "", "espaco");
              setButtonState({ ...buttonState, step3: false });
              return;
            }
            funcChangeStep("responsavel", "", "espaco");
            setButtonState({ ...buttonState, step3: false });
            return;
          }, 1000);
          return;
        })
        .catch((e) => {
          setFile({ ...file, tabela_status: "falhou" });
          return;
        });
    } else {
      if (complete.responsavel) {
        funcChangeStep("password", "", "espaco");
        return;
      }
      funcChangeStep("responsavel", "", "espaco");
      return;
    }
  };

  // const handleFinish = () => {
  //   if (complete.telefone) {
  //     funcChangeStep("password", "", "data");
  //     setTimeout(() => {
  //       setStep("preenchendo");
  //     }, 200);

  //     return;
  //   } else {
  //     funcChangeStep("telefone", "", "data");
  //     setTimeout(() => {
  //       setStep("preenchendo");
  //     }, 200);
  //   }
  //   return;
  // };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {!complete.responsavel && (
        <div className="w-full h-full flex items-start flex-col justify-start">
          {step === "dados" && (
            <p className="textos regular pb-4 self-center">Etapa 1 de 3</p>
          )}
          {step === "espaco" && (
            <p className="textos regular pb-4 self-center">Etapa 2 de 3</p>
          )}
          {step === "tabela-preco" && (
            <p className="textos regular pb-4 self-center">Etapa 3 de 3</p>
          )}
          {step === "dados" && (
            <>
              <p className="body medium text-primaria4">
                Dados da razão social
              </p>
              <form
                className="w-full px-1 items-center justify-center flex flex-row flex-wrap mt-8"
                onSubmit={(e) => funcConfirmBtn(e)}
              >
                <div className="mb-5 w-6/12">
                  <Input
                    background="terciaria2"
                    placer="CEP"
                    name="cep"
                    value={inputs.cep}
                    onChange={(e) => setCep(e)}
                    tipo="number"
                  />
                </div>
                <div className="mb-5 w-6/12 pl-4 self-start">
                  <Input
                    background="terciaria2"
                    placer="Número"
                    tipo="number"
                    name="numero"
                    value={inputs.numero}
                    onChange={(e) =>
                      setInputs({
                        ...inputs,
                        numero: e.target.value,
                      })
                    }
                    maxLength={18}
                  />
                </div>

                <div className="mb-5 w-full">
                  <Input
                    background="terciaria2"
                    placer="Logradouro"
                    name="logradouro"
                    value={inputs.logradouro}
                    onChange={(e) =>
                      setInputs({ ...inputs, logradouro: e.target.value })
                    }
                  />
                </div>

                <div className="mb-5 w-full">
                  <Input
                    background="terciaria2"
                    placer="Bairro"
                    name="bairro"
                    value={inputs.bairro}
                    onChange={(e) =>
                      setInputs({ ...inputs, bairro: e.target.value })
                    }
                  />
                </div>
                <div className="mb-5 w-full">
                  <Input
                    background="terciaria2"
                    placer="Município"
                    name="municipio"
                    value={inputs.municipio}
                    onChange={(e) =>
                      setInputs({ ...inputs, municipio: e.target.value })
                    }
                  />
                </div>
                <div className="mb-5 w-full">
                  <Input
                    background="terciaria2"
                    placer="Estado"
                    name="estado"
                    value={inputs.estado}
                    onChange={(e) =>
                      setInputs({ ...inputs, estado: e.target.value })
                    }
                  />
                </div>
                <div className="w-full flex items-center justify-center px-6 h-10 mt-2">
                  <ButtonDark legend="CONFIRMAR" />
                </div>
              </form>
            </>
          )}
          {step === "espaco" && (
            <div className="flex items-center justify-center flex-col gap-2">
              <h4 className="bold text-primaria4">
                Nos envie uma foto ou vídeo do seu espaço
              </h4>
              <p className="textos regular text-secundaria3 self-start py-2">
                Escolha uma imagem ou pequeno video que melhor defina seu
                negócio. Podendo ser: Da estrutura, fachada, sala, etc.
              </p>
              <div className="w-full mt-4">
                <FileUploader
                  handleChange={handleChangeEspaco}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, cnpj_status: "falhou" })
                  }
                  maxSize={150}
                  children={
                    (file.espaco_status === "vazio" && (
                      <div className="w-full py-16 h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-4 text-center cursor-pointer">
                        <img src={upload} alt="Upload de arquivos" />
                        <div className="flex gap-2 text-center mt-4">
                          <span className="text-primaria4 textos semibold">
                            Escolha um ficheiro
                          </span>{" "}
                          <span className="textos regular">ou</span>
                          <span className="textos semibold"> arraste aqui</span>
                        </div>
                        <p className="button regular text-secundaria3 mt-4">
                          Limite de download 10MB
                        </p>
                      </div>
                    )) ||
                    (file.espaco_status === "adicionado" && (
                      <div className="h-auto cursor-pointer">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center">
                          <p className="button regular text-primaria5 mt-4">
                            {file.espaco_file.name}
                          </p>
                          <p className="button regular text-secundaria3 mt-4">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.espaco_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
                {file.espaco_status === "adicionado" && (
                  <div className="w-full mt-8">
                    <ButtonLight
                      legend="CONTINUAR"
                      onClick={() => handleSendDoc("espaco")}
                      loading={buttonState.step2}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {step === "tabela-preco" && !complete.espaco && (
            <div className="flex items-center justify-center flex-col gap-2">
              <h4 className="bold text-primaria4">
                Nos envie uma imagem da sua tabela de preços. (opcional)
              </h4>
              <p className="textos regular text-secundaria3 self-start py-2">
                Demonstre os valores utilizados em seu espaço.
              </p>
              <div className="w-full">
                <FileUploader
                  handleChange={handleChangeTabela}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, tabela_status: "falhou" })
                  }
                  maxSize={150}
                  children={
                    (file.tabela_status === "vazio" && (
                      <div className="w-full py-16 h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-4 text-center cursor-pointer">
                        <img src={upload} alt="Upload de arquivos" />
                        <div className="flex gap-2 text-center mt-4">
                          <span className="text-primaria4 textos semibold">
                            Escolha um ficheiro
                          </span>{" "}
                          <span className="textos regular">ou</span>
                          <span className="textos semibold"> arraste aqui</span>
                        </div>
                        <p className="button regular text-secundaria3 mt-4">
                          Limite de download 10MB
                        </p>
                      </div>
                    )) ||
                    (file.tabela_status === "adicionado" && (
                      <div className="h-auto cursor-pointer">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center">
                          <p className="button regular text-primaria5 mt-4">
                            {file.tabela_file.name}
                          </p>
                          <p className="button regular text-secundaria3 mt-4">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.tabela_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
                <div className="w-full mt-8">
                  <ButtonLight
                    legend="CONTINUAR"
                    onClick={() => handleSendDoc("tabela-preco")}
                    loading={buttonState.step3}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {complete.espaco && (
        <form onSubmit={(e) => handleEdit(e)}>
          <p className="body bold text-primaria4 mt-4">Editar dados</p>
          <div className="flex w-full flex-wrap gap-3 pt-6 justify-between overflow-hidden">
            <div className="w-full overflow-hidden">
              <InputEdit
                edit={inputsStatus.logradouro}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                name="logradouro"
                placer="Logradouro"
                value={inputs.logradouro}
                onChange={(e) =>
                  setInputs({ ...inputs, logradouro: e.target.value })
                }
              />
            </div>
            <div className="w-full">
              <InputEdit
                edit={inputsStatus.numero}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                placer="Número"
                name="numero"
                value={inputs.numero}
                onChange={(e) =>
                  setInputs({ ...inputs, numero: e.target.value })
                }
              />
            </div>
            <div className="w-full lg:w-6/12">
              <InputEdit
                edit={inputsStatus.cep}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                placer="CEP"
                background="terciaria2"
                name="cep"
                value={inputs.cep}
                onChange={(e) => setInputs({ ...inputs, cep: e.target.value })}
              />
            </div>
            <div className="w-full lg:w-5/12">
              <InputEdit
                edit={inputsStatus.bairro}
                setInputs={setInputsStatus}
                placer="Bairro"
                inputs={inputsStatus}
                background="terciaria2"
                name="bairro"
                value={inputs.bairro}
                onChange={(e) =>
                  setInputs({ ...inputs, bairro: e.target.value })
                }
              />
            </div>
            <div className="w-full lg:w-6/12">
              <InputEdit
                edit={inputsStatus.municipio}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                placer="Município"
                background="terciaria2"
                name="municipio"
                value={inputs.municipio}
                onChange={(e) =>
                  setInputs({ ...inputs, municipio: e.target.value })
                }
              />
            </div>
            <div className="w-full lg:w-5/12">
              <InputEdit
                edit={inputsStatus.estado}
                setInputs={setInputsStatus}
                placer="Estado"
                inputs={inputsStatus}
                background="terciaria2"
                name="estado"
                value={inputs.estado}
                onChange={(e) =>
                  setInputs({ ...inputs, estado: e.target.value })
                }
              />
            </div>
            <div className="w-full flex items-center justify-between gap-4 flex-col py-4 mb-4 lg:flex-row overflow-hidden">
              <div className="w-full lg:w-6/12 overflow-hidden">
                <FileUploader
                  handleChange={handleChangeEspaco}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, cnpj_status: "falhou" })
                  }
                  maxSize={150}
                  children={
                    (file.espaco_status === "vazio" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    )) ||
                    (file.espaco_status === "adicionado" && (
                      <div className="h-auto cursor-pointer overflow-hidden">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center lg:h-28 lg:overflow-hidden lg:text-ellipsis">
                          <div className="lg:overflow-hidden lg:w-20">
                            <p className="button regular text-primaria5 mt-4 lg:mt-0 overflow-hidden text-ellipsis">
                              {file.espaco_file.name}
                            </p>
                          </div>
                          <p className="button regular text-secundaria3 mt-4 lg:hidden">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.espaco_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
              </div>
              <div className="w-full lg:w-6/12 overflow-hidden">
                <FileUploader
                  handleChange={handleChangeTabela}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, contrato_status: "falhou" })
                  }
                  maxSize={150}
                  children={
                    (file.tabela_status === "vazio" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer lg:h-28">
                        <p className="button regular text-secundaria3 mt-4">
                          Nenhum arquivo selecionado.
                        </p>
                      </div>
                    )) ||
                    (file.tabela_status === "adicionado" && (
                      <div className="h-auto cursor-pointer overflow-hidden">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center lg:h-28 overflow-hidden lg:text-ellipsis">
                          <div className="lg:overflow-hidden lg:w-20">
                            <p className="button regular text-primaria5 mt-4 lg:mt-0 overflow-hidden text-ellipsis">
                              {file.tabela_file.name}
                            </p>
                          </div>
                          <p className="button regular text-secundaria3 mt-4 lg:hidden">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.tabela_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer lg:h-28">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <ButtonLight legend="SALVAR" />
          </div>
        </form>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ParceiroEspaco;
