import React from "react";
import ButtonDark from "../ButtonDark";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import PasswordInput from "../passwordInput";
import RegisterEmpresa from "../../services/Register/RegisterEmpresa";
import checkValidate from "../../assets/check-validate.svg";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const EmpresaPassword = (props) => {
  let { funcChangeStep, complete } = props;

  const navigate = useNavigate();
  const [step, setStep] = useState("preenchendo");

  const [inputs, setInputs] = useState({
    senha: "",
    confirmarSenha: "",
  });

  const [pass, setPass] = useState(true);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();

    if (inputs.senha === "" || inputs.confirmarSenha === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    } else if (inputs.senha !== inputs.confirmarSenha) {
      setModal({
        text: "As senhas não conferem!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      const telefoneEmpresa = localStorage.getItem("empresa-telefone")
        ? JSON.parse(localStorage.getItem("empresa-telefone"))
        : null;

      const emailEmpresa = localStorage.getItem("empresa-email-register")
        ? JSON.parse(localStorage.getItem("empresa-email-register"))
        : null;

      const dadosEmpresa = localStorage.getItem("empresa-information-register")
        ? JSON.parse(localStorage.getItem("empresa-information-register"))
        : null;

      let cnpj = dadosEmpresa.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");

      RegisterEmpresa({
        email: emailEmpresa.email.toLowerCase(),
        password1: inputs.senha,
        password2: inputs.confirmarSenha,
        razao_social: dadosEmpresa.razao_social,
        cnpj: cnpj,
        telefone: telefoneEmpresa.telefone,
      })
        .then((response) => {
          setModal({
            text: response.data.message,
            alertType: "ok",
            active: true,
          });
          setStep("concluido");
        })
        .catch((e) => {
          console.log(e);
          setModal({
            text: e.response.data.message,
            alertType: "fail",
            active: true,
          });
        });

      return;
    }
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "preenchendo" && (
        <>
          <p className="body medium text-primaria4">Preencha seus dados</p>
          <p className="button regular text-secundaria4">
            Preencha seu nome completo e seu CPF
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-8 flex-wrap"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <div className="h-12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Senha"
                name="senha"
                value={inputs.senha}
                onChange={(e) =>
                  setInputs({ ...inputs, senha: e.target.value })
                }
                pass={pass}
                setPass={setPass}
              />
            </div>
            <div className="h-12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Confirmar Senha"
                name="confirmarSenha"
                value={inputs.confirmarSenha}
                pass={pass}
                setPass={setPass}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    confirmarSenha: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-full flex items-center justify-center px-6 mt-4 h-10">
              <ButtonDark legend="CONFIRMAR" />
            </div>
          </form>
        </>
      )}
      {step === "concluido" && (
        <div className="flex items-center justify-center flex-col gap-2">
          <img src={checkValidate} alt="E-mail validado com sucesso" />
          <p className="body medium text-primaria4">Cadastro realizado!</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2 text-center">
            Para usufruir dos nossos escritórios, contrate um dos nossos planos
            mensais!
          </p>
          <Link
            to="/empresas#planos"
            className="w-10/12 flex items-center justify-center h-11"
          >
            <ButtonDark
              legend="VER PLANOS"
              func={() => navigate("/empresas#planos")}
              id="finaliza-preenchimento-dados"
            />
          </Link>
          <p
            className="button bold self-center text-center mt-4 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Acessar conta
          </p>
        </div>
      )}
      {!complete.password && step !== "concluido" && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("password", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default EmpresaPassword;
