import React from "react";

const ButtonDark4xl = (props) => {
  let { legend, id } = props;

  return (
    <button
      className="flex-grow p-2 rounded-3xl h-11 gradient-button shadow-4xl medium text-terciaria2"
      id={id}
    >
      {legend}
    </button>
  );
};

export default ButtonDark4xl;
