import React from "react";

const ButtonDark = (props) => {
  let { legend, shadow, func, id, loading } = props;
  let load = loading ? " pointer-events-none opacity-40 " : " ";
  let classes =
    "flex-grow p-2 rounded-3xl h-full gradient-button flex items-center justify-center w-full " +
    load +
    shadow;

  return (
    <button className={classes} onClick={func} id={id}>
      <p className="button medium text-secundaria1">
        {loading ? "ENVIANDO..." : legend}
      </p>
    </button>
  );
};

export default ButtonDark;
