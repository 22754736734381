import axiosInstance from "../AxiosInstance";

const GetProfileParceiro = async (id) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`parceiros/info/?user=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default GetProfileParceiro;
