import React, { useContext, useState } from "react";
import colabIcon from "../../assets/colaboradores-icon.svg";
import ButtonLight from "../../components/ButtonLight";
import acrescentar from "../../assets/acrescent.svg";
import Input from "../../components/Input";
import trash from "../../assets/trash.svg";
import AddColabs from "../../services/Company/AddColabs";
import AuthContext from "../../hooks/AuthContext";
import pen from "../../assets/pen.svg";
import check from "../../assets/check.svg";
import validateCPF from "../../utils/validateCPF";
import validateEmail from "../../utils/ValidateEmail";
import ModalAlert from "../../components/ModalAlert";
import maskDoc from "../../utils/maskDoc";

const AdicionarColaboradores = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [count, setCount] = useState(0);
  const [edit, setEdit] = useState("");

  let { user } = useContext(AuthContext);
  const handleAdd = () => {
    if (edit === "") {
      let newColab = {
        nome: "",
        email: "",
        confirmar_email: "",
        cpf: "",
        key: count,
        editing: true,
        error: "",
      };
      setCount(count + 1);
      setColaboradores([newColab, ...colaboradores]);
      setEdit(count);
    } else {
      setModal({
        text: "Valide o colaborador atual antes de adicionar o seguinte.",
        alertType: "fail",
        active: true,
      });
      return;
    }
  };

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const handleEdit = (id, field, value) => {
    let colabs = colaboradores;
    let index = colabs.findIndex((x) => x.key === id);
    colabs[index][field] = value;
    setColaboradores([...colabs]);
  };

  const handleDelete = (id) => {
    if (edit !== "" && edit !== id) {
      setModal({
        text: "Valide o colaborador atual antes de excluir este.",
        alertType: "fail",
        active: true,
      });
      return;
    }
    let colabs = colaboradores;
    let filtered = colabs.filter(function (el) {
      return el.key !== id;
    });
    setColaboradores([...filtered]);
    setEdit("");
  };

  const colabValues = (id, value) => {
    let colabs = colaboradores;
    let index = colabs.findIndex((x) => x.key === id);
    return colabs[index][value];
  };

  const handleSubmit = () => {
    let colabs = colaboradores;
    let newColabs = [];
    if (edit === "" && colaboradores.length !== 0) {
      colabs.map((colab, count) => {
        let cpf = colab.cpf;
        cpf = cpf.replace(/[^\d]+/g, "");
        colabs[count].cpf = cpf;
        return true;
      });
      AddColabs(colaboradores, user.uuid)
        .then((resp) => {
          setModal({
            text: "Colaboradores adicionados com sucesso!",
            alertType: "ok",
            active: true,
          });
          setColaboradores([]);
          return;
        })
        .catch((e) => {
          setModal({
            text: `Ocorreu um erro ao tentar cadastrar estes colaborador(es)`,
            alertType: "fail",
            active: true,
          });
          colabs.map((colab) => {
            if (e.message.emails.includes(colab.email)) {
              let index = e.message.user_errors.findIndex(
                (x) => x.email === colab.email
              );
              let add = {
                nome: colab.nome,
                email: colab.email,
                confirmar_email: colab.confirmar_email,
                cpf: colab.cpf,
                key: count,
                editing: false,
                error: e.message.user_errors[index].error,
              };
              newColabs.push(add);
              return true;
            } else {
              return false;
            }
          });
          setColaboradores([...newColabs]);
          return;
        });
    } else if (edit === "") {
      setModal({
        text: "Termine de editar seus colaboradores",
        alertType: "fail",
        active: true,
      });
    } else {
      setModal({
        text: "Adicione pelo menos 1 colaborador a sua lista",
        alertType: "fail",
        active: true,
      });
    }
  };

  const editColab = (colab_id) => {
    if (edit !== "") {
      setModal({
        text: "Termine de editar o colaborador atual!",
        alertType: "fail",
        active: true,
      });
      return;
    }
    setEdit(colab_id);
  };

  const confirmEditColab = (colab) => {
    if (
      colab.nome === "" ||
      colab.email === "" ||
      colab.confirmar_email === "" ||
      colab.cpf === ""
    ) {
      setModal({
        text: "Preencha todos os campos",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      if (colab.email !== colab.confirmar_email) {
        setModal({
          text: "Os emails devem ser iguais",
          alertType: "fail",
          active: true,
        });
        return;
      } else {
        if (validateCPF(colab.cpf)) {
          if (validateEmail(colab.email)) {
            setEdit("");
            handleEdit(colab.key, "error", "");
          } else {
            setModal({
              text: "E-mail inválido",
              alertType: "fail",
              active: true,
            });
          }
        } else {
          setModal({
            text: "CPF inválido",
            alertType: "fail",
            active: true,
          });
        }
      }
    }
  };

  return (
    <section className="min-h-screen flex flex-col items-center justify-start bg-terciariafundo py-6">
      <div className="flex w-11/12 bg-terciariafundo colab-bg-div rounded-md p-5 flex-col gap-4">
        <header className="flex flex-row gap-3 border-b border-b-secundaria3 border-opacity-40 w-full pb-2 items-center justify-start">
          <img src={colabIcon} alt="Icone de colaboradores" className="pb-1" />
          <p className="textos semibold text-secundaria5">
            ADICIONAR COLABORADORES
          </p>
        </header>
        <div className="lg:w-6/12 lg:self-center lg:py-6">
          <ButtonLight legend="ADICIONAR" onClick={() => handleSubmit()} />
        </div>
        <p className="textos semibold text-primaria3 lg:pb-4">
          Adicionar individualmente
        </p>
        <div className="flex items-center justify-center flex-col gap-4 lg:flex-row lg:flex-wrap lg:gap-6">
          <div
            className="w-full bg-terciaria2 h-24 border-primaria2 border rounded-md flex items-center justify-center cursor-pointer lg:w-[420px] lg:h-[480px] lg:rounded-3xl"
            id="adicionar-colaborador"
            onClick={() => handleAdd()}
          >
            <img src={acrescentar} alt="Adicionar colaborador" />
          </div>
          {colaboradores.map((colab) => {
            return (
              <div
                className="bg-terciaria2 border-primaria2 border rounded-md flex items-center justify-start w-full p-4 flex-col gap-6 lg:w-[420px] lg:px-12 lg:py-6 lg:gap-8 lg:h-[480px] lg:rounded-3xl lg:relative"
                key={colab.key}
              >
                <p className="textos semibold text-secundaria4 self-start">
                  Dados do colaborador
                </p>
                {colab.error ? (
                  <p className="text-alerta1">{colab.error}</p>
                ) : (
                  ""
                )}
                <Input
                  background={edit === colab.key ? "terciaria2" : "terciaria1"}
                  placer="Nome"
                  name="nome"
                  value={colabValues(colab.key, "nome")}
                  onChange={(e) =>
                    handleEdit(colab.key, "nome", e.target.value)
                  }
                  id={`nome-${colab.key}`}
                  block={edit === colab.key ? false : true}
                />
                <Input
                  background={edit === colab.key ? "terciaria2" : "terciaria1"}
                  placer="Email"
                  name="email"
                  value={colabValues(colab.key, "email")}
                  onChange={(e) =>
                    handleEdit(colab.key, "email", e.target.value)
                  }
                  id={`email-${colab.key}`}
                  block={edit === colab.key ? false : true}
                />
                <Input
                  background={edit === colab.key ? "terciaria2" : "terciaria1"}
                  placer="Confirmar E-mail"
                  name="confirmar_email"
                  value={colabValues(colab.key, "confirmar_email")}
                  onChange={(e) =>
                    handleEdit(colab.key, "confirmar_email", e.target.value)
                  }
                  id={`confirmar_email-${colab.key}`}
                  block={edit === colab.key ? false : true}
                />
                <Input
                  background={edit === colab.key ? "terciaria2" : "terciaria1"}
                  placer="CPF"
                  name="cpf"
                  maxLength={14}
                  value={maskDoc(colabValues(colab.key, "cpf"), "cpf")}
                  onChange={(e) => handleEdit(colab.key, "cpf", e.target.value)}
                  id={`cpf-${colab.key}`}
                  block={edit === colab.key ? false : true}
                />
                <div className="flex flex-row gap-4 lg:absolute lg:bottom-8">
                  <img
                    src={check}
                    alt="confirmar-edicao-colaborador"
                    id={`confirmar-${colab.key}`}
                    onClick={() => confirmEditColab(colab)}
                    className={
                      edit === colab.key ? "cursor-pointer w-6" : "hidden"
                    }
                  />
                  <img
                    src={pen}
                    alt="editar-colaborador"
                    id={`edit-${colab.key}`}
                    onClick={() => editColab(colab.key)}
                    className={
                      edit === colab.key ? "hidden" : "cursor-pointer w-6"
                    }
                  />
                  <img
                    src={trash}
                    alt="excluir-colaborador"
                    id={`excluir-${colab.key}`}
                    onClick={() => handleDelete(colab.key)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default AdicionarColaboradores;
