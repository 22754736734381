import axiosInstance from "../AxiosInstance";

const ParceiroPrimeiroAcesso = async (body) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("parceiros/primeiro-acesso", body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default ParceiroPrimeiroAcesso;
