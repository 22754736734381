import React, { useContext, useEffect, useState } from "react";
import fundoParceiro from "../../assets/fundoParceiros.jpg";
import empresasVideo from "../../assets/empresasVideo.mp4";
import passo1 from "../../assets/passo1.svg";
import passo2 from "../../assets/passo2.svg";
import passo3 from "../../assets/passo3.svg";
import passo4 from "../../assets/passo4.svg";
import ico1 from "../../assets/ico1.svg";
import ico2 from "../../assets/ico2.svg";
import ico3 from "../../assets/ico3.svg";
import ico4 from "../../assets/ico4.svg";
import ico5 from "../../assets/ico5.svg";
import ico6 from "../../assets/ico6.svg";
import ico7 from "../../assets/ico7.svg";
import propositoM from "../../assets/propositoMini.svg";
import proposito from "../../assets/proposito.svg";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";
import ModalAlert from "../../components/ModalAlert";
import FormParceiro from "../../services/LadingPage/FormParceiro";
import passo1S from "../../assets/passo1S.svg";
import passo2S from "../../assets/passo2S.svg";
import passo3S from "../../assets/passo3S.svg";
import passo4S from "../../assets/passo4S.svg";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const ParaParceiros = () => {
  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  const navigate = useNavigate();
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [inputs, setInputs] = useState({
    nome: "",
    empresa: "",
    email: "",
    telefone: "",
    assunto: "",
  });

  const sendForm = (e) => {
    e.preventDefault();
    FormParceiro({
      nome: e.target.nome.value,
      empresa: e.target.empresa.value,
      email: e.target.email.value,
      telefone: e.target.telefone.value,
      assunto: e.target.assunto.value,
    })
      .then((resp) => {
        setModal({
          text: "Formulário enviado com sucesso!",
          alertType: "ok",
          active: true,
        });
        setInputs({
          nome: "",
          empresa: "",
          email: "",
          telefone: "",
          assunto: "",
        });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Para parceiros");
    }, [1]);

    let video = document.getElementById("parceiros-video");
    video.muted = true;
    video.play();
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <section className="relative flex flex-col">
        <div className="bg-decal-parceiros">
          <video muted autoplay loop playsInline id="parceiros-video">
            <source src={empresasVideo} type="video/mp4" />
          </video>
        </div>
        <div className="absolute bottom-[55px] flex px-4 items-start lg:items-start lg:justify-between lg:flex-col justify-center lg:px-[200px] flex-col lg:w-full">
          <p className="argentum-medium-20 text-terciaria2 w-[220px] lg:w-fit flex lg:hidden lg:self-start">
            Expandir e monetizar seu espaço nunca foi tão fácil
          </p>
          <p className="argentum-medium-36 text-terciaria2 w-[220px] lg:w-fit hidden lg:flex lg:self-start">
            Expandir e monetizar seu espaço nunca foi tão fácil
          </p>
          <p className="argentum-caption text-terciaria2 mt-4 lg:hidden">
            Cadastre seu espaço compartilhado e adequado do Coworking e/ou
            Cafeteria agora mesmo e seja nosso parceiro!
          </p>
          <p className="argentum-medium text-terciaria2 mt-4 lg:flex hidden">
            Cadastre seu espaço compartilhado e adequado do Coworking e/ou
            Cafeteria agora mesmo e seja nosso parceiro!
          </p>
          <div className="w-full flex items-center justify-between">
            <p className="argentum-caption-18 text-terciaria2 mt-4 lg:flex hidden pt-4">
              Sem custos e conectado ao público corporativo!
            </p>
            <Link
              to="/parceiro/cadastro"
              className=""
              onClick={() => window.dataLayer.push({ event: "virar_parceiro" })}
            >
              <div className="div-lp w-full lg:w-[280px] lg:self-end flex items-center justify-center px-8 py-4 cursor-pointer self-center mt-8">
                <p className="argentum-normal text-terciaria2">
                  Quero ser um parceiro
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="relative flex flex-col bg-terciaria2 py-8 px-4 lg:py-12 lg:px-[200px]">
        <p className="argentum-medium-500 text-primaria5 w-[250px] lg:w-full">
          Veja o que podemos fazer para você!
        </p>
        <p className="argentum-caption-18 text-detalhes1 mt-4 flex lg:hidden">
          Visibilidade e escalabilidade ao seu espaço, apoiamos na divulgação em
          nossos canais de mídias sociais, resultando em crescimento e
          engajamento do seu espaço, gerando maior acessibilidade e rotatividade
          do público que trará mais receita para o seu negócio.
        </p>
        <p className="argentum-caption-24 text-detalhes1 mt-4 lg:flex hidden">
          Visibilidade e escalabilidade ao seu espaço, apoiamos na divulgação em
          nossos canais de mídias sociais, resultando em crescimento e
          engajamento do seu espaço, gerando maior acessibilidade e rotatividade
          do público que trará mais receita para o seu negócio.
        </p>
        <div className="h-[250px] overflow-hidden relative mt-12 rounded-md sm:flex hidden">
          <img
            src={fundoParceiro}
            className="absolute h-auto -bottom-[200px]"
            alt="imagem-colaboradores"
          />
          <Link to="/parceiro/cadastro" className="">
            <button
              className="w-[300px] h-14 argentum-normal text-terciaria2 rounded-md absolute bottom-6 right-[10%]"
              id="send-parceiro"
            >
              Quero ser um parceiro
            </button>
          </Link>
        </div>
      </section>
      <section className="h-[250px] relative sm:hidden items-center justify-center flex">
        <img
          src={fundoParceiro}
          alt="imagem de trabalhadores"
          className="absolute max-h-[250px] w-full"
        />
        <Link to="/parceiro/cadastro" className="">
          <button
            className="lg:w-10/12 w-7/12 h-14 argentum-normal text-terciaria2 rounded-md absolute right-4 bottom-6"
            id="send-parceiro"
          >
            Quero ser um parceiro
          </button>
        </Link>
      </section>
      <section className="flex flex-col bg-terciaria2 lg:px-[200px] px-4 py-8 lg:py-0">
        <p className="argentum-medium-500 text-primaria5 w-full lg:w-full">
          Como funciona nossa parceria!
        </p>
        <div className="lg:mt-4 hidden lg:flex flex-col lg:flex-wrap lg:justify-between lg:flex-row lg:h-auto lg:items-start h-[1150px] py-8 w-full items-center justify-start gap-4">
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo1}
              alt="passo 1"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                1
              </p>
              <p className="argentum-caption-18">
                Cliente busca pelo seu espaço
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo2}
              alt="passo 2"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                2
              </p>
              <p className="argentum-caption-18">
                Você aceita a reserva solicitada
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo3}
              alt="passo 3"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                3
              </p>
              <p className="argentum-caption-18">
                O cliente desfruta do espaço
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo4}
              alt="passo 4"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px]  bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                4
              </p>
              <p className="argentum-caption-18">A ON paga pela utilização</p>
            </div>
          </div>
        </div>
        <div className="lg:mt-4 flex lg:hidden flex-col lg:flex-wrap lg:justify-between lg:flex-row lg:h-auto lg:items-start h-[1150px] py-8 w-full items-center justify-start gap-4">
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo1S}
              alt="passo 1"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                1
              </p>
              <p className="argentum-caption-18">
                Cliente busca pelo seu espaço
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo2S}
              alt="passo 2"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                2
              </p>
              <p className="argentum-caption-18">
                Você aceita a reserva solicitada
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo3S}
              alt="passo 3"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px] bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                3
              </p>
              <p className="argentum-caption-18">
                O cliente desfruta do espaço
              </p>
            </div>
          </div>
          <div className="shadowLP rounded-md relative h-[260px] lg:w-[48%] lg:h-auto w-[340px]">
            <img
              src={passo4S}
              alt="passo 4"
              className="absolute lg:relative w-full"
            />
            <div className="absolute bg-terciaria2 h-[50px] lg:h-[60px]  bottom-0 w-full flex items-center justify-center gap-5">
              <p className="argentum-caption-18 rounded-sm px-[10px] py-1 bg-primaria5 text-terciaria2 w-fit">
                4
              </p>
              <p className="argentum-caption-18">A ON paga pela utilização</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-4 lg:px-[200px] px-4">
        <p className="argentum-medium-500 text-primaria5 w-full lg:w-full">
          Você terá acesso á:
        </p>
        <p className="argentum-caption-18 text-detalhes1 mt-8 flex lg:pt-8">
          Descubra todas as funções incríveis que a nossa plataforma tem para
          oferecer! Junte-se à nós e descubra como você pode aproveitar ao
          máximo cada recurso disponível para alcançar seus objetivos.
        </p>
        <div className="flex-row flex-wrap gap-14 lg:py-20 py-16 hidden lg:flex">
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico1} alt="icone 1" />
            <p className="argentum-medium-18">GRÁFICOS DE UTILIZAÇÃO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico2} alt="icone 2" />
            <p className="argentum-medium-18">RELATÓRIO FINANCEIRO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico3} alt="icone 3" />
            <p className="argentum-medium-18">GESTÃO DE OCUPAÇÃO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico4} alt="icone 4" />
            <p className="argentum-medium-18">
              SATISFAÇÃO DOS CLIENTES & AVALIAÇÃO DO SEU ESPAÇO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico5} alt="icone 5" />
            <p className="argentum-medium-18">CONTROLE DE RESERVAS</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico6} alt="icone 6" />
            <p className="argentum-medium-18">ESTRATÉGIA DE MONETIZAÇÃO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico7} alt="icone 7" />
            <p className="argentum-medium-18">DASHBOARD ADMINISTRATIVO</p>
          </div>
        </div>
        <div className="flex-row flex-wrap gap-14 lg:py-20 py-16 flex lg:hidden">
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico4} alt="icone 4" className="w-8" />
            <p className="argentum-medium-15">
              SATISFAÇÃO DOS COLABORADORES & AVALIAÇÃO DO ESPAÇO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico6} alt="icone 6" className="w-8" />
            <p className="argentum-medium-15">ESTRATÉGIA DE MONETIZAÇÃO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico7} alt="icone 7" className="w-8" />
            <p className="argentum-medium-15">DASHBOARD ESTRATÉGICO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico2} alt="icone 2" className="w-8" />
            <p className="argentum-medium-15">RELATÓRIO FINANCEIRO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico5} alt="icone 5" className="w-8" />
            <p className="argentum-medium-15">CONTROLE DE RESERVAS</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico1} alt="icone 1" className="w-8" />
            <p className="argentum-medium-15">GRÁFICO DE UTILIZAÇÃO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico3} alt="icone 3" className="w-8" />
            <p className="argentum-medium-15">GESTÃO DE OCUPAÇÃO</p>
          </div>
        </div>
      </section>
      <section className="h-[800px] flex lg:hidden bg-decal-3 items-start justify-end px-4 flex-col">
        <p className="argentum-normal text-terciaria2">E o melhor...</p>
        <p className="argentum-normal-20 text-terciaria2">VOCÊ NÃO PAGARÁ</p>
        <p className="argentum-medium-56 text-terciaria2">NADA!!</p>
        <p className="argentum-caption text-terciaria2 mb-8 mt-4">
          Esta é uma parceira para que as pessoas possam conhecer seu espaço e
          utilizá-los, e por meio das suas utilizações será realizados repasses
          por a prestação do serviço.
        </p>
        <p className="argentum-caption text-terciaria2 mb-12">
          Ótima oportunidade para aumentar as reservas do seu espaço e ganhando
          por isso.
        </p>
        <Link to="/parceiro/cadastro" className="">
          <button
            className="w-full h-12 argentum-normal px-4 text-terciaria2 rounded-lg mb-20"
            id="send-lp-form"
          >
            Quero ser um parceiro
          </button>
        </Link>
      </section>
      <section className="bg-decal-4 h-auto overflow-hidden hidden lg:flex lg:flex-col py-[100px] px-[200px]">
        <div className="flex flex-col w-6/12">
          <p className="argentum-normal text-terciaria2">E o melhor...</p>
          <p className="argentum-normal-20 text-terciaria2 mt-8">
            VOCÊ NÃO PAGARÁ
          </p>
          <p className="argentum-medium-56 text-terciaria2">NADA!!</p>
          <p className="argentum-caption-18 text-terciaria2 mb-8 mt-4">
            Esta é uma parceira para que as pessoas possam conhecer seu espaço e
            utilizá-los, e por meio das suas utilizações será realizados
            repasses por a prestação do serviço.
          </p>
          <p className="argentum-caption-18 text-terciaria2 mb-12">
            Ótima oportunidade para aumentar as reservas do seu espaço e
            ganhando por isso.
          </p>
          <Link to="/parceiro/cadastro" className="">
            <button
              className="w-full h-12 argentum-normal text-terciaria2 rounded-lg mb-20"
              id="send-lp-form"
            >
              Quero ser um parceiro
            </button>
          </Link>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 lg:px-[200px] px-4 md:gap-8 flex flex-row lg:justify-between gap-3 ">
        <img
          src={propositoM}
          alt="imagem de proposito"
          className="w-6/12 flex md:hidden"
        />
        <img
          src={proposito}
          alt="imagem de proposito"
          className="w-6/12 hidden md:flex"
        />
        <div className="flex-col gap-4 flex md:hidden">
          <p className="argentum-normal-400 text-primaria5">
            Nosso propósito nos move!
          </p>
          <p className="argentum-normal-400 text-detalhes1">Propósito</p>
          <p className="argentum-caption-16 text-detalhes1">
            Nosso propósito é conectar todos os colaboradores a espaços
            colaborativos, pois acreditamos que necessidades surgem e nada
            melhor que poder contar com espaços bem estruturados.
          </p>
          <div
            onClick={() => navigate("/parceiro/cadastro")}
            className="border-2 text-primaria4 flex items-center justify-center argentum-extralight-4 border-primaria4 py-2 rounded-md cursor-pointer hover:bg-primaria4 hover:text-terciaria2 transition-all"
          >
            Cadastrar meu espaço
          </div>
        </div>
        <div className="flex-col gap-4 hidden md:flex pt-12 pb-8">
          <p className="argentum-medium text-primaria2lp">
            Nosso propósito nos move!
          </p>
          <p className="argentum-medium-18 text-detalhes1">Propósito</p>
          <p className="argentum-caption-15 text-detalhes1 w-[350px]">
            Nosso propósito é conectar todos os colaboradores a espaços
            colaborativos, pois acreditamos que necessidades surgem e nada
            melhor que poder contar com espaços bem estruturados.
          </p>
          <Link
            to="/parceiro/cadastro"
            className="mt-12 border-2 text-primaria4 flex items-center justify-center argentum-extralight-4 border-primaria4 py-2 rounded-md cursor-pointer hover:bg-primaria4 hover:text-terciaria2 transition-all"
          >
            Cadastrar meu espaço
          </Link>
        </div>
      </section>
      <section
        className="bg-decal-2 lg:flex-row flex-col-reverse flex items-start justify-center py-8 w-full lg:px-8 px-3"
        id="form"
      >
        <div className="w-full items-center justify-center text-center lg:hidden">
          <p className="argentum-caption-16 text-terciaria2 mt-12 lg:hidden text-center">
            contato@onworkingpass.com
          </p>
        </div>
        <div className="flex items-center justify-center lg:w-6/12 mt-8 lg:mt-0 w-full">
          <form
            className="bg-terciaria2 lg:w-8/12 max-w-[600px] w-full px-8 py-12 gap-6 flex items-center justify-center flex-col rounded-md"
            onSubmit={(e) => sendForm(e)}
          >
            <input
              placeholder="Nome completo"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="nome"
              value={inputs.nome}
              onChange={(e) => setInputs({ ...inputs, nome: e.target.value })}
            />
            <input
              placeholder="Empresa"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="empresa"
              value={inputs.empresa}
              onChange={(e) =>
                setInputs({ ...inputs, empresa: e.target.value })
              }
            />
            <input
              placeholder="Email"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <input
              placeholder="Telefone"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="telefone"
              value={inputs.telefone}
              onChange={(e) =>
                setInputs({ ...inputs, telefone: e.target.value })
              }
            />
            <textarea
              className="w-full bg-secundaria1 rounded-md placeholder:text-secundaria4 pl-4 pt-3 resize-none min-h-[150px]"
              placeholder="Assunto"
              name="assunto"
              value={inputs.assunto}
              onChange={(e) =>
                setInputs({ ...inputs, assunto: e.target.value })
              }
            />
            <button
              className="w-full h-14 argentum-normal text-terciaria2 rounded-sm"
              id="send-lp-form"
            >
              ENVIAR
            </button>
          </form>
        </div>
        <div className="lg:ml-20 lg:w-6/12 w-full flex items-start justify-start flex-col lg:gap-[40px] gap-12 py-8">
          <p className="argentum-medium text-detalhes4">Fale conosco</p>
          <p className="argentum-medium-36 text-terciaria2 lg:w-[450px]">
            Seja nosso Parceiro!
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[520px]">
            Conte conosco para dar a visibilidade e escalabilidade para seu
            espaço.
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[520px] mt-12 hidden lg:flex">
            contato@onworkingpass.com
          </p>
        </div>
      </section>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default ParaParceiros;
