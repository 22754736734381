import axiosInstance from "../AxiosInstance";

const VerifyTokenCompany = async (email, token) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`auth/empresa/valida-token-cadastro-empresa/`, {
        email: email,
        token: token,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export default VerifyTokenCompany;
