import React, { useContext, useEffect } from "react";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";

const Termos = () => {
  const { changeMenuLogged } = useContext(LoggedHeaderContext);

  useEffect(() => {
    window.scrollTo(0, 0, "Smooth");

    setTimeout(() => {
      changeMenuLogged("Termos");
    }, [1]);

    // eslint-disable-next-line
  }, []);

  return (
    <section className="w-full px-[80px] flex flex-col items-center justify-start gap-8 pb-12 bg-terciaria2">
      <p className="argentum-medium pt-8">TERMOS E CONDIÇÕES DE USO</p>
      <div className="w-full text-justify">
        <p className="argentum-caption">
          Bem-vindo à ON Working pass, uma plataforma social, acessível pelo
          site www.onworkingpass.com (“Site”) e/ou pelo aplicativo ONpass
          (“ONpass App”), que oferece acesso aos principais espaços de trabalho
          compartilhado do Brasil (“Serviços de Coworkings e espaços das
          Cafeterias”) e torna possível a conexão de pessoas baseadas em
          informações relevantes. A ON Working pass é de titularidade exclusiva
          e é operado pela ON Working LTDA Estes Termos e Condições de Uso
          (“Termo”) representam o acordo integral entre você e a ON Working para
          a utilização.
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">1. Aceite do Termo</p>
        <div>
          <p className="argentum-caption indent-12">
            Ao criar uma conta, fazer downloads, usar informações, ferramentas,
            recursos, serviços e funcionalidades do Site e/ou da ONpass App,
            você concorda com todas as condições deste Termo, e declara que o
            faz sem qualquer indução, coação ou obrigação imposta por terceiros,
            sendo o exercício de tais condutas sua opção e exercício de seu
            livre arbítrio.
          </p>
          <p className="argentum-caption indent-12">
            As condições deste Termo aplicam-se a você a partir do momento em
            que você navega pelo Site (“Visitante”) e também quando cadastra uma
            conta via um plano atrelado ao seu empregador para utilização dos
            Serviços de Coworking e/ou espaços de Cafeterias (“Usuário”). Caso
            você seja Visitante e deseje se tornar Usuário, atente para as
            disposições específicas deste Termo destinadas aos Usuários, pois
            elas serão automaticamente aplicáveis a você. Por favor, analise
            atentamente todos os Termos antes de utilizar o Site ou a ONpass
            App.{" "}
            <span className="font-bold">
              {" "}
              Caso você não concorde com qualquer disposição deste Termo, por
              favor, não use o Site, não faça download da ONpass App e não se
              cadastre como Usuário ou Membro.
            </span>
          </p>
          <p className="argentum-caption indent-12">
            Você reconhece e aceita que a ON Working pass é destinada a pessoas
            com 18 (dezoito) anos ou mais, plenamente capazes conforme o Direito
            Brasileiro. Dessa forma, ao utilizar o Site ou a ONpas App, você
            concorda em seguir todas as condições deste Termo e declara que tem,
            pelo menos, 18 (dezoito) anos. Ao usuário, será permitido apenas uma
            conta junto à ON Working pass. Contas duplicadas serão
            automaticamente desativadas pela ON Working pass, sem prejuízo das
            demais penalidades cabíveis. Para o devido cadastramento junto ao
            serviço, você deverá fornecer integralmente os dados requeridos.
            Todas as informações fornecidas por você devem ser precisas,
            verdadeiras e atualizadas. Em qualquer caso, o usuário responderá,
            em âmbito cível e criminal, pela veracidade, exatidão e
            autenticidade dos dados informados.
          </p>
          <p className="argentum-caption indent-12">
            Você deverá fornecer um endereço de email válido e este ser o
            endereço com domínio corporativo atrelado a empresa contratante e
            sua empregadora, através do qual a ON Working pass realizará todos
            os contatos necessários e irá validar os dados junto ao cadastro
            prévio realizado pela empresa que estabeleceu contrato de prestação
            de serviços e disponibilização dos planos para seu colaboradores.
            Todas as comunicações enviadas para o referido endereço serão
            consideradas lidas pelo usuário, que se compromete, assim, a
            consultar regularmente suas mensagens recebidas e respondê-las em
            prazo razoável. Não será permitido ceder, vender, alugar ou
            transferir, de qualquer forma, a conta, que é pessoal e
            intransferível. Será automaticamente descadastrado o usuário que
            descumprir quaisquer das normas contidas no presente instrumento,
            sendo-lhe vedado realizar novo cadastro no site e assim notificar a
            sua empregadora. O usuário poderá, a qualquer tempo, requerer o
            cancelamento do seu cadastro junto à ON Working pass através única e
            exclusivamente ao departamento responsável do benefício de sua
            empregadora. O seu descadastramento será realizado o mais breve
            possível.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">2. Modificação do Termo</p>
        <div>
          <p className="argentum-caption indent-12">
            A ON Working pass poderá modificar, a qualquer tempo, as condições
            deste Termo e quaisquer outras condições aplicáveis aos serviços
            regulados por este Termo. A ON Working pass poderá, a qualquer
            momento, interromper, limitar ou suspender o acesso ao site ou a
            algumas de suas páginas, a fim de realizar atualizações,
            modificações de conteúdo ou qualquer outra ação julgada necessária
            para o seu bom funcionamento. Nesses casos, os Visitantes e/ou
            Usuários serão avisados previamente à implementação dessas mudanças,
            com antecedência razoável. A persistência na utilização do Site ou
            da ONpass App após o fim do período de prévio aviso referente a
            qualquer mudança será considerada como uma aceitação dessas
            alterações. Se você não concordar ou não estiver satisfeito com as
            mudanças informadas pela ON Working pass, você deverá cancelar sua
            conta junto ao departamento da sua empregadora e deixar de utilizar
            o Site e/ou a ONpass App.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">3. Serviços de Coworking</p>
        <div>
          <p className="argentum-caption-18 pb-4">Funcionalidade dos planos</p>
          <p className="argentum-caption indent-12">
            Ao cadastrar uma conta no Site ou na ONpass App, você poderá acessar
            os Serviços de Coworking e/ou espaços Cafeterias conforme o plano
            credenciado junto a ON Working pass e seu empregador, conforme
            característica e vigência. Os planos são corporativos e sendo assim
            somente contratados por empresas e essas são responsáveis por
            gerenciar planos e os usuários que usufruíram do benefício assim
            pagos pela mesma. A partir do momento em que você ativar o benefício
            o plano disponível pela empresa será atrelado a sua conta, você será
            considerado usuário e estará sujeito a todas as disposições desta
            seção e a outras disposições pertinentes deste Termo. Para efetivar
            o benefício você precisará logar com seu e-mail de domínio
            corporativo correspondente e pré cadastrado pela sua empregadora,
            fornecendo, para isso, os seus dados pessoais na plataforma da ON
            Working pass.
          </p>
          <p className="argentum-caption indent-12">
            A ativação lhe dará direito de utilizar estações individuais de
            trabalho em qualquer um dos espaços de trabalho compartilhados
            disponíveis no Site e/ou na ONpass App (“Coworkings e/ou Cafeterias
            Parceiras”), na quantidade de horas indicada no Plano
            disponibilizado pela seu empregador na característica de quantidade
            total de horas (“Créditos”). Os créditos de qualquer Plano devem ser
            utilizados durante o período de validade indicado para cada plano
            (“Validade dos Créditos”), que será sempre contado a partir da data
            de ativação/cadastro do usuário. Os créditos serão disponibilizados
            em horas e lhe dará direito de utilização de 1 (uma) estação
            individual em qualquer um dos Coworkings e/ou espaço das Cafeterias
            Parceiras (dentro do horário de funcionamento de cada Coworking e/ou
            espaço das Cafeteria Parceira). A utilização dos créditos em
            determinado Coworking e/ou espaço da Cafeteria Parceira estará
            sujeita à disponibilidade de vagas naquela data e hora específica.
          </p>
          <p className="argentum-caption indent-12">
            Os créditos poderão ser utilizados em dias úteis, de segunda a
            sexta-feira. É possível, contudo, que os dias de funcionamento de
            determinados Coworkings e/ou espaço das Cafeterias Parceiras sejam
            diferentes, e que em determinadas datas os Coworkings e/ou espaços
            das Cafeterias Parceiras não estejam em funcionamento. Alguns
            Planos, você poderá utilizar os créditos em mais de um Coworking
            e/ou espaço de Cafeteria Parceiras no mesmo dia, porém outros planos
            tem em sua característica um limitador de espaço diário a ser
            utilizado por seus créditos, sendo assim é válido a política
            estabelecida no plano ativo. Além disso, é vedada a utilização de
            mais de 10 (dez) visitas em um mesmo Coworking e/ou espaço de
            Cafeteria Parceira dentro do prazo de 30 (trinta) dias, contados da
            data de confirmação do pagamento da Assinatura, ainda que a Validade
            dos Créditos do seu Plano seja superior a esse prazo.
          </p>
          <p className="indent-12 argentum-caption">
            <span className="font-bold argentum-caption">
              Os Créditos são intransferíveis, não são cumulativos, e deverão
              obrigatoriamente ser utilizados dentro do prazo de Validade dos
              Créditos, que será sempre contado a partir da data estabelecida em
              contrato junto a empresa/empregador contratante. A não utilização
              dos Créditos dentro do período de Validade dos Créditos não lhe
              dará direito a qualquer reembolso, nem à possibilidade de
              reaproveitamento dos Créditos após o término da Validade dos
              Créditos. Para que não haja dúvidas, a simples solicitação de
              agendamento é considerada utilização dos Créditos, para os fins
              desta disposição, sendo também necessário que a data da efetiva
              utilização do espaço no Coworking e/ou espaço da Cafeteria
              Parceira esteja dentro do período de Validade dos Créditos.
            </span>
          </p>
          <p className="argentum-caption-18 py-4">Agendamentos</p>
          <p className="argentum-caption indent-12">
            Previamente à utilização dos Créditos nos Coworkings e/ou espaço da
            Cafeterias Parceiras, você deverá realizar a solicitação do
            pré-agendamento com a data e hora para a sua utilização. Esse
            pré-agendamento deverá ser feito por meio do Site ou da ONpass App,
            e a data e hora agendada para efetiva utilização do Crédito deverá
            necessariamente estar abrangida pelo período de Validade dos
            Créditos e o Coworking e/ou espaço da Cafeteria necessitará
            confirmar o agendamento garantindo assim a disponibilidade do
            espaço, assim o agendamento passará do status “a confirmar” para
            "confirmada". O cancelamento ou o reagendamento só poderá ser feito
            com pelo menos 24 (vinte e quatro) horas de antecedência ao horário
            de início da reserva que está com o status “cofirmada”.
            Exemplificando: caso você tenha feito uma reserva para o dia 10 às
            10h da manhã, e o Coworking e/ou espaço da Cafeteria Parceira tenham
            confirmado sua reserva e ela esteja como “Confirmada” , você poderá
            cancelar ou reagendar a sua reserva até as 10 horas da manhã do dia
            9.
          </p>
          <p className="argentum-caption indent-12">
            Você reconhece que o agendamento de espaços para utilização de
            Créditos nos Coworkings e/ou espaços das Cafeterias Parceiras está
            sujeito à disponibilidade de espaços. A ON Working pass, portanto,
            não garante que o Coworking e/ou espaços da Cafeteria Parceira de
            sua preferência terá espaços disponíveis no dia escolhido por você.
            Você reconhece que o não comparecimento ao Coworking e/ou espaço da
            Cafeteria Parceira na data e horário agendado – sem que tenha sido
            realizado o cancelamento ou reagendamento com pelo menos 24 (vinte e
            quatro) horas de antecedência – acarretará a perda do seu Crédito,
            que não poderá, em nenhuma hipótese, ser reembolsado nem
            reaproveitado posteriormente. Você reconhece que em caso de
            utilização inferior do agendamento Para a utilização dos Créditos
            nos Coworkings/Cafeterias Parceiras na data agendada, você deverá
            estar na sua área logada e solicitar Check-in, assim como informar
            ao espaço parceiro seu token de acesso como Usuário da ON Working
            pass e apresentar documento oficial de identificação com foto.
          </p>
          <p className="argentum-caption indent-12">
            Ao utilizar seus créditos, você concorda em seguir todas as regras e
            diretrizes internas determinadas pelos Coworking/Cafeteria Parceira,
            responsabilizando-se, integral e exclusivamente, por qualquer
            descumprimento e pelos danos decorrentes. Sem prejuízo das
            indenizações cabíveis, o descumprimento das regras e das diretrizes
            internas determinadas pelos Coworkings/Cafeterias Parceiras poderá
            provocar o bloqueio imediato do seu benefício, sem qualquer aviso
            prévio, não sendo devido qualquer reembolso.
          </p>
          <p className="argentum-caption-18 py-4">
            <span className="font-bold">Cobranças</span>
          </p>
          <p className="argentum-caption indent-12">
            <span className="font-bold">
              O seu Plano será automaticamente renovado na data de encerramento
              da Vigência dos Créditos, sendo a Assinatura automaticamente
              cobrada nessa data (“Data de Vencimento”) ao seu empregador. Você
              poderá solicitar o cancelamento do seu Plano ao seu empregador a
              qualquer momento, sendo que, nesse caso, você não terá mais
              qualquer acesso ou reembolso ou ressarcimento pelos Créditos
              porventura não utilizados (que serão imediatamente cancelados),
              nem por valores já pagos.
            </span>
          </p>
          <p className="argentum-caption indent-12">
            Caso ocorra qualquer problema no pagamento de sua Assinatura não
            provocado por culpa da ON Working pass, o Plano contratado será
            automaticamente suspenso e você não poderá utilizar os seus créditos
            até que o pagamento seja regularizado junto ao seu empregador e a ON
            Working pass.{" "}
            <span className="font-bold">
              A suspensão do seu Plano não implicará prorrogação da validade dos
              créditos. Caso o pagamento só seja regularizado após o término da
              Validade dos Créditos, você não terá qualquer direito a reembolso
              ou ressarcimento pelos créditos não utilizados no período de
              suspensão.
            </span>
          </p>
          <p className="argentum-caption indent-12">
            {" "}
            A persistência dos problemas de pagamento da Assinatura por período
            superior a 15 (quinze) dias, contados da Data de Vencimento, poderá
            resultar no cancelamento definitivo do Plano.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          4. Direitos de propriedade do ON Working pass
        </p>
        <div>
          <p className="argentum-caption indent-12">
            Você reconhece que todos os direitos de qualquer natureza aplicáveis
            à ON Working pass e aos e Serviços de Coworking e Cafeterias são de
            titularidade exclusiva da ON Working pass. Quaisquer modificações,
            criações, aprimoramentos e atualizações da ON Working pass que
            porventura venham a ser realizadas direta ou indiretamente pela ON
            Working pass também serão de sua titularidade exclusiva. Este Termo
            não implica, em qualquer hipótese, a transferência da titularidade
            dos direitos sobre a ON Working, sobre os Serviços de Coworking e
            Cafeterias, nem de quaisquer outros direitos pertencentes à ON
            Working. É expressamente vedado a você modificar, copiar, fazer
            trabalhos derivados, acessar, distribuir, realizar engenharia
            reversa, decodificar ou exportar os códigos-fonte, executáveis ou
            quaisquer outros arquivos que promovam o funcionamento da ON Working
            pass (sendo vedada também qualquer tentativa de realização de
            quaisquer desses atos), seja no Site, seja na ONpass App.
          </p>
          <p className="argentum-caption indent-12">
            Você reconhece também que as logomarcas, marcas registradas, nomes
            comerciais, nomes de domínio e todos os demais nomes ou símbolos que
            identifiquem o ON Working pass (em conjunto, “Marcas”), bem como
            todos os cabeçalhos, gráficos personalizados, desenhos, ícones e
            scripts (em conjunto, “Layout”) do Site e do ONpass App são de
            titularidade exclusiva do ON Working pass ou de parceiros do ON
            Working, sendo expressamente vedada a cópia, a reprodução e/ou a
            utilização (e qualquer tentativa de realização de quaisquer desses
            atos) das Marcas e/ou do Layout sem a obtenção de autorização
            prévia, expressa e por escrito do ON Working. Você reconhece também
            que as logomarcas, marcas registradas, nomes comerciais, nomes de
            domínio e todos os demais nomes ou símbolos que identifiquem a ON
            Working (em conjunto, “Marcas”), bem como todos os cabeçalhos,
            gráficos personalizados, desenhos, ícones e scripts (em conjunto,
            “Layout”) do Site e da ONpas App são de titularidade exclusiva da ON
            Working ou de parceiros da ON Working, sendo expressamente vedada a
            cópia, a reprodução e/ou a utilização (e qualquer tentativa de
            realização de quaisquer desses atos) das Marcas e/ou do Layout sem a
            obtenção de autorização prévia, expressa e por escrito da ON
            Working.
          </p>
          <p className="argentum-caption indent-12">
            É vedado a você criar Contas na ONpass App, bem como inserir ou
            coletar informações no Site por meios ilícitos e/ou não autorizados
            pela ON Working, incluindo, sem limitação, pela utilização de um
            dispositivo automático, script, bot, spider, ou crawler.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">5. Indenizações</p>
        <div>
          <p className="argentum-caption indent-12">
            Você reconhece que, em caso de descumprimento de qualquer disposição
            deste Termo, a ON Working poderá imediatamente, sem concessão de
            aviso prévio e a seu exclusivo critério, cancelar ou suspender a sua
            Conta, caso você seja um Usuário, ou o seu Plano, caso você seja um
            Membro, bem como recusar que você cadastre nova Conta ou Plano, sem
            qualquer ônus para a ON Working e sem prejuízo do seu dever de
            indenizar a ON Working por todas as perdas e danos, incluindo custas
            judiciais e honorários advocatícios eventualmente despendidos, que o
            seu descumprimento tenha provocado, seja de forma direta ou
            indireta. Caso a ON Working venha a ser demandado e/ou
            responsabilizado – seja judicial, extrajudicial e/ou
            administrativamente – por quaisquer perdas e danos causados a outros
            Visitantes, Usuários, Membros e/ou terceiros em decorrência do
            descumprimento deste Termo por você, você se obriga a ressarcir a ON
            Working todos os custos em que ele venha incorrer para realizar a
            sua defesa, inclusive honorários advocatícios e custas judiciais, e
            também os custos decorrentes de eventual condenação ou celebração de
            acordo judicial ou extrajudicial para indenizar os Visitantes,
            Usuários, Membros e/ou terceiros lesados.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">6. Garantias</p>
        <div>
          <p className="argentum-caption indent-12">
            A ON Working pass é disponibilizada pela ON Working na forma e no
            estado em que se encontra, e a ON Working não fornece qualquer tipo
            de garantia expressa ou implícita, quanto a qualquer resultado ou
            adequação a propósitos específicos pretendidos pelos Visitantes e
            Usuários. A ON Working pass esclarece que os Serviços de Coworking e
            Cafeterias têm a finalidade de facilitar a exposição dos Coworkings
            e Cafeterias Parceiras e o acesso aos serviços prestados por eles
            pelos usuários da ON Working pass. Você reconhece que a ON Working
            não possui qualquer ingerência sobre os serviços prestados pelos
            Coworking e Cafeterias Parceiras e que, portanto, figurando como
            mero intermediador da relação jurídica estabelecida entre Usuário e
            Coworking e/ou Cafeteria Parceira, ele não se responsabiliza nem
            presta quaisquer garantias quanto à qualidade dos serviços
            oferecidos, horário de funcionamento, disponibilidade de espaços,
            regras internas de utilização dos espaços, nem por quaisquer
            condições determinadas pelos Coworkings e Cafeterias Parceiras para
            a prestação de seus serviços.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          7. Limitação de responsabilidade
        </p>
        <div>
          <p className="argentum-caption indent-12">
            <span className="font-bold">
              Sob nenhuma circunstância a ON Working ou seus parceiros, sócios
              e/ou colaboradores poderão ser responsabilizados perante você ou
              perante terceiros por lucros cessantes nem por quaisquer danos
              indiretos, consequenciais, incidentais, especiais, perdas de
              chance e/ou perda de receita, decorrentes da sua utilização da ON
              Working pass, ainda que a ON Working tenha sido previamente
              avisado sobre a possibilidade de ocorrência de tais danos. A ON
              Working não poderá ser responsabilizada, ainda, por quaisquer
              tipos de prejuízos, incluindo, sem limitação, quaisquer perdas e
              danos, decorrentes do descumprimento deste Termo pelos Visitantes
              e/ou Usuários. Caso seja comprovada a culpa da ON Working por
              prejuízos sofridos pelos Usuários em decorrência do uso da ONpass
              (incluindo cadastramento de Conta, Utilização de Plano e/ou
              simples navegação no Site), a responsabilidade da ON Working não
              excederá, em nenhuma hipótese, a soma total dos valores
              efetivamente pagos pela contratante da ON Working como
              contraprestação pela disponibilização dos Serviços de Coworking
              e/ou dos Serviços de espaços das Cafeteiras.
            </span>
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          8. Atualizações e indisponibilidade
        </p>
        <div>
          <p className="argentum-caption indent-12">
            A ON Working preocupa-se com a constante melhoria na prestação de
            seus serviços e no constante desenvolvimento da ON Working pass. Por
            isso, nossos desenvolvedores trabalham para oferecer a você um
            sistema mais seguro, interativo e atrativo. Como consequência, a
            ONpass App e/ou o Site poderão sofrer modificações, criações,
            aprimoramentos ou atualizações que impliquem remoção e/ou inclusão
            de funcionalidades e limitações e restrições de acesso criadas, sem
            que seja necessária a comunicação prévia aos Usuários e Membros. Em
            decorrência da constante necessidade de se realizarem modificações,
            criações, aprimoramentos e atualizações na ONpass App e/ou no Site,
            ocorrerão situações em que, sem aviso prévio, os Serviços de
            Coworking e/ou os Serviços de Cafeterias poderão ser temporariamente
            interrompidos, inclusive, sem limitação, para manutenção ou
            atualizações programadas, para reparos de emergência, ou devido a
            falhas de ligações de telecomunicações e/ou equipamentos.
          </p>
          <p className="argentum-caption indent-12">
            A ON Working pass compromete-se a manter infraestrutura adequada,
            com vistas a minimizar os riscos de interrupções na disponibilidade
            de acesso aos Serviços de Coworking e Serviços de espaço de
            Cafeterias. Você concorda, contudo, que, por se tratar de serviços
            disponibilizados por meio da Internet, é possível que ocorram
            indisponibilidades temporárias. A ON Working fica, desde já, isento
            de qualquer responsabilidade relacionada a eventuais danos e
            prejuízos de qualquer natureza que venham a ser causados em razão da
            indisponibilidade dos Serviços de Coworking e Serviços de espaços
            das Cafeterias, seja por questões técnicas ou de outra natureza,
            inclusive, sem limitação, circunstâncias além do controle da ON
            Working pass, tais como atos de órgãos governamentais, guerras,
            embargos, incêndios, enchentes, greves ou outras perturbações
            trabalhistas, indisponibilidade ou interrupção em serviços de
            telecomunicação; ataques ou outros atos praticados por terceiros;
            problemas com DNS ou outros problemas relacionados à conexão e/ou
            disponibilidade da Internet que estejam fora do controle direto da
            ON Working; manutenção programada ou emergencial e atualizações do
            Site, da ONpass App e/ou dos Serviços de Coworking e dos Serviços de
            espaços de Cafeterias.
          </p>
          <p className="argentum-caption indent-12">
            Paralelamente às manutenções e atualizações programadas e executadas
            pela ON Working pass, você poderá entrar em contato com a ON Working
            caso tenha alguma dúvida ou entenda haver qualquer problema ou falha
            no funcionamento da ON Working pass. Em um prazo razoável, a ON
            Working analisará a sua reclamação, questionamento e/ou sugestão, e
            apresentará uma solução adequada, sempre que possível.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">9. Comunicações</p>
        <div>
          <p className="argentum-caption indent-12">
            A ON Working, por si, por seus funcionários, sócios, parceiros e
            colaboradores, poderá enviar a você (e você autoriza e concorda com
            o envio) mensagens, incluindo, sem limitação, comunicações
            relacionadas a mudanças deste Termo, seja por e-mail, SMS, MMS e/ou
            por meio do Site e/ou da ONpass App, sempre visando à correta
            utilização do Site e/ou da ONpass App ou o oferecimento de produtos
            e/ou serviços que possam ser de seu interesse. Caso você deseje,
            você poderá cancelar o recebimento de tais mensagens a qualquer
            momento, mediante envio de comunicação ao endereço
            contato@onworkingpass.com
          </p>
          <p className="argentum-caption indent-12">
            Caso você deseje se comunicar com a ON Working ou com algum de seus
            funcionários, sócios, parceiros e colaboradores, por qualquer outro
            motivo, você deverá enviar mensagem para contato@onworkingpass.com
            ou utilizar a ferramenta de chat disponibilizada no Site e na ONpass
            App. É expressamente vedado o envio de mensagens para a ON Woking
            com qualquer conteúdo que promova racismo, ódio, discriminação e/ou
            violência; que procure extorquir outros Usuários ou da ON Working;
            que contenha spam ou semelhantes; que promova informações falsas,
            imprecisas e/ou atividades ilícitas; que contenha gravações de áudio
            e/ou imagem de terceiros, de modo a violar seus direitos de
            personalidade; que direcione a materiais que explorem terceiros de
            forma sexual, violenta ou ilícita; que contenha worm, vírus,
            spyware, malware ou qualquer outro código de natureza destrutiva ou
            perturbadora; que disponibilize informações de cunho confidencial
            sem a autorização legal e/ou do titular de tais informações.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">10. Privacidade</p>
        <div>
          <p className="argentum-caption indent-12">
            Essa seção contém informações a respeito do tratamento de dados
            pessoais do usuário, total ou parcialmente, automatizados ou não,
            realizados pelo Site ou ONpass App e que poderão ou não ser
            armazenados. O objetivo é fornecer orientações a respeito das
            informações coletadas, os motivos da coleta e como o usuário ou
            membro poderão atualizar, gerenciar, exportar ou excluir tais
            informações. A sua privacidade é muito importante para a ON Working.
            Por esse motivo, a Política de Privacidade da ON Working, disponível
            para sua leitura no Site e na ONpass App, é ora incorporada ao
            presente Termo, estando de acordo com a Lei Federal nº. 12.965, de
            23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal nº.
            13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados) e
            com o Regulamento UE nº. 2016/679 de 27 de abril de 2016
            (Regulamento Europeu de Proteção de Dados Pessoais).
          </p>
          <p className="argentum-caption indent-12">
            Pedimos para que leia atentamente as disposições da nossa Política
            de Privacidade para que entenda quais informações suas coletamos, e
            como as utilizamos e protegemos, podendo ela ser atualizada em
            decorrência de eventual atualização normativa, razão pela qual se
            convida o usuário a consultar periodicamente esta seção. Sem
            prejuízo de qualquer outra via de recurso administrativo ou
            judicial, todos os titulares de dados têm direito a apresentar
            reclamação a uma autoridade de controle. A reclamação poderá ser
            feita à autoridade da sede do site, do país de residência habitual
            do usuário, do seu local de trabalho ou do local onde foi
            alegadamente praticada a infração.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          11. Comprovante de pagamentos
        </p>
        <div>
          <p className="argentum-caption indent-12">
            Não emitimos notas fiscais sobre os planos para os usuários. Ao
            empregador é realizado os pagamentos e comprovantes, caso você
            necessite, poderá solicitar ao mesmo entrando em contato com o
            departamento responsável da sua empresa.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">12. Morte</p>
        <div>
          <p className="argentum-caption indent-12">
            Em caso de falecimento de algum usuário, a empresa contratante será
            responsável por realizar o cancelamento da Conta e/ou do Plano junto
            a nossa equipe técnica.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          Foro para dirimir controvérsias
        </p>
        <div>
          <p className="argentum-caption indent-12">
            A competência para dirimir todas e quaisquer controvérsias relativas
            à relação entre você e a ON Working pass decorrente deste Termo será
            do foro da comarca de Florianópolis, em Santa Catarina, Brasil, com
            renúncia expressa a qualquer outro.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Termos;
