import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import lupa from "../../assets/SearchLupa.svg";
import DropDownFiltro from "../../components/DropDownFiltro";
import SearchBar from "../../components/SearchBar";
import green from "../../assets/green.svg";
import starPurple from "../../assets/star3.svg";
import fullFav from "../../assets/favoritefullHeart.svg";
import emptyFav from "../../assets/favoriteemprtyHeart.svg";
import BuscarCoworkingProximos from "../../services/Colaborador/BuscarCoworkingsProximos";
import BuscarCoworking from "../../services/Colaborador/BuscarCoworkings";
import red from "../../assets/closeCoworking.svg";
import FavoriteCoworking from "../../services/Colaborador/FavoriteCoworking";
import getParamsFromUrl from "../../utils/getParamsFromUrl";

const BuscarEspaco = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(false);
  const [nextPage, setNextPage] = useState("");

  const [coworkings, setCoworkings] = useState([]);
  const [geo, setGeo] = useState({
    liberado: false,
    latitude: "",
    longitude: "",
  });
  const [filtros, setFiltros] = useState({
    disponibilidade: "",
    proximos: false,
    melhores_avaliacoes: false,
    capacidade: "",
    travar_busca: false,
    travar_capacidade: false,
    tipo: "Todos",
  });

  const favorite = (uuid) => {
    let newCoworkings = coworkings;
    let index = newCoworkings.findIndex((x) => x.uuid === uuid);

    FavoriteCoworking(uuid)
      .then((resp) => {
        newCoworkings[index]["favorito"] = resp.data.status;
        setCoworkings([...newCoworkings]);
      })
      .catch((e) => {
        newCoworkings[index]["favorito"] = false;
        setCoworkings([...newCoworkings]);
      });
  };

  const loadMore = () => {
    if (!load) return;
    if (!nextPage) return;
    if (filtros.proximos === true) {
      BuscarCoworkingProximos(
        filtros.disponibilidade === "hoje" ? true : false,
        filtros.capacidade ? filtros.capacidade : "",
        filtros.melhores_avaliacoes,
        geo.latitude,
        geo.longitude,
        filtros.tipo,
        nextPage
      )
        .then((resp) => {
          if (resp.data.next) {
            setNextPage(getParamsFromUrl("page", resp.data.next));
            let newCoworkings = coworkings.concat(resp.data.results);
            setCoworkings(newCoworkings);
            setLoad(true);
          } else {
            let newCoworkings = coworkings.concat(resp.data.results);
            setCoworkings(newCoworkings);
            setNextPage("");
            setLoad(true);
          }
        })
        .catch((e) => console.log(e));
    } else {
      BuscarCoworking(
        filtros.disponibilidade === "hoje" ? true : false,
        filtros.capacidade ? filtros.capacidade : "",
        filtros.melhores_avaliacoes,
        filtros.tipo,
        search,
        nextPage
      )
        .then((resp) => {
          if (resp.data.next) {
            setNextPage(getParamsFromUrl("page", resp.data.next));
            let newCoworkings = coworkings.concat(resp.data.results);
            setCoworkings(newCoworkings);
            setLoad(true);
          } else {
            let newCoworkings = coworkings.concat(resp.data.results);
            setCoworkings(newCoworkings);
            setNextPage("");
            setLoad(true);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setGeo({
        liberado: true,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });

    if (filtros.proximos === true) {
      BuscarCoworkingProximos(
        filtros.disponibilidade === "hoje" ? true : false,
        filtros.capacidade ? filtros.capacidade : "",
        filtros.melhores_avaliacoes,
        geo.latitude,
        geo.longitude,
        filtros.tipo
      )
        .then((resp) => {
          if (resp.data.next) {
            setNextPage(getParamsFromUrl("page", resp.data.next));
            setCoworkings(resp.data.results);
            setLoad(true);
          } else {
            setCoworkings(resp.data.results);
            setNextPage("");
            setLoad(true);
          }
          console.log(resp);
        })
        .catch((e) => console.log(e));
    } else {
      BuscarCoworking(
        filtros.disponibilidade === "hoje" ? true : false,
        filtros.capacidade ? filtros.capacidade : "",
        filtros.melhores_avaliacoes,
        filtros.tipo,
        search
      )
        .then((resp) => {
          if (resp.data.next) {
            setNextPage(getParamsFromUrl("page", resp.data.next));
            setCoworkings(resp.data.results);
            setLoad(true);
          } else {
            setCoworkings(resp.data.results);
            setNextPage("");
            setLoad(true);
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line
  }, [filtros]);

  const limparFiltros = () => {
    setFiltros({
      disponibilidade: "",
      proximos: false,
      melhores_avaliacoes: false,
      capacidade: "",
      travar_busca: false,
      travar_capacidade: false,
      tipo: "Todos",
    });

    setNextPage("");
    setSearch("");
  };

  useEffect(() => {
    setNextPage("");
    setLoad(false);
    // setLoadSearching(true);
    const delayDebounceFn = setTimeout(() => {
      BuscarCoworking(
        filtros.disponibilidade === "hoje" ? true : false,
        filtros.capacidade ? filtros.capacidade : "",
        filtros.melhores_avaliacoes,
        filtros.tipo,
        search,
        nextPage
      )
        .then((resp) => {
          if (resp.data.next) {
            console.log(getParamsFromUrl("page", resp.data.next));
            setNextPage(getParamsFromUrl("page", resp.data.next));
            setCoworkings(resp.data.results);
            setLoad(true);
          } else {
            setCoworkings(resp.data.results);
            setNextPage("");
            setLoad(true);
          }
        })
        .catch((e) => console.log(e));
      // setLoadSearching(false);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [search]);

  const setProximos = () => {
    if (geo.liberado) {
      setFiltros({
        ...filtros,
        proximos: !filtros.proximos,
        travar_busca: !filtros.travar_busca,
      });
      setSearch("");
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        setGeo({
          liberado: true,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
      setSearch("");
    }
  };

  return (
    <section className="bg-terciariafundo flex items-center justify-start pb-12 py-6 px-6 flex-col min-h-screen lg:px-[80px]">
      <div className="flex items-center justify-center relative w-full mt-8">
        <SearchBar
          background={filtros.travar_busca ? "bg-detalhes6" : "bg-terciaria2"}
          className="bg-"
          placer="Buscar por coworking, cidade..."
          name="name"
          height="12"
          pl="12"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          search={filtros.travar_busca}
        />
        <img src={lupa} alt="buscar" className="absolute -left-5" />
      </div>
      <div className="mt-10 flex items-start justify-center flex-col w-full">
        <p className="button bold text-secundaria4">Filtrar por</p>
        <div className="w-full lg:w-full my-6 flex flex-row flex-wrap gap-6 ">
          <button
            className={
              filtros.disponibilidade === "hoje"
                ? "textos normal text-terciaria2 px-3 py-2 button-filtros-selected"
                : "textos normal text-secundaria3 px-3 py-2 button-filtros"
            }
            onClick={
              filtros.disponibilidade === "hoje"
                ? () =>
                    setFiltros({
                      ...filtros,
                      disponibilidade: "",
                      travar_capacidade: false,
                    })
                : () =>
                    setFiltros({
                      ...filtros,
                      disponibilidade: "hoje",
                      travar_capacidade: false,
                    })
            }
          >
            Reservar agora
          </button>
          <button
            className={
              filtros.disponibilidade === "futura"
                ? "textos normal text-terciaria2 px-3 py-2 button-filtros-selected"
                : "textos normal text-secundaria3 px-3 py-2 button-filtros"
            }
            onClick={
              filtros.disponibilidade === "futura"
                ? () =>
                    setFiltros({
                      ...filtros,
                      disponibilidade: "",
                      capacidade: "",
                      travar_capacidade: false,
                    })
                : () =>
                    setFiltros({
                      ...filtros,
                      disponibilidade: "futura",
                      capacidade: "",
                      travar_capacidade: true,
                    })
            }
          >
            Reserva Futura
          </button>
          <button
            className={
              filtros.proximos
                ? "textos normal text-terciaria2 px-3 py-2 button-filtros-selected"
                : "textos normal text-secundaria3 px-3 py-2 button-filtros"
            }
            onClick={() => setProximos()}
          >
            Mais Próximos
          </button>
          <button
            className={
              filtros.melhores_avaliacoes
                ? "textos normal text-terciaria2 px-3 py-2 button-filtros-selected"
                : "textos normal text-secundaria3 px-3 py-2 button-filtros"
            }
            onClick={() =>
              setFiltros({
                ...filtros,
                melhores_avaliacoes: !filtros.melhores_avaliacoes,
              })
            }
          >
            Melhores Avaliações
          </button>
          <div className=" lg:w-auto">
            <DropDownFiltro
              options={[
                { value: "Qualquer", name: "Qualquer" },
                { value: "1", name: "1" },
                { value: "2", name: "2" },
                { value: "3", name: "3" },
                { value: "4", name: "4" },
                { value: "5", name: "5" },
                { value: "6", name: "6" },
                { value: "7", name: "7" },
                { value: "8", name: "8" },
                { value: "9", name: "9" },
                { value: "10+", name: "10+" },
              ]}
              pointer={filtros.travar_capacidade}
              message="Capacidade"
              value={filtros.capacidade}
              onChange={(e) =>
                setFiltros({ ...filtros, capacidade: e.target.value })
              }
            />
          </div>
          <div className=" lg:w-auto">
            <DropDownFiltro
              options={[
                { value: "Todos", name: "Todos" },
                { value: "Cafeteria", name: "Cafeteria" },
                { value: "Coworking", name: "Coworking" },
                { value: "Hotel", name: "Hotel" },
              ]}
              message="Tipo"
              value={filtros.tipo}
              onChange={(e) => setFiltros({ ...filtros, tipo: e.target.value })}
            />
          </div>
          <button
            className=" text-primarialp textos medium border border-primarialp rounded-md px-3 h-10 hover:bg-primarialp hover:text-terciaria2"
            onClick={() => limparFiltros()}
          >
            LIMPAR FILTROS
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col mt-4 gap-10">
        <div className="flex flex-row gap-1">
          <p className="button regular">Espaços encontrados:</p>{" "}
          <p className="button bold">52</p>
        </div>
        <div className="w-full flex flex-col gap-[30px]">
          {load &&
            coworkings.length > 0 &&
            coworkings.map((coworking) => {
              return (
                <div className="w-full h-auto bg-terciaria2 p-3 rounded-md relative flex lg:flex-row flex-col items-start justify-start">
                  <div className="absolute top-6 flex flex-row gap-2 right-0 mr-6 bg-terciaria2 px-3 rounded-md lg:hidden">
                    <img src={starPurple} alt="avaliação" className="w-5" />
                    <p className="argentum-medium pt-1">
                      {parseFloat(coworking.nota_coworking)}
                    </p>
                  </div>
                  <img
                    alt="Imagem do coworking"
                    src={coworking.imagem_fundo}
                    className="lg:w-4/12 w-full h-[245px] object-cover rounded-md"
                  />
                  <div className="argentum-normal-20 lg:pl-4 pt-4 lg:pt-0 lg:gap-0 gap-2 flex flex-col lg:h-[245px]">
                    <p className="argentum-normal-20">
                      {coworking.nome_unidade}
                    </p>
                    <p className="argentum-caption-15 lg:mt-4">
                      {coworking.tipo}
                    </p>
                    <p className="argentum-normal-16 text-secundaria4 lg:mt-4">
                      {coworking.endereco_coworking.logradouro},{" "}
                      {coworking.endereco_coworking.numero} -{" "}
                      {coworking.endereco_coworking.municipio} -{" "}
                      {coworking.endereco_coworking.estado}
                    </p>
                    {!filtros.proximos === "" && ""}
                    {filtros.proximos && (
                      <p className="argentum-normal-16 text-secundaria4 lg:mt-4">
                        {parseFloat(coworking.distancia) < 1
                          ? "Menos de 1 KM"
                          : `${coworking.distancia} KM`}
                      </p>
                    )}
                    {coworking.aberto === "disponivel" && (
                      <div className="flex flex-row items-center justify-start gap-2 lg:mt-4">
                        <p className="argentum-normal-16 text-secundaria4">
                          Aberto!
                        </p>
                        <img src={green} alt="aberto" className="w-3" />
                      </div>
                    )}
                    {coworking.aberto === "sem vagas" && (
                      <div className="flex flex-row items-center justify-start gap-2 lg:mt-4">
                        <p className="argentum-normal-16 text-secundaria4">
                          Sem vagas!
                        </p>
                        <img src={red} alt="aberto" className="w-3" />
                      </div>
                    )}
                    {coworking.aberto === "fechado" && (
                      <div className="flex flex-row items-center justify-start gap-2 lg:mt-4">
                        <p className="argentum-normal-16 text-secundaria4">
                          Fechado!
                        </p>
                        <img src={red} alt="aberto" className="w-3" />
                      </div>
                    )}
                  </div>
                  <div className="relative lg:items-end flex flex-grow justify-between w-full lg:w-auto lg:flex-col flex-row-reverse items-center flex-wrap lg:h-[245px] lg:pr-4 mt-5 lg:mt-0">
                    <div className="lg:flex flex-row items-center justify-center gap-3 hidden">
                      <img src={starPurple} alt="avaliação" className="w-5" />
                      <p className="argentum-medium pt-1">
                        {parseFloat(coworking.nota_coworking)}
                      </p>
                    </div>
                    {coworking.favorito ? (
                      <img
                        src={fullFav}
                        alt="Favoritar"
                        className="w-8 cursor-pointer"
                        onClick={() => favorite(coworking.uuid)}
                      />
                    ) : (
                      <img
                        src={emptyFav}
                        alt="Favoritar"
                        className="w-8 cursor-pointer"
                        onClick={() => favorite(coworking.uuid)}
                      />
                    )}

                    <button
                      className="lg:px-8 lg:py-3 px-6 py-3 rounded-md bg-primaria4"
                      onClick={() =>
                        navigate(`/parceiro/espaco/${coworking.uuid}`)
                      }
                    >
                      <p className="argentum-caption-15 text-terciaria2">
                        Consultar disponibilidade
                      </p>
                    </button>
                  </div>
                </div>
              );
            })}
          {load && coworkings.length === 0 && filtros.proximos !== true && (
            <p className="button bold">
              Nenhum espaço encontrado com os filtros selecionados.
            </p>
          )}
          {load && coworkings.length === 0 && filtros.proximos !== false && (
            <p className="button bold">
              Nenhum espaço encontrado em um raio de 20 KM com os filtros
              selecionados.
            </p>
          )}
          {nextPage ? (
            <p
              className="body medium text-primaria4 mt-4 cursor-pointer"
              onClick={() => loadMore()}
            >
              Carregar mais
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default BuscarEspaco;
