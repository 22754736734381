import React from "react";

const Switch = (props) => {
  let checked = props.checked;
  let func2 = props.func2;
  let func = props.func;
  let empresa = props.empresa;
  let colab = props.colab;
  let id = props.id;

  const handleChange = () => {
    if (func) {
      func(empresa.uuid, colab.email)
        .then((response) => {
          func2(colab.email);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <label className="switch">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onClick={() => handleChange()}
          readOnly
        />
        <span className="slider"></span>
      </label>
    </>
  );
};

export default Switch;
