import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import predio from "../../assets/icon-predio.svg";
import ButtonCounter from "../../components/ButtonCounterAssentos";
import SwitchAssentos from "../../components/SwitchAssentos";
import AuthContext from "../../hooks/AuthContext";
import GetProfileParceiro from "../../services/Parceiro/GetProfileParceiro";
import EditarDisponibilidade from "../../services/Parceiro/EditarDisponibilidade";
import changeTipo from "../../services/Parceiro/ChangeTipoCheckIn";

const Espacos = () => {
  let { user } = useContext(AuthContext);
  const [disponivel, setDisponivel] = useState("");
  const [assentos, setAssentos] = useState("");
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState({});
  const navigate = useNavigate();
  const [change, setChange] = useState("reserva");
  const [loadTipo, setLoadTipo] = useState(false);

  useEffect(() => {
    GetProfileParceiro(user.user_id).then((resp) => {
      console.log(resp);
      setDisponivel(resp.data.message.disponivel);
      setAssentos(resp.data.message.espacos_disponiveis);
      setChange(resp.data.message.tipo_reserva);
      setDados(resp.data.message);
      setLoad(true);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (disponivel !== "" && assentos !== "") {
      EditarDisponibilidade({ disponivel: disponivel, espacos_atual: assentos })
        .then((resp) => {})
        .catch((e) => console.log(e));
    }
  }, [assentos, disponivel]);

  const changeTipoCheck = (tipo) => {
    let reserva = tipo.toString();
    setLoadTipo(true);

    changeTipo({ tipo_reserva: reserva.toLowerCase() }).then((resp) => {
      setChange(tipo);
      setLoadTipo(false);
    });
  };

  return (
    <section className="p-6 lg:p-4 flex items-center justify-start bg-terciariafundo min-h-screen flex-col">
      {load && (
        <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-4 w-full lg:w-10/12 my-4 flex items-center justify-center flex-col">
          <div className="flex flex-row gap-3 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25 w-full">
            <img src={predio} alt="check-in" />
            <p className="textos semibold text-secundaria4 pt-1">
              MEU COWORKING
            </p>
          </div>
          <div className="border border-detalhes2 border-opacity-50 w-full mt-4 rounded-lg px-4">
            <div className="flex flex-col items-center justify-start lg:items-start lg:text-start text-center gap-3 first-letter py-4">
              <p className="textos medium text-secundaria5">Tipo de Check-in</p>
              <div
                className={
                  loadTipo
                    ? "flex flex-row gap-4 pointer-events-none"
                    : "flex flex-row gap-4"
                }
              >
                <div
                  className={
                    change === "reserva"
                      ? "bg-primaria4 w-auto px-4 py-3 rounded-lg cursor-pointer"
                      : "bg-detalhes2 w-auto px-4 py-3 rounded-lg cursor-pointer"
                  }
                  onClick={() => changeTipoCheck("reserva")}
                >
                  <p className="argentum-normal text-terciaria2">RESERVA</p>
                </div>
                <div
                  className={
                    change === "disponibilidade"
                      ? "bg-primaria4 w-auto px-4 py-3 rounded-lg cursor-pointer"
                      : "bg-detalhes2 w-auto px-4 py-3 rounded-lg cursor-pointer"
                  }
                  onClick={() => changeTipoCheck("disponibilidade")}
                >
                  <p className="argentum-normal text-terciaria2">
                    DISPONIBILIDADE
                  </p>
                </div>
                {loadTipo && (
                  <p className="argentum-normal py-3">Processando...</p>
                )}
              </div>
            </div>
            {change === "reserva" && (
              <div className="w-full flex flex-col items-center justify-start gap-4 py-4 lg:py-4 lg:flex-row">
                <div className="flex flex-col items-center justify-center lg:items-start lg:text-start text-center lg:w-60 gap-4 h-14">
                  <p className="textos medium text-secundaria5">
                    Tem vagas atualmente?
                  </p>
                  <div className="flex flex-row items-center justify-center gap-4">
                    <p className="textos regular text-secundaria5">Não</p>
                    <SwitchAssentos
                      checked={disponivel}
                      setChecked={setDisponivel}
                    />
                    <p className="textos regular text-secundaria5">Sim</p>
                  </div>
                </div>
                <div
                  className={
                    disponivel
                      ? "flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full lg:mt-0 mt-4 py-4 gap-4 lg:flex-row lg:w-[550px] lg:h-14"
                      : "flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full lg:mt-0 mt-4 py-4 gap-4 lg:flex-row lg:w-[550px] lg:h-14 opacity-40 pointer-events-none"
                  }
                >
                  <p className="textos regular text-secundaria3 px-2 text-center">
                    Quantidades de assentos disponíveis?
                  </p>
                  <ButtonCounter dados={assentos} setDados={setAssentos} />
                </div>
              </div>
            )}
            {change === "disponibilidade" && (
              <div className="w-full flex flex-col items-center justify-start gap-4 py-4 lg:py-4 lg:flex-row">
                <div className="flex flex-col items-center justify-center lg:items-start lg:text-start text-center lg:w-60 gap-4 h-14">
                  <p className="textos medium text-secundaria5">
                    Tem vagas atualmente?
                  </p>
                  <div className="flex flex-row items-center justify-center gap-4">
                    <p className="textos regular text-secundaria5">Não</p>
                    <SwitchAssentos
                      checked={disponivel}
                      setChecked={setDisponivel}
                    />
                    <p className="textos regular text-secundaria5">Sim</p>
                  </div>
                </div>
                <div
                  className={
                    disponivel
                      ? "flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full lg:mt-0 mt-4 py-4 gap-4 lg:flex-row lg:w-auto px-4 lg:h-14"
                      : "flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full lg:mt-0 mt-4 py-4 gap-4 lg:flex-row lg:w-auto px-4 lg:h-14 opacity-40 pointer-events-none"
                  }
                >
                  <p className="textos medium text-secundaria5">
                    Quantidades de assentos disponíveis:{" "}
                    {dados.espacos - dados.visitas_atuais}
                  </p>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center justify-center rounded-lg pb-4 lg:hidden">
              {dados.imagem ? (
                <div className="w-full h-auto rounded-t-lg">
                  <img
                    src={dados.imagem}
                    alt="Imagem do coworking"
                    className="rounded-t-lg"
                  />
                </div>
              ) : (
                ""
              )}
              <div className="border border-detalhes2 w-full flex items-center justify-center rounded-b-lg h-auto bg-terciaria2 flex-col px-5">
                <div className="flex items-center justify-center flex-col text-center py-4">
                  <p className="textos semibold">{dados.nome_unidade}</p>
                  <p className="button regular text-secundaria4">
                    {dados.endereco.logradouro},{dados.endereco.numero} -{" "}
                    {dados.endereco.municipio} - {dados.endereco.estado}
                  </p>
                </div>
                <div className="w-40 border mb-4 border-detalhes2 border-opacity-40" />
                <div className="flex items-center justify-center gap-4 flex-col w-full py-4">
                  <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3">
                    <p className="textos regular text-secundaria4">
                      Visitas atualmente: {dados.visitas_atuais}
                    </p>
                  </div>
                  <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3">
                    <p className="textos regular text-secundaria4">
                      Total de assentos: {dados.espacos}
                    </p>
                  </div>
                </div>
                <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3 mb-6">
                  <p className="textos regular text-secundaria4">
                    Total visitas: {dados.total_acessos}
                  </p>
                </div>
              </div>
              <div
                className="bg-primaria4 w-24 flex items-center justify-center h-12 rounded-b-md"
                onClick={() => navigate("/parceiro/editar-espaco")}
              >
                <p className="textos medium text-terciaria2">Editar</p>
              </div>
            </div>
            <div className="w-full py-4 h-auto hidden lg:flex items-center">
              <div className="w-[300px] h-[140px] rounded-sm">
                <img
                  src={dados.imagem}
                  alt="Imagem do coworking"
                  className="rounded-l-sm w-[300px] h-[140px] object-cover border-l border-t border-b border-opacity-40 border-detalhes2"
                />
              </div>
              <div className="w-full h-[140px] bg-terciaria2 flex flex-row border-secundaria3 border-opacity-40 border-t border-r border-b rounded-r-md items-center">
                <div className="flex flex-col w-4/12 items-start justify-center ml-8 gap-2 border-r border-secundaria3 border-opacity-40 h-fit pr-8 py-6">
                  <p className="textos semibold">{dados.nome_unidade}</p>
                  <p className="button regular text-secundaria4">
                    {dados.endereco.logradouro},{dados.endereco.numero} -{" "}
                    {dados.endereco.municipio} - {dados.endereco.estado}
                  </p>
                </div>
                <div className="flex flex-col w-3/12 ml-8 gap-3">
                  <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3">
                    <p className="textos regular text-secundaria4">
                      Visitas atualmente: {dados.visitas_atuais}
                    </p>
                  </div>
                  <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3">
                    <p className="textos regular text-secundaria4">
                      Total de assentos: {dados.espacos}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col w-3/12 ml-8 gap-3">
                  <div className="w-full border-detalhes2 border border-opacity-60 flex items-center justify-center rounded-lg py-3">
                    <p className="textos regular text-secundaria4">
                      Total visitas: {dados.total_acessos}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="h-[80px] w-[80px] bg-primaria4 flex items-center justify-center rounded-r-md cursor-pointer"
                onClick={() => navigate("/parceiro/editar-espaco")}
              >
                <p className="textos medium text-terciaria2 cursor-pointer">
                  Editar
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Espacos;
