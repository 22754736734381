import React from "react";
import pen from "../assets/pen.svg";
import check from "../assets/check.svg";
const InputEdit = (props) => {
  let {
    placer,
    background,
    tipo,
    name,
    value,
    onChange,
    maxLength,
    edit,
    setInputs,
    inputs,
    id,
  } = props;

  const bckg = background ? "bg-" + background : "bg-terciaria1";
  const edt = edit ? " " : "pointer-events-none ";
  const classe =
    "w-full relative justify-center rounded-lg border-secundaria2 border h-10 pl-5 pr-12 items-center placeholder:text-secundaria3 text-secundaria3 text-ellipsis " +
    edt +
    bckg;

  const sendAction = (field) => {
    let temp = inputs;
    temp[field] = !edit;
    setInputs({ ...temp });
  };

  return (
    <div className="w-full relative">
      <input
        className={classe}
        placeholder={placer}
        type={tipo ? tipo : "text"}
        name={name}
        onChange={onChange}
        value={value}
        maxLength={maxLength ? maxLength : ""}
        id={id}
      ></input>
      {edit ? (
        <img
          src={check}
          alt="confirmar edição"
          className="w-5 h-auto absolute top-[9px] right-5 cursor-pointer"
          onClick={() => sendAction(name)}
        />
      ) : (
        <img
          src={pen}
          alt="editar campo"
          className="w-5 h-auto absolute top-[9px] right-5 cursor-pointer"
          onClick={() => sendAction(name)}
        />
      )}
    </div>
  );
};

export default InputEdit;
