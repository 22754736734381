import axiosInstance from "../AxiosInstance";

const RemoveImageCoworking = async (uuid, cnpj) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`parceiros/exclui-imagem-coworking/`, { uuid: uuid, cnpj: cnpj })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default RemoveImageCoworking;
