import { useEffect } from "react";
import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import jwt_decode from "jwt-decode";

const LoggedHeaderContext = createContext();

export default LoggedHeaderContext;

export const LoggedHeaderProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedMenuLogged, setSelectedMenuLogged] = useState({
    actual: "",
    type: "",
  });

  useEffect(() => {
    setSelectedMenuLogged({
      ...selectedMenuLogged,
      type: user?.type
        ? localStorage.getItem("authTokens")
          ? jwt_decode(localStorage.getItem("authTokens")).type
          : null
        : "",
      actual: "",
    });

    // eslint-disable-next-line
  }, []);

  const changeMenuLogged = (newMenu) => {
    setSelectedMenuLogged({
      type: user?.type ? user.type : "",
      actual: newMenu,
    });

    let listaDefaultMenu = [
      "Login",
      "Home",
      "Sobre nós",
      "Para empresas",
      "Para colaboradores",
      "Para parceiros",
      "Parceiros",
      "Contato",
      "Espaco",
      "Cadastro empresa",
      "Cadastro parceiro",
      "Cadastro colaborador",
      "Cadastro2",
      "Termos",
      "Privacidade",
    ];

    if (listaDefaultMenu.includes(newMenu)) {
      if (newMenu === "Sobre nós") navigate("/sobre-nos");
      else if (newMenu === "Para empresas") navigate("/empresas");
      else if (newMenu === "Para colaboradores") navigate("/colaboradores");
      else if (newMenu === "Para parceiros") navigate("/parceiro");
      else if (newMenu === "Contato") navigate("/contato");
      else if (newMenu === "Login") navigate("/login");
      else if (newMenu === "Espaco") navigate("/buscar-espaco");
      else if (newMenu === "Cadastro2") navigate("/cadastro");
      else if (newMenu === "Cadastro empresa") navigate("/empresa/cadastro");
      else if (newMenu === "Cadastro parceiro") navigate("/parceiro/cadastro");
      else if (newMenu === "Cadastro colaborador")
        navigate("/colaborador/cadastro");
      else if (newMenu === "Termos") navigate("/termos");
      else if (newMenu === "Privacidade") navigate("/privacidade");
      else navigate("/home");
    } else {
      switch (selectedMenuLogged.type) {
        case "Colaborador":
          if (newMenu === "Reservas") navigate("/colaborador/reservas");
          else if (newMenu === "Favoritos") navigate("/colaborador/favoritos");
          else if (newMenu === "Histórico") navigate("/colaborador/historico");
          else navigate("/colaborador/perfil");
          break;

        case "Coworking":
          if (newMenu === "Token") navigate("/parceiro/token");
          else if (newMenu === "Solicitações")
            navigate("/parceiro/solicitacoes");
          else if (newMenu === "Reservas") navigate("/parceiro/reservas");
          else if (newMenu === "Relatórios") navigate("/parceiro/relatorios");
          else if (newMenu === "Espaços") navigate("/parceiro/espacos");
          else navigate("/parceiro/perfil");
          break;

        case "Empresa":
          if (newMenu === "Colaboradores") navigate("/empresa/colaboradores");
          else if (newMenu === "Relatório de visitas")
            navigate("/empresa/relatorios");
          else navigate("/empresa/perfil");
          break;

        default:
          break;
      }
    }
  };

  let contextData = {
    selectedMenuLogged,
    changeMenuLogged,
  };

  return (
    <LoggedHeaderContext.Provider value={contextData}>
      {children}
    </LoggedHeaderContext.Provider>
  );
};
