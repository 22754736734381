import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axiosInstance from "../services/AxiosInstance";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const loginUser = async (e, modal) => {
    e.preventDefault();

    const setModal = modal || null;

    axiosInstance
      .post("/auth/token/", {
        username: e.target.login.value.toLowerCase(),
        password: e.target.password.value,
      })
      .then((response) => {
        let data = response.data;
        if (response.status === 200) {
          setAuthTokens(data);
          setUser(jwt_decode(data.access));
          let userRedirect = jwt_decode(data.access);
          localStorage.setItem("authTokens", JSON.stringify(data));
          setModal({
            text: "Login efetuado com sucesso!",
            alertType: "ok",
            active: true,
          });
          if (userRedirect?.type === "Colaborador") {
            navigate("/colaborador/perfil/");
            return;
          } else if (userRedirect?.type === "Empresa") {
            navigate("/empresa/perfil/");
            return;
          } else if (userRedirect?.type === "Coworking") {
            navigate("/parceiro/perfil/");
            return;
          }
        }
      })
      .catch((e) => {
        setModal({
          text: "Credenciais inválidas, tente novamente!",
          alertType: "fail",
          active: true,
        });
      });
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/home");
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }

    setLoading(false);
  }, [authTokens, loading]);

  let contextData = {
    user: user,
    loginUser: loginUser,
    authTokens: authTokens,
    logoutUser: logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
