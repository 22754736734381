import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import profile from "../../assets/profile-page.svg";
// import InputEdit from "../../components/InputEdit";
import InputFixed from "../../components/InputFixed";
import maskDoc from "../../utils/maskDoc";
// import ButtonLigth from "../../components/ButtonLight";
import { useNavigate } from "react-router-dom";
import GetProfileColab from "../../services/Colaborador/GetProfileColab";

const PerfilColab = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  // const [inputsStatus, setInputsStatus] = useState({ email: false });
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    cnpj: "",
    telefone: "",
    endereco: "",
  });

  useEffect(() => {
    GetProfileColab()
      .then((response) => {
        setInputs({
          name: response.data.nome,
          email: response.data.email,
          cpf: response.data.cpf,
          telefone: response.data.telefone,
          endereco: response.data.address,
        });
        setLoad(true);
      })
      .catch((e) => {
        console.log(e);
        console.log("error");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <section className="p-6 lg:p-4 flex items-center justify-center bg-terciariafundo">
      {load && (
        <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-4 px-8 w-full lg:w-10/12 lg:my-12 my-4">
          <div className="flex w-full items-center justify-between text-center gap-2 flex-wrap border-b border-b-secundaria3 pb-2 border-opacity-25">
            <img src={profile} alt="" className="hidden" />
            <p className="textos semibold text-secundaria5">MEUS DADOS</p>
            <p
              className="textos semibold text-link cursor-pointer"
              onClick={() => navigate("/colaborador/alterar-senha")}
            >
              Alterar Senha
            </p>
          </div>
          <div className="w-full flex items-center justify-center py-4 flex-col lg:flex-row">
            <div className="flex items-center justify-center w-full flex-col lg:w-3/12">
              <img
                src={profile}
                alt="Imagem de perfil do usuário"
                className="w-16 h-auto"
              />
              {/* <p className="button regular mt-3 cursor-pointer">Editar foto</p> */}
            </div>
            <div className="flex items-center justify-center flex-col w-full py-6 lg:flex-wrap lg:flex-row gap-4 lg:px-6">
              <div className="flex flex-grow w-full lg:w-5/12">
                <InputFixed value={inputs.name} background="terciaria2" />
              </div>
              {/* <div className="flex flex-grow w-full lg:w-5/12">
                <InputEdit
                  edit={inputsStatus.email}
                  setInputs={setInputsStatus}
                  inputs={inputsStatus}
                  background="terciaria2"
                  name="email"
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs({ ...inputs, email: e.target.value })
                  }
                />
              </div> */}
              <div className="flex flex-grow w-full lg:w-5/12">
                <InputFixed value={`${inputs.email}`} background="terciaria2" />
              </div>
              <div className="flex flex-grow w-full lg:w-5/12">
                <InputFixed
                  value={`${inputs.telefone}`}
                  background="terciaria2"
                />
              </div>
              <div className="flex flex-grow w-full lg:w-5/12">
                <InputFixed
                  value={maskDoc(inputs.cpf, "cpf")}
                  background="terciaria2"
                />
              </div>
              <div className="w-full bg-terciaria2 h-auto border-secundaria2 border rounded-xl px-4 py-4 flex items-start flex-col text-start gap-8 lg:flex-row lg:items-center lg:justify-center lg:py-4">
                <div className="flex flex-col gap-3 lg:w-full">
                  <p className="textos regular text-secundaria3">{`${inputs.endereco.logradouro}, ${inputs.endereco.numero} - ${inputs.endereco.cep} - ${inputs.endereco.municipio} - ${inputs.endereco.estado}`}</p>
                </div>
                <div className="w-full flex flex-col gap-4 lg:w-6/12 lg:gap-2">
                  {/* <div className="w-full mt-4 lg:mt-0">
                    <ButtonLigth legend="ALTERAR ENDEREÇO" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-full bg-terciaria2 h-full border-secundaria2 border rounded-xl px-4 py-4 lg:mt-6 lg:w-4/12">
              <p className="textos semibold text-secundaria4 self-center text-center">
                Ver relatório
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PerfilColab;
