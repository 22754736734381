import React from "react";

const InputHorario = (props) => {
  let {
    placer,
    background,
    tipo,
    name,
    value,
    onChange,
    maxLength,
    id,
    block,
  } = props;

  const bckg = background ? "bg-" + background : "bg-terciaria1";
  const bloq = block ? " pointer-events-none" : "";
  const classe =
    "w-full justify-center rounded-lg shadow-3xl h-9 lg:pl-5 pl-3 items-center placeholder:text-secundaria3 text-secundaria3 " +
    bckg +
    bloq;

  return (
    <input
      id={id}
      className={classe}
      placeholder={placer}
      type={tipo ? tipo : "text"}
      name={name}
      onChange={onChange}
      value={value}
      maxlength={maxLength ? maxLength : ""}
    ></input>
  );
};

export default InputHorario;
