import React, { useState, useEffect, useContext } from "react";
import empresasVideo from "../../assets/empreV.mp4";
import icon1 from "../../assets/iconEmpresa1.svg";
import icon2 from "../../assets/iconEmpresa2.svg";
import icon3 from "../../assets/iconEmpresa3.svg";
import seeSpaces from "../../assets/seeSpaces.jpg";
import bemEstar from "../../assets/bemEstar.svg";
import produtividade from "../../assets/produtividade.svg";
import talento from "../../assets/talento.svg";
import engajamento from "../../assets/engajamento.svg";
import bemEstarB from "../../assets/bemEstarB.jpg";
import produtividadeB from "../../assets/produtividadeB.jpg";
import talentoB from "../../assets/talentoB.jpg";
import engajamentoB from "../../assets/engajamentoB.jpg";
import Replace from "../../assets/Replace.svg";
import ico2 from "../../assets/ico2.svg";
import ico3 from "../../assets/ico3.svg";
import ico4 from "../../assets/ico4.svg";
import ico5 from "../../assets/ico5.svg";
import ico7 from "../../assets/ico7.svg";
import rank from "../../assets/Leaderboard.svg";
import empresa from "../../assets/empresa2.jpg";
import planos from "../../assets/planos.jpg";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";
import ModalAlert from "../../components/ModalAlert";
import FormEmpresa from "../../services/LadingPage/FormEmpresa";

const ParaEmpresas = () => {
  const [change, setChange] = useState("colaborador");

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  const [inputs, setInputs] = useState({
    nome_empresa: "",
    email: "",
    telefone: "",
    colaboradores: "",
    visitas: "",
  });

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [colabs, setColabs] = useState("");

  const changeColabs = (valor) => {
    let num = "";

    if (isNaN(valor)) {
      setColabs("");
      return;
    }

    num = parseInt(valor);

    if (isNaN(num)) {
      setColabs("");
      return;
    }

    if (num <= 9) {
      setCalc({
        mes: {
          custo_espaco: num * 556,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 556 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num > 9 && num <= 49) {
      setCalc({
        mes: {
          custo_espaco: num * 772,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 772 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num >= 50 && num <= 249) {
      setCalc({
        mes: {
          custo_espaco: num * 939,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 939 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num > 249) {
      setCalc({
        mes: {
          custo_espaco: num * 1221,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 1221 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    }
    setColabs(num);
  };

  const [calc, setCalc] = useState({
    mes: {
      tempo_deslocamento: 0,
      km_deslocamento: 0,
      taxa_carbono: 0,
      custo_espaco: 0,
    },
    ano: {
      tempo_deslocamento: 0,
      km_deslocamento: 0,
      taxa_carbono: 0,
      custo_espaco: 0,
    },
  });

  const sendForm = (e) => {
    e.preventDefault();
    FormEmpresa({
      nome_empresa: e.target.nome_empresa.value,
      email: e.target.email.value,
      telefone: e.target.telefone.value,
      colaboradores: e.target.colaboradores.value,
      visitas: e.target.visitas.value,
    })
      .then((resp) => {
        setModal({
          text: "Formulário enviado com sucesso!",
          alertType: "ok",
          active: true,
        });
        setInputs({
          nome_empresa: "",
          email: "",
          telefone: "",
          colaboradores: "",
          visitas: "",
        });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Para empresas");
    }, [1]);

    changeColabs(10);

    let video = document.getElementById("empresas-video");
    video.muted = true;
    video.play();
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <section className="relative flex flex-col">
        <div className="bg-decal-parceiros">
          <video muted autoplay loop playsInline id="empresas-video">
            <source src={empresasVideo} type="video/mp4" />
          </video>
        </div>
        <div className="absolute bottom-[55px] flex px-4 items-start lg:items-start lg:justify-between lg:flex-col justify-center lg:px-[200px] flex-col lg:w-full ">
          <p className="argentum-medium-20 text-terciaria2 w-[220px] lg:w-fit flex lg:hidden lg:self-start">
            Forneça Infraestrutura adequadas e flexíveis com ampla cobertura.
          </p>
          <p className="argentum-medium-28 text-terciaria2 w-[220px] lg:w-[525px] hidden lg:flex lg:self-start">
            Forneça Infraestrutura adequadas e flexíveis com ampla cobertura.
          </p>
          <p className="argentum-caption text-terciaria2 mt-4 lg:hidden">
            Conecte seus colaboradores a milhares de espaços, tudo na palma da
            mão.
          </p>
          <div className="w-full flex items-center justify-between">
            <p className="argentum-caption-18 text-terciaria2 mt-4 lg:flex hidden w-[525px] pt-4">
              Conecte seus colaboradores a milhares de espaços, tudo na palma da
              mão.
            </p>
            <div className="div-lp w-full lg:w-[400px] lg:self-end flex items-center justify-center px-8 py-4 cursor-pointer self-center mt-8">
              <a
                href="#planos"
                className="argentum-normal text-terciaria2 hidden lg:flex"
              >
                Confira nossos planos de assinatura
              </a>
              <a
                href="#planos-mobile"
                className="argentum-normal text-terciaria2 lg:hidden"
              >
                Confira nossos planos de assinatura
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 flex items-center justify-center text-center flex-col px-4 lg:px-[200px] lg:hidden">
        <p className="argentum-medium-500 text-primaria5 lg:w-full">
          Benefícios para o seu Time
        </p>
        <div className="flex flex-col items-start justify-center w-full py-4 gap-8 px-5">
          <div className="flex flex-row items-center justify-center text-start w-full gap-2">
            <img src={icon1} className="w-[80px] h-auto mt-1" alt="casa" />
            <div className="flex flex-col items-start justify-center w-full">
              <p className="argentum-medium-12 text-detalhes1">
                Ambientes melhores
              </p>
              <p className="argentum-extralight-10">
                Aproveitar ambientes inspiradores e confortáveis que trazem a
                criatividade e qualidade no trabalho.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center text-start w-full gap-2">
            <img src={icon2} className="w-[80px] h-auto mt-1" alt="casa" />
            <div className="flex flex-col items-start justify-center w-full">
              <p className="argentum-medium-12 text-detalhes1">Mobilidade</p>
              <p className="argentum-extralight-10">
                Mobilidade de trabalhar onde quiser e quando precisar, perto de
                casa ou em regiões estratégicas.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center text-start w-full gap-2">
            <img src={icon3} className="w-[80px] h-auto mt-1" alt="casa" />
            <div className="flex flex-col items-start justify-center w-full">
              <p className="argentum-medium-12 text-detalhes1">
                Rede colaborativa
              </p>
              <p className="argentum-extralight-10">
                Aproveitar ambientes inspiradores e confortáveis que trazem a
                criatividade e qualidade no trabalho.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 items-stretch justify-center xl:px-[200px] lg:px-[50px] flex-row hidden lg:flex">
        <div className="w-6/12 h-auto relative flex items-center justify-center">
          <img src={seeSpaces} alt="ver espaços" className="w-full h-auto" />
        </div>
        <div className="w-6/12 px-8 pl-14 flex items-center justify-between min-h-full">
          <div className="justify-center items-start w-full flex-col h-full flex">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Benefícios para o seu Time
            </p>
            <div className="flex flex-col items-center justify-between h-full">
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <img src={icon1} className="w-[65px] h-auto mt-1" alt="casa" />
                <div className="flex flex-col items-start justify-center w-full">
                  <p className="argentum-medium-16 text-detalhes1">
                    Ambientes melhores
                  </p>
                  <p className="argentum-extralight-14">
                    Aproveitar ambientes inspiradores e confortáveis que trazem
                    a criatividade e qualidade no trabalho.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <img src={icon2} className="w-[65px] h-auto mt-1" alt="carro" />
                <div className="flex flex-col items-start justify-center w-full">
                  <p className="argentum-medium-16 text-detalhes1">
                    Mobilidade
                  </p>
                  <p className="argentum-extralight-14">
                    Mobilidade de trabalhar onde quiser e quando precisar, perto
                    de casa ou em regiões estratégicas.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <img
                  src={icon3}
                  className="w-[65px] h-auto mt-1"
                  alt="pessoas"
                />
                <div className="flex flex-col items-start justify-center w-full">
                  <p className="argentum-medium-16 text-detalhes1">
                    Rede colaborativa
                  </p>
                  <p className="argentum-extralight-14">
                    Aproveitar ambientes inspiradores e confortáveis que trazem
                    a criatividade e qualidade no trabalho.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 flex items-center justify-center lg:px-[200px] flex-col w-full lg:flex-row lg:hidden">
        <div className="relative flex items-center justify-center h-fit w-full lg:w-auto">
          <img src={bemEstar} alt="bem estar" className="w-full h-auto" />
          <div className="absolute flex items-center justify-center flex-col gap-4">
            <p className="argentum-medium-20 text-terciaria2">BEM ESTAR</p>
          </div>
        </div>
        <div className="relative flex items-center justify-center h-fit w-full">
          <img src={produtividade} alt="bem estar" className="w-full h-auto " />
          <div className="absolute flex items-center justify-center flex-col gap-4">
            <p className="argentum-medium-20 text-terciaria2">Produtividade</p>
          </div>
        </div>
        <div className="relative flex items-center justify-center h-fit w-full">
          <img src={engajamento} alt="bem estar" className="w-full h-auto" />
          <div className="absolute flex items-center justify-center flex-col gap-4">
            <p className="argentum-medium-20 text-terciaria2">Engajamento</p>
          </div>
        </div>
        <div className="relative flex items-center justify-center h-fit w-full">
          <img src={talento} alt="bem estar" className="w-full h-auto" />
          <div className="absolute flex items-center justify-center flex-col gap-4">
            <p className="argentum-medium-20 text-terciaria2">Talento</p>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 flex-row items-center justify-center px-[50px] xl:px-[200px] w-full hidden lg:flex">
        <div className="w-3/12 h-auto flex items-start justify-start relative">
          <img
            src={bemEstarB}
            alt="bem-estart"
            className="w-full h-[500px] object-cover"
          />
          <div className="w-full h-full bg-detalhes1 absolute opacity-50"></div>
          <div className="absolute flex items-center justify-center flex-col gap-4 mt-[150px]">
            <p className="argentum-medium-20 text-terciaria2">BEM ESTAR</p>
            <p className="argentum-extralight-24 text-terciaria2 w-10/12 text-center">
              Melhore a qualidade de vida de todos os colaboradores com uma
              solução de mobilidade efetiva.
            </p>
          </div>
        </div>
        <div className="w-3/12 h-auto flex items-start justify-start relative">
          <img
            src={produtividadeB}
            alt="bem-estart"
            className="w-full h-[500px] object-cover"
          />
          <div className="w-full h-full bg-detalhes1 absolute opacity-50"></div>
          <div className="absolute flex items-center justify-center flex-col gap-4 mt-[150px]">
            <p className="argentum-medium-20 text-terciaria2">PRODUTIVIDADE</p>
            <p className="argentum-extralight-24 text-terciaria2 w-10/12 text-center">
              Colaboradores com total gestão e aproveitamento do seu tempo e
              espaço.
            </p>
          </div>
        </div>
        <div className="w-3/12 h-auto flex items-start justify-start relative">
          <img
            src={engajamentoB}
            alt="bem-estart"
            className="w-full h-[500px] object-cover"
          />
          <div className="w-full h-full bg-detalhes1 absolute opacity-50"></div>
          <div className="absolute flex items-center justify-center flex-col gap-4 mt-[150px]">
            <p className="argentum-medium-20 text-terciaria2">ENGAJAMENTO</p>
            <p className="argentum-extralight-24 text-terciaria2 w-10/12 text-center">
              Aumente os relacionamentos e criatividade, construindo uma força
              de trabalho mais feliz e engajada.
            </p>
          </div>
        </div>
        <div className="w-3/12 h-auto flex items-start justify-start relative">
          <img
            src={talentoB}
            alt="bem-estart"
            className="w-full h-[500px] object-cover"
          />
          <div className="w-full h-full bg-detalhes1 absolute opacity-50"></div>
          <div className="absolute flex items-center justify-center flex-col gap-4 mt-[150px]">
            <p className="argentum-medium-20 text-terciaria2">TALENTO</p>
            <p className="argentum-extralight-24 text-terciaria2 w-10/12 text-center">
              Empresas que oferecem grandes benefícios tem uma vantagem na
              competitividade no recrutamento e retenção.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-primaria5 py-8 px-4 flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-between lg:flex-row lg:px-20">
          <div className="w-full flex flex-col lg:w-6/12 lg:pr-[35px] mt-8">
            <p className="argentum-medium-20 text-terciaria2 ">
              Simule os benefícios que serão gerados pela sua empresa e para a
              sua empresa!
            </p>
            <p className="argentum-extralight-2 text-terciaria2 mt-8 text-start pr-5">
              Descubra quanto você pode economizar com a ON! Use nossa
              calculadora interativa para estimar seus benefícios financeiros,
              desde economias mensais até redução de custos anuais. Saiba como
              nossa plataforma pode ajudar a impulsionar sua eficiência,
              aumentar sua produtividade e reduzir seus gastos. Experimente
              agora e veja o que podemos fazer por você!
            </p>
          </div>
          <div className="flex flex-col w-full lg:w-6/12 self-center">
            <p className="argentum-caption-low text-terciaria2 mt-8 text-center">
              Digite a quantidade de colaboradores da sua empresa
            </p>
            <input
              className="w-full mt-3 bg-terciaria2 h-12 rounded-md text-center"
              placeholder="Digite a quantidade de colaboradores da sua empresa"
              onChange={(e) => changeColabs(e.target.value)}
              value={colabs}
            ></input>
          </div>
        </div>
        <div className="flex flex-row pt-32 items-center justify-between lg:px-20 gap-4">
          <div className="flex flex-col w-6/12 gap-4">
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Tempo de deslocamento
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              KM por deslocamento
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Taxa de Carbono
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Custo de espaço
            </p>
          </div>
          <div className="flex flex-col gap-4 w-3/12">
            <div className="h-auto relative">
              <p className="w-full argentum-normal absolute h-14 -top-[70px] text-terciaria2 flex items-center justify-center bg-primaria2lp z-[20] rounded-lg">
                Mês
              </p>
              <p className="w-full argentum-normal h-14 flex items-center justify-center z-[21] bg-terciaria2 rounded-lg">
                {calc.mes.tempo_deslocamento} Horas
              </p>
            </div>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.mes.km_deslocamento} KM
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.mes.taxa_carbono}
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              R$ {calc.mes.custo_espaco.toLocaleString("pt-BR")}
            </p>
          </div>
          <div className="flex flex-col gap-4 w-3/12">
            <div className="h-auto relative">
              <p className="w-full argentum-normal absolute h-14 -top-[70px] text-terciaria2 flex items-center justify-center bg-primaria2lp z-[20] rounded-lg">
                Ano
              </p>
              <p className="w-full argentum-normal h-14 flex items-center justify-center z-[21] bg-terciaria2 rounded-lg">
                {calc.ano.tempo_deslocamento} Horas
              </p>
            </div>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.ano.km_deslocamento} KM
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.ano.taxa_carbono}
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              R$ {calc.ano.custo_espaco.toLocaleString("pt-BR")}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 lg:hidden">
        <p className="argentum-medium text-primaria5 lg:px-20 px-4 lg:w-12">
          COMO FUNCIONA?
        </p>
        <div className="flex flex-row items-center justify-center gap-2 pt-8 lg:hidden">
          <div
            className={
              change === "empresas"
                ? "bg-primaria4 w-fit px-4 py-3 rounded-lg"
                : "bg-detalhes2 w-fit px-4 py-3 rounded-lg"
            }
            onClick={() => setChange("empresas")}
          >
            <p className="argentum-normal text-terciaria2">Para empresa</p>
          </div>
          <img src={Replace} alt="alternar" />
          <div
            className={
              change === "colaborador"
                ? "bg-primaria4 w-fit px-4 py-3 rounded-lg"
                : "bg-detalhes2 w-fit px-4 py-3 rounded-lg"
            }
            onClick={() => setChange("colaborador")}
          >
            <p className="argentum-normal text-terciaria2">Para colaborador</p>
          </div>
        </div>
        {change === "colaborador" && (
          <div className="flex flex-col px-4 py-10 h-[450px] lg:hidden pr-10">
            <p className="argentum-normal-600">Receba o convite</p>
            <p className="argentum-caption-400 mt-3">
              A empresa cadastra o colaborador no beneficio e o colaborador
              recebe um e-mail para ativação.
            </p>
            <p className="argentum-normal-600 mt-8">Agende quando quiser</p>
            <p className="argentum-caption-400 mt-3">
              Com diversos espaços compartilhados e flexíveis espalhados por
              cada m² quadrado desse país, o colaborador escolhe o melhor
              conforme sua necessidade, quando e onde quiser.
            </p>
            <p className="argentum-normal-600 mt-8">
              Acompanha e gerencie seu plano
            </p>
            <p className="argentum-caption-400 mt-3">
              O colaborador terá acesso a agenda, histórico de visitações,
              avaliações realizadas e a realizar e acompanhar toda suas
              atividades em nossa plataforma.
            </p>
          </div>
        )}
        {change === "empresas" && (
          <div className="flex flex-col px-4 py-10 h-[450px] lg:hidden pr-10">
            <p className="argentum-normal-600">Contrata o benefício</p>
            <p className="argentum-caption-400 mt-3">
              A empresa escolhe o plano e a quantidade de colaboradores conforme
              suas necessidades.
            </p>
            <p className="argentum-normal-600 mt-8">
              Disponibiliza para os colaboradores
            </p>
            <p className="argentum-caption-400 mt-3">
              A empresa cadastra, disponibiliza e gerencia os planos para seus
              colaboradores
            </p>
            <p className="argentum-normal-600 mt-8">
              Acompanha todos os indicadores
            </p>
            <p className="argentum-caption-400 mt-3">
              A empresa companha o nível de satisfação, avaliação sobre os
              espaços e demais gestão das necessidades de forma prática,
              assertiva e eficiente.
            </p>
          </div>
        )}
        <div className="items-center justify-between py-6 hidden lg:flex">
          <div className="self-start w-6/12 pr-10"></div>
          <div className="items-start justify-start w-6/12 px-20 flex-col ">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Para sua empresa
            </p>
            <div className="flex flex-col gap-3 pl-3">
              <p className="argentum-normal-600">Contrata o benefício</p>
              <p className="argentum-caption-400">
                A empresa escolhe o plano e a quantidade de colaboradores
                conforme suas necessidades.
              </p>
              <p className="argentum-normal-600 mt-8">
                Disponibiliza para os colaboradores
              </p>
              <p className="argentum-caption-400">
                A empresa cadastra, disponibiliza e gerencia os planos para seus
                colaboradores
              </p>
              <p className="argentum-normal-600 mt-8">
                Acompanha todos os indicadores
              </p>
              <p className="argentum-caption-400">
                A empresa companha o nível de satisfação, avaliação sobre os
                espaços e demais gestão das necessidades de forma prática,
                assertiva e eficiente.
              </p>
            </div>
          </div>
        </div>
        <div className="items-center justify-between py-6 hidden lg:flex">
          <div className="flex items-start justify-start w-6/12 px-20 flex-col ">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Para colaboradores
            </p>
            <div className="flex flex-col gap-3 pl-3">
              <p className="argentum-normal-600">Receba o convite</p>
              <p className="argentum-caption-400">
                A empresa cadastra o colaborador no beneficio e o colaborador
                recebe um e-mail para ativação.
              </p>
              <p className="argentum-normal-600 mt-8">Agende quando quiser</p>
              <p className="argentum-caption-400">
                Com diversos espaços compartilhados e flexíveis espalhados por
                cada m² quadrado desse país, o colaborador escolhe o melhor
                conforme sua necessidade, quando e onde quiser.
              </p>
              <p className="argentum-normal-600 mt-8">
                Acompanha e gerencie seu plano
              </p>
              <p className="argentum-caption-400">
                O colaborador terá acesso a agenda, histórico de visitações,
                avaliações realizadas e a realizar e acompanhar toda suas
                atividades em nossa plataforma.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-16 items-stretch justify-center xl:px-[200px] lg:px-[50px] flex-row hidden lg:flex">
        <div className="w-6/12 pr-16 flex items-center justify-between min-h-full">
          <div className="justify-center items-start w-full flex-col h-full flex">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Para sua empresa
            </p>
            <div className="flex flex-col items-center justify-between h-full pl-4">
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <div className="flex flex-col items-start justify-center w-full gap-2">
                  <p className="argentum-medium-16 text-detalhes1">
                    Contrata o benefício
                  </p>
                  <p className="argentum-extralight-14">
                    A empresa escolhe o plano e a quantidade de colaboradores
                    conforme suas necessidades.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <div className="flex flex-col items-start justify-center w-full gap-2">
                  <p className="argentum-medium-16 text-detalhes1">
                    Disponibiliza para os colaboradores
                  </p>
                  <p className="argentum-extralight-14">
                    A empresa cadastra, disponibiliza e gerencia os planos para
                    seus colaboradores
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center text-start w-full gap-2">
                <div className="flex flex-col items-start justify-center w-full gap-2">
                  <p className="argentum-medium-16 text-detalhes1">
                    Acompanha todos os indicadores
                  </p>
                  <p className="argentum-extralight-14">
                    A empresa companha o nível de satisfação, avaliação sobre os
                    espaços e demais gestão das necessidades de forma prática,
                    assertiva e eficiente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-6/12 h-auto relative flex items-center justify-center">
          <img src={empresa} alt="para sua empresa" className="w-full h-auto" />
          <div className="div-lp w-5/12 lg:self-end flex items-center justify-center px-8 py-4 cursor-pointer self-center mt-8 absolute bottom-4">
            <a className="argentum-normal text-terciaria2" href="#planos">
              Ver planos
            </a>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-4 xl:px-[200px] lg:px-[50px] px-4">
        <p className="argentum-medium-500 text-primaria5 w-full lg:w-full">
          Você terá acesso á:
        </p>
        <p className="argentum-caption-18 text-detalhes1 mt-8 flex lg:pt-8">
          Descubra todas as funções incríveis que a nossa plataforma tem para
          oferecer! Junte-se à nós e descubra como você pode aproveitar ao
          máximo cada recurso disponível para alcançar seus objetivos.
        </p>
        <div className="flex-row flex-wrap gap-14 lg:py-20 py-16 hidden lg:flex px-8">
          <div className="flex flex-row items-center justify-center gap-3 w-[280px]">
            <img src={ico2} alt="icone 1" />
            <p className="argentum-medium-18">
              GESTÃO DE ACESSOS & RELATÓRIOS DE USO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3 w-[280px]">
            <img src={ico3} alt="icone 2" />
            <p className="argentum-medium-18">GESTÃO E CONTROLE DO PLANO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico5} alt="icone 3" />
            <p className="argentum-medium-18">CONTROLE DE RESERVAS</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico4} alt="icone 4" />
            <p className="argentum-medium-18 w-[350px]">
              SATISFAÇÃO DOS COLABORADORES & AVALIAÇÃO DO SEU ESPAÇO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico7} alt="icone 5" />
            <p className="argentum-medium-18">DASHBOARD ESTRATÉGICO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={rank} alt="icone 6" />
            <p className="argentum-medium-18">RANKING DE USO</p>
          </div>
        </div>
        <div className="flex-row flex-wrap gap-14 lg:py-20 py-16 flex lg:hidden">
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico2} alt="icone 4" className="w-8" />
            <p className="argentum-medium-15">
              GESTÃO DE ACESSOS & RELATÓRIO DE USO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico4} alt="icone 6" className="w-8" />
            <p className="argentum-medium-15">
              SATISFAÇÃO DOS COLABORADORES & AVALIAÇÃO DO ESPAÇO
            </p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico3} alt="icone 7" className="w-8" />
            <p className="argentum-medium-15">GESTÃO DE CONTROLE DO PLANO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico7} alt="icone 2" className="w-8" />
            <p className="argentum-medium-15">DASHBOARD ESTRATÉGICO</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={ico5} alt="icone 5" className="w-8" />
            <p className="argentum-medium-15">CONTROLE DE RESERVAS</p>
          </div>
        </div>
      </section>
      <section
        className="py-8 bg-terciariafundo px-4 lg:hidden"
        id="planos-mobile"
      >
        <p className="argentum-medium-500 text-detalhes1 w-[300px] lg:w-full">
          Aproveite ao máximo nossos serviços
        </p>
        <p className="argentum-caption-18 text-detalhes1 py-4 flex lg:pt-8">
          Encontre o plano de assinatura perfeito para você - escolha entre as
          nossas opções personalizadas e comece a aproveitar ao máximo os nossos
          serviços hoje mesmo!
        </p>
        <div className="flex items-start justify-between flex-row">
          <div className="w-6/12 pr-2 flex items-center justify-center flex-col gap-4">
            <div className="w-full bg-primaria4 flex items-center justify-center py-4 rounded-t-lg">
              <p className="argentum-caption-162 text-terciaria2">
                Planos Básicos
              </p>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria4 flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Basic I</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">69,90</p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b border-opacity-70 px-1 border-detalhes1">
                  8h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2">1 check-in ao dia</p>
                <button className=" bg-primaria4 text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria4 flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Basic II</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">124,90</p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b border-opacity-70 px-1 border-detalhes1">
                  16h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2">1 check-in ao dia</p>
                <button className=" bg-primaria4 text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria4 flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Basic III</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">179,90</p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b border-opacity-70 px-1 border-detalhes1">
                  24h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2">1 check-in ao dia</p>
                <button className=" bg-primaria4 text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria4 flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Basic IV</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">224,88</p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b border-opacity-70 px-1 border-detalhes1">
                  32h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2">1 check-in ao dia</p>
                <button className=" bg-primaria4 text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
          </div>
          <div className="w-6/12 pl-2 flex items-center justify-center flex-col gap-4">
            <div className="w-full bg-primaria5 flex items-center justify-center py-4 rounded-t-lg">
              <p className="argentum-caption-162 text-terciaria2">
                Planos Avançados
              </p>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria3lp flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Silver</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 pt-2 text-terciaria2">
                    434,90
                  </p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b px-1 border-terciaria2 text-terciaria2">
                  64h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2 text-terciaria2">
                  2 check-in ao dia
                </p>
                <button className=" bg-primarialp text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-primaria3lp flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Gold</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 pt-2 text-terciaria2">
                    629,90
                  </p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b px-1 border-terciaria2 text-terciaria2">
                  96h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2 text-terciaria2">
                  2 check-in ao dia
                </p>
                <button className=" bg-primarialp text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-detalhes1 flex items-center justify-center">
                <p className="argentum-normal-20 text-terciaria2">Platinum</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 pt-2 text-terciaria2">
                    754,90
                  </p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b px-1 border-terciaria2 text-terciaria2">
                  128h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2 text-terciaria2">
                  3 check-in ao dia
                </p>
                <button className=" bg-primarialp text-terciaria2 px-6 py-3 mt-3 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full rounded-t-lg bg-detalhes1 flex items-center justify-center">
                <p className="argentum-normal-20 text-detalhes5">Black</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-6">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 pt-2 text-terciaria2">
                    829,90
                  </p>
                </div>
                <p className="argentum-extralight-4 py-2 border-b px-1 border-terciaria2 text-terciaria2">
                  176h de uso/ mês
                </p>
                <p className="argentum-extralight-4 py-2 text-terciaria2">
                  Check-in ilimitado!
                </p>
                <button className=" bg-primaria3lp text-detalhes5 px-6 py-2 mt-3 argentum-medium-18 rounded-lg">
                  Contratar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="py-16 pb-32 hidden lg:flex bg-terciariafundo lg:px-[200px] flex-col items-center justify-center"
        id="planos"
      >
        <p className="argentum-medium-500 text-detalhes1 w-[300px] lg:w-full">
          Aproveite ao máximo nossos serviços
        </p>
        <p className="argentum-caption-18 text-detalhes1 py-4 flex lg:pt-8">
          Encontre o plano de assinatura perfeito para você - escolha entre as
          nossas opções personalizadas e comece a aproveitar ao máximo os nossos
          serviços hoje mesmo!
        </p>
        <div className="flex items-start justify-start w-full relative overflow-hidden">
          <div className="w-4/12 flex flex-col items-center justify-center pr-2 gap-2">
            <div className="w-full flex items-center justify-center bg-primaria4 h-[55px] rounded-t-md">
              <p className="argentum-caption-162 text-terciaria2">
                Planos Básicos
              </p>
            </div>
            <div className="w-full mt-2">
              <div className="w-full py-1 bg-primaria4 flex items-center justify-center rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Basic I</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">69,90</p>
                </div>
                <button className=" bg-primaria5 text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-3 border-detalhes1">
                  8h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4">1 check-in ao dia</p>
              </div>
            </div>
            <div className="w-full mt-2">
              <div className="w-full py-1 bg-primaria4 flex items-center justify-center rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Basic II</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">124,90</p>
                </div>
                <button className=" bg-primaria5 text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-detalhes1">
                  16h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4">1 check-in ao dia</p>
              </div>
            </div>
            <div className="w-full mt-2">
              <div className="w-full py-1 bg-primaria4 flex items-center justify-center rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Basic III</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">179,90</p>
                </div>
                <button className=" bg-primaria5 text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-detalhes1">
                  24h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4">1 check-in ao dia</p>
              </div>
            </div>
            <div className="w-full mt-2">
              <div className="w-full py-1 bg-primaria4 flex items-center justify-center rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Basic IV</p>
              </div>
              <div className="w-full bg-terciaria2 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10">R$</p>
                  <p className="argentum-normal-20 pt-2">224,88</p>
                </div>
                <button className=" bg-primaria5 text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-detalhes1">
                  32h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4">1 check-in ao dia</p>
              </div>
            </div>
          </div>
          <div className="w-4/12 flex flex-col items-center justify-center px-2 gap-2">
            <div className="w-full flex items-center justify-center bg-primaria5 h-[55px] rounded-t-md">
              <p className="argentum-medium-18 text-terciaria2">
                Planos Avançados
              </p>
            </div>
            <div className="w-full ">
              <div className="w-full py-1 bg-primaria3lp flex items-center justify-center mt-2 rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Silver</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 text-terciaria2 pt-2">
                    434,90
                  </p>
                </div>
                <button className=" bg-primarialp text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-terciaria2 text-terciaria2">
                  64h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4 text-terciaria2">
                  2 check-in ao dia
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full py-1 bg-primaria3lp flex items-center justify-center mt-2 rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Gold</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 text-terciaria2 pt-2">
                    629,90
                  </p>
                </div>
                <button className=" bg-primarialp text-terciaria2 px-6 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-terciaria2 text-terciaria2">
                  96h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4 text-terciaria2">
                  2 check-in ao dia
                </p>
              </div>
            </div>
          </div>
          <div className="w-4/12 flex flex-col items-center justify-center pl-2 gap-2">
            <div className="w-full flex items-center justify-center bg-primaria5 h-[55px] rounded-t-md">
              <p className="argentum-medium-18 text-detalhes5">
                Planos Premium
              </p>
            </div>
            <div className="w-full ">
              <div className="w-full py-1 bg-detalhes1 flex items-center justify-center mt-2 rounded-t-md">
                <p className="argentum-normal-16 text-terciaria2">Platinum</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 text-terciaria2 pt-2">
                    754,90
                  </p>
                </div>
                <button className=" bg-primaria3lp text-terciaria2 px-12 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  Contratar
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-terciaria2 text-terciaria2">
                  128h de uso/ mês
                </p>
                <p className="argentum-extralight-4 mt-4 text-terciaria2">
                  3 check-in ao dia
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full py-1 bg-detalhes1 flex items-center justify-center mt-2 rounded-t-md">
                <p className="argentum-normal-16 text-detalhes5">Black</p>
              </div>
              <div className="w-full bg-primaria5 rounded-b-lg flex flex-col items-center justify-center pb-3">
                <div className="flex items-center justify-center flex-row gap-2">
                  <p className="argentum-normal-10 text-terciaria2">R$</p>
                  <p className="argentum-normal-20 text-terciaria2 pt-2">
                    829,90
                  </p>
                </div>
                <button className=" bg-primaria3lp text-detalhes5 px-8 py-1 mt-1 mb-1 argentum-caption-162 rounded-lg">
                  CONTRATAR
                </button>
                <p className="argentum-extralight-4 py-3 border-b border-opacity-70 px-7 border-terciaria2 text-terciaria2">
                  176h de uso/ mês
                </p>
                <p className="argentum-medium-15 mt-3 text-terciaria2">
                  Check-in ilimitado!
                </p>
              </div>
            </div>
          </div>
          <div className="w-8/12 absolute bottom-0 right-0 h-[413px] rounded-md pl-2 flex items-center">
            <p className="argentum-medium-28 text-terciaria2 w-[220px] bottom-[180px] left-[70px] lg:w-[350px] hidden lg:flex lg:self-start absolute z-20">
              Mais que um benefício, uma necessidade!
            </p>
            <p className="argentum-caption absolute z-20 text-terciaria2 bottom-[135px] left-[70px]">
              Se é bom para o colaborador, é bom para a empresa!
            </p>
            <img
              src={planos}
              alt="planos"
              className="absolute w-full h-[413px] object-cover rounded-md"
            />
            <div className="w-full h-[413px] bg-detalhes1 opacity-70 rounded-md"></div>
          </div>
        </div>
      </section>
      <section className="bg-decal-22 lg:flex-row flex-col-reverse flex items-start justify-center py-8 w-full lg:px-8 px-3">
        <div className="w-full items-center justify-center text-center lg:hidden">
          <p className="argentum-caption-16 text-terciaria2 mt-12 lg:hidden text-center">
            contato@onworkingpass.com
          </p>
        </div>
        <div className="flex items-center justify-center lg:w-6/12 mt-8 lg:mt-0 w-full">
          <form
            className="bg-terciaria2 lg:w-8/12 max-w-[600px] w-full px-8 py-12 gap-6 flex items-center justify-center flex-col rounded-md"
            onSubmit={(e) => sendForm(e)}
          >
            <input
              placeholder="Nome empresa"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="nome_empresa"
              value={inputs.nome_empresa}
              onChange={(e) =>
                setInputs({ ...inputs, nome_empresa: e.target.value })
              }
            />
            <input
              placeholder="Email"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <input
              placeholder="Telefone"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="telefone"
              value={inputs.telefone}
              onChange={(e) =>
                setInputs({ ...inputs, telefone: e.target.value })
              }
            />
            <input
              placeholder="Quantidade de colaboradores"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="colaboradores"
              value={inputs.colaboradores}
              onChange={(e) =>
                setInputs({ ...inputs, colaboradores: e.target.value })
              }
            />
            <textarea
              className="w-full bg-secundaria1 rounded-md placeholder:text-secundaria4 pl-4 pt-3 resize-none min-h-[150px]"
              placeholder="Quantidade de visitas por mês"
              name="visitas"
              value={inputs.visitas}
              onChange={(e) =>
                setInputs({ ...inputs, visitas: e.target.value })
              }
            />
            <button
              className="w-full h-14 argentum-normal text-terciaria2 rounded-sm"
              id="send-lp-form"
            >
              ENVIAR
            </button>
          </form>
        </div>
        <div className="lg:ml-20 lg:w-6/12 w-full flex items-start justify-start flex-col lg:gap-[40px] gap-12 py-8">
          <p className="argentum-medium text-detalhes4">Fale conosco</p>
          <p className="argentum-medium-36 text-terciaria2 lg:w-[450px]">
            Contratar benefício
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[650px]">
            Conte conosco para oferecer muito mais do que um benefício - um
            apoio completo para aumentar a produtividade e extensão da sua
            empresa.
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[520px] mt-12 hidden lg:flex">
            contato@onworkingpass.com
          </p>
        </div>
      </section>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default ParaEmpresas;
