function maskDoc(v, mask) {
  v = v.replace(/\D/g, "");

  if (mask === "cpf") {
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else if (mask === "cnpj") {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
  } else if (mask === "cep") {
    v = v.replace(/[^\d]+/g, "");
    return v;
  } else {
    return v;
  }

  return v;
}

export default maskDoc;
