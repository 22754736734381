import React, { useContext, useState } from "react";
import check from "../../assets/checkInGrey.svg";
import ButtonLight from "../../components/ButtonLight";
import Input from "../../components/Input";
import exit from "../../assets/exit.svg";
import CheckReserva from "../../services/Parceiro/CheckReserva";
import ModalAlert from "../../components/ModalAlert";
import AuthContext from "../../hooks/AuthContext";
import ConsultaReserva from "../../services/Parceiro/ConsultaReserva";
import left from "../../assets/leftGray.svg";

const Token = () => {
  const [checks, setChecks] = useState({ checkIn: "", checkOut: "" });
  let { user } = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    data: "",
    email: "",
    hora_fim: "",
    hora_inicio: "",
    name: "",
    tipo: "",
  });
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const handleBack = () => {
    setInputs({
      data: "",
      email: "",
      hora_fim: "",
      hora_inicio: "",
      name: "",
      tipo: "",
    });
    setChecks({ checkIn: "", checkOut: "" });
  };

  const handleCheckIn = () => {
    let newDate = new Date();
    CheckReserva({
      token: checks.checkIn,
      tipo: "checkIn",
      hora: newDate,
      coworking: user.uuid,
    })
      .then((resp) => {
        setModal({
          text: "Check-in realizado com sucesso!",
          alertType: "ok",
          active: true,
        });
        setInputs({
          data: "",
          email: "",
          hora_fim: "",
          hora_inicio: "",
          name: "",
          tipo: "",
        });
        setChecks({ checkIn: "", checkOut: "" });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  const consultaCheckIn = () => {
    ConsultaReserva({
      token: checks.checkIn,
      tipo: "checkIn",
      coworking: user.uuid,
    })
      .then((resp) => {
        let newDate = new Date(resp.data.hora_inicio);
        let newDate2 = new Date(resp.data.hora_fim);
        setInputs({
          data: resp.data.data,
          email: resp.data.email,
          hora_inicio: newDate,
          hora_fim: newDate2,
          name: resp.data.name,
          tipo: "check-in",
        });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  const consultaCheckOut = () => {
    ConsultaReserva({
      token: checks.checkOut,
      tipo: "checkOut",
      coworking: user.uuid,
    })
      .then((resp) => {
        let newDate = new Date(resp.data.hora_inicio);
        let newDate2 = new Date(resp.data.hora_fim);
        setInputs({
          data: resp.data.data,
          email: resp.data.email,
          hora_inicio: newDate,
          hora_fim: newDate2,
          name: resp.data.name,
          tipo: "check-out",
        });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  const handleCheckOut = () => {
    let newDate = new Date();
    CheckReserva({
      token: checks.checkOut,
      tipo: "checkOut",
      hora: newDate,
      coworking: user.uuid,
    })
      .then((resp) => {
        setModal({
          text: "Check-Out realizado com sucesso!",
          alertType: "ok",
          active: true,
        });
        setInputs({
          data: "",
          email: "",
          hora_fim: "",
          hora_inicio: "",
          name: "",
          tipo: "",
        });
        setChecks({ checkIn: "", checkOut: "" });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  return (
    <section className="p-6 lg:p-4 flex items-center justify-start bg-terciariafundo min-h-screen flex-col">
      {inputs.tipo === "" && (
        <>
          <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-8 w-full lg:w-10/12 my-4">
            <div className="flex flex-row gap-2 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25">
              <img src={check} alt="check-in" />
              <p className="textos semibold text-secundaria4">
                REALIZAR CHECK-IN
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-around">
              <div className="w-full md:w-4/12 mt-6">
                <Input
                  background="terciariafundo"
                  placer="Digite o token do visitante"
                  name="checkIn"
                  value={checks.checkIn}
                  onChange={(e) =>
                    setChecks({ ...checks, checkIn: e.target.value })
                  }
                />
              </div>
              <div className="w-full md:w-4/12 mt-6">
                <ButtonLight
                  legend="REALIZAR"
                  onClick={() => consultaCheckIn()}
                />
              </div>
            </div>
          </div>
          <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-8 w-full lg:w-10/12 mt-8 my-4">
            <div className="flex flex-row gap-2 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25">
              <img src={exit} alt="check-out" />
              <p className="textos semibold text-secundaria4">
                REALIZAR CHECK-OUT
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-around">
              <div className="w-full md:w-4/12 mt-6">
                <Input
                  background="terciariafundo"
                  placer="Digite o token do visitante"
                  name="checkOut"
                  value={checks.checkOut}
                  onChange={(e) =>
                    setChecks({ ...checks, checkOut: e.target.value })
                  }
                />
              </div>
              <div className="w-full md:w-4/12 mt-6">
                <ButtonLight
                  legend="REALIZAR"
                  onClick={() => consultaCheckOut()}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {inputs.tipo === "check-in" && (
        <>
          <img
            src={left}
            alt="voltar"
            className="self-start py-4 cursor-pointer"
            onClick={() => handleBack()}
          />
          <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-8 w-full lg:w-8/12 my-4 lg:flex lg:flex-col">
            <div className="flex flex-row gap-2 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25">
              <img src={check} alt="check-in" />
              <p className="textos semibold text-secundaria4">
                REALIZAR CHECK-IN
              </p>
            </div>
            <div className="w-full flex items-center justify-center mt-4 flex-col gap-4 lg:flex-row lg:flex-wrap lg:bg-terciaria2 lg:gap-4 lg:border lg:border-secundaria2 lg:pb-8 lg:mt-12 lg:rounded-t-lg lg:rounded-b-lg">
              <div className="w-full border lg:border-t-0 lg:border-r-0 lg:border-l-0 lg:border-b lg:border-b-secundaria2 border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:rounded-t-lg">
                <p className="textos semibold text-secundaria5">
                  {checks.checkIn}
                </p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">{inputs.name}</p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">
                  {inputs.email}
                </p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">{inputs.data}</p>
              </div>
              <div className="flex items-center justify-between w-full lg:w-5/12">
                <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg">
                  <p className="textos regular text-secundaria3">
                    {inputs.hora_inicio.getHours()}:
                    {inputs.hora_inicio.getMinutes()}h
                  </p>
                </div>
                <p className="textos regular text-secundaria3">às</p>
                <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg">
                  <p className="textos regular text-secundaria3">
                    {inputs.hora_fim.getHours()}:{inputs.hora_fim.getMinutes()}h
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mt-2 lg:w-4/12 lg:self-center lg:mt-12">
              <ButtonLight legend="REALIZAR" onClick={() => handleCheckIn()} />
            </div>
          </div>
        </>
      )}
      {inputs.tipo === "check-out" && (
        <>
          <img
            src={left}
            alt="voltar"
            className="self-start py-4 cursor-pointer"
            onClick={() => handleBack()}
          />
          <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-8 w-full lg:w-8/12 my-4 lg:flex lg:flex-col">
            <div className="flex flex-row gap-2 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25">
              <img src={exit} alt="check-in" />
              <p className="textos semibold text-secundaria4">
                REALIZAR CHECK-OUT
              </p>
            </div>
            <div className="w-full flex items-center justify-center mt-4 flex-col gap-4 lg:flex-row lg:flex-wrap lg:bg-terciaria2 lg:gap-4 lg:border lg:border-secundaria2 lg:pb-8 lg:mt-12 lg:rounded-t-lg lg:rounded-b-lg">
              <div className="w-full border lg:border-t-0 lg:border-r-0 lg:border-l-0 lg:border-b lg:border-b-secundaria2 border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:rounded-t-lg">
                <p className="textos semibold text-secundaria5">
                  {checks.checkOut}
                </p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">{inputs.name}</p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">
                  {inputs.email}
                </p>
              </div>
              <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center lg:w-5/12 rounded-lg">
                <p className="textos regular text-secundaria3">{inputs.data}</p>
              </div>
              <div className="flex items-center justify-between w-full lg:w-5/12">
                <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg">
                  <p className="textos regular text-secundaria3">
                    {inputs.hora_inicio.getHours()}:
                    {inputs.hora_inicio.getMinutes()}h
                  </p>
                </div>
                <p className="textos regular text-secundaria3">às</p>
                <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg">
                  <p className="textos regular text-secundaria3">
                    {inputs.hora_fim.getHours()}:{inputs.hora_fim.getMinutes()}h
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mt-2 lg:w-4/12 lg:self-center lg:mt-12">
              <ButtonLight legend="REALIZAR" onClick={() => handleCheckOut()} />
            </div>
          </div>
        </>
      )}

      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default Token;
