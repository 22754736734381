import React, { useEffect, useState } from "react";

const DropDown = (props) => {
  let {
    options,
    background,
    message,
    id,
    onChange,
    value,
    escolhido,
    h,
    shadow,
  } = props;

  const [arr, setArr] = useState([]);
  const bckg = background ? "bg-" + background : "bg-terciaria1";
  let height = h ? " h-full " : " h-10 ";
  let shad = shadow ? ` ${shadow} ` : " shadow-3xl ";

  const classe =
    "w-full justify-center rounded-lg pl-3 items-center placeholder:text-secundaria3 text-secundaria3" +
    shad +
    height +
    bckg;

  useEffect(() => {
    let newArr = [];
    options.map((option) => {
      if (option) {
        newArr.push(option);
        setArr(newArr);
        return true;
      }
      return false;
    });
  }, [options]);

  return options ? (
    <select
      className={classe}
      id={id}
      onChange={onChange}
      value={value}
      escolhido={escolhido}
    >
      <option value="" disabled selected>
        {message}
      </option>
      {arr.map((option) => {
        if (escolhido?.substring(0, 5) === option.value) {
          return (
            <option selected value={option?.value}>
              {option?.name}
            </option>
          );
        }
        return <option value={option?.value}>{option?.name}</option>;
      })}
    </select>
  ) : (
    <></>
  );
};

export default DropDown;
