import maskData from "./maskDate";

function validateDate(dataNascimento) {
  let data = dataNascimento;
  data = maskData(dataNascimento);
  data = data.replace(/\//g, "-");
  let data_array = data.split("-");

  if (data.length !== 10) {
    return false;
  }

  if (parseInt(data_array[1]) > 12 || parseInt(data_array[1]) < 1) {
    return false;
  }

  if (parseInt(data_array[0]) > 31 || parseInt(data_array[0]) < 1) {
    return false;
  }

  if (data_array[0].length !== 4) {
    data_array[0] = parseInt(data_array[0]) + 1;
    data = data_array[2] + "-" + data_array[1] + "-" + String(data_array[0]);
  }

  let hoje = new Date();
  let nasc = new Date(data);
  let idade = hoje.getFullYear() - nasc.getFullYear();
  let m = hoje.getMonth() - nasc.getMonth();

  if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--;

  if (idade < 15 || idade > 120) {
    return false;
  }

  return true;
}
export default validateDate;
