import React, { useEffect } from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import maskDoc from "../../utils/maskDoc";
import axios from "axios";

const ColabAddress = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    logradouro: "",
    numero: "",
    cep: "",
    bairro: "",
    municipio: "",
    estado: "",
  });

  const setCep = (e) => {
    if (inputs?.cep?.length > 8) {
      return;
    }
    setInputs({
      ...inputs,
      cep: maskDoc(e.target.value, "cep"),
    });
  };

  useEffect(() => {
    if (inputs?.cep?.length === 8) {
      axios.get(`https://viacep.com.br/ws/${inputs.cep}/json/`).then((resp) => {
        setInputs({
          logradouro: resp.data.logradouro,
          bairro: resp.data.bairro,
          municipio: resp.data.localidade,
          estado: resp.data.uf,
          numero: "",
          cep: inputs.cep,
        });
      });
    }

    if (inputs?.cep?.length === 0) {
      setInputs({
        logradouro: "",
        numero: "",
        cep: "",
        bairro: "",
        municipio: "",
        estado: "",
      });
    }
  }, [inputs.cep]);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();

    if (
      inputs.logradouro === "" ||
      inputs.numero === "" ||
      inputs.cep === "" ||
      inputs.bairro === "" ||
      inputs.municipio === "" ||
      inputs.estado === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    } else if (inputs.cep.length !== 8) {
      setModal({
        text: "CEP inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      localStorage.setItem("colab-address-register", JSON.stringify(inputs));
      funcChangeStep("password", "", "address");
      return;
    }
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      <p className="body medium text-primaria4">Preencha seus dados</p>
      <p className="button regular text-secundaria4 mt-2">
        Preencha seu nome completo e seu CPF
      </p>
      <form
        className="w-full px-1 items-center justify-center gap-4 flex mt-4 flex-wrap"
        onSubmit={(e) => funcConfirmBtn(e)}
      >
        <div className="w-full flex flex-row gap-4 mt-4">
          <div className="w-6/12">
            <Input
              background="terciaria2"
              placer="CEP"
              name="cep"
              value={inputs.cep}
              onChange={(e) => setCep(e)}
              maxLength={8}
            />
          </div>
          <div className="w-6/12">
            <Input
              background="terciaria2"
              placer="Número"
              name="numero"
              value={inputs.numero}
              onChange={(e) =>
                setInputs({ ...inputs, numero: maskDoc(e.target.value, "cep") })
              }
            />
          </div>
        </div>
        <Input
          background="terciaria2"
          placer="Logradouro"
          name="logradouro"
          value={inputs.logradouro}
          onChange={(e) => setInputs({ ...inputs, logradouro: e.target.value })}
        />

        <Input
          background="terciaria2"
          placer="Bairro"
          name="bairro"
          value={inputs.bairro}
          onChange={(e) => setInputs({ ...inputs, bairro: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="Município"
          name="municipio"
          value={inputs.municipio}
          onChange={(e) => setInputs({ ...inputs, municipio: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="Estado"
          name="estado"
          value={inputs.estado}
          onChange={(e) => setInputs({ ...inputs, estado: e.target.value })}
        />
        <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
          <ButtonDark legend="CONFIRMAR" />
        </div>
      </form>
      {!complete.address && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("address", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ColabAddress;
