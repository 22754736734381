import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import maskDoc from "../../utils/maskDoc";
import ModalAlert from "../ModalAlert";
import validateCNPJ from "../../utils/validateCNPJ";
import ValidateEmail from "../../utils/ValidateEmail";
import { FileUploader } from "react-drag-drop-files";
import upload from "../../assets/upload.svg";
import ButtonLight from "../../components/ButtonLight";
import ParceiroDoc from "../../services/Register/ParceiroDoc";
import InputEdit from "../InputEdit";
import InputFixed from "../InputFixed";
import ParceiroVerify from "../../services/Register/ParceiroVerify";
const fileTypes = ["JPG", "PNG", "GIF", "PDF", "JPEG"];

const ParceiroRazaoSocial = (props) => {
  let { funcChangeStep, complete } = props;
  const [file, setFile] = useState({
    cnpj_file: null,
    cnpj_status: "vazio",
    contrato_file: null,
    contrato_status: "vazio",
  });

  const handleChangeCNPJ = (arq) => {
    setFile({ ...file, cnpj_file: arq, cnpj_status: "adicionado" });
    return;
  };

  const handleChangeContrato = (arq) => {
    setFile({ ...file, contrato_file: arq, contrato_status: "adicionado" });
    return;
  };

  const [inputsStatus, setInputsStatus] = useState({
    razao_social: false,
    cnpj: false,
    telefone: false,
    email: false,
    site: false,
  });

  const [inputs, setInputs] = useState({
    razao_social: "",
    cnpj: "",
    telefone: "",
    email: "",
    site: "",
  });

  const [step, setStep] = useState("dados");

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [buttonState, setButtonState] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    let cnpj = validateCNPJ(e.target.cnpj.value);
    let cnpj_limpo = e.target.cnpj.value;
    cnpj_limpo = cnpj_limpo.replace(/[^\d]+/g, "");
    let telefone = e.target.telefone.value.length;

    setButtonState({ ...buttonState, step1: true });

    if (
      inputs.cnpj === "" ||
      inputs.razao_social === "" ||
      inputs.telefone === "" ||
      inputs.email === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (!cnpj) {
      setModal({
        text: "CNPJ inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (telefone >= 12 || telefone <= 9) {
      setModal({
        text: "Telefone inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (!ValidateEmail(e.target.email.value)) {
      setModal({
        text: "Email inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    ParceiroVerify({
      email: e.target.email.value.toLowerCase(),
      cnpj: cnpj_limpo,
    })
      .then((resp) => {
        localStorage.setItem(
          "parceiro-information-register",
          JSON.stringify({ ...inputs, cnpj: cnpj_limpo })
        );
        setButtonState({ ...buttonState, step1: false });
        setStep("cnpj");
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
        setButtonState({ ...buttonState, step1: false });
        return;
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    let cnpj = validateCNPJ(inputs.cnpj);
    let telefone = inputs.telefone.length;

    if (
      inputs.cnpj === "" ||
      inputs.razao_social === "" ||
      inputs.telefone === "" ||
      inputs.email === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (!cnpj) {
      setModal({
        text: "CNPJ inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (telefone >= 12 || telefone <= 9) {
      setModal({
        text: "Telefone inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (!ValidateEmail(inputs.email)) {
      setModal({
        text: "Email inválido!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    localStorage.setItem(
      "parceiro-information-register",
      JSON.stringify(inputs)
    );

    if (file.cnpj_file) {
      handleEditDoc("CNPJ")
        .then((resp) => {
          if (file.contrato_file) {
            handleEditDoc("contrato-social")
              .then((resp) => {
                setModal({
                  text: "Alterações registradas com sucesso!",
                  alertType: "ok",
                  active: true,
                });
                localStorage.setItem(
                  "parceiro-information-register",
                  JSON.stringify(inputs)
                );
                setTimeout(() => {
                  if (complete.espaco) {
                    if (complete.responsavel) {
                      funcChangeStep("password", "", "razao_social");
                      return;
                    }
                    funcChangeStep("responsavel", "", "razao_social");
                    return;
                  }
                  funcChangeStep("espaco", "", "razao_social");
                  return;
                }, 1000);
              })
              .catch((e) =>
                setModal({
                  text: "Não foi possível enviar o seu arquivo de Contrato Social, tente outro",
                  alertType: "fail",
                  active: true,
                })
              );
            return;
          }
          localStorage.setItem(
            "parceiro-information-register",
            JSON.stringify(inputs)
          );
          if (complete.espaco) {
            if (complete.responsave) {
              funcChangeStep("password", "", "razao_social");
              return;
            }
            funcChangeStep("responsavel", "", "razao_social");
            return;
          }
          funcChangeStep("espaco", "", "razao_social");
          return;
        })
        .catch((e) => {
          setModal({
            text: "Não foi possível enviar o seu arquivo de CNPJ, tente outro",
            alertType: "fail",
            active: true,
          });
          return;
        });
    }
    return;
  };

  const handleEditDoc = async (tipo) => {
    return new Promise(function (resolve, reject) {
      const formData = new FormData();
      if (tipo === "CNPJ") {
        let cnpj = inputs.cnpj;
        cnpj = cnpj.replace(/[^\d]+/g, "");
        formData.append("file", file.cnpj_file);
        formData.append("tipo_documento", "cnpj");
        formData.append("cnpj", cnpj);
        ParceiroDoc(formData)
          .then((resp) => {
            setStep("contrato-social");
            resolve("ok");
            return;
          })
          .catch((e) => {
            setFile({ ...file, cnpj_status: "falhou" });
            reject(e);
            return;
          });
        return;
      }

      if (tipo === "contrato-social") {
        let cnpj = inputs.cnpj;
        cnpj = cnpj.replace(/[^\d]+/g, "");
        formData.append("file", file.contrato_file);
        formData.append("tipo_documento", "contrato-social");
        formData.append("cnpj", cnpj);
        ParceiroDoc(formData)
          .then((resp) => {
            resolve("ok");
            return;
          })
          .catch((e) => {
            setFile({ ...file, contrato_status: "falhou" });
            reject(e);
            return;
          });
      }
    });
  };

  const handleSendDoc = (tipo) => {
    const formData = new FormData();
    if (tipo === "CNPJ") {
      setButtonState({ ...buttonState, step2: true });
      let cnpj = inputs.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");
      formData.append("file", file.cnpj_file);
      formData.append("tipo_documento", "cnpj");
      formData.append("cnpj", cnpj);
      ParceiroDoc(formData)
        .then((resp) => {
          setModal({
            text: "Documento enviado com sucesso!",
            alertType: "ok",
            active: true,
          });
          setButtonState({ ...buttonState, step2: false });
          setStep("contrato-social");
          return;
        })
        .catch((e) => {
          setFile({ ...file, cnpj_status: "falhou" });
          setButtonState({ ...buttonState, step1: false });
          return;
        });
      return;
    }

    if (tipo === "contrato-social" && file.contrato_file) {
      setButtonState({ ...buttonState, step3: true });
      let cnpj = inputs.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");
      formData.append("file", file.contrato_file);
      formData.append("tipo_documento", "contrato-social");
      formData.append("cnpj", cnpj);
      ParceiroDoc(formData)
        .then((resp) => {
          setModal({
            text: "Documento enviado com sucesso!",
            alertType: "ok",
            active: true,
          });
          setTimeout(() => {
            if (complete.espaco) {
              funcChangeStep("responsavel", "", "razao_social");
              setButtonState({ ...buttonState, step3: false });
              return;
            }
            funcChangeStep("espaco", "", "razao_social");
            setButtonState({ ...buttonState, step3: false });
            return;
          }, 1000);
          return;
        })
        .catch((e) => {
          setFile({ ...file, contrato_status: "falhou" });
          setButtonState({ ...buttonState, step1: false });
          return;
        });
    } else {
      if (complete.espaco) {
        funcChangeStep("responsavel", "", "razao_social");
        setButtonState({ ...buttonState, step1: false });
        return;
      }
      funcChangeStep("espaco", "", "razao_social");
      setButtonState({ ...buttonState, step1: false });
      return;
    }
  };

  // const handleFinish = () => {
  //   if (complete.telefone) {
  //     funcChangeStep("password", "", "data");
  //     setTimeout(() => {
  //       setStep("preenchendo");
  //     }, 200);

  //     return;
  //   } else {
  //     funcChangeStep("telefone", "", "data");
  //     setTimeout(() => {
  //       setStep("preenchendo");
  //     }, 200);
  //   }
  //   return;
  // };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {!complete.razao_social && (
        <div className="flex items-center justify-start flex-col gap-2 relative h-full w-full">
          {step === "dados" && (
            <p className="textos regular pb-4">Etapa 1 de 3</p>
          )}
          {step === "cnpj" && (
            <p className="textos regular pb-4">Etapa 2 de 3</p>
          )}
          {step === "contrato-social" && (
            <p className="textos regular pb-4">Etapa 3 de 3</p>
          )}
          {step === "dados" && (
            <>
              <p className="body medium text-primaria4">
                Dados da razão social
              </p>
              <form
                className="w-full px-1 items-center justify-center gap-6 flex flex-col mt-8"
                onSubmit={(e) => funcConfirmBtn(e)}
              >
                <Input
                  background="terciaria2"
                  placer="Razão Social"
                  name="razao_social"
                  value={inputs.razao_social}
                  onChange={(e) =>
                    setInputs({ ...inputs, razao_social: e.target.value })
                  }
                />
                <Input
                  background="terciaria2"
                  placer="CNPJ"
                  name="cnpj"
                  value={inputs.cnpj}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      cnpj: maskDoc(e.target.value, "cnpj"),
                    })
                  }
                  maxLength={18}
                />
                <Input
                  background="terciaria2"
                  placer="Telefone"
                  name="telefone"
                  value={inputs.telefone}
                  onChange={(e) =>
                    setInputs({ ...inputs, telefone: e.target.value })
                  }
                  tipo="number"
                />
                <Input
                  background="terciaria2"
                  placer="E-mail"
                  name="email"
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs({ ...inputs, email: e.target.value })
                  }
                />
                <Input
                  background="terciaria2"
                  placer="Site oficial (opcional)"
                  name="site"
                  value={inputs.site}
                  onChange={(e) =>
                    setInputs({ ...inputs, site: e.target.value })
                  }
                />
                <div className="w-full flex items-center justify-center px-6 mt-3 h-10">
                  <ButtonDark legend="CONFIRMAR" loading={buttonState.step1} />
                </div>
              </form>
            </>
          )}
          {step === "cnpj" && (
            <>
              <h4 className="bold text-primaria4">
                Envie uma foto ou arquivo do cartão de CNPJ
              </h4>
              <p className="textos regular text-secundaria3 self-start py-2">
                Este pode ser emitido por{" "}
                <a
                  href={`https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp`}
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  aqui
                </a>
              </p>
              <div className="w-full">
                <FileUploader
                  handleChange={handleChangeCNPJ}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, cnpj_status: "falhou" })
                  }
                  maxSize={10}
                  children={
                    (file.cnpj_status === "vazio" && (
                      <div className="w-full py-4 h-54 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-4 text-center cursor-pointer">
                        <img src={upload} alt="Upload de arquivos" />
                        <div className="flex gap-2 text-center mt-4 flex-col lg:flex-row">
                          <span className="text-primaria4 textos semibold">
                            Escolha um ficheiro
                          </span>{" "}
                          <span className="textos regular">ou</span>
                          <span className="textos semibold"> arraste aqui</span>
                        </div>
                        <p className="button regular text-secundaria3 mt-4">
                          Limite de download 10MB
                        </p>
                      </div>
                    )) ||
                    (file.cnpj_status === "adicionado" && (
                      <div className="h-auto cursor-pointer">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center">
                          <p className="button regular text-primaria5 mt-4">
                            {file.cnpj_file.name}
                          </p>
                          <p className="button regular text-secundaria3 mt-4">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.cnpj_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
                {file.cnpj_status === "adicionado" && (
                  <div className="w-full mt-8">
                    <ButtonLight
                      legend="CONTINUAR"
                      onClick={() => handleSendDoc("CNPJ")}
                      loading={buttonState.step2}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {step === "contrato-social" && (
            <>
              <h4 className="bold text-primaria4">
                Envie uma cópia do contrato social da empresa
              </h4>
              <p className="textos regular text-secundaria3 self-start py-2">
                Não obrigatório. Mas para que a validação do cadastro seja
                rápida, pedimos para que seja enviado o contrato.
              </p>
              <div className="w-full">
                <FileUploader
                  handleChange={handleChangeContrato}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                  onSizeError={() =>
                    setFile({ ...file, contrato_status: "falhou" })
                  }
                  maxSize={10}
                  children={
                    (file.contrato_status === "vazio" && (
                      <div className="w-full py-8 h-46 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-4 text-center cursor-pointer">
                        <img src={upload} alt="Upload de arquivos" />
                        <div className="flex gap-2 text-center mt-4 flex-col lg:flex-row">
                          <span className="text-primaria4 textos semibold">
                            Escolha um ficheiro
                          </span>{" "}
                          <span className="textos regular">ou</span>
                          <span className="textos semibold"> arraste aqui</span>
                        </div>
                        <p className="button regular text-secundaria3 mt-4">
                          Limite de download 10MB
                        </p>
                      </div>
                    )) ||
                    (file.contrato_status === "adicionado" && (
                      <div className="h-auto cursor-pointer">
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center">
                          <p className="button regular text-primaria5 mt-4">
                            {file.contrato_file.name}
                          </p>
                          <p className="button regular text-secundaria3 mt-4">
                            Documento adicionado, clique aqui para alterá-lo
                          </p>
                        </div>
                      </div>
                    )) ||
                    (file.contrato_status === "falhou" && (
                      <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                        <p className="button regular text-secundaria3 mt-4">
                          Não foi possível enviar a sua requisição, escolha
                          outro arquivo.
                        </p>
                      </div>
                    ))
                  }
                />
                {file.contrato_status === "adicionado" && (
                  <div className="w-full mt-8">
                    <ButtonLight
                      legend="CONTINUAR"
                      onClick={() => handleSendDoc("contrato-social")}
                      loading={buttonState.step3}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {complete.razao_social && (
        <form>
          <p className="body bold text-primaria4 mt-4">Editar dados</p>
          <div className="flex w-full flex-wrap gap-3 pt-6 justify-between">
            <div className="w-full">
              <InputEdit
                edit={inputsStatus.razao_social}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                name="razao_social"
                placer="Razão Social"
                value={inputs.razao_social}
                onChange={(e) =>
                  setInputs({ ...inputs, razao_social: e.target.value })
                }
              />
            </div>
            <div className="w-full lg:w-6/12">
              <InputFixed value={`${inputs.cnpj}`} background="terciaria2" />
            </div>
            <div className="w-full lg:w-5/12">
              <InputEdit
                edit={inputsStatus.telefone}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                name="telefone"
                value={inputs.telefone}
                onChange={(e) =>
                  setInputs({ ...inputs, telefone: e.target.value })
                }
                placer="Telefone"
              />
            </div>
            <div className="w-full">
              <InputFixed value={`${inputs.email}`} background="terciaria2" />
            </div>
            <div className="w-full">
              <InputEdit
                edit={inputsStatus.site}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                name="site"
                value={inputs.site}
                placer="Site"
                onChange={(e) => setInputs({ ...inputs, site: e.target.value })}
              />
              <div className="w-full flex items-center justify-between gap-4 flex-col py-8 lg:flex-row">
                <div className="w-full lg:w-6/12">
                  <FileUploader
                    handleChange={handleChangeCNPJ}
                    name="file"
                    types={fileTypes}
                    multiple={false}
                    onSizeError={() =>
                      setFile({ ...file, cnpj_status: "falhou" })
                    }
                    maxSize={10}
                    children={
                      (file.cnpj_status === "vazio" && (
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                          <p className="button regular text-secundaria3 mt-4">
                            Não foi possível enviar a sua requisição, escolha
                            outro arquivo.
                          </p>
                        </div>
                      )) ||
                      (file.cnpj_status === "adicionado" && (
                        <div className="h-auto cursor-pointer">
                          <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center lg:h-28 lg:overflow-hidden lg:text-ellipsis">
                            <p className="button regular text-primaria5 mt-4 lg:mt-0">
                              {file.cnpj_file.name}
                            </p>
                            <p className="button regular text-secundaria3 mt-4 lg:hidden">
                              Documento adicionado, clique aqui para alterá-lo
                            </p>
                          </div>
                        </div>
                      )) ||
                      (file.cnpj_status === "falhou" && (
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer">
                          <p className="button regular text-secundaria3 mt-4">
                            Não foi possível enviar a sua requisição, escolha
                            outro arquivo.
                          </p>
                        </div>
                      ))
                    }
                  />
                </div>
                <div className="w-full lg:w-6/12 overflow-hidden">
                  <FileUploader
                    handleChange={handleChangeContrato}
                    name="file"
                    types={fileTypes}
                    multiple={false}
                    onSizeError={() =>
                      setFile({ ...file, contrato_status: "falhou" })
                    }
                    maxSize={10}
                    children={
                      (file.contrato_status === "vazio" && (
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer lg:h-28">
                          <p className="button regular text-secundaria3 mt-4">
                            Nenhum arquivo selecionado.
                          </p>
                        </div>
                      )) ||
                      (file.contrato_status === "adicionado" && (
                        <div className="h-auto cursor-pointer overflow-hidden">
                          <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center lg:h-28 overflow-hidden lg:text-ellipsis">
                            <p className="button regular text-primaria5 mt-4 lg:mt-0 overflow-hidden">
                              {file.contrato_file.name}
                            </p>
                            <p className="button regular text-secundaria3 mt-4 lg:hidden">
                              Documento adicionado, clique aqui para alterá-lo
                            </p>
                          </div>
                        </div>
                      )) ||
                      (file.contrato_status === "falhou" && (
                        <div className="w-full h-48 border-2 border-primaria3 border-dashed flex items-center justify-center bg-primaria1 flex-col px-8 text-center cursor-pointer lg:h-28">
                          <p className="button regular text-secundaria3 mt-4">
                            Não foi possível enviar a sua requisição, escolha
                            outro arquivo.
                          </p>
                        </div>
                      ))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <ButtonLight legend="SALVAR" onClick={(e) => handleEdit(e)} />
          </div>
        </form>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ParceiroRazaoSocial;
