import axiosInstance from "../AxiosInstance";

const ListarSolicitacoes = async (page) => {
  return new Promise(function (resolve, reject) {
    if (!page) {
      axiosInstance
        .get(`parceiros/listar-solicitacoes`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
    if (page) {
      axiosInstance
        .get(`parceiros/listar-solicitacoes/?page=${page}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export default ListarSolicitacoes;
