import React from "react";

const ButtonLight = (props) => {
  let { legend, onClick, icon, id, loading } = props;
  let load = loading ? " pointer-events-none opacity-20 " : " ";
  return (
    <button
      className={
        "w-full border-2 border-primaria3 p-2 rounded-3xl h-full hover:bg-primaria3 regular text-primaria3 hover:text-terciaria2 flex items-center justify-center gap-4" +
        load
      }
      onClick={onClick}
      id={id}
    >
      {loading ? "ENVIANDO..." : legend}
      {icon ? <img src={icon} alt="" /> : ""}
    </button>
  );
};

export default ButtonLight;
