import React from "react";
import SearchBar from "../../components/SearchBar";
import lupa from "../../assets/SearchLupa.svg";
import iconColab from "../../assets/colaboradores-icon.svg";
import ButtonLight from "../../components/ButtonLight";
import DropDown from "../../components/DropDown";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../../hooks/AuthContext";
import { useEffect } from "react";
import GetSingleColab from "../../services/Company/GetSingleColab";
import { useNavigate } from "react-router-dom";
import CardColab from "../../components/CardColab";
import getParamsFromUrl from "../../utils/getParamsFromUrl";
import FilterColabs from "../../services/Company/FilterColabs";
import Switch from "../../components/Switch";
import ChangeBenefict from "../../services/Company/ChangeBenefict";
import trash from "../../assets/trash.svg";
import InputEdit from "../../components/InputEdit";
import InputFixed from "../../components/InputFixed";
import maskDoc from "../../utils/maskDoc";
import close from "../../assets/close.svg";
import DeleteColabs from "../../services/Company/DeleteColabs";

const Colaboradores = () => {
  let { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openColab, setOpenColab] = useState(false);
  const [colabs, setColabs] = useState();
  const [inputsStatus, setInputsStatus] = useState({
    email: false,
    nome: false,
  });
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState();
  const [nextPage, setNextPage] = useState("");
  const [inputs, setInputs] = useState({
    nome: "",
    email: "",
    cpf: "",
    visitas_totais: "",
    visitas_utilizadas: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const loadMore = () => {
    if (!load) return;
    if (!nextPage) return;
    let newColabs = colabs;
    FilterColabs(user, nextPage, searchTerm, status)
      .then((response) => {
        if (response.data.count !== 0) {
          response.data.results.forEach((element) => {
            newColabs.push({
              name: element.name,
              email: element.email,
              status: element.is_active,
              benefict: element.is_benefict_active,
              activation_date: element.activation_date,
              remain_visits: element.total_visits,
              selected: false,
            });
          });
          setColabs(newColabs);
          setCount(response.data.count);
          if (response.data.next) {
            setNextPage(getParamsFromUrl("page", response.data.next));
            setLoad(true);
          } else {
            setNextPage();
            setLoad(true);
          }
        } else {
          setColabs();
          setLoad(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDelete = (colaboradores) => {
    let delColabs = [];
    const newColabs = [];
    if (colaboradores === "selecionados") {
      delColabs = colabs.filter((colab) => colab.selected === true);
    }
    const emailsColabs = [];
    delColabs.forEach((colab) => {
      emailsColabs.push(colab.email);
    });
    DeleteColabs(emailsColabs, user.uuid)
      .then((resp) => {
        FilterColabs(user, nextPage, searchTerm, status)
          .then((response) => {
            if (response.data.count !== 0) {
              response.data.results.forEach((element) => {
                newColabs.push({
                  name: element.name,
                  email: element.email,
                  status: element.is_active,
                  benefict: element.is_benefict_active,
                  activation_date: element.activation_date,
                  remain_visits: element.total_visits,
                  selected: false,
                });
              });
              setColabs(newColabs);
              setCount(response.data.count);
              if (response.data.next) {
                setNextPage(getParamsFromUrl("page", response.data.next));
                setLoad(true);
              } else {
                setNextPage();
                setLoad(true);
              }
            } else {
              setColabs();
              setLoad(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteSingle = (colab) => {
    let newColabs = [];
    let emailsColabs = [];
    emailsColabs.push(colab);
    DeleteColabs(emailsColabs, user.uuid)
      .then((resp) => {
        FilterColabs(user, nextPage, searchTerm, status)
          .then((response) => {
            if (response.data.count !== 0) {
              response.data.results.forEach((element) => {
                newColabs.push({
                  name: element.name,
                  email: element.email,
                  status: element.is_active,
                  benefict: element.is_benefict_active,
                  activation_date: element.activation_date,
                  remain_visits: element.total_visits,
                  selected: false,
                });
              });
              setColabs(newColabs);
              setCount(response.data.count);
              if (response.data.next) {
                setNextPage(getParamsFromUrl("page", response.data.next));
                setLoad(true);
              } else {
                setNextPage();
                setLoad(true);
              }
            } else {
              setColabs();
              setLoad(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeAllSelects = (e) => {
    let newColabs = colabs;
    if (e.target.checked === false) {
      newColabs.map((colab, id) => {
        newColabs[id]["selected"] = false;
        return false;
      });
    } else {
      newColabs.map((colab, id) => {
        newColabs[id]["selected"] = true;
        return true;
      });
    }
    setColabs([...newColabs]);
  };

  const handleOpenColab = (email) => {
    GetSingleColab(email)
      .then((response) => {
        setOpenColab(!openColab);
        setInputs({
          nome: response.data.nome,
          email: response.data.email,
          cpf: response.data.cpf,
          visitas_totais: parseInt(response.data.visitas_totais),
          visitas_utilizadas: response.data.visitas_utilizadas,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let newColabs = [];
    FilterColabs(user, nextPage, searchTerm, status)
      .then((response) => {
        if (response.data.count !== 0) {
          response.data.results.forEach((element) => {
            newColabs.push({
              name: element.name,
              email: element.email,
              status: element.is_active,
              benefict: element.is_benefict_active,
              activation_date: element.activation_date,
              remain_visits: element.total_visits,
              selected: false,
            });
          });
          setColabs(newColabs);
          setCount(response.data.count);
          if (response.data.next) {
            setNextPage(getParamsFromUrl("page", response.data.next));
            setLoad(true);
          } else {
            setNextPage();
            setLoad(true);
          }
        } else {
          setColabs();
          setLoad(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);

  const changeChecked = (email) => {
    let newColabs = colabs;
    let index = newColabs.findIndex((x) => x.email === email);
    newColabs[index]["selected"] = !newColabs[index].selected;
    setColabs([...newColabs]);
  };

  const checkBenefict = (email) => {
    let newColabs = colabs;
    let index = newColabs.findIndex((x) => x.email === email);
    newColabs[index]["benefict"] = !newColabs[index].benefict;
    setColabs([...newColabs]);
  };

  const handleDropDown = (value) => {
    const newColabs = [];
    if (value === "nothing") {
      setStatus();
      FilterColabs(user, nextPage, searchTerm, "")
        .then((response) => {
          if (response.data.count !== 0) {
            response.data.results.forEach((element) => {
              newColabs.push({
                name: element.name,
                email: element.email,
                status: element.is_active,
                benefict: element.is_benefict_active,
                activation_date: element.activation_date,
                remain_visits: element.total_visits,
                selected: false,
              });
            });
            setColabs(newColabs);
            setCount(response.data.count);
            if (response.data.next) {
              setNextPage(getParamsFromUrl("page", response.data.next));
              setLoad(true);
            } else {
              setNextPage();
              setLoad(true);
            }
          } else {
            setColabs();
            setLoad(true);
            setCount(0);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      return;
    }
    setStatus(value);
    FilterColabs(user, nextPage, searchTerm, value)
      .then((response) => {
        if (response.data.count !== 0) {
          response.data.results.forEach((element) => {
            newColabs.push({
              name: element.name,
              email: element.email,
              status: element.is_active,
              benefict: element.is_benefict_active,
              activation_date: element.activation_date,
              remain_visits: element.total_visits,
              selected: false,
            });
          });
          setColabs(newColabs);
          setCount(response.data.count);
          if (response.data.next) {
            setNextPage(getParamsFromUrl("page", response.data.next));
            setLoad(true);
          } else {
            setNextPage();
            setLoad(true);
          }
        } else {
          setColabs();
          setLoad(true);
          setCount(0);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return;
  };

  useEffect(() => {
    let newColabs = [];
    // setLoadSearching(true);
    setLoad(false);
    setNextPage();
    const delayDebounceFn = setTimeout(() => {
      FilterColabs(user, nextPage, searchTerm, status)
        .then((response) => {
          if (response.data.count !== 0) {
            response.data.results.forEach((element) => {
              newColabs.push({
                name: element.name,
                email: element.email,
                status: element.is_active,
                benefict: element.is_benefict_active,
                activation_date: element.activation_date,
                remain_visits: element.total_visits,
                selected: false,
              });
            });
            setColabs(newColabs);
            setCount(response.data.count);
            if (response.data.next) {
              setNextPage(getParamsFromUrl("page", response.data.next));
              setLoad(true);
            } else {
              setNextPage();
              setLoad(true);
            }
          } else {
            setColabs();
            setLoad(true);
            setCount(0);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // setLoadSearching(false);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <section className="py-4 flex items-center justify-center flex-col bg-terciariafundo">
      <div className="flex items-center justify-center relative w-10/12 lg:hidden">
        <SearchBar
          background="terciaria2"
          className="bg-"
          placer="Buscar por nome do colaborador"
          name="name"
          height="12"
          pl="12"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <img src={lupa} alt="buscar" className="absolute -left-5" />
      </div>
      <div className="w-11/12 colab-bg-div rounded-lg mt-8 pt-5 flex items-center justify-center flex-col ">
        <div className=" gap-3 pb-8 w-full px-4 items-center justify-between hidden lg:flex">
          <div className="flex flex-row gap-3">
            <img src={iconColab} alt="colaboradores-icone" />
            <p className="textos semibold text-secundaria5">COLABORADORES</p>
          </div>
          <div className="w-3/12 gap-3 flex flex-col py-4 px-4 lg:flex-row justify-self-end">
            <ButtonLight
              legend="ADICIONAR COLABORADOR"
              onClick={() => navigate("/empresa/adicionar-colaboradores")}
            />
          </div>
        </div>
        <div className="w-full gap-3 flex flex-col py-4 border-b-secundaria2 border-b px-4 lg:flex-row lg:hidden">
          <ButtonLight
            legend="ADICIONAR COLABORADOR"
            onClick={() => navigate("/empresa/adicionar-colaboradores")}
          />
          <ButtonLight legend="EXPORTAR COLABORADORES" onClick="" />
        </div>
        <p className="textos semibold p-2 border border-secundaria4 flex items-center justify-center w-9/12 my-8 text-secundaria4 px-4 lg:hidden">
          Total de colaboradores: {count}
        </p>
        <div className="w-full px-4 lg:flex lg:flex-row justify-between">
          <div className="lg:w-4/12 w-full">
            <DropDown
              options={[
                { value: "true", name: "Ativo" },
                { value: "false", name: "Aguardando Aprovação" },
                { value: "nothing", name: "Todos" },
              ]}
              background="terciaria1"
              message="Status do beneficio"
              onChange={(e) => handleDropDown(e.target.value)}
            />
          </div>
          <div className="items-center justify-center relative w-6/12 hidden lg:flex ">
            <SearchBar
              background="terciaria2"
              className="bg-"
              placer="Buscar por nome do colaborador"
              name="name"
              height="12"
              pl="12"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <img src={lupa} alt="buscar" className="absolute -left-5" />
          </div>
        </div>
        <table className="w-full hidden px-8 lg:flex flex-col bg-terciaria2 py-4 items-center mt-6 rounded-b-lg">
          <div className="flex flex-row items-center justify-between w-full">
            <p className="textos semibold p-2 border border-secundaria4 flex items-center justify-center my-8 text-secundaria4 px-4 w-3/12 rounded-lg">
              Total de colaboradores: {count}
            </p>
            <div className="w-3/12">
              <ButtonLight
                legend="EXCLUIR SELECIONADOS"
                onClick={() => handleDelete("selecionados")}
              />
            </div>
          </div>
          <tr className="w-full flex items-center justify-between gap-3 px-5 py-3">
            <th className="text-secundaria5 w-5 flex items-center">
              <input
                type="checkbox"
                className="bg-primaria4"
                onChange={(e) => changeAllSelects(e)}
              ></input>
            </th>
            <th className="text-secundaria5 w-2/12 flex items-center textos semibold">
              <p classname="textos semibold">Nome</p>
            </th>
            <th className="text-secundaria5 w-2/12 flex items-center textos semibold">
              <p classname="textos semibold">Benefício</p>
            </th>
            <th className="text-secundaria5 w-3/12 flex items-center textos semibold">
              <p classname="textos semibold">Status do benefício</p>
            </th>
            <th className="text-secundaria5 w-2/12 flex items-center justify-center textos semibold">
              <p classname="textos semibold">Data da ativação</p>
            </th>
            <th className="text-secundaria5 w-2/12 flex items-center justify-center textos semibold">
              <p classname="textos semibold">Liberar benefício</p>
            </th>
            <th className="text-secundaria5 w-1/12 flex items-center justify-center textos semibold">
              <p classname="textos semibold">Excluir</p>
            </th>
          </tr>
          {colabs && load
            ? colabs.map((colab, count) => {
                return (
                  <tr
                    className={
                      count % 2 === 0
                        ? "w-full flex items-center justify-between gap-3 p-5 rounded-full bg-terciariafundo"
                        : "w-full flex items-center justify-between gap-3 p-5 rounded-full bg-terciaria2"
                    }
                  >
                    <th className="w-5 flex items-center justify-start">
                      <input
                        type="checkbox"
                        onChange={() => {
                          changeChecked(colab.email);
                        }}
                        checked={colab.selected}
                      ></input>
                    </th>
                    <th
                      className="w-2/12 flex items-start justify-start overflow-hidden flex-col"
                      onClick={() => handleOpenColab(colab.email)}
                    >
                      <p className="textos medium text-primaria4 cursor-pointer">
                        {colab.name ? colab.name : ""}
                      </p>
                      <p className="caption regular">
                        {colab.email ? colab.email : ""}
                      </p>
                    </th>
                    <th className="w-2/12 flex items-center justify-start text-secundaria4">
                      <p className="textos regular">
                        {colab.remain_visits
                          ? colab.remain_visits
                          : "0 visitas liberadas"}
                      </p>
                    </th>
                    <th className="w-3/12 flex items-center justify-start">
                      {colab.status === false ? (
                        <div
                          className="flex items-center text-center justify-center border-alerta2 border-2 rounded-lg text-alerta2 w-28 lg:w-10/12 lg:h-8
                         h-11"
                        >
                          <p className="textos regular">Aguardando ativação</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {colab.status === true ? (
                        <div className="flex items-center text-center justify-center border-y-detalhes4 border-2 rounded-lg text-detalhes4 w-28 lg:w-10/12 lg:h-8 h-11">
                          <p className="textos regular">ATIVO</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="w-2/12 flex items-center justify-center text-secundaria4">
                      <p className="textos medium">
                        {colab.activation_date
                          ? colab.activation_date
                          : "12/11/2022"}
                      </p>
                    </th>
                    <th className="w-2/12 flex items-center justify-center">
                      <Switch
                        checked={colab.benefict}
                        func={ChangeBenefict}
                        func2={checkBenefict}
                        colab={colab}
                        empresa={user}
                      />
                    </th>
                    <th className="w-1/12 flex items-center justify-center">
                      <img
                        src={trash}
                        alt="excluir colaborador"
                        className="cursor-pointer w-5 h-auto"
                        onClick={() => handleDeleteSingle(colab.email)}
                      />
                    </th>
                  </tr>
                );
              })
            : ""}
          {nextPage ? (
            <p
              className="body medium text-primaria4 mt-4 cursor-pointer"
              onClick={() => loadMore(searchTerm)}
            >
              Carregar mais
            </p>
          ) : (
            ""
          )}
          {!load && (
            <p className="body medium text-primaria4 mt-4 cursor-pointer">
              Carregando colaboradores...
            </p>
          )}
          {load && count === 0 && (
            <p className="body medium text-primaria4 mt-12 cursor-pointer">
              Nenhum colaborador foi encontrado.
            </p>
          )}
        </table>
        <div
          className={
            openColab
              ? "w-full h-full lg:flex fixed z-[200] top-0 items-center justify-center left-0 hidden"
              : "w-full h-full hidden z-[200] top-0 items-center justify-center"
          }
        >
          <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
          <div className="w-6/12 rounded-3xl white-box h-auto z-[300] flex px-6 flex-col pt-8 pb-8 gap-3 relative">
            <img
              src={close}
              alt="Fechar Menu"
              className="w-4 h-auto absolute right-10 top-6 cursor-pointer"
              onClick={() => setOpenColab(!openColab)}
            />
            <h4 className="bold text-primaria3">Dados do colaborador</h4>
            <p className="button bold">Dados do colaborador</p>
            <InputEdit
              edit={inputsStatus.email}
              setInputs={setInputsStatus}
              inputs={inputsStatus}
              background="terciaria2"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <InputEdit
              edit={inputsStatus.nome}
              setInputs={setInputsStatus}
              inputs={inputsStatus}
              background="terciaria2"
              name="nome"
              value={inputs.nome}
              onChange={(e) => setInputs({ ...inputs, nome: e.target.value })}
            />
            <div className="flex flex-row justify-between gap-2">
              <div className="w-full">
                <InputFixed
                  value={maskDoc(inputs.cpf, "cpf")}
                  background="terciaria2"
                />
              </div>
            </div>
            <p className="button bold">Quantidade de horas</p>
            <div className="flex items-center justify-center gap-2">
              <div className="white-box border border-secundaria2 rounded-md w-6/12 flex items-center justify-center flex-col p-4 gap-2 h-24">
                <p className="textos medium text-secundaria5">Consumidas</p>
                <p className="textos regular text-secundaria3">{`Total: ${inputs.visitas_utilizadas}`}</p>
              </div>
              <div className="white-box border border-secundaria2 rounded-md w-6/12 flex items-center justify-center flex-col p-4 gap-2 h-24">
                <p className="textos medium text-secundaria5">Disponíveis</p>
                <p className="textos regular text-secundaria3">{`Total: ${inputs.visitas_totais}`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full px-4 pt-8 pb-4 lg:hidden">
          <div className="flex flex-row gap-2">
            <input
              type="checkbox"
              className="bg-primaria4"
              onChange={(e) => changeAllSelects(e)}
            ></input>
            <p className="textos semibold text-primaria4">Selecionar todos</p>
          </div>
          <div className="w-[150px]">
            <ButtonLight
              legend="EXCLUIR SELECIONADOS"
              onClick={() => handleDelete("selecionados")}
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-6 bg-terciaria2 mt-4 rounded-b-lg py-4 lg:hidden">
          {colabs && load
            ? colabs.map((colab) => {
                return (
                  <CardColab
                    colab={colab}
                    user={user}
                    inputs={inputs}
                    setInputs={setInputs}
                    inputsStatus={inputsStatus}
                    setInputsStatus={setInputsStatus}
                    handleOpenColab={handleOpenColab}
                    openColab={openColab}
                    setOpenColab={setOpenColab}
                    colabs={colabs}
                    setColabs={setColabs}
                  />
                );
              })
            : ""}
          {nextPage ? (
            <p
              className="body medium text-primaria4 mt-4 cursor-pointer"
              onClick={() => loadMore(searchTerm)}
            >
              Carregar mais
            </p>
          ) : (
            ""
          )}
          {!load && (
            <p className="body medium text-primaria4 mt-4 cursor-pointer">
              Carregando colaboradores...
            </p>
          )}
          {load && count === 0 && (
            <p className="body medium text-primaria4 mt-12 cursor-pointer">
              Nenhum colaborador foi encontrado.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Colaboradores;
