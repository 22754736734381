import React, { useEffect, useState } from "react";
import calendar from "../../assets/calendar.svg";
import ModalAlert from "../../components/ModalAlert";
import GetReservas from "../../services/Colaborador/GetReservas";
import ButtonLight from "../../components/ButtonLight";
import CheckReserve from "../../services/Colaborador/CheckReserve";
import CancelarReserva from "../../services/Colaborador/CancelarReserva";

const Reservas = () => {
  const [reservas, setReservas] = useState([]);
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });
  const [token, setToken] = useState({ token: "", active: false, tipo: "" });
  const [messageOut, setMessageOut] = useState({ message: "", active: false });

  const addItens = async (arr, itens) => {
    return new Promise(function (resolve, reject) {
      itens.map((iten) => {
        let newDate = new Date(iten.hora_inicio);
        iten.hora_inicio = newDate;
        let newDate2 = new Date(iten.hora_fim);
        iten.hora_fim = newDate2;
        arr.push(iten);
        return true;
      });
      resolve(arr);
    });
  };

  useEffect(() => {
    GetReservas()
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        addItens(newArr, resultados).then((resp) => {
          setReservas(resp);
          setLoad(true);
        });
      })
      .catch((e) => {
        setModal({
          text: "Ocorreu um erro, tente novamente!",
          alertType: "fail",
          active: true,
        });
      });
  }, []);

  const atualizarReservas = () => {
    GetReservas()
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        addItens(newArr, resultados).then((resp) => {
          setReservas(resp);
          setLoad(true);
        });
      })
      .catch((e) => {
        setModal({
          text: "Ocorreu um erro, tente novamente!",
          alertType: "fail",
          active: true,
        });
      });
  };

  const cancelarReserva = (uuid) => {
    CancelarReserva({ reserva_uuid: uuid })
      .then((resp) => {
        atualizarReservas();
      })
      .catch((e) => console.log(e));
  };

  const handleCheck = (check, uuid, tipo_reserva) => {
    if (check === "check-in") {
      CheckReserve("check-in", uuid)
        .then((resp) => {
          setToken({ token: resp.data.token, active: true, tipo: "check-in" });
        })
        .catch((e) => {
          console.log(e);
        });
      return;
    }

    if (check === "check-out") {
      if (tipo_reserva === "reserva") {
        CheckReserve("check-out", uuid, tipo_reserva)
          .then((resp) => {
            setToken({
              token: resp.data.token,
              active: true,
              tipo: "check-out",
              tipo_reserva: tipo_reserva,
            });
            atualizarReservas();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        CheckReserve("check-out", uuid, tipo_reserva)
          .then((resp) => {
            setMessageOut({
              message: "Check-out realizado com sucesso",
              active: true,
            });
            atualizarReservas();
          })
          .catch((e) => {
            console.log(e);
          });
      }

      return;
    }
  };

  return (
    <section className="bg-terciariafundo flex items-start justify-center py-6 px-4 min-h-screen">
      <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-4 px-8 w-full lg:w-11/12 lg:my-12 md:px-4 xl:px-8 my-4">
        <div className="flex w-full items-center justify-start gap-4 flex-wrap border-b border-b-secundaria3 pb-2 border-opacity-25">
          <img src={calendar} alt="" className="pb-1" />
          <p className="textos semibold text-secundaria5">Reservas</p>
        </div>
        <div className="w-full px-4 flex gap-4 flex-col py-4 lg:hidden">
          {reservas &&
            load &&
            reservas.map((reserva) => {
              return (
                <div className="flex items-center justify-center flex-col border border-secundaria2 bg-terciaria2 rounded-lg pb-2">
                  <img
                    src={reserva.imagem_url}
                    alt="imagem do coworking"
                    className="rounded-t-md w-full border-secundaria2 object-cover h-[300px]"
                  />
                  <div className="px-5 flex flex-col items-center justify-center text-center py-3 w-full">
                    <div className="flex flex-col">
                      <p className="body bold">{reserva.nome_coworking}</p>
                      <p className="button regular text-secundaria4">
                        {reserva.endereco_coworking.logradouro},
                        {reserva.endereco_coworking.numero} -{" "}
                        {reserva.endereco_coworking.municipio} -{" "}
                        {reserva.endereco_coworking.estado}
                      </p>
                    </div>
                    <div className="w-full">
                      <p className="body bold text-secundaria4 mt-4">
                        Seu agendamento:
                      </p>
                      <div className="px-2 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1">
                        <p className="body regular text-secundaria3">
                          {reserva.data_agendamento}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full mt-2 flex-wrap">
                      <div className="px-5 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1">
                        <p className="body regular text-secundaria3">
                          {reserva.hora_inicio.getHours()}:
                          {reserva.hora_inicio.getMinutes()}h
                        </p>
                      </div>
                      <p className="body regular text-secundaria3">ás</p>
                      <div className="px-5 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1">
                        <p className="body regular text-secundaria3">
                          {reserva.hora_fim.getHours()}:
                          {reserva.hora_fim.getMinutes()}h
                        </p>
                      </div>
                      {reserva.status === "A confirmar" && (
                        <div className="py-1 border-[0.4px] border-alerta2 rounded-lg mt-3 w-full">
                          <p className="textos regular text-alerta2 uppercase">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Confirmada" && (
                        <div className="flex items-center justify-center flex-col w-full">
                          <div className="py-1 border-[0.4px] border-detalhes4 rounded-lg mt-3 w-full">
                            <p className="textos regular text-detalhes4 uppercase">
                              {reserva.status}
                            </p>
                          </div>
                          <div className="mt-10 w-10/12 flex items-center justify-center flex-col gap-4">
                            <ButtonLight
                              legend="REALIZAR CHECK-IN"
                              onClick={() =>
                                handleCheck(
                                  "check-in",
                                  reserva.uuid,
                                  reserva.tipo_reserva
                                )
                              }
                            />
                            <p
                              className="button bold"
                              onClick={() => cancelarReserva(reserva.uuid)}
                            >
                              Cancelar
                            </p>
                          </div>
                        </div>
                      )}
                      {reserva.status === "Recusada" && (
                        <div className="py-1 border-[0.4px] border-alerta1 rounded-lg mt-3 w-full">
                          <p className="textos regular text-alerta1 uppercase">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Atraso" && (
                        <div className="py-1 border-[0.4px] border-alerta1 rounded-lg mt-3 w-full">
                          <p className="textos regular text-alerta1 uppercase">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Ativa" && (
                        <div className="flex items-center justify-center flex-col w-full">
                          <div className="py-1 border-[0.4px] border-detalhes4 rounded-lg mt-3 w-full">
                            <p className="textos regular text-detalhes4 uppercase">
                              {reserva.status}
                            </p>
                          </div>
                          <div className="mt-10 w-10/12 flex items-center justify-center flex-col gap-4">
                            <ButtonLight
                              legend="REALIZAR CHECK-OUT"
                              onClick={() =>
                                handleCheck(
                                  "check-out",
                                  reserva.uuid,
                                  reserva.tipo_reserva
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="hidden lg:flex py-4 w-full">
          {reservas &&
            load &&
            reservas.map((reserva) => {
              return (
                <div className="w-full">
                  <div className="flex items-center justify-between flex-row border border-secundaria2 bg-terciaria2 rounded-lg w-full xl:pr-10 md:pr-2">
                    <img
                      src={reserva.imagem_url}
                      alt="imagem do coworking"
                      className="rounded-l-md xl:w-3/12 max-h-[180px] h-auto border-secundaria2 md:w-4/12 object-cover"
                    />
                    <div className="xl:pr-8 border-r-2 border-r-detalhes2 border-opacity-40 py-5 flex flex-col gap-2">
                      <p className="body bold">{reserva.nome_coworking}</p>
                      <p className="button regular text-secundaria4">
                        {reserva.endereco_coworking.logradouro},
                        {reserva.endereco_coworking.numero} -{" "}
                        {reserva.endereco_coworking.municipio} -{" "}
                        {reserva.endereco_coworking.estado}
                      </p>
                    </div>
                    <div className="w-2/12 flex items-center flex-col justify-center gap-2">
                      <p className="textos semibold text-secundaria5 mt-4">
                        Seu agendamento:
                      </p>
                      <div className="px-2 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1 w-full flex items-center justify-center">
                        <p className="body regular text-secundaria3">
                          {reserva.data_agendamento}
                        </p>
                      </div>
                    </div>
                    <div className="flex xl:flex-row items-center xl:justify-between w-2/12 mt-2 xl:flex-wrap gap-2 lg:flex-col lg:justify-center">
                      <div className="px-2 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1 lg:w-auto">
                        <p className="body regular text-secundaria3">
                          {reserva.hora_inicio.getHours()}:
                          {reserva.hora_inicio.getMinutes()}h
                        </p>
                      </div>
                      <p className="body regular text-secundaria3">ás</p>
                      <div className="px-2 py-1 border-[0.4px] border-secundaria2 rounded-lg mt-1">
                        <p className="body regular text-secundaria3">
                          {reserva.hora_fim.getHours()}:
                          {reserva.hora_fim.getMinutes()}h
                        </p>
                      </div>
                      {reserva.status === "A confirmar" && (
                        <div className="py-1 border-[0.4px] border-alerta2 rounded-lg mt-1 w-full">
                          <p className="textos regular text-alerta2 uppercase text-center">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Confirmada" && (
                        <div className="flex items-center justify-center flex-col w-full">
                          <div className="py-1 border-[0.4px] border-detalhes4 rounded-lg mt-1 w-full">
                            <p className="textos regular text-detalhes4 uppercase text-center">
                              {reserva.status}
                            </p>
                          </div>
                        </div>
                      )}
                      {reserva.status === "Recusada" && (
                        <div className="py-1 border-[0.4px] border-alerta1 rounded-lg mt-1 w-full">
                          <p className="textos regular text-alerta1 uppercase text-center">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Atraso" && (
                        <div className="py-1 border-[0.4px] border-alerta1 rounded-lg mt-1 w-full">
                          <p className="textos regular text-alerta1 uppercase text-center">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                      {reserva.status === "Ativa" && (
                        <div className="py-1 border-[0.4px] border-detalhes4 rounded-lg mt-1 w-full">
                          <p className="textos regular text-detalhes4 uppercase text-center">
                            {reserva.status}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {reserva.status === "A confirmar" && (
                    <div className=" mt-6 w-full flex items-center justify-center border-b border-b-secundaria3 border-opacity-30 pb-8">
                      <p
                        className="textos regular text-secundaria5 cursor-pointer"
                        onClick={() => cancelarReserva(reserva.uuid)}
                      >
                        Cancelar
                      </p>
                    </div>
                  )}
                  {reserva.status === "Confirmada" && (
                    <div className=" mt-6 w-full flex items-center justify-center border-b border-b-secundaria3 border-opacity-30 pb-8 gap-8">
                      <div className="w-64">
                        <ButtonLight
                          legend="REALIZAR CHECK-IN"
                          onClick={() =>
                            handleCheck(
                              "check-in",
                              reserva.uuid,
                              reserva.tipo_reserva
                            )
                          }
                        />
                      </div>
                      <p
                        className="textos regular text-secundaria5 cursor-pointer"
                        onClick={() => cancelarReserva(reserva.uuid)}
                      >
                        Cancelar
                      </p>
                    </div>
                  )}
                  {reserva.status === "Ativa" && (
                    <div className=" mt-6 w-full flex items-center justify-center border-b border-b-secundaria3 border-opacity-30 pb-8 gap-8">
                      <div className="w-64">
                        <ButtonLight
                          legend="REALIZAR CHECK-OUT"
                          onClick={() =>
                            handleCheck(
                              "check-out",
                              reserva.uuid,
                              reserva.tipo_reserva
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {reservas.length === 0 && load && (
          <p className="textos regular text-secundaria5 w-full text-center py-8">
            Sem nenhuma reserva no momento
          </p>
        )}
      </div>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
      <div
        className={
          token.active
            ? "w-full h-full flex fixed z-[200] top-0 items-end justify-center left-0 lg:items-center"
            : "w-full h-full hidden z-[200] top-0 items-center justify-center"
        }
      >
        <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
        <div className="lg:w-6/12 w-full lg:rounded-3xl rounded-t-3xl white-box h-auto z-[300] flex px-6 flex-col pt-8 pb-8 gap-3 relative items-center justify-center text-center">
          {token.tipo === "check-in" && (
            <div className="flex gap-6 flex-col items-center justify-center">
              <h4 className="bold text-primaria4">REALIZAR CHECK-IN</h4>
              <p className="textos regular">
                Para realizar o check-in, apresente o token a baixo na recepção
                do coworking agendado.
              </p>
              <h2 className="bold text-primaria4">{token.token}</h2>
              <div className="w-48 flex items-center justify-center">
                <ButtonLight
                  legend="VOLTAR"
                  onClick={() =>
                    setToken({ token: "", active: false, tipo: "" })
                  }
                />
              </div>
            </div>
          )}
          {token.tipo === "check-out" && (
            <div className="flex gap-6 flex-col items-center justify-center">
              <h4 className="bold text-primaria4">REALIZAR CHECK-OUT</h4>
              <p className="textos regular">
                Para realizar o check-out, apresente o token a baixo na recepção
                do coworking agendado.
              </p>
              <h2 className="bold text-primaria4">{token.token}</h2>
              <div className="w-48 flex items-center justify-center">
                <ButtonLight
                  legend="VOLTAR"
                  onClick={() =>
                    setToken({ token: "", active: false, tipo: "" })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          messageOut.active
            ? "w-full h-full flex fixed z-[200] top-0 items-end justify-center left-0 lg:items-center"
            : "w-full h-full hidden z-[200] top-0 items-center justify-center"
        }
      >
        <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
        <div className="lg:w-6/12 w-full lg:rounded-3xl rounded-t-3xl white-box h-auto z-[300] flex px-6 flex-col pt-8 pb-8 gap-8 relative items-center justify-center text-center">
          <h4 className="bold text-primaria4">
            CHECK-OUT REALIZADO COM SUCESSO!
          </h4>
          <div className="w-48 flex items-center justify-center">
            <ButtonLight
              legend="VOLTAR"
              onClick={() => setMessageOut({ message: "", active: false })}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reservas;
