import React, { useState, useEffect } from "react";
import relatorios from "../../assets/relatorios.svg";
import DropDown from "../../components/DropDown";
import ListarHistorico from "../../services/Parceiro/ListarHistorico";
import getParamsFromUrl from "../../utils/getParamsFromUrl";

const Relatorios = () => {
  const [visitas, setVisitas] = useState([]);
  const [load, setLoad] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const [filtro, setFiltro] = useState("Todos");

  useEffect(() => {
    getVisitas();
    // eslint-disable-next-line
  }, []);

  const addItens = async (arr, itens) => {
    return new Promise(function (resolve, reject) {
      itens.map((iten) => {
        let newDate = new Date(iten.hora_inicio);
        iten.hora_inicio = newDate;
        let newDate2 = new Date(iten.hora_fim);
        iten.hora_fim = newDate2;
        arr.push(iten);
        return true;
      });
      resolve(arr);
    });
  };

  const handleFiltro = (value) => {
    setFiltro(value);
    ListarHistorico(false, value)
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        if (resp.data.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }

        addItens(newArr, resultados).then((resp) => {
          setVisitas(resp);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSeeMore = () => {
    if (filtro === "Todos") {
      ListarHistorico(nextPage, false)
        .then((resp) => {
          if (resp?.data?.next) {
            setNextPage(getParamsFromUrl("page", resp?.data?.next));
          } else {
            setNextPage("");
          }
          let resultados = resp.data.results;
          let newArr = visitas;
          addItens(newArr, resultados).then((resp) => {
            setVisitas(resp);
            setNextPage(resp?.data?.next);
            setLoad(true);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      ListarHistorico(nextPage, filtro)
        .then((resp) => {
          if (resp?.data?.next) {
            setNextPage(getParamsFromUrl("page", resp?.data?.next));
          } else {
            setNextPage("");
          }
          let resultados = resp.data.results;
          let newArr = visitas;
          addItens(newArr, resultados).then((resp) => {
            setVisitas(resp);
            setNextPage(resp?.data?.next);
            setLoad(true);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getVisitas = () => {
    ListarHistorico(false, false)
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        if (resp.data.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }

        addItens(newArr, resultados).then((resp) => {
          setVisitas(resp);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <section className="p-6 lg:p-4 flex items-center justify-start bg-terciariafundo min-h-screen flex-col">
      <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-4 w-full lg:w-10/12 my-4 flex items-center justify-center flex-col">
        <div className="flex flex-row gap-3 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25 w-full">
          <img src={relatorios} alt="check-in" />
          <p className="textos semibold text-secundaria4 pt-1">
            RELATÓRIO DE SOLICITAÇÕES
          </p>
        </div>
        <h4 className="bold text-secundaria5 self-start mt-8">Filtrar por</h4>
        <div className="self-start mt-2 lg:w-2/12 w-6/12">
          <DropDown
            options={[
              { value: "Atraso", name: "Atraso" },
              { value: "Cancelada", name: "Cancelada" },
              { value: "Concluida", name: "Concluída" },
              { value: "Recusada", name: "Recusada" },
              { value: "Todos", name: "Todos" },
            ]}
            background="terciaria1"
            message="Ordenar por"
            onChange={(e) => handleFiltro(e.target.value)}
          />
        </div>
        <div className="w-full mt-8 flex flex-col items-center justify-center gap-2 lg:flex-row lg:flex-wrap">
          {load &&
            visitas.map((visita) => {
              return (
                <div
                  key={visita.uuid}
                  className="bg-terciaria2 w-full gap-4 lg:border border-secundaria2 rounded-t-lg rounded-b-lg border flex flex-col py-4 lg:w-5/12 lg:px-0 px-4 lg:max-w-[580px]"
                >
                  <p className="textos semibold text-secundaria4 lg:pl-6">
                    Dados do visitante
                  </p>
                  <div className="w-full flex items-center justify-center flex-col gap-4 lg:flex-row lg:flex-wrap">
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {visita.nome_colaborador}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {visita.email}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12">
                      <p className="textos regular text-secundaria3">
                        {visita.data_agendamento}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full lg:w-5/12">
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {visita.hora_inicio.getHours()}:
                          {visita.hora_inicio.getMinutes()}h
                        </p>
                      </div>
                      <p className="textos regular text-secundaria3">às</p>
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {visita.hora_fim.getHours()}:
                          {visita.hora_fim.getMinutes()}h
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full px-8 items-start justify-start gap-2">
                      <p className="textos semibold text-secundaria4 w-full">
                        Situação
                      </p>
                      {visita.status === "Concluida" && (
                        <div className="w-full border-2 rounded-md px-2 py-1 border-detalhes4 lg:w-60">
                          <p className="button medium text-detalhes4 select-none text-center">
                            CONCLUÍDA
                          </p>
                        </div>
                      )}
                      {visita.status === "Recusada" && (
                        <div className="w-full border-2 rounded-md px-2 py-1 border-alerta1">
                          <p className="button medium text-alerta1 select-none text-center">
                            RECUSADA
                          </p>
                        </div>
                      )}
                      {visita.status === "Atraso" && (
                        <div className="w-full border-2 rounded-md px-2 py-1 border-alerta1">
                          <p className="button medium text-alerta1 select-none text-center">
                            ATRASO
                          </p>
                        </div>
                      )}
                      {visita.status === "Cancelada" && (
                        <div className="w-full border-2 rounded-md px-2 py-1 border-alerta1">
                          <p className="button medium text-alerta1 select-none text-center">
                            CANCELADA
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {nextPage && (
          <p
            className="textos semibold text-secundaria4 mt-2"
            onClick={() => handleSeeMore()}
          >
            Ver mais
          </p>
        )}
        {load && visitas.length === 0 && (
          <p className="textos semibold text-secundaria4 mt-2">
            Não há nenhuma reserva.
          </p>
        )}
      </div>
    </section>
  );
};

export default Relatorios;
