import React from "react";
import maskDoc from "../utils/maskDoc";
import Switch from "./Switch";
import ChangeBenefict from "../services/Company/ChangeBenefict";
import InputEdit from "./InputEdit";
import InputFixed from "./InputFixed";
import close from "../assets/close.svg";
const CardColab = (props) => {
  let {
    colab,
    user,
    inputs,
    setInputs,
    inputsStatus,
    setInputsStatus,
    handleOpenColab,
    openColab,
    setOpenColab,
    colabs,
    setColabs,
  } = props;

  const changeChecked = (email) => {
    let newColabs = colabs;
    let index = newColabs.findIndex((x) => x.email === email);
    newColabs[index]["selected"] = !newColabs[index].selected;
    setColabs([...newColabs]);
  };

  const checkBenefict = (email) => {
    let newColabs = colabs;
    let index = newColabs.findIndex((x) => x.email === email);
    newColabs[index]["benefict"] = !newColabs[index].benefict;
    setColabs([...newColabs]);
  };

  return (
    <div
      className="flex flex-row items-center justify-between px-4 bg-terciaria2 flex-wrap gap-4 w-full"
      key={colab.uuid}
    >
      <input
        type="checkbox"
        onChange={() => {
          changeChecked(colab.email);
        }}
        checked={colab.selected}
      ></input>
      <div
        className="cursor-pointer max-w-[150px] overflow-clip"
        onClick={() => handleOpenColab(colab.email)}
      >
        <p className="textos medium text-primaria4 cursor-pointer">
          {colab.name}
        </p>
        <p className="caption regular">{colab.email}</p>
      </div>
      {colab.status === false ? (
        <div className="flex items-center text-center justify-center border-alerta2 border-2 rounded-lg text-alerta2 w-28 h-11">
          <p className="textos regular">Aguardando ativação</p>
        </div>
      ) : (
        ""
      )}
      {colab.status === true ? (
        <div className="flex items-center text-center justify-center border-y-detalhes4 border-2 rounded-lg text-detalhes4 w-28 h-11">
          <p className="textos regular">ATIVO</p>
        </div>
      ) : (
        ""
      )}
      <div className="w-9/12 border border-primaria2 p-3 rounded-lg flex items-center justify-center gap-6 mx-auto">
        <p className="body regular">Liberar benefício</p>
        <Switch
          checked={colab.benefict}
          func={ChangeBenefict}
          func2={checkBenefict}
          colab={colab}
          empresa={user}
        />
      </div>
      <div
        className={
          openColab
            ? "w-full h-full fixed z-[200] top-0 flex items-center justify-center lg:w-[600px] left-0"
            : "w-full h-full hidden z-[200] top-0 items-center justify-center"
        }
      >
        <div className="w-full h-full fixed bg-primaria3 top-0 opacity-30 flex items-center justify-center" />
        <div
          className="absolute w-12 h-12 bg-terciaria2 shadow-xl bottom-[385px] right-8 rounded-full z-[350] flex items-center justify-center"
          onClick={() => setOpenColab(!openColab)}
        >
          <img src={close} alt="Fechar Menu" />
        </div>
        <div className="w-full rounded-t-3xl white-box h-auto z-[300] flex px-6 flex-col pt-8 pb-8 absolute bottom-0 gap-3">
          <h4 className="bold text-primaria3">Dados do colaborador</h4>
          <p className="button bold">Dados do colaborador</p>
          <InputEdit
            edit={inputsStatus.email}
            setInputs={setInputsStatus}
            inputs={inputsStatus}
            background="terciaria2"
            name="email"
            value={inputs.email}
            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
          />
          <InputEdit
            edit={inputsStatus.nome}
            setInputs={setInputsStatus}
            inputs={inputsStatus}
            background="terciaria2"
            name="nome"
            value={inputs.nome}
            onChange={(e) => setInputs({ ...inputs, nome: e.target.value })}
          />
          <div className="flex flex-row justify-between gap-2">
            <div className="w-full">
              <InputFixed
                value={maskDoc(inputs.cpf, "cpf")}
                background="terciaria2"
              />
            </div>
          </div>
          <p className="button bold">Quantidade de horas</p>
          <div className="flex items-center justify-center gap-2">
            <div className="white-box border border-secundaria2 rounded-md w-6/12 flex items-center justify-center flex-col p-4 gap-2 h-24">
              <p className="textos medium text-secundaria5">Consumidas</p>
              <p className="textos regular text-secundaria3">{`Total: ${inputs.visitas_utilizadas}`}</p>
            </div>
            <div className="white-box border border-secundaria2 rounded-md w-6/12 flex items-center justify-center flex-col p-4 gap-2 h-24">
              <p className="textos medium text-secundaria5">Disponíveis</p>
              <p className="textos regular text-secundaria3">{`Total: ${inputs.visitas_totais}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardColab;
