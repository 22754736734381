import React, { useContext } from "react";
import logoFooter from "../assets/logoFooter.svg";
import LoggedHeaderContext from "../hooks/LoogedHeaderContext";

const Footer = () => {
  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  const changeMenu = (id) => {
    changeMenuLogged(id);
  };

  return (
    <section>
      <div className="bg-primaria5 bottom-0 h-auto pt-6 pb-2 w-full lg:hidden flex flex-row flex-wrap justify-between px-4 items-start gap-4">
        <img
          alt="Logo Onworking"
          src={logoFooter}
          className="w-24 h-auto object-center pb-2"
        />
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-2">
            <p className="argentum-medium-12 text-terciaria2">DOCUMENTOS</p>
            <p
              className="argentum-extralight-8 text-terciaria2 cursor-pointer "
              onClick={() => changeMenu("Termos")}
            >
              Termos e condições de uso
            </p>
            <p
              className="argentum-extralight-8 text-terciaria2 cursor-pointer"
              onClick={() => changeMenu("Privacidade")}
            >
              Privacidade
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="argentum-medium-12 text-terciaria2">ONWORKING</p>
            <p className="argentum-extralight-8 text-terciaria2 cursor-pointer ">
              Parceiros
            </p>
            <p className="argentum-extralight-8 text-terciaria2 cursor-pointer">
              Empresas
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="argentum-medium-12 text-terciaria2">SUPORTE</p>
            <p className="argentum-extralight-8 text-terciaria2 cursor-pointer">
              contato@onworkingpass.com
            </p>
          </div>
        </div>
        <div className="w-full h-fit text-center mt-10">
          <p className="argentum-extralight-8 text-terciaria2 w-full h-auto">
            © Copyright 2022 - Onworking Pass. Todos os direitos reservados. O
            conteúdo deste site não pode ser reproduzido, distribuído,
            transmitido, ou usado, exceto com a permissão prévia por escrito.
          </p>
        </div>
      </div>
      <div className="bottom-0 hidden lg:flex  h-0 w-full z-20 bg-primaria5 lg:h-52  flex-row items-start justify-between py-9 flex-wrap">
        <div className="flex flex-row justify-between w-full xl:px-[145px] md:px-24">
          <img
            src={logoFooter}
            alt="Logo Onworking"
            className="w-40 h-auto self-start"
          />
          <div className="flex flex-row gap-[150px]">
            <div className="flex flex-col gap-2">
              <p className="textos semibold text-terciaria2">DOCUMENTOS</p>
              <p
                className="argentum-extralight text-terciaria2 cursor-pointer "
                onClick={() => changeMenu("Termos")}
              >
                Termos e condição de uso
              </p>
              <p
                className="argentum-extralight text-terciaria2 cursor-pointer"
                onClick={() => changeMenu("Privacidade")}
              >
                Privacidade
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="textos semibold text-terciaria2">ONWORKING</p>
              <p className="argentum-extralight text-terciaria2 cursor-pointer ">
                Parceiros
              </p>
              <p className="argentum-extralight text-terciaria2 cursor-pointer">
                Empresas
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="textos semibold text-terciaria2">Suporte</p>
              <p className="argentum-extralight text-terciaria2 cursor-pointer">
                contato@onworkingpass.com
              </p>
            </div>
          </div>
        </div>
        <p className="caption regular text-terciaria2 text-center w-full mt-16 select-none px-4">
          © Copyright 2022 - Onworking Pass. Todos os direitos reservados. O
          conteúdo deste site não pode ser reproduzido, distribuído,
          transmitido, ou usado, exceto com a permissão prévia por escrito.
        </p>
      </div>
    </section>
  );
};

export default Footer;
