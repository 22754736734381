function maskData(strData, mask) {
  if (mask === "reverter-data") {
    let v = strData;
    let ano = v.substring(6);
    let mes = v.substring(3, 5);
    let dia = v.substring(0, 2);
    let novaData = String(ano) + "-" + String(mes) + "-" + String(dia);
    return novaData;
  }
  strData = strData.replace(/\D/g, "");

  strData = strData.replace(/(\d{2})(\d)/, "$1/$2");

  strData = strData.replace(/(\d{2})(\d)/, "$1/$2");

  return strData;
}

export default maskData;
