import React from "react";
import closeEye from "../assets/closeEye.svg";
import openedEye from "../assets/openedEye.svg";
const PasswordInput = (props) => {
  let {
    placer,
    background,
    name,
    value,
    onChange,
    maxLength,
    pass,
    setPass,
    id,
  } = props;

  const bckg = background ? "bg-" + background : "bg-terciaria1";
  const classe =
    "w-full justify-center border border-secundaria2 rounded-lg h-full pl-5 items-center placeholder:text-secundaria3 text-secundaria3 " +
    bckg;

  return (
    <div className="relative w-full h-full">
      <input
        id={id}
        className={classe}
        placeholder={placer}
        type={pass ? "password" : "text"}
        name={name}
        onChange={onChange}
        value={value}
        maxLength={maxLength ? maxLength : ""}
      ></input>
      <img
        src={pass ? openedEye : closeEye}
        alt=""
        className="absolute w-6 h-auto top-4 right-5"
        onClick={() => setPass(!pass)}
      />
    </div>
  );
};

export default PasswordInput;
