import axiosInstance from "../AxiosInstance";

const RegisterColab = async (body, set) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("auth/colaborador/criar-senha/", body)
      .then((response) => {
        resolve({ message: "Logado com sucesso!", status: 200 });
      })
      .catch((error) => {
        reject({
          message: error.response.data.data,
          status: 400,
        });
      });
  });
};

export default RegisterColab;
