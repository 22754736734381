import React from "react";

const Switch = (props) => {
  let { checked, inputs, setInputs, dia } = props;

  const handleChange = () => {
    let changeInputs = inputs;
    let change = inputs.findIndex((x) => x.dia === dia);
    changeInputs[change].funcionamento = !changeInputs[change].funcionamento;
    setInputs([...changeInputs]);
  };

  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onClick={() => handleChange()}
          readOnly
        />
        <span className="slider"></span>
      </label>
    </>
  );
};

export default Switch;
