import React from "react";
import LoginPage from "../screens/login";
import { Routes, Route } from "react-router-dom";
import Cadastro from "../screens/Colaborador/Cadastro";
import CadastroEmpresa from "../screens/Empresa/Cadastro";
import CadastroParceiro from "../screens/Coworking/Cadastro";
import ParaEmpresas from "../screens/LandingPage/ParaEmpresas";
import Home from "../screens/LandingPage/Home";
import ParaParceiros from "../screens/LandingPage/ParaParceiros.js";
import EscolherCadastro from "../screens/EscolherCadastro";
import Contato from "../screens/LandingPage/Contato";
import Termos from "../screens/LandingPage/Termos";
import Privacidade from "../screens/LandingPage/Privacidade";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/colaborador/cadastro" element={<Cadastro />} />
        <Route path="/empresa/cadastro" element={<CadastroEmpresa />} />
        <Route path="/parceiro/cadastro" element={<CadastroParceiro />} />
        <Route path="/empresas" element={<ParaEmpresas />} />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/parceiro" element={<ParaParceiros />} />
        <Route path="/cadastro" element={<EscolherCadastro />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/termos" element={<Termos />} />
        <Route path="/privacidade" element={<Privacidade />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
