import React from "react";
import ButtonLight from "../../components/ButtonLight";
import TextArea from "../../components/TextArea";
import left from "../../assets/left-gray.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import close from "../../assets/close.svg";

const CancelPlan = () => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);

  const handleClick = () => {
    setConfirm(!confirm);
  };

  return (
    <section className="flex justify-center items-center bg-terciariafundo px-4 py-8">
      <div className="bg-terciaria rounded-xl shadow-shadowMin py-4 px-4 w-full lg:w-8/12 lg:flex lg:flex-col">
        <div className="flex items-center justify-between border-b border-secundaria2 pb-2">
          <img
            src={left}
            alt="Retornar para o perfil"
            className="w-6 h-auto cursor-pointer"
            onClick={() => navigate("/empresa/perfil")}
          />
          <p className="textos semibold text-secundaria4">Cancelamento</p>
        </div>
        <div className="white-box py-4 my-6 w-fit px-6 rounded-xl">
          <p className="body medium text-secundaria4">Plano atual: Platinum</p>
        </div>
        <div className="w-full h-36 lg:h-56 mt-2">
          <TextArea placer="Descreva o motivo do cancelamento" />
        </div>
        <div className="w-full mt-6 lg:w-8/12 lg:justify-self-center lg:self-center">
          <ButtonLight
            legend="SOLICITAR CANCELAMENTO"
            onClick={() => handleClick()}
          />
        </div>
      </div>
      <div
        className={
          confirm
            ? "w-full h-full fixed z-[200] top-0 flex items-center justify-center lg:w-[600px]"
            : "w-full h-full hidden z-[200] top-0 items-center justify-center"
        }
      >
        <div className="w-full h-full fixed bg-primaria3 z-[200] top-0 opacity-80 flex items-center justify-center" />
        <div className="w-10/12 white-box h-auto z-[300] flex items-center justify-center text-center px-6 flex-col pt-4 pb-8">
          <img
            src={close}
            alt="Fechar"
            className="self-end cursor-pointer"
            onClick={() => setConfirm(!confirm)}
          />
          <p className="textos regular mt-12 lg:w-9/12">
            Sua solicitação foi direcionada para nossa central, que entrará em
            contato para finalizar o processo.
          </p>
          <div className="w-64 mt-12">
            <ButtonLight
              legend="Início"
              onClick={() => navigate("/empresa/perfil")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CancelPlan;
