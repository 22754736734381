import React from "react";

const ButtonDark = (props) => {
  let { legend, shadow, func, id } = props;
  let classes =
    "flex-grow p-3 rounded-sm h-full gradient-button flex items-center justify-center w-full " +
    shadow;

  return (
    <button className={classes} onClick={func} id={id}>
      <p className="button regular text-secundaria1">{legend}</p>
    </button>
  );
};

export default ButtonDark;
