// import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AuthProvider } from "./hooks/AuthContext";
import { LoggedHeaderProvider } from "./hooks/LoogedHeaderContext";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import ComingSoon from "./screens/ComingSoon";
import "./fonts/ArgentumSans-Medium.ttf";

function App() {
  return (
    <Router>
      <AuthProvider>
        <LoggedHeaderProvider>
          <Header />
          <PublicRoutes />
          <PrivateRoutes />
          <Footer />
        </LoggedHeaderProvider>
      </AuthProvider>
      <Routes>
        <Route exact path="/" element={<ComingSoon />} />
      </Routes>
    </Router>
  );
}

export default App;
