import Input from "../components/Input";
import React, { useContext, useState } from "react";
import key from "../assets/key.svg";
import ButtonLight from "../components/ButtonLight";
import ChangePassword from "../services/Register/ChangePassword";
import AuthContext from "../hooks/AuthContext";
import ModalAlert from "../components/ModalAlert";
import { useNavigate } from "react-router-dom";

const AlterarSenha = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    senha_atual: "",
    nova_senha: "",
    confirmar_nova_senha: "",
  });

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  let { user } = useContext(AuthContext);
  const handleSubmit = (e) => {
    e.preventDefault();

    ChangePassword({
      email: user.email,
      senha_atual: inputs.senha_atual,
      password1: inputs.nova_senha,
      password2: inputs.confirmar_nova_senha,
    })
      .then((resp) => {
        setModal({
          text: resp.data.message,
          alertType: "ok",
          active: true,
        });
        setTimeout(() => {
          switch (user.type) {
            case "Coworking":
              navigate("/parceiro/perfil");
              break;
            case "Colaborador":
              navigate("/colaborador/perfil");
              break;

            case "Empresa":
              navigate("/empresa/perfil");
              break;
            default:
              break;
          }
        }, 1000);
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  return (
    <section className=" py-6 px-6 lg:p-4 flex items-start justify-center bg-terciariafundo min-h-screen">
      <div className="bg-terciaria rounded-xl shadow-shadowMin py-4 px-6 w-full lg:w-10/12 lg:flex lg:flex-col mt-4">
        <header className="flex gap-5 items-center justify-start border-b pb-3 border-b-secundaria3 border-opacity-20">
          <img src={key} alt="chave" />
          <p className="textos semibold text-secundaria4">ALTERAR SENHA</p>
        </header>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex flex-col gap-6 mt-6 lg:flex-row"
        >
          <Input
            background="terciariafundo"
            placer="Senha atual"
            name="senha_atual"
            value={inputs.senha_atual}
            onChange={(e) =>
              setInputs({ ...inputs, senha_atual: e.target.value })
            }
            tipo="password"
            id="senha_atual"
          />
          <Input
            background="terciariafundo"
            placer="Nova senha"
            name="nova_senha"
            value={inputs.nova_senha}
            onChange={(e) =>
              setInputs({ ...inputs, nova_senha: e.target.value })
            }
            tipo="password"
            id="nova_senha"
          />
          <Input
            background="terciariafundo"
            placer="Confirmar nova senha"
            name="confirmar_nova_senha"
            value={inputs.confirmar_nova_senha}
            onChange={(e) =>
              setInputs({ ...inputs, confirmar_nova_senha: e.target.value })
            }
            tipo="password"
            id="confirmar_nova_senha"
          />
          <ButtonLight legend="ALTERAR SENHA" id="alterar_senha_1" />
        </form>
      </div>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </section>
  );
};

export default AlterarSenha;
