import React from "react";
import ButtonLight from "../components/ButtonLight";
import CountdownTimer from "../components/CountdownTimer";
import ButtonDark4xl from "../components/ButtonDark4xl";
import Input from "../components//Input";
import { useState } from "react";
import DropDown from "../components/DropDown";
import ButtonDark from "../components/ButtonDark";
import close from "../assets/close.svg";
import TextArea from "../components/TextArea";
import logoOn from "../assets/logo-on.svg";
import FooterCommingSoon from "../components/FooterCommingSoon";
import left from "../assets/right.svg";
import axiosInstance from "../services/AxiosInstance";
import ModalAlert from "../components/ModalAlert";

const ComingSoon = () => {
  const [control, setControl] = useState(true);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const handleSend = () => {
    if (
      nome === "" &&
      ident === "" &&
      email === "" &&
      descricao === "" &&
      assunto === ""
    ) {
      setModal({
        text: "Todos os campos devem estar preenchidos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    axiosInstance
      .post(`api/v1/solicitacao/contato/`, {
        nome: nome,
        identificacao: ident,
        email: email,
        descricao: descricao,
        assunto: assunto,
      })
      .then((resp) => {
        setModal({
          text: "Solicitação enviada!",
          alertType: "ok",
          active: true,
        });
      })
      .catch((e) => {
        setModal({
          text: "Ocorreu um erro, tente novamente!",
          alertType: "fail",
          active: true,
        });
      });
  };

  const [nome, setNome] = useState("");
  const [ident, setIdent] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [assunto, setAssunto] = useState("");

  const changeButton = () => {
    return setControl(!control);
  };

  return (
    <body className="min-h-[1100px] lg:min-h-screen lg:h-auto max-h-screen bg-terciariafundo relative overflow-hidden flex flex-col lg:bg-commingsoon lg:bg-right comming-soon-bg bg-no-repeat">
      <header
        className={
          control
            ? "flex w-screen py-6 lg:px-20 px-4 lg:py-10 justify-between transition-all"
            : "flex w-screen py-6 lg:px-20 px-4 lg:py-10 justify-between blur-3xl transition-all lg:blur-0 pointer-events-none lg:pointer-events-auto"
        }
      >
        <img src={logoOn} alt="logo onworking" />
        <div
          className={control ? "items-center flex w-5/12 lg:w-2/12" : "hidden"}
        >
          <ButtonLight legend="CONTATE-NOS" onClick={changeButton} />
        </div>
      </header>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
      <div
        className={
          control
            ? "w-full flex-grow flex flex-col lg:flex-row transition-all"
            : "w-full flex-grow flex flex-col lg:flex-row lg:opacity-0 blur-3xl transition-all pointer-events-none"
        }
      >
        <div className="flex-row justify-center align-middle w-screen py-4 px-4 lg:px-12 lg:py-12  items-center text-center lg:text-start lg:w-9/12 lg:items-start lg:ml-20">
          <h2 className="bold text-primaria3">Em breve, officepass.</h2>
          <h4 className="medium mt-1 text-secundaria4">
            Nosso site está em construção.
          </h4>
          <div className="w-full flex flex-col lg:flex lg:flex-row justify-center items-center align-middle mt-8 lg:justify-start lg:items-start pb-6 lg:pb-0">
            <div className="w-11/12 lg:w-7/12">
              <Input placer="Seu email" />
            </div>
            <div className="items-center flex w-8/12 lg:w-2/12 lg:ml-4 lg:mt-0 z-10 absolute mt-32 lg:relative">
              <ButtonDark4xl legend="NOTIFICA-ME" />
            </div>
          </div>
          <div className="background-faixa mt-24 px-4 py-8 relative right-56 hidden lg:flex lg:w-11/12">
            <CountdownTimer countdownTimestampMs={1680283284000} />
          </div>
        </div>
        <div className="w-full bg-commingsoon flex-grow bg-no-repeat bg-cover lg:hidden">
          <div className="background-faixa mt-16 p-4 relative">
            <CountdownTimer countdownTimestampMs={1671062400000} />
          </div>
        </div>
      </div>
      <div
        className={
          !control
            ? "fixed bottom-0 lg:top-[55%] lg:left-[50%] lg:translate-x-[-50%] lg:translate-y-[-50%] transition-opacity py-8 bg-terciaria2 rounded-t-[45px] z-40 w-full lg:w-8/12 lg:h-fit lg:rounded-3xl px-16 lg:flex-wrap lg:flex lg:px-36 lg:justify-between form-background"
            : "fixed bottom-0 lg:top-[55%] lg:left-[50%] lg:translate-x-[-50%] lg:translate-y-[-50%] transition-opacity h-0 opacity-0 py-8 lg:w-8/12 lg:h-fit lg:rounded-3xl px-16 lg:flex-wrap lg:flex lg:px-36 lg:justify-between form-background pointer-events-none"
        }
      >
        <img
          src={left}
          alt=""
          className="absolute hidden lg:flex left-10 cursor-pointer"
          onClick={changeButton}
        />
        <div
          className="w-16 h-16 bg-terciaria2 absolute right-10 -top-8 rounded-full shadow-md flex align-middle justify-center p-5 lg:hidden"
          onClick={changeButton}
        >
          <img src={close} alt="fechar" className="" />
        </div>
        <h4 className="bold text-primaria3 mb-5 w-full">Contate-nos</h4>
        <div className="w-full lg:w-7/12 my-6">
          <Input
            placer="Nome"
            background="terciaria1"
            onChange={setNome}
            value={nome}
          />
        </div>
        <div className="w-full lg:w-4/12 my-6">
          <DropDown
            options={[
              { value: "Colaborador", name: "Colaborador" },
              { value: "Coworking", name: "Coworking" },
              { value: "Empresa", name: "Empresa" },
            ]}
            background="terciaria1"
            message="Identificação"
            onChange={setIdent}
            value={ident}
          />
        </div>
        <div className="w-full lg:w-7/12 my-6">
          <Input
            placer="Email"
            background="terciaria1"
            onChange={setEmail}
            value={email}
          />
        </div>
        <div className="w-full lg:w-4/12 my-6">
          <DropDown
            options={[
              { value: "Contratação", name: "Contratação" },
              { value: "Reclamação", name: "Reclamação" },
              { value: "Sugestões", name: "Sugestões" },
            ]}
            background="terciaria1"
            message="Assunto"
            onChange={setAssunto}
            value={assunto}
          />
        </div>
        <div className="w-full lg:w-12/12 my-6">
          <TextArea
            placer="Descrição"
            background="terciaria1"
            onChange={setDescricao}
            value={descricao}
          />
        </div>
        <div className="w-full lg:w-6/12 mt-8 lg:mx-auto">
          <ButtonDark legend="ENVIAR" func={() => handleSend()} />
        </div>
      </div>
      <FooterCommingSoon />
    </body>
  );
};

export default ComingSoon;
