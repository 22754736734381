import React from "react";

const SearchBar = (props) => {
  let {
    placer,
    background,
    tipo,
    name,
    value,
    onChange,
    maxLength,
    height,
    id,
    search,
  } = props;

  const bckg = background;
  const h = height ? " h-" + height : " h-10";
  const pointer = search ? " pointer-events-none" : " pointer-events-auto";
  const classe =
    "w-full justify-center rounded-3xl pl-12 shadow-3xl items-center placeholder:text-secundaria3 text-secundaria3 " +
    bckg +
    pointer +
    h;

  return (
    <input
      id={id}
      className={classe}
      placeholder={placer}
      type={tipo ? tipo : "text"}
      name={name}
      onChange={onChange}
      value={value}
      maxLength={maxLength ? maxLength : ""}
    ></input>
  );
};

export default SearchBar;
