import axiosInstance from "../AxiosInstance";

const ChangeBenefict = async (empresa, colab_email) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`empresas/atualizar-status-beneficios-colaborador/`, {
        empresa: empresa,
        email: colab_email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({
          message: error.response.data,
          status: 400,
        });
      });
  });
};

export default ChangeBenefict;
