import axiosInstance from "../AxiosInstance";

const RemoveCapaCoworking = async (cnpj) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`parceiros/exclui-capa-coworking/`, { cnpj: cnpj })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default RemoveCapaCoworking;
