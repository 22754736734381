import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import InputEdit from "../../components/InputEdit";
import AuthContext from "../../hooks/AuthContext";
import ButtonLigth from "../../components/ButtonLight";
import { useNavigate } from "react-router-dom";
import add from "../../assets/add.svg";
import GetProfileParceiro from "../../services/Parceiro/GetProfileParceiro";
import addGrey from "../../assets/addGrey.svg";
import ButtonCounter from "../../components/ButtonCounterAssentos";
import TextArea from "../../components/TextArea";
import DropDown from "../../components/DropDown";
import Switch from "../../components/SwitchHorario";
import close from "../../assets/close.svg";
import SwitchFacilitie from "../../components/SwitchFacilitie";
import ParceiroCadastroImagens from "../../services/Parceiro/ParceiroCadastroImagens";
import EditSpace from "../../services/Parceiro/EditarEspaco";
import ModalAlert from "../../components/ModalAlert";
import RemoveImageCoworking from "../../services/Parceiro/RemoveImageCoworking";
import ParceiroCadastraCapa from "../../services/Parceiro/ParceiroCadastroCapa";
import RemoveCapaCoworking from "../../services/Parceiro/RemoveCapaCoworking";
import InputFixed from "../../components/InputFixed";
import InputHorario from "../../components/InputHorario";

const EditarEspaco = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  let { user } = useContext(AuthContext);

  const [buttonState, setButtonState] = useState({
    step1: false,
  });

  const [files, setFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [espacos, setEspacos] = useState(0);
  const [facilities, setFacilities] = useState(false);
  const [cobrados, setCobrados] = useState(false);
  const [itensCobrados, setItensCobrados] = useState([]);
  const [facilitiesItens, setFacilitiesItens] = useState([]);
  const [horario, setHorario] = useState(false);
  const [tipo, setTipo] = useState("");
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [inputsStatus, setInputsStatus] = useState({
    logradouro: false,
    municipio: false,
    estado: false,
    complemento: false,
    numero: false,
    cep: false,
    nome_unidade: false,
  });

  const [descricao, setDescricao] = useState("");
  const [dados, setDados] = useState({
    email: "",
    razaoSocial: "",
    nome_unidade: "",
    descricao: "",
    cnpj: "",
    site: "",
    telefone: "",
    endereco: {
      logradouro: "",
      municipio: "",
      estado: "",
      complemento: "",
      numero: "",
      cep: "",
    },
    acesso_liberado: "",
    primeiro_acesso: "",
    acesso_negado: "",
    motivo: "",
    tipo: "",
  });
  const [load, setLoad] = useState(false);
  const [horariosCoworking, setHorariosCoworking] = useState([]);

  const handleHorario = () => {
    setHorario(!horario);
  };

  const closeHorario = () => {
    let liberado = true;
    horariosCoworking.forEach((dia) => {
      if (!dia.funcionamento && dia.hora_abertura.length !== 0) {
        let horarios = horariosCoworking;
        let index = horarios.findIndex((x) => x.dia === dia.dia);
        horarios[index].hora_abertura = "";
        setHorariosCoworking([...horarios]);
      }
      if (!dia.funcionamento && dia.hora_fechamento.length !== 0) {
        let horarios = horariosCoworking;
        let index = horarios.findIndex((x) => x.dia === dia.dia);
        horarios[index].hora_fechamento = "";
        setHorariosCoworking([...horarios]);
      }
      if (dia.funcionamento) {
        if (!dia.hora_abertura || !dia.hora_fechamento) {
          setModal({
            text: `Preencha o horário de abertura e fechamento - (${dia.dia})`,
            alertType: "fail",
            active: true,
          });
          liberado = false;
          return;
        } else if (
          dia.hora_abertura.length !== 5 ||
          dia.hora_fechamento.length !== 5
        ) {
          setModal({
            text: `Os horários precisam ter o seguinte formato HH:MM - (${dia.dia})`,
            alertType: "fail",
            active: true,
          });
          liberado = false;
          return;
        } else {
          let hora1 = dia.hora_abertura.split(":");
          let hora2 = dia.hora_fechamento.split(":");

          // if (!horarios[index.abertura]) return;

          let d = new Date();
          let data1 = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            hora1[0],
            hora1[1]
          );
          let data2 = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            hora2[0],
            hora2[1]
          );

          if (data1 >= data2) {
            setModal({
              text: `Horário de abertura maior que o horário de fechamento (${dia.dia})`,
              alertType: "fail",
              active: true,
            });
            liberado = false;
            return;
          }
        }
      }
      return;
    });
    if (liberado) {
      setHorario(false);
    }
  };

  const sendCadastro = () => {
    setButtonState({ ...buttonState, step1: true });
    let formData = new FormData();
    let cnpj = dados.cnpj;
    cnpj = cnpj.replace(/[^\d]+/g, "");
    files.map((file) => {
      if (file?.file) {
        formData = new FormData();
        formData.append("file", file.file);
        ParceiroCadastroImagens(formData, cnpj)
          .then((resp) => {
            return;
          })
          .catch((e) => {
            console.log(e);
            return;
          });
        return true;
      } else {
        return false;
      }
    });

    EditSpace({
      horariosCoworking: horariosCoworking,
      cnpj: cnpj,
      espacos: espacos,
      descricao: descricao,
      facilities: facilitiesItens,
      itensCobrados: itensCobrados,
      razaoSocial: dados.razaoSocial,
      tipo_espaco: tipo,
      endereco: dados.endereco,
      nome_unidade: dados.nome_unidade,
    })
      .then((resp) => {
        setButtonState({ ...buttonState, step1: false });
        navigate("/parceiro/espacos");
      })
      .catch((e) => {
        setModal({
          text: "Ocorreu um erro ao tentar atualizar os dados do seu espaço",
          alertType: "fail",
          active: true,
        });
        console.log(e);
        setButtonState({ ...buttonState, step1: false });
      });
    return;
  };

  useEffect(() => {
    GetProfileParceiro(user.user_id)
      .then((response) => {
        setDados(response.data.message);
        setEspacos(response.data.message.espacos);
        setHorariosCoworking(response.data.message.funcionamento);
        setDescricao(response.data.message.descricao);
        setFacilitiesItens(response.data.message.facilidades);
        setItensCobrados(response.data.message.cobrados);
        setFiles(response.data.message.imagens);
        setTipo(response.data.message.tipo_espaco);
        setLoad(true);
        setCapa({ ...capa, url: response.data.message.imagem });
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);

  const handleImages = (e) => {
    let newFiles = files;
    let novoCount = count;
    let thisFiles = Array.from(e);
    thisFiles.map((file) => {
      newFiles.push({
        file: file,
        url: URL.createObjectURL(file),
        key: novoCount,
      });
      novoCount += 1;
      return true;
    });
    setCount(novoCount);
    setFiles([...newFiles]);
  };

  const handleRemove = (id) => {
    let newFiles = files;
    let cnpj = dados.cnpj;
    cnpj = cnpj.replace(/[^\d]+/g, "");
    let filtered = newFiles.filter(function (el) {
      return el.uuid !== id;
    });
    let index = newFiles.findIndex((x) => x.uuid === id);
    if (!newFiles[index]?.file) {
      RemoveImageCoworking(id, cnpj)
        .then((resp) => {})
        .catch((e) => {
          console.log(e);
        });
    }
    setFiles([...filtered]);
  };

  function replaceBadInputs(val, code) {
    if (!val) {
      return;
    }

    if (code.nativeEvent.inputType === "deleteContentBackward") {
      return val;
    }

    if (code.nativeEvent.inputType === "insertFromPaste") {
      return "";
    }

    val = val.replace(/[^\dh:]/, "");
    val = val.replace(/^([3-9])/, "0$1");
    val = val.replace(/^([2-9])[4-9]/, "$1");
    val = val.replace(/^\d[:h]/, "");
    val = val.replace(/^([01][0-9])[^:h]/, "$1");
    val = val.replace(/^(2[0-3])[^:h]/, "$1");
    val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
    val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
    val = val.replace(/^(\d{2})/, "$1:");
    val = val.replace(/^(\d{2}):(\d{2})^/, "$1:$2");
    val = val.replace(/^(\d{2}:):/, "$1");
    return val;
  }

  const handleAbertura = (e, dia) => {
    let horarios = horariosCoworking;
    let index = horarios.findIndex((x) => x.dia === dia);
    horarios[index].hora_abertura = replaceBadInputs(e.target.value, e);
    horarios[index].hora_fechamento = "";
    setHorariosCoworking([...horarios]);
  };

  const [capa, setCapa] = useState();

  const handleCapa = (e) => {
    let thisFiles = Array.from(e);
    thisFiles.map((file) => {
      setCapa({
        file: file,
        url: URL.createObjectURL(file),
        key: 0,
      });
      let cnpj = dados.cnpj;
      cnpj = cnpj.replace(/[^\d]+/g, "");
      let capaForm = new FormData();
      capaForm.append("file", file);
      ParceiroCadastraCapa(capaForm, cnpj)
        .then((resp) => {})
        .catch((e) => console.log(e));
      return true;
    });
  };

  const handleRemoveCapa = () => {
    setCapa();
    let cnpj = dados.cnpj;
    cnpj = cnpj.replace(/[^\d]+/g, "");
    RemoveCapaCoworking(cnpj)
      .then((resp) => {})
      .catch((e) => console.log(e));
  };

  const handleFechar = (e, dia) => {
    let horarios = horariosCoworking;
    let index = horarios.findIndex((x) => x.dia === dia);

    let lenHora2 = e.target.value;
    let hora1 = horarios[index].hora_abertura.split(":");
    let hora2 = e.target.value.split(":");

    // if (!horarios[index.abertura]) return;

    let d = new Date();
    let data1 = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      hora1[0],
      hora1[1]
    );
    let data2 = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      hora2[0],
      hora2[1]
    );

    if (data1 >= data2 && lenHora2.length === 5) {
      setModal({
        text: "O horário de fechamento não pode ser menor que o de abertura",
        alertType: "fail",
        active: true,
      });
      horarios[index].hora_fechamento = "";
      return;
    }

    horarios[index].hora_fechamento = replaceBadInputs(e.target.value, e);
    setHorariosCoworking([...horarios]);
  };

  return (
    <section className="flex items-center justify-center w-full bg-terciariafundo px-4">
      <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-8 px-8 w-full lg:w-10/12 lg:my-12 my-4">
        <div className="flex lg:items-center lg:justify-start border-b border-secundaria4 border-opacity-20 justify-center">
          <p className="textos semibold pb-4">CADASTRAR ESCRITÓRIO</p>
        </div>
        <div className="pt-4 text-center">
          <div className="flex flex-row overflow-scroll w-full gap-4 py-12 ">
            <div className="flex flex-row gap-4 pr-8 border-r-2 border-r-detalhes2 border-opacity-40">
              {!capa && (
                <div
                  className="min-w-[150px] relative rounded-md bg-terciaria2 h-20 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    document.getElementById("add-capa").click();
                  }}
                >
                  <img src={add} alt="Adicionar imagens" />
                  <p className="textos medium text-secundaria4 absolute -top-7">
                    Capa
                  </p>
                  <input
                    type="file"
                    className="hidden"
                    id="add-capa"
                    onChange={(e) => handleCapa(e.target.files)}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              )}
              {capa ? (
                <div
                  className="min-w-[150px] w-auto min-h-20 h-20 rounded-md relative"
                  key={capa.uuid}
                >
                  <p className="textos medium text-secundaria4 absolute -top-7 right-14">
                    Capa
                  </p>
                  <img
                    src={capa.url}
                    className="bg-cover rounded-md w-full h-full"
                    alt="imagem"
                  ></img>
                  <div className="flex flex-row w-full justify-between">
                    <p
                      className="mt-2 textos semibold text-primaria3 cursor-pointer"
                      onClick={() =>
                        document.getElementById("add-capa").click()
                      }
                    >
                      Alterar
                    </p>
                    <p
                      className="mt-2 textos semibold text-primaria3 cursor-pointer"
                      onClick={() => handleRemoveCapa()}
                    >
                      Excluir
                    </p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id="add-capa"
                    onChange={(e) => handleCapa(e.target.files)}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-row gap-4 pl-4">
              <div
                className="min-w-[150px] relative rounded-md bg-terciaria2 h-20 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  document.getElementById("add-img").click();
                }}
              >
                <img src={add} alt="Adicionar imagens" />
                <p className="textos medium text-secundaria4 absolute -top-7">
                  Imagens
                </p>
                <input
                  type="file"
                  className="hidden"
                  id="add-img"
                  multiple
                  onChange={(e) => handleImages(e.target.files)}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </div>
              {files?.map((file) => {
                return (
                  <div
                    className="min-w-[150px] w-auto min-h-20 h-20 rounded-md"
                    key={file.uuid}
                  >
                    <img
                      src={file.url}
                      className="bg-cover rounded-md w-full h-full"
                      alt="imagem"
                    ></img>
                    <p
                      className="mt-2 textos semibold text-primaria3 cursor-pointer"
                      onClick={() => handleRemove(file.uuid)}
                    >
                      Excluir
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="py-6 flex flex-col gap-8 lg:gap-0 border-b border-t mt-8 border-secundaria3 border-opacity-40 lg:flex-row lg:flex-wrap">
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
              <p className="textos regular text-secundaria3">Razão Social</p>
              <InputFixed
                background="terciaria2"
                name="razaoSocial"
                value={dados.razaoSocial}
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
              <p className="textos regular text-secundaria3">Nome Fantasia</p>
              <InputEdit
                edit={inputsStatus.nome_unidade}
                setInputs={setInputsStatus}
                inputs={inputsStatus}
                background="terciaria2"
                name="nome_unidade"
                value={dados.nome_unidade}
                onChange={(e) =>
                  setDados({ ...dados, nome_unidade: e.target.value })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
              <p className="textos regular text-secundaria3">Logradouro</p>
              <InputEdit
                edit={inputsStatus.logradouro}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="logradouro"
                value={dados.endereco.logradouro}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: { ...dados.endereco, logradouro: e.target.value },
                  })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
              <p className="textos regular text-secundaria3">Município</p>
              <InputEdit
                edit={inputsStatus.municipio}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="municipio"
                value={dados.endereco.municipio}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: { ...dados.endereco, municipio: e.target.value },
                  })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start lg:pt-12 gap-2">
              <p className="textos regular text-secundaria3">Estado</p>
              <InputEdit
                edit={inputsStatus.estado}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="estado"
                value={dados.endereco.estado}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: { ...dados.endereco, estado: e.target.value },
                  })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
              <p className="textos regular text-secundaria3">Complemento</p>
              <InputEdit
                edit={inputsStatus.complemento}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="complemento"
                value={dados.endereco.complemento}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: {
                      ...dados.endereco,
                      complemento: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
              <p className="textos regular text-secundaria3">Número</p>
              <InputEdit
                edit={inputsStatus.numero}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="numero"
                tipo="number"
                value={dados.endereco.numero}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: { ...dados.endereco, numero: e.target.value },
                  })
                }
              />
            </div>
            <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
              <p className="textos regular text-secundaria3">CEP</p>
              <InputEdit
                edit={inputsStatus.cep}
                setInputs={setInputsStatus}
                inputs={dados}
                background="terciaria2"
                name="cep"
                tipo="number"
                value={dados.endereco.cep}
                onChange={(e) =>
                  setDados({
                    ...dados,
                    endereco: { ...dados.endereco, cep: e.target.value },
                  })
                }
              />
            </div>
          </div>
          <div className="py-6 flex items-center justify-center flex-row gap-4 border-b border-secundaria3 border-opacity-40">
            <div
              className="flex shadow-coworkingShadow rounded-lg w-full items-center justify-center cursor-pointer"
              onClick={() => handleHorario()}
            >
              <p className="textos regular text-secundaria3 py-4 px-4">
                Horário de funcionamento
              </p>
              <img src={addGrey} alt="Adicionar horário" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full py-4 mt-8 gap-4">
            <p className="textos regular text-secundaria3 px-4 text-center">
              Quantidade total de assentos
            </p>
            <ButtonCounter dados={espacos} setDados={setEspacos} />
          </div>
          <div className="py-6 flex items-center justify-center flex-col lg:flex-row gap-8 lg:gap-4 border-opacity-40">
            <div
              className="flex items-center justify-center shadow-coworkingShadow rounded-lg w-full cursor-pointer lg:w-6/12"
              onClick={() => setFacilities(true)}
            >
              <p className="textos regular text-secundaria3 py-4 px-4">
                Adicionar facilities
              </p>
              <img src={addGrey} alt="Adicionar horário" />
            </div>

            <div
              className="flex items-center lg:w-6/12 justify-center shadow-coworkingShadow lg:flex-row rounded-lg w-full flex-col py-4 cursor-pointer"
              onClick={() => setCobrados(true)}
            >
              <p className="textos regular text-secundaria3 lg:py-0 py-4 px-4">
                Adicionar itens cobrado a parte
              </p>
              <img src={addGrey} alt="Adicionar horário" />
            </div>
            <div className="w-full lg:w-5/12 h-[52px] mt-[5px]">
              <DropDown
                options={[
                  { value: "Cafeteria", name: "Cafeteria" },
                  { value: "Coworking", name: "Coworking" },
                  { value: "Hotel", name: "Hotel" },
                ]}
                background="terciaria1"
                message="Tipo do espaço"
                shadow="shadow-coworkingShadow"
                h={true}
                onChange={(e) => setTipo(e.target.value)}
                value={tipo}
              />
            </div>
          </div>
          <div className="flex items-center justify-center flex-col gap-4 border-b border-secundaria3 border-opacity-40"></div>
          <div className="pt-6 flex items-center justify-center flex-col gap-4">
            <div className="w-full h-64">
              <TextArea
                placer="Sobre o espaço"
                background="terciaria1"
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao}
              />
            </div>
            <div className="w-full mt-4">
              <ButtonLigth
                legend="SALVAR"
                onClick={() => sendCadastro()}
                loading={buttonState.step1}
              />
            </div>
          </div>
        </div>
        <div
          className={
            horario
              ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10"
              : "w-full h-full hidden z-[200] top-0 items-center justify-center"
          }
        >
          <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
          <div className="w-full rounded-3xl white-box h-auto z-[500] flex px-4 flex-col pt-8 pb-8 gap-3 relative items-center justify-center mt-[500px] lg:w-5/12 lg:px-10">
            <img
              src={close}
              alt="fechar"
              className="self-end mr-4 mb-4 cursor-pointer"
              onClick={() => closeHorario()}
            />
            <p className="textos semibold text-primaria4">
              Adicione o horário de funcionamento
            </p>
            <p className="textos regular text-center">
              Selecione o dia e horário de funcionamento do seu espaço
            </p>
            <div className="flex flex-col items-center justify-center py-5 gap-10">
              {load &&
                horariosCoworking.map((dia) => {
                  return (
                    <div
                      className={
                        dia.funcionamento
                          ? "flex flex-col items-center justify-center gap-6 w-full lg:flex-row"
                          : "flex flex-col items-center justify-center gap-6 opacity-40 w-full lg:flex-row"
                      }
                      key={dia.dia}
                    >
                      <div
                        className={
                          dia.funcionamento
                            ? "flex fle-row gap-3 items-center justify-between w-full lg:flex-row"
                            : "flex fle-row gap-3 items-center justify-between pointer-events-none w-full lg:flex-row"
                        }
                      >
                        <p className="textos regular w-5/12">{dia.dia}</p>
                        <div className="w-3/12 ">
                          <InputHorario
                            id="horario-entrada"
                            value={dia.hora_abertura}
                            onChange={(e) => handleAbertura(e, dia.dia)}
                            placer="De"
                            background="terciaria2"
                            name="horarioInicio"
                          />
                          {/* <DropDown
                            options={hours.map((hour) => {
                              return hour;
                            })}
                            background="terciaria1"
                            message="De"
                            onChange={(e) => handleAbertura(e, dia.dia)}
                            escolhido={dia.hora_abertura}
                          /> */}
                        </div>
                        <p className="textos regular w-1/12">ás</p>
                        <div className="w-3/12">
                          {/* <DropDown
                            options={hours.map((hour) => {
                              return hour;
                            })}
                            background="terciaria1"
                            message="Até"
                            onChange={(e) => handleFechar(e, dia.dia)}
                            escolhido={dia.hora_fechamento}
                          /> */}
                          <InputHorario
                            id="horario-entrada"
                            value={dia.hora_fechamento}
                            onChange={(e) => handleFechar(e, dia.dia)}
                            placer="Até"
                            background="terciaria2"
                            name="horarioInicio"
                          />
                        </div>
                      </div>
                      <Switch
                        checked={dia.funcionamento}
                        inputs={horariosCoworking}
                        setInputs={setHorariosCoworking}
                        dia={dia.dia}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div
          className={
            facilities
              ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10"
              : "w-full h-full hidden z-[200] top-0 items-center justify-center"
          }
        >
          <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
          <div className="w-full rounded-3xl white-box h-auto z-[300] flex px-4 flex-col mt-[220px] pt-8 pb-8 gap-3 relative items-center justify-center lg:w-5/12 lg:px-10">
            <img
              src={close}
              alt="fechar"
              className="self-end mr-4 mb-4 cursor-pointer"
              onClick={() => setFacilities(false)}
            />
            <p className="textos semibold text-primaria4 text-center">
              Adicione as facilidades oferecidas pelo seu coworking
            </p>
            <div className="flex flex-col items-center justify-center py-5 gap-10 w-full">
              {facilitiesItens.map((facilitie) => {
                return (
                  <div
                    className="flex flex-row w-full justify-between"
                    key={facilitie.uuid}
                  >
                    <p className="button medium uppercase">{facilitie.nome}</p>
                    <SwitchFacilitie
                      checked={facilitie.ativo}
                      inputs={facilitiesItens}
                      setInputs={setFacilitiesItens}
                      facilitie={facilitie.uuid}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={
            cobrados
              ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10"
              : "w-full h-full hidden z-[200] top-0 items-center justify-center"
          }
        >
          <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
          <div className="w-full rounded-3xl white-box h-auto z-[300] flex px-4 flex-col pt-8 pb-8 gap-3 relative items-center justify-center lg:w-5/12 lg:px-10">
            <img
              src={close}
              alt="fechar"
              className="self-end mr-4 mb-4 cursor-pointer"
              onClick={() => setCobrados(false)}
            />
            <p className="textos semibold text-primaria4 text-center">
              Adicione os itens que podem ser cobrados a parte pelo seu
              coworking
            </p>
            <div className="flex flex-col items-center justify-center py-5 gap-10 w-full">
              {itensCobrados.map((iten) => {
                return (
                  <div
                    className="flex flex-row w-full justify-between"
                    key={iten.uuid}
                  >
                    <p className="button medium uppercase">{iten.nome}</p>
                    <SwitchFacilitie
                      checked={iten.ativo}
                      inputs={itensCobrados}
                      setInputs={setItensCobrados}
                      facilitie={iten.uuid}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {modal.active && (
          <ModalAlert
            text={modal.text}
            alertType={modal.alertType}
            setModal={setModal}
          />
        )}
      </div>
    </section>
  );
};

export default EditarEspaco;
