import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import profile from "../../assets/profile-page.svg";
import InputEdit from "../../components/InputEdit";
import InputFixed from "../../components/InputFixed";
import AuthContext from "../../hooks/AuthContext";
import maskDoc from "../../utils/maskDoc";
import ButtonLigth from "../../components/ButtonLight";
import { useNavigate } from "react-router-dom";
import add from "../../assets/add.svg";
import GetProfileParceiro from "../../services/Parceiro/GetProfileParceiro";
import addGrey from "../../assets/addGrey.svg";
import ButtonCounter from "../../components/ButtonCounterAssentos";
import TextArea from "../../components/TextArea";
import DropDown from "../../components/DropDown";
import Switch from "../../components/SwitchHorario";
import close from "../../assets/close.svg";
import GetFacilities from "../../services/Parceiro/GetFacilities";
import SwitchFacilitie from "../../components/SwitchFacilitie";
import GetItensCobrados from "../../services/Parceiro/GetItensCobrados";
import ParceiroCadastroImagens from "../../services/Parceiro/ParceiroCadastroImagens";
import ParceiroPrimeiroAcesso from "../../services/Parceiro/ParceiroPrimeiroAcesso";
import ModalAlert from "../../components/ModalAlert";
import ParceiroCadastraCapa from "../../services/Parceiro/ParceiroCadastroCapa";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";
import InputHorario from "../../components/InputHorario";
const PerfilParceiro = () => {
  const navigate = useNavigate();
  let { user, logoutUser } = useContext(AuthContext);

  const [buttonState, setButtonState] = useState({
    step1: false,
  });

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  const [tipo, setTipo] = useState("");

  const [files, setFiles] = useState([]);
  const [inputsStatus, setInputsStatus] = useState({
    logradouro: false,
    municipio: false,
    estado: false,
    complemento: false,
    numero: false,
    cep: false,
    nome_unidade: false,
    email: false,
  });
  const [count, setCount] = useState(0);
  const [espacos, setEspacos] = useState(0);
  const [facilities, setFacilities] = useState(false);
  const [cobrados, setCobrados] = useState(false);
  const [itensCobrados, setItensCobrados] = useState([]);
  const [facilitiesItens, setFacilitiesItens] = useState([]);
  const [horario, setHorario] = useState(false);
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  function replaceBadInputs(val, code) {
    if (!val) {
      return;
    }

    if (code.nativeEvent.inputType === "deleteContentBackward") {
      return val;
    }

    if (code.nativeEvent.inputType === "insertFromPaste") {
      return "";
    }

    val = val.replace(/[^\dh:]/, "");
    val = val.replace(/^([3-9])/, "0$1");
    val = val.replace(/^([2-9])[4-9]/, "$1");
    val = val.replace(/^\d[:h]/, "");
    val = val.replace(/^([01][0-9])[^:h]/, "$1");
    val = val.replace(/^(2[0-3])[^:h]/, "$1");
    val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
    val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
    val = val.replace(/^(\d{2})/, "$1:");
    val = val.replace(/^(\d{2}):(\d{2})^/, "$1:$2");
    val = val.replace(/^(\d{2}:):/, "$1");
    return val;
  }

  const [descricao, setDescricao] = useState("");
  const [liberado, setLiberado] = useState(true);
  const [dados, setDados] = useState({
    email: "",
    razaoSocial: "",
    nome_unidade: "",
    descricao: "",
    cnpj: "",
    site: "",
    telefone: "",
    endereco: {
      logradouro: "",
      municipio: "",
      estado: "",
      complemento: "",
      numero: "",
      cep: "",
    },
    acesso_liberado: "",
    primeiro_acesso: "",
    acesso_negado: "",
    motivo: "",
    total_acessos: "",
  });
  const [load, setLoad] = useState(false);
  const [horariosCoworking, setHorariosCoworking] = useState([
    {
      dia: "segunda",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Segunda-feira",
    },
    {
      dia: "terca",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Terça-feira",
    },
    {
      dia: "quarta",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Quarta-feira",
    },
    {
      dia: "quinta",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Quinta-feira",
    },
    {
      dia: "sexta",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Sexta-feira",
    },
    {
      dia: "sabado",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Sábado",
    },
    {
      dia: "domingo",
      hora_abertura: "",
      hora_fechamento: "",
      funcionamento: false,
      nome: "Domingo",
    },
  ]);

  const handleHorario = () => {
    setHorario(!horario);
  };

  const closeHorario = () => {
    let liberado = true;
    horariosCoworking.forEach((dia) => {
      if (!dia.funcionamento && dia.hora_abertura.length !== 0) {
        let horarios = horariosCoworking;
        let index = horarios.findIndex((x) => x.nome === dia.nome);
        horarios[index].hora_abertura = "";
        setHorariosCoworking([...horarios]);
      }
      if (!dia.funcionamento && dia.hora_fechamento.length !== 0) {
        let horarios = horariosCoworking;
        let index = horarios.findIndex((x) => x.nome === dia.nome);
        horarios[index].hora_fechamento = "";
        setHorariosCoworking([...horarios]);
      }
      if (dia.funcionamento) {
        if (!dia.hora_abertura || !dia.hora_fechamento) {
          setModal({
            text: `Preencha o horário de abertura e fechamento - (${dia.nome})`,
            alertType: "fail",
            active: true,
          });
          liberado = false;
          return;
        } else if (
          dia.hora_abertura.length !== 5 ||
          dia.hora_fechamento.length !== 5
        ) {
          setModal({
            text: `Os horários precisam ter o seguinte formato HH:MM - (${dia.nome})`,
            alertType: "fail",
            active: true,
          });
          liberado = false;
          return;
        } else {
          let hora1 = dia.hora_abertura.split(":");
          let hora2 = dia.hora_fechamento.split(":");

          // if (!horarios[index.abertura]) return;

          let d = new Date();
          let data1 = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            hora1[0],
            hora1[1]
          );
          let data2 = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            hora2[0],
            hora2[1]
          );

          if (data1 >= data2) {
            setModal({
              text: `Horário de abertura maior que o horário de fechamento (${dia.dia})`,
              alertType: "fail",
              active: true,
            });
            liberado = false;
            return;
          }
        }
      }
      return;
    });
    if (liberado) {
      setHorario(false);
    }
  };

  const sendCadastro = () => {
    setButtonState({ ...buttonState, step1: true });

    if (!liberado) {
      setModal({
        text: "O horário de fechamento não pode ser menor que o de abertura",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    if (!capa) {
      setModal({
        text: "Selecione uma imagem de capa para continuar com o seu cadastro!",
        alertType: "fail",
        active: true,
      });
      setButtonState({ ...buttonState, step1: false });
      return;
    }

    let formData = new FormData();
    let cnpj = dados.cnpj;
    cnpj = cnpj.replace(/[^\d]+/g, "");
    files.map((file) => {
      formData = new FormData();
      formData.append("file", file.file);
      ParceiroCadastroImagens(formData, cnpj)
        .then((resp) => {
          return;
        })
        .catch((e) => {
          console.log(e);
          return;
        });
      return true;
    });

    ParceiroPrimeiroAcesso({
      horariosCoworking: horariosCoworking,
      cnpj: cnpj,
      espacos: espacos,
      descricao: descricao,
      facilities: facilitiesItens,
      itensCobrados: itensCobrados,
      tipo_espaco: tipo,
      endereco: dados.endereco,
      nome_unidade: dados.nome_unidade,
    })
      .then((resp) => {
        if (capa) {
          let capaForm = new FormData();
          capaForm.append("file", capa.file);
          ParceiroCadastraCapa(capaForm, cnpj)
            .then((resp) => {
              setButtonState({ ...buttonState, step1: false });
              window.location.reload();
            })
            .catch((e) => console.log(e));
        } else {
          setButtonState({ ...buttonState, step1: false });
          window.location.reload();
        }
      })
      .catch((e) => {
        setButtonState({ ...buttonState, step1: false });
        console.log(e);
      });
    return;
  };

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Perfil-Coworking");
    }, [10]);

    GetProfileParceiro(user.user_id)
      .then((response) => {
        console.log(response);
        if (
          response.data.message.primeiro_acesso === true ||
          response.data.message.acesso_liberado === false
        ) {
          let element = document.getElementById("coworking-header");
          element.style.display = "none";
        }
        setDados(response.data.message);
        setEspacos(response.data.message.espacos);
        GetFacilities()
          .then((resp) => {
            setFacilitiesItens(resp.data.facilities);
            GetItensCobrados()
              .then((resp) => {
                setItensCobrados(resp.data.chargeds);
                setLoad(true);
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);

  const handleImages = (e) => {
    let newFiles = files;
    let novoCount = count;
    let thisFiles = Array.from(e);
    thisFiles.map((file) => {
      newFiles.push({
        file: file,
        url: URL.createObjectURL(file),
        key: novoCount,
      });
      novoCount += 1;
      return true;
    });
    setCount(novoCount);
    setFiles([...newFiles]);
  };

  const [capa, setCapa] = useState();

  const handleCapa = (e) => {
    let thisFiles = Array.from(e);
    thisFiles.map((file) => {
      setCapa({
        file: file,
        url: URL.createObjectURL(file),
        key: 0,
      });
      return true;
    });
  };

  const handleRemove = (id) => {
    let newFiles = files;
    let filtered = newFiles.filter(function (el) {
      return el.key !== id;
    });
    setFiles([...filtered]);
  };

  const handleRemoveCapa = () => {
    setCapa();
  };

  const handleAbertura = (e, dia) => {
    let horarios = horariosCoworking;
    let index = horarios.findIndex((x) => x.dia === dia);
    horarios[index].hora_abertura = replaceBadInputs(e.target.value, e);
    horarios[index].hora_fechamento = "";
    setHorariosCoworking([...horarios]);
    setLiberado(true);
  };

  const handleFechar = (e, dia) => {
    let horarios = horariosCoworking;
    let index = horarios.findIndex((x) => x.dia === dia);

    let lenHora2 = e.target.value;
    let hora1 = horarios[index].hora_abertura.split(":");
    let hora2 = e.target.value.split(":");

    // if (!horarios[index.abertura]) return;

    let d = new Date();
    let data1 = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      hora1[0],
      hora1[1]
    );
    let data2 = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      hora2[0],
      hora2[1]
    );

    if (data1 >= data2 && lenHora2.length === 5) {
      setModal({
        text: "O horário de fechamento não pode ser menor que o de abertura",
        alertType: "fail",
        active: true,
      });
      setLiberado(false);
      horarios[index].hora_fechamento = "";
      return;
    }
    horarios[index].hora_fechamento = replaceBadInputs(e.target.value, e);
    setHorariosCoworking([...horarios]);
    setLiberado(true);
  };

  return (
    <section className="p-6 lg:p-4 flex items-start justify-center bg-terciariafundo min-h-screen">
      {dados?.acesso_liberado && load ? (
        <>
          {dados?.primeiro_acesso ? (
            <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-8 px-8 w-full lg:w-10/12 lg:my-12 my-4">
              <div className="flex lg:items-center lg:justify-start border-b border-secundaria4 border-opacity-20 justify-center">
                <p className="textos semibold pb-4">CADASTRAR ESCRITÓRIO</p>
              </div>
              <div className="pt-8 text-center">
                <div className="flex flex-row overflow-scroll w-full gap-4 py-12 border-b border-opacity-40 border-secundaria3 ">
                  <div className="flex flex-row gap-4 pr-8 border-r-2 border-r-detalhes2 border-opacity-40">
                    {!capa && (
                      <div
                        className="min-w-[150px] relative rounded-md bg-terciaria2 h-20 flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          document.getElementById("add-capa").click();
                        }}
                      >
                        <img src={add} alt="Adicionar imagens" />
                        <p className="textos medium text-secundaria4 absolute -top-7">
                          Capa
                        </p>
                        <input
                          type="file"
                          className="hidden"
                          id="add-capa"
                          onChange={(e) => handleCapa(e.target.files)}
                          accept="image/png, image/jpeg, image/jpg"
                        />
                      </div>
                    )}
                    {capa ? (
                      <div
                        className="min-w-[150px] w-auto min-h-20 h-20 rounded-md relative"
                        key={capa.key}
                      >
                        <p className="textos medium text-secundaria4 absolute -top-7 right-14">
                          Capa
                        </p>
                        <img
                          src={capa.url}
                          className="bg-cover rounded-md w-full h-full"
                          alt="imagem"
                        ></img>
                        <input
                          type="file"
                          className="hidden"
                          id="add-capa"
                          onChange={(e) => handleCapa(e.target.files)}
                          accept="image/png, image/jpeg, image/jpg"
                        />
                        <div className="flex flex-row w-full justify-between">
                          <p
                            className="mt-2 textos semibold text-primaria3 cursor-pointer"
                            onClick={() =>
                              document.getElementById("add-capa").click()
                            }
                          >
                            Alterar
                          </p>
                          <p
                            className="mt-2 textos semibold text-primaria3 cursor-pointer"
                            onClick={() => handleRemoveCapa()}
                          >
                            Excluir
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-row gap-4 pl-4">
                    <div
                      className="min-w-[150px] relative rounded-md bg-terciaria2 h-20 flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        document.getElementById("add-img").click();
                      }}
                    >
                      <img src={add} alt="Adicionar imagens" />
                      <p className="textos medium text-secundaria4 absolute -top-7">
                        Imagens
                      </p>
                      <input
                        type="file"
                        className="hidden"
                        id="add-img"
                        multiple
                        onChange={(e) => handleImages(e.target.files)}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                    </div>
                    {files?.map((file) => {
                      return (
                        <div
                          className="min-w-[150px] w-auto min-h-20 h-20 rounded-md"
                          key={file.key}
                        >
                          <img
                            src={file.url}
                            className="bg-cover rounded-md w-full h-full"
                            alt="imagem"
                          ></img>
                          <p
                            className="mt-2 textos semibold text-primaria3 cursor-pointer"
                            onClick={() => handleRemove(file.key)}
                          >
                            Excluir
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="py-6 flex flex-col gap-8 lg:gap-0 border-b border-t mt-8 border-secundaria3 border-opacity-40 lg:flex-row lg:flex-wrap">
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
                    <p className="textos regular text-secundaria3">
                      Razão Social
                    </p>
                    <InputFixed
                      background="terciaria2"
                      name="razaoSocial"
                      value={dados.razaoSocial}
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
                    <p className="textos regular text-secundaria3">
                      Nome Fantasia
                    </p>
                    <InputEdit
                      edit={inputsStatus.nome_unidade}
                      setInputs={setInputsStatus}
                      inputs={inputsStatus}
                      background="terciaria2"
                      name="nome_unidade"
                      value={dados.nome_unidade}
                      onChange={(e) =>
                        setDados({ ...dados, nome_unidade: e.target.value })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
                    <p className="textos regular text-secundaria3">
                      Logradouro
                    </p>
                    <InputEdit
                      edit={inputsStatus.logradouro}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="logradouro"
                      value={dados.endereco.logradouro}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: {
                            ...dados.endereco,
                            logradouro: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2">
                    <p className="textos regular text-secundaria3">Município</p>
                    <InputEdit
                      edit={inputsStatus.municipio}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="municipio"
                      value={dados.endereco.municipio}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: {
                            ...dados.endereco,
                            municipio: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start lg:pt-12 gap-2">
                    <p className="textos regular text-secundaria3">Estado</p>
                    <InputEdit
                      edit={inputsStatus.estado}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="estado"
                      value={dados.endereco.estado}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: {
                            ...dados.endereco,
                            estado: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
                    <p className="textos regular text-secundaria3">
                      Complemento
                    </p>
                    <InputEdit
                      edit={inputsStatus.complemento}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="complemento"
                      value={dados.endereco.complemento}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: {
                            ...dados.endereco,
                            complemento: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
                    <p className="textos regular text-secundaria3">Número</p>
                    <InputEdit
                      edit={inputsStatus.numero}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="numero"
                      tipo="number"
                      value={dados.endereco.numero}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: {
                            ...dados.endereco,
                            numero: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="lg:w-3/12 w-full px-2 flex flex-col items-start gap-2 lg:pt-12">
                    <p className="textos regular text-secundaria3">CEP</p>
                    <InputEdit
                      edit={inputsStatus.cep}
                      setInputs={setInputsStatus}
                      inputs={dados}
                      background="terciaria2"
                      name="cep"
                      tipo="number"
                      value={dados.endereco.cep}
                      onChange={(e) =>
                        setDados({
                          ...dados,
                          endereco: { ...dados.endereco, cep: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>
                <div className="py-6 flex items-center justify-center flex-col gap-4 border-b border-secundaria3 border-opacity-40">
                  <div
                    className="flex shadow-coworkingShadow rounded-lg w-full cursor-pointer items-center justify-center"
                    onClick={() => handleHorario()}
                  >
                    <p className="textos regular text-secundaria3 py-4 px-4 self-center">
                      Horário de funcionamento
                    </p>
                    <img src={addGrey} alt="Adicionar horário" />
                  </div>
                  <div className="flex flex-col items-center justify-center shadow-coworkingShadow rounded-lg w-full lg:flex-row py-4 mt-4 gap-4">
                    <p className="textos regular text-secundaria3 px-4 text-center">
                      Quantidade total de assentos
                    </p>
                    <ButtonCounter dados={espacos} setDados={setEspacos} />
                  </div>
                </div>
                <div className="py-6 flex items-center justify-center flex-col xl:flex-row gap-4 border-b border-secundaria3 border-opacity-40">
                  <div
                    className="flex items-center justify-center shadow-coworkingShadow rounded-lg w-full cursor-pointer"
                    onClick={() => setFacilities(true)}
                  >
                    <p className="textos regular text-secundaria3 py-4 px-4">
                      Adicionar facilities
                    </p>
                    <img src={addGrey} alt="Adicionar horário" />
                  </div>
                  <div
                    className="flex items-center justify-center lg:flex-row shadow-coworkingShadow rounded-lg w-full flex-col lg:py-0 py-4 cursor-pointer"
                    onClick={() => setCobrados(true)}
                  >
                    <p className="textos regular text-secundaria3 py-4 px-4">
                      Adicionar itens cobrado a parte
                    </p>
                    <img src={addGrey} alt="Adicionar horário" />
                  </div>
                  <div className="w-full h-[52px] mt-[5px]">
                    <DropDown
                      options={[
                        { value: "Cafeteria", name: "Cafeteria" },
                        { value: "Coworking", name: "Coworking" },
                        { value: "Hotel", name: "Hotel" },
                      ]}
                      background="terciaria1"
                      message="Tipo do espaço"
                      shadow="shadow-coworkingShadow"
                      h={true}
                      onChange={(e) => setTipo(e.target.value)}
                      value={tipo}
                    />
                  </div>
                </div>
                <div className="py-6 flex items-center justify-center flex-col gap-4 border-b border-secundaria3 border-opacity-40"></div>
                <div className="pt-6 flex items-center justify-center flex-col gap-4">
                  <div className="w-full h-64">
                    <TextArea
                      placer="Sobre o espaço"
                      background="terciaria1"
                      onChange={(e) => setDescricao(e.target.value)}
                      value={descricao}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <ButtonLigth
                      legend="CADASTRAR"
                      onClick={() => sendCadastro()}
                      loading={buttonState.step1}
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  horario
                    ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10 lg:mt-0 mt-12"
                    : "w-full h-full hidden z-[200] top-0 items-center justify-center"
                }
              >
                <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
                <div className="w-full rounded-3xl white-box h-auto z-[500] flex px-4 flex-col pt-8 pb-8 gap-3 relative items-center justify-center mt-52 lg:w-5/12 lg:px-10">
                  <img
                    src={close}
                    alt="fechar"
                    className="self-end mr-4 mb-4 cursor-pointer"
                    onClick={() => closeHorario()}
                  />
                  <p className="textos semibold text-primaria4">
                    Adicione o horário de funcionamento
                  </p>
                  <p className="textos regular text-center">
                    Selecione o dia e horário de funcionamento do seu espaço
                  </p>
                  <div className="flex flex-col items-center justify-center py-5 gap-10">
                    {horariosCoworking.map((dia) => {
                      return (
                        <div
                          className={
                            dia.funcionamento
                              ? "flex flex-col items-center justify-center gap-6 w-full lg:flex-row"
                              : "flex flex-col items-center justify-center gap-6 opacity-40 w-full lg:flex-row"
                          }
                          key={dia.dia}
                        >
                          <div
                            className={
                              dia.funcionamento
                                ? "flex fle-row gap-3 items-center justify-between w-full lg:flex-row"
                                : "flex fle-row gap-3 items-center justify-between pointer-events-none w-full lg:flex-row"
                            }
                          >
                            <p className="textos regular w-5/12">{dia.nome}</p>

                            <div className="w-3/12 ">
                              <InputHorario
                                id="horario-entrada"
                                value={dia.hora_abertura}
                                onChange={(e) => handleAbertura(e, dia.dia)}
                                placer="De"
                                background="terciaria2"
                                name="horarioInicio"
                              />
                              {/* <DropDown
                                options={hours.map((hour) => {
                                  return hour;
                                })}
                                background="terciaria1"
                                message="De"
                                onChange={(e) => handleAbertura(e, dia.dia)}
                                value={dia.hora_abertura}
                              /> */}
                            </div>
                            <p className="textos regular w-1/12">ás</p>
                            <div className="w-3/12">
                              {/* <DropDown
                                value={dia.hora_fechamento}
                                options={hours.map((hour) => {
                                  let index = horariosCoworking.findIndex(
                                    (x) => x.dia === dia.dia
                                  );

                                  let abertura =
                                    horariosCoworking[index].hora_abertura;
                                  if (
                                    parseInt(hour.value.substring(0, 2)) >
                                    parseInt(abertura.substring(0, 2))
                                  ) {
                                    return hour;
                                  } else {
                                    return false;
                                  }
                                })}
                                background="terciaria1"
                                message="Até"
                                onChange={(e) => handleFechar(e, dia.dia)}
                              /> */}
                              <InputHorario
                                id="horario-entrada"
                                value={dia.hora_fechamento}
                                onChange={(e) => handleFechar(e, dia.dia)}
                                placer="Até"
                                background="terciaria2"
                                name="horarioInicio"
                              />
                            </div>
                          </div>
                          <Switch
                            checked={dia.funcionamento}
                            inputs={horariosCoworking}
                            setInputs={setHorariosCoworking}
                            dia={dia.dia}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className={
                  facilities
                    ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10"
                    : "w-full h-full hidden z-[200] top-0 items-center justify-center"
                }
              >
                <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
                <div className="w-full rounded-3xl white-box h-auto z-[300] flex px-4 flex-col mt-[220px] pt-8 pb-8 gap-3 relative items-center justify-center lg:w-5/12 lg:px-10">
                  <img
                    src={close}
                    alt="fechar"
                    className="self-end mr-4 mb-4 cursor-pointer"
                    onClick={() => setFacilities(false)}
                  />
                  <p className="textos semibold text-primaria4 text-center">
                    Adicione as facilidades oferecidas pelo seu coworking
                  </p>
                  <div className="flex flex-col items-center justify-center py-5 gap-10 w-full">
                    {facilitiesItens.map((facilitie) => {
                      return (
                        <div
                          className="flex flex-row w-full justify-between"
                          key={facilitie.uuid}
                        >
                          <p className="button medium uppercase">
                            {facilitie.nome}
                          </p>
                          <SwitchFacilitie
                            checked={facilitie.ativo}
                            inputs={facilitiesItens}
                            setInputs={setFacilitiesItens}
                            facilitie={facilitie.uuid}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className={
                  cobrados
                    ? "w-full h-full flex fixed z-[400] top-0 items-center justify-center left-0 px-4 overflow-scroll py-10"
                    : "w-full h-full hidden z-[200] top-0 items-center justify-center"
                }
              >
                <div className="w-full h-full fixed bg-primaria3 opacity-90 flex items-center justify-center" />
                <div className="w-full rounded-3xl white-box h-auto z-[300] flex px-4 flex-col pt-8 pb-8 gap-3 relative items-center justify-center lg:w-5/12 lg:px-10">
                  <img
                    src={close}
                    alt="fechar"
                    className="self-end mr-4 mb-4 cursor-pointer"
                    onClick={() => setCobrados(false)}
                  />
                  <p className="textos semibold text-primaria4 text-center">
                    Adicione os itens que podem ser cobrados a parte pelo seu
                    coworking
                  </p>
                  <div className="flex flex-col items-center justify-center py-5 gap-10 w-full">
                    {itensCobrados.map((iten) => {
                      return (
                        <div
                          className="flex flex-row w-full justify-between"
                          key={iten.uuid}
                        >
                          <p className="button medium uppercase">{iten.nome}</p>
                          <SwitchFacilitie
                            checked={iten.ativo}
                            inputs={itensCobrados}
                            setInputs={setItensCobrados}
                            facilitie={iten.uuid}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {modal.active && (
                <ModalAlert
                  text={modal.text}
                  alertType={modal.alertType}
                  setModal={setModal}
                />
              )}
            </div>
          ) : (
            <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-4 px-8 w-full lg:w-10/12 lg:my-12 my-4">
              <div className="flex w-full items-center justify-between text-center gap-2 flex-wrap border-b border-b-secundaria3 pb-2 border-opacity-25">
                <img src={profile} alt="" className="hidden" />
                <p className="textos semibold text-secundaria5">
                  DADOS DA EMPRESA
                </p>
                <p
                  className="textos semibold text-link cursor-pointer"
                  onClick={() => navigate("/parceiro/alterar-senha")}
                >
                  Alterar Senha
                </p>
              </div>
              <div className="w-full flex items-center justify-center py-4 flex-col lg:flex-row">
                <div className="flex items-center justify-center w-full flex-col lg:w-3/12">
                  <img
                    src={profile}
                    alt="Imagem de perfil do usuário"
                    className="w-16 h-auto"
                  />
                  <p className="button regular mt-3 cursor-pointer">
                    Editar foto
                  </p>
                </div>
                <div className="flex items-center justify-center flex-col w-full py-6 lg:flex-wrap lg:flex-row gap-4 lg:px-6">
                  <div className="flex flex-grow flex-col gap-2 w-full lg:w-5/12">
                    <p className="textos regular text-secundaria3">
                      Razão Social
                    </p>
                    <InputFixed
                      value={dados.razaoSocial}
                      background="terciaria2"
                    />
                  </div>
                  <div className="flex flex-grow flex-col gap-2 w-full lg:w-5/12">
                    <p className="textos regular text-secundaria3">Email</p>
                    <InputEdit
                      edit={inputsStatus.email}
                      setInputs={setInputsStatus}
                      inputs={inputsStatus}
                      background="terciaria2"
                      name="email"
                      value={dados.email}
                      onChange={(e) =>
                        setDados({ ...dados, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-grow flex-col gap-2 w-full lg:w-5/12">
                    <p className="textos regular text-secundaria3">CNPJ</p>
                    <InputFixed
                      value={maskDoc(dados.cnpj, "cnpj")}
                      background="terciaria2"
                    />
                  </div>
                  <div className="flex flex-grow flex-col gap-2 w-full lg:w-5/12">
                    <p className="textos regular text-secundaria3">
                      Total de visitas
                    </p>
                    <InputFixed
                      value={`${dados.total_acessos}`}
                      background="terciaria2"
                    />
                  </div>
                </div>
                <div className="w-full px-4 py-4 lg:mt-6 lg:w-4/12 flex flex-col gap-3">
                  <h4 className="bold text-secundaria4 self-center text-center">
                    Central de ajuda
                  </h4>
                  <p className="textos regular text-center self-center text-secundaria3">
                    admin@onworkingpass.com
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="bg-terciariafundo rounded-xl shadow-shadowMin py-4 lg:h-80 px-8 w-full lg:w-10/12 lg:my-12 my-4 flex items-center justify-start flex-col">
          {load && (
            <>
              <p className="textos semibold text-secundaria5 py-5 border-b-2 w-full text-center border-b-secundaria3 border-opacity-20">
                ANÁLISE DE CADASTRO
              </p>
              <p className="body mt-5 text-justify">
                Estamos analisando suas informações enviadas no pré-cadastro, em
                breve você será notificado via e-mail com a finalização dessa
                etapa.
              </p>
              <p className="body mt-5 text-justify">
                Nosso tempo médio é de 5 dias úteis, podendo ocorrer antes.
              </p>
              <p className="semi mt-5 text-justify self-start sm:self-center">
                Agradecemos a parceria.
              </p>
              <div className="w-40 mt-10">
                <ButtonLigth legend="SAIR" onClick={logoutUser} />
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default PerfilParceiro;
