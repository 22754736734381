import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import validateEmail from "../../utils/ValidateEmail";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import checkValidate from "../../assets/check-validate.svg";
import VerifyEmailColab from "../../services/Register/VerifyEmailColab";
import VerifyTokenColab from "../../services/Register/VerifyTokenColab";
const ColabEmail = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    email: "",
    token: "",
  });

  const [step, setStep] = useState("validar");

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    let email = validateEmail(e.target.email.value);

    if (inputs.email === "") {
      setModal({
        text: "Preencha o e-mail!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!email) {
      setModal({
        text: "Email inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    VerifyEmailColab(inputs.email.toLowerCase())
      .then((resp) => {
        setStep("validar");
      })
      .catch((e) => {
        console.log(e);
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });

    return;
  };

  const validarToken = (e) => {
    const colab = localStorage.getItem("colab-information-register")
      ? JSON.parse(localStorage.getItem("colab-information-register"))
      : null;

    e.preventDefault();
    VerifyTokenColab(colab.email, inputs.token)
      .then((resp) => {
        localStorage.setItem("empresa-email-register", JSON.stringify(inputs));
        setStep("validado");
      })
      .catch((e) => {
        setModal({
          text: e.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  const finishValidateEmail = () => {
    if (complete.address) {
      funcChangeStep("password", "", "confirmEmail");
      setStep("validar");
      setInputs({ ...inputs, token: "" });
      return;
    }
    funcChangeStep("address", "", "confirmEmail");
    setStep("validar");
    setInputs({ ...inputs, token: "" });
    return;
  };

  const holdBack = () => {
    setStep("enviar");
    funcChangeStep("confirmEmail", "", "");
    setInputs({ ...inputs, email: "", token: "" });
    return;
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "enviar" && (
        <>
          <p className="body medium text-primaria4">Digite seu e-mail</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6">
            Enviaremos um e-mail para confirmá-lo
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex flex-col mt-4 pt-4"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <Input
              background="terciaria2"
              placer="E-mail"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
              <ButtonDark legend="ENVIAR E-MAIL" />
            </div>
          </form>
        </>
      )}
      {step === "validar" && (
        <>
          <p className="body medium text-primaria4">Digite o código recebido</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2">
            Enviamos um código para o seu e-mail. Se não encontrá-lo veja a
            caixa de spam.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex flex-col mt-4 pt-4"
            onSubmit={(e) => validarToken(e)}
          >
            <Input
              background="terciaria2"
              placer="Token"
              name="token"
              value={inputs.token}
              onChange={(e) => setInputs({ ...inputs, token: e.target.value })}
            />
            <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
              <ButtonDark legend="CONFIMAR CÓDIGO" />
            </div>
          </form>
        </>
      )}
      {step === "validado" && (
        <div className="flex items-center justify-center flex-col gap-2">
          <img src={checkValidate} alt="E-mail validado com sucesso" />
          <p className="body medium text-primaria4">E-mail validado!</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2">
            Este e-mail será utilizado para acessar sua conta e caso você
            precise recuperar o acesso.
          </p>
          <div className="w-10/12 flex items-center justify-center h-11">
            <ButtonDark
              legend="CONTINUAR CADASTRO"
              func={() => finishValidateEmail()}
              id="finaliza-validacao-email"
            />
          </div>
        </div>
      )}
      {!complete.confirmEmail && step !== "validado" && step !== "validar" && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => holdBack()}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ColabEmail;
