import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import validateEmail from "../../utils/ValidateEmail";
import validateCPF from "../../utils/validateCPF";
import validateDate from "../../utils/validateDate";
import { useState } from "react";
import maskData from "../../utils/maskDate";
import maskDoc from "../../utils/maskDoc";
import ModalAlert from "../ModalAlert";
import VerifyEmailColab from "../../services/Register/VerifyEmailColab";

const ColabData = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    name: "",
    cpf: "",
    email: "",
    birthday: "",
    telefone: "",
  });

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    let email = validateEmail(e.target.email.value);
    let cpf = validateCPF(e.target.cpf.value);
    let birthday = validateDate(e.target.birthday.value);

    if (
      inputs.email === "" ||
      inputs.cpf === "" ||
      inputs.birthday === "" ||
      inputs.name === "" ||
      inputs.telefone === ""
    ) {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!email) {
      setModal({
        text: "Email inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!cpf) {
      setModal({
        text: "CPF inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (inputs.telefone.length < 10 || inputs.telefone.length > 11) {
      setModal({
        text: "Telefone inválido, tente novamente!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!birthday) {
      setModal({
        text: "Data de nascimento inválida!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      let cpf = inputs.cpf;
      cpf = cpf.replace(/[^\d]+/g, "");
      VerifyEmailColab(inputs.email, cpf)
        .then((resp) => {
          localStorage.setItem(
            "colab-information-register",
            JSON.stringify(inputs)
          );
          if (complete.data && complete.confirmEmail && complete.address)
            funcChangeStep("confirmEmail", "confirmEmail", "data");
          else if (complete.data && complete.confirmEmail)
            funcChangeStep("confirmEmail", "confirmEmail", "data");
          else funcChangeStep("confirmEmail", "confirmEmail", "data");
          return;
        })
        .catch((e) => {
          setModal({
            text: e.response.data.message,
            alertType: "fail",
            active: true,
          });
        });
    }
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      <p className="body medium text-primaria4">Preencha seus dados</p>
      <p className="button regular text-secundaria4 lg:mb-4">
        Preencha seu nome completo e seu CPF
      </p>
      <form
        className="w-full px-1 items-center justify-center gap-4 flex flex-col mt-4"
        onSubmit={(e) => funcConfirmBtn(e)}
      >
        <Input
          background="terciaria2"
          placer="Nome Completo"
          name="name"
          value={inputs.name}
          onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="Telefone"
          name="telefone"
          value={inputs.telefone}
          onChange={(e) => setInputs({ ...inputs, telefone: e.target.value })}
          tipo={"number"}
        />
        <Input
          background="terciaria2"
          placer="CPF"
          name="cpf"
          value={inputs.cpf}
          onChange={(e) =>
            setInputs({ ...inputs, cpf: maskDoc(e.target.value, "cpf") })
          }
          maxLength={14}
        />
        <Input
          background="terciaria2"
          placer="E-mail"
          name="email"
          value={inputs.email}
          onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
        />
        <Input
          background="terciaria2"
          placer="Data de nascimento"
          name="birthday"
          value={inputs.birthday}
          onChange={(e) =>
            setInputs({ ...inputs, birthday: maskData(e.target.value) })
          }
          maxLength={10}
        />
        <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
          <ButtonDark legend="CONFIRMAR" />
        </div>
      </form>
      {!complete.data && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("data", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ColabData;
