import React from "react";

const SwitchAssentos = (props) => {
  let { checked, setChecked } = props;

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onClick={() => handleChange()}
          readOnly
        />
        <span className="slider"></span>
      </label>
    </>
  );
};

export default SwitchAssentos;
