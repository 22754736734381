import React from "react";
import { useContext } from "react";
import AuthContext from "../hooks/AuthContext";
import { useState } from "react";
import LoggedHeaderContext from "../hooks/LoogedHeaderContext";
import headerLogo from "../assets/headerLogo.svg";
import menuLogo from "../assets/menuLogo.svg";
import closePurple from "../assets/closePurple.svg";
import logoOpen from "../assets/logoOpen.svg";
import profileIcon from "../assets/profile-icon-notlogged.svg";
import menuPurple from "../assets/menuPurple.svg";
import menuMobile from "../assets/vector-menu-mobile.svg";
import { useNavigate } from "react-router-dom";
import logoutImg from "../assets/logout.svg";

const Header = () => {
  let { user, logoutUser } = useContext(AuthContext);
  let { selectedMenuLogged, changeMenuLogged } =
    useContext(LoggedHeaderContext);
  let [control, setControl] = useState(false);
  const navigate = useNavigate();

  const menuC = (redirect) => {
    changeMenuLogged(redirect);
    setControl(!control);
  };

  const logout = () => {
    changeMenuLogged("Home");
    logoutUser();
    setControl(false);
  };

  // const listMenuLogin = () => {
  //   if (!user) changeMenuLogged("Login");
  //   else {
  //     setLoggedMenuControl(!loggedMenuControl);
  //   }
  // };

  return (
    <>
      {/* Mobile -  Not logged - LP */}
      {(selectedMenuLogged.actual === "Para empresas" ||
        selectedMenuLogged.actual === "Para parceiros" ||
        selectedMenuLogged.actual === "Home") && (
        <>
          <ul className="flex xl:hidden flex-row justify-between items-center h-auto absolute top-0 w-full z-[1000] px-4 mt-4">
            <li className="w-3/12 flex items-center justify-center pb-6">
              <img
                src={headerLogo}
                alt="Logo Onworking Minimalist"
                className="w-auto h-auto self-center"
              />
            </li>
            <li
              className="flex items-center justify-center pb-7"
              onClick={() => setControl(!control)}
            >
              <img
                src={menuLogo}
                alt="Abrir menu"
                className="w-9 h-auto self-end"
              />
            </li>
          </ul>
          {!user && control && (
            <section className="fixed flex items-start flex-col justify-start xl:hidden top-0 min-h-screen bg-terciaria2 w-full z-[2000]">
              <ul className="flex flex-row justify-between h-auto sticky top-0 w-full bg-terciaria2 px-4 py-4 z-[1000]">
                <img
                  src={logoOpen}
                  alt="Logo Onworking Minimalist"
                  className="w-20 h-auto self-end"
                />
                <img
                  onClick={() => setControl(!control)}
                  src={menuPurple}
                  alt="Abrir menu"
                  className="w-9 h-auto self-end"
                />
              </ul>
              <li className="flex flex-col items-center justify-center mt-20 w-full gap-12">
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Login")}
                >
                  LOGIN / CADASTRE-SE
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Home")}
                >
                  HOME
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Para empresas")}
                >
                  PARA EMPRESAS
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Para parceiros")}
                >
                  PARA PARCEIROS
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Contato")}
                >
                  CONTATO
                </p>
                {user?.type === "Colaborador" && (
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Espaco")}
                  >
                    BUSCAR ESPAÇOS
                  </p>
                )}
              </li>
            </section>
          )}
          {user && control && (
            <section className="fixed flex items-start flex-col justify-start xl:hidden top-0 min-h-screen bg-terciaria2 w-full z-[2000]">
              <ul className="flex flex-row justify-between h-auto sticky top-0 w-full bg-terciaria2 px-4 py-4 z-[1000]">
                <img
                  src={logoOpen}
                  alt="Logo Onworking Minimalist"
                  className="w-20 h-auto self-end"
                />
                <img
                  onClick={() => setControl(!control)}
                  src={menuPurple}
                  alt="Abrir menu"
                  className="w-9 h-auto self-end"
                />
              </ul>
              <li className="flex flex-col items-center justify-center mt-20 w-full gap-12">
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Perfil")}
                >
                  PERFIL
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Home")}
                >
                  HOME
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Para empresas")}
                >
                  PARA EMPRESAS
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Para parceiros")}
                >
                  PARA PARCEIROS
                </p>
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => menuC("Contato")}
                >
                  CONTATO
                </p>
                {user?.type === "Colaborador" && (
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Espaco")}
                  >
                    BUSCAR ESPAÇOS
                  </p>
                )}
                <p
                  className="argentum-extralight-25 text-primaria5 cursor-pointer"
                  onClick={() => logout()}
                >
                  SAIR
                </p>
              </li>
            </section>
          )}
        </>
      )}
      {/* Mobile - Not Logged - Menu Aberto */}

      {/* Mobile - Header Logado */}
      {selectedMenuLogged.actual !== "Para empresas" &&
        selectedMenuLogged.actual !== "Para parceiros" &&
        selectedMenuLogged.actual !== "Home" && (
          <>
            <ul className="flex flex-row justify-between h-auto xl:hidden sticky top-0 w-full bg-terciaria2 px-4 py-4 z-[1000]">
              <img
                src={logoOpen}
                alt="Logo Onworking Minimalist"
                className="w-20 h-auto self-end"
              />
              <img
                onClick={() => setControl(!control)}
                src={menuPurple}
                alt="Abrir menu"
                className="w-9 h-auto self-end"
              />
            </ul>
            {!user && control && (
              <section className="fixed flex items-start flex-col xl:hidden justify-start top-0 min-h-screen bg-terciaria2 w-full z-[2000]">
                <ul className="flex flex-row justify-between h-auto sticky top-0 w-full bg-terciaria2 px-4 py-3 z-[1000]">
                  <img
                    src={logoOpen}
                    alt="Logo Onworking Minimalist"
                    className="w-20 h-auto self-end"
                  />
                  <img
                    onClick={() => setControl(!control)}
                    src={menuMobile}
                    alt="Abrir menu"
                    className="w-9 h-auto self-end"
                  />
                </ul>
                <li className="flex flex-col items-center justify-center mt-20 w-full gap-12">
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Login")}
                  >
                    LOGIN / CADASTRE-SE
                  </p>
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Para empresas")}
                  >
                    HOME
                  </p>
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Para empresas")}
                  >
                    PARA EMPRESAS
                  </p>
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Para parceiros")}
                  >
                    PARA PARCEIROS
                  </p>
                  <p
                    className="argentum-extralight-25 text-primaria5 cursor-pointer"
                    onClick={() => menuC("Contato")}
                  >
                    CONTATO
                  </p>
                  {user?.type === "Colaborador" && (
                    <p className="argentum-extralight-25 text-primaria5 cursor-pointer">
                      BUSCAR ESPAÇOS
                    </p>
                  )}
                </li>
              </section>
            )}
            {/* Mobile - Logged - Menu Aberto */}
            {user && control && (
              <section className="fixed flex items-start flex-col justify-start xl:hidden top-0 min-h-screen bg-terciaria2 w-full z-[2000]">
                <ul className="flex flex-row justify-between h-auto sticky top-0 w-full bg-terciaria2 px-4 py-4 z-[1000]">
                  <img
                    src={logoOpen}
                    alt="Logo Onworking Minimalist"
                    className="w-20 h-auto self-end"
                  />
                  <img
                    onClick={() => setControl(!control)}
                    src={menuPurple}
                    alt="Abrir menu"
                    className="w-9 h-auto self-end"
                  />
                </ul>
                {user?.type === "Colaborador" && (
                  <li className="flex flex-col items-center justify-center mt-12 w-full gap-12">
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Home")}
                    >
                      HOME
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Perfil")}
                    >
                      PERFIL
                    </p>
                    {user?.type === "Colaborador" && (
                      <p
                        className="argentum-extralight-25 text-primaria5 cursor-pointer"
                        onClick={() => menuC("Espaco")}
                      >
                        BUSCAR ESPAÇOS
                      </p>
                    )}
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Reservas")}
                    >
                      RESERVAS
                    </p>
                    {/* <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Favoritos")}
                    >
                      FAVORITOS
                    </p> */}
                    {/* <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Histórico")}
                    >
                      HÍSTÓRICO
                    </p> */}
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => logout()}
                    >
                      SAIR
                    </p>
                  </li>
                )}
                {user?.type === "Coworking" && (
                  <li className="flex flex-col items-center justify-center mt-12 w-full gap-12">
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Home")}
                    >
                      HOME
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Perfil-Coworking")}
                    >
                      PERFIL
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Token")}
                    >
                      TOKEN
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Solicitações")}
                    >
                      SOLICITAÇÕES
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Reservas")}
                    >
                      RESERVAS
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Relatórios")}
                    >
                      RELATÓRIOS
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Espaços")}
                    >
                      ESPAÇO
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => logout()}
                    >
                      SAIR
                    </p>
                  </li>
                )}
                {user?.type === "Empresa" && (
                  <li className="flex flex-col items-center justify-center mt-12 w-full gap-12">
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Home")}
                    >
                      HOME
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Perfil-Empresa")}
                    >
                      PERFIL
                    </p>
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Colaboradores")}
                    >
                      COLABORADORES
                    </p>
                    {/* <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => menuC("Relatório de visitas")}
                    >
                      RELATÓRIO DE VISITAS
                    </p> */}
                    <p
                      className="argentum-extralight-25 text-primaria5 cursor-pointer"
                      onClick={() => logout()}
                    >
                      SAIR
                    </p>
                  </li>
                )}
              </section>
            )}
          </>
        )}
      {/* Web nao logado - Menu Fechado */}
      {(selectedMenuLogged.actual === "Para empresas" ||
        (!user && selectedMenuLogged.actual === "") ||
        selectedMenuLogged.actual === "Para parceiros" ||
        selectedMenuLogged.actual === "Home") && (
        <nav className="px-20 flex-row h-24 justify-between w-full hidden xl:flex z-[1000] absolute top-0">
          <ul className="flex flex-row gap-12 justify-start">
            <img
              src={headerLogo}
              alt="Logo Onworking/"
              className="w-40 h-auto cursor-pointer"
              onClick={() => changeMenuLogged("Home")}
            />
            <li
              onClick={() => changeMenuLogged("Para empresas")}
              className="flex flex-col items-center justify-center gap-2 cursor-pointer"
            >
              <p
                className={
                  selectedMenuLogged.actual === "Para empresas"
                    ? "body medium text-primaria3 select-none"
                    : "body medium text-terciaria2 select-none"
                }
              >
                Para empresas
              </p>
            </li>
            <li
              onClick={() => changeMenuLogged("Para parceiros")}
              className="flex flex-col items-center justify-center gap-2 cursor-pointer"
            >
              <p
                className={
                  selectedMenuLogged.actual === "Para parceiros"
                    ? "body medium text-primaria3 select-none"
                    : "body medium text-terciaria2 select-none"
                }
              >
                Para parceiros
              </p>
            </li>
          </ul>
          <div
            className="flex items-center justify-center gap-3 cursor-pointer"
            onClick={() => setControl(!control)}
          >
            <img src={menuLogo} alt="menu" className="w-6 h-auto" />
            <p className="argentum-medium text-terciaria2">Menu</p>
          </div>
        </nav>
      )}
      {/* Web logado - Nao LP */}
      {user &&
        selectedMenuLogged.actual !== "Para empresas" &&
        selectedMenuLogged.actual !== "Para parceiros" &&
        selectedMenuLogged.actual !== "Home" && (
          <>
            <nav className="px-20 flex-row h-[98px] justify-between items-center w-full hidden xl:flex z-[1000] top-0">
              <ul className="flex flex-row gap-12 justify-start">
                <img
                  src={logoOpen}
                  alt="Logo Onworking/"
                  className="w-40 h-auto cursor-pointer"
                  onClick={() => changeMenuLogged("Home")}
                />
                <li
                  onClick={() => changeMenuLogged("Para empresas")}
                  className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                >
                  <p
                    className={
                      selectedMenuLogged.actual === "Para empresas"
                        ? "body medium text-primaria3 select-none"
                        : "body medium text-secundaria4 select-none"
                    }
                  >
                    Para empresas
                  </p>
                </li>
                <li
                  onClick={() => changeMenuLogged("Para parceiros")}
                  className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                >
                  <p
                    className={
                      selectedMenuLogged.actual === "Para parceiros"
                        ? "body medium text-primaria3 select-none"
                        : "body medium text-secundaria4 select-none"
                    }
                  >
                    Para parceiros
                  </p>
                </li>
                <li
                  onClick={() => changeMenuLogged("Espaco")}
                  className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                >
                  {/* {user?.type === "Colaborador" && (
                    <p
                      className={
                        selectedMenuLogged.actual === "Espaco"
                          ? "body medium text-primaria3 select-none"
                          : "body medium text-secundaria4 select-none"
                      }
                    >
                      Buscar espaços
                    </p>
                  )} */}
                </li>
              </ul>
              <ul className="flex flex-row gap-3">
                <div className="flex items-center justify-between border-2 rounded-lg border-primarialp px-5 w-auto gap-2 py-2 h-[50px] cursor-pointer">
                  <img src={profileIcon} alt="usuário" />
                  <div className="flex items-start flex-col justify-center text-ellipsis">
                    <p className="argentum-caption text-primarialp text-ellipsis flex-grow-0">
                      {user.name}
                    </p>
                    <p className="argentum-medium-14 text-primarialp">
                      Seja bem vindo(a)
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between border-2 rounded-lg border-primarialp px-2 w-auto gap-2 py-2 h-[50px] cursor-pointer">
                  <img src={logoutImg} alt="logout" onClick={logout} />
                </div>
              </ul>
            </nav>
            {user?.type === "Colaborador" && (
              <div className="hidden xl:flex items-center w-full justify-center bg-header-web-logger py-6 px-56 sticky -top-1 z-50">
                <ul className="flex flex-row gap-12 w-full items-center justify-between">
                  <li
                    onClick={() => changeMenuLogged("Perfil")}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    <p
                      className={
                        selectedMenuLogged.actual === "Perfil"
                          ? "body bold text-terciariafundo select-none"
                          : "body bold text-secundaria2 select-none opacity-60"
                      }
                    >
                      Perfil
                    </p>
                  </li>
                  <li
                    onClick={() => changeMenuLogged("Reservas")}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    <p
                      className={
                        selectedMenuLogged.actual === "Reservas"
                          ? "body bold text-terciariafundo select-none"
                          : "body bold text-secundaria2 select-none opacity-60"
                      }
                    >
                      Reservas
                    </p>
                  </li>
                  <li
                    onClick={() => changeMenuLogged("Espaco")}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    <p
                      className={
                        selectedMenuLogged.actual === "Espaco"
                          ? "body bold text-terciariafundo select-none"
                          : "body bold text-secundaria2 select-none opacity-60"
                      }
                    >
                      Buscar Espaço
                    </p>
                  </li>
                  <li
                    onClick={() => changeMenuLogged("Favoritos")}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    {/* <p
                      className={
                        selectedMenuLogged.actual === "Favoritos"
                          ? "body bold text-terciariafundo select-none"
                          : "body bold text-secundaria2 select-none opacity-60"
                      }
                    >
                      Favoritos
                    </p> */}
                  </li>
                  {/* <li
                    onClick={() => changeMenuLogged("Histórico")}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    <p
                      className={
                        selectedMenuLogged.actual === "Histórico"
                          ? "body bold text-terciariafundo select-none"
                          : "body bold text-secundaria2 select-none opacity-60"
                      }
                    >
                      Histórico/Avaliar
                    </p>
                  </li> */}
                  <li
                    onClick={() => logout()}
                    className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                  >
                    <p className="body bold text-secundaria2 select-none opacity-60">
                      Sair
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {user?.type === "Empresa" && (
              <>
                <div className="hidden xl:flex items-center w-full justify-center bg-header-web-logger py-6 px-56 sticky -top-1 z-50">
                  <ul className="flex flex-row gap-12 w-full items-center justify-between">
                    <li
                      onClick={() => changeMenuLogged("Perfil-Empresa")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Perfil-Empresa"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Perfil
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Colaboradores")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Colaboradores"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Colaboradores
                      </p>
                    </li>
                    {/* <li
                      onClick={() => changeMenuLogged("Relatório de visitas")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Relatório de visitas"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Relatório de visitas
                      </p>
                    </li> */}
                    <li
                      onClick={() => logout()}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p className="body bold text-secundaria2 select-none opacity-60">
                        Sair
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}
            {user?.type === "Coworking" && (
              <>
                <div
                  id="coworking-header"
                  className="hidden xl:flex items-center w-full justify-center bg-header-web-logger py-6 px-40 sticky -top-1 z-50"
                >
                  <ul className="flex flex-row gap-12 w-full items-center justify-between">
                    <li
                      onClick={() => changeMenuLogged("Perfil-Coworking")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Perfil-Coworking"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Perfil
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Token")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Token"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Token
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Solicitações")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Solicitações"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Solicitações
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Reservas")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Reservas"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Reservas
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Relatórios")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Relatórios"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Relatórios
                      </p>
                    </li>
                    <li
                      onClick={() => changeMenuLogged("Espaços")}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p
                        className={
                          selectedMenuLogged.actual === "Espaços"
                            ? "body bold text-terciariafundo select-none"
                            : "body bold text-secundaria2 select-none opacity-60"
                        }
                      >
                        Espaços
                      </p>
                    </li>
                    <li
                      onClick={() => logout()}
                      className="flex flex-col items-center justify-center gap-2 cursor-pointer"
                    >
                      <p className="body bold text-secundaria2 select-none opacity-60">
                        Sair
                      </p>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </>
        )}
      {/* {Web nao logado - Nao LP} */}
      {!user &&
        selectedMenuLogged.actual !== "" &&
        selectedMenuLogged.actual !== "Para empresas" &&
        selectedMenuLogged.actual !== "Para parceiros" &&
        selectedMenuLogged.actual !== "Cadastro" &&
        selectedMenuLogged.actual !== "Home" && (
          <nav className="px-20 flex-row h-[98px] justify-between items-center w-full hidden xl:flex z-[1000] top-0">
            <ul className="flex flex-row gap-12 justify-start">
              <img
                src={logoOpen}
                alt="Logo Onworking/"
                className="w-40 h-auto cursor-pointer"
                onClick={() => changeMenuLogged("Home")}
              />
              <li
                onClick={() => changeMenuLogged("Para empresas")}
                className="flex flex-col items-center justify-center gap-2 cursor-pointer"
              >
                <p
                  className={
                    selectedMenuLogged.actual === "Para empresas"
                      ? "body medium text-primaria3 select-none"
                      : "body medium text-secundaria4 select-none"
                  }
                >
                  Para empresas
                </p>
              </li>
              <li
                onClick={() => changeMenuLogged("Para parceiros")}
                className="flex flex-col items-center justify-center gap-2 cursor-pointer"
              >
                <p
                  className={
                    selectedMenuLogged.actual === "Para parceiros"
                      ? "body medium text-primaria3 select-none"
                      : "body medium text-secundaria4 select-none"
                  }
                >
                  Para parceiros
                </p>
              </li>
            </ul>
            <div
              className="flex items-center justify-between border-2 rounded-lg border-primarialp px-5 h-[35px] cursor-pointer"
              onClick={() => {
                changeMenuLogged("Perfil");
              }}
            >
              <div
                className="flex items-start flex-col justify-center"
                onClick={() => navigate("/cadastro")}
              >
                <p className="argentum-caption text-primarialp">É novo aqui?</p>
              </div>
            </div>
          </nav>
        )}
      {/* Web nao logado - Menu Aberto */}
      {!user && (
        <section
          className={
            control
              ? "fixed bottom-0 z-[3000] bg-terciaria2 w-full min-h-screen visible hidden xl:flex"
              : "fixed bottom-0 z-50 bg-primaria4 w-0 h-0 invisible xl:hidden"
          }
        >
          <div className="flex flex-row items-center justify-between absolute top-0 w-full px-20 py-7">
            <ul className="flex flex-row gap-12 justify-start">
              <img
                src={logoOpen}
                alt="Logo Onworking/"
                className="w-40 h-auto cursor-pointer"
                onClick={() => changeMenuLogged("Home")}
              />
            </ul>
            <div
              className="flex items-center justify-center gap-3 cursor-pointer"
              onClick={() => setControl(!control)}
            >
              <img src={closePurple} alt="menu" className="w-6 h-auto" />
              <p className="argentum-normal-25 text-primaria5">Menu</p>
            </div>
          </div>
          <li className="flex flex-col items-start justify-start mt-40 w-full px-[200px] pl-[450px] gap-12">
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Login")}
            >
              LOGIN / CADASTRE-SE
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Para empresas")}
            >
              PARA EMPRESAS
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Para parceiros")}
            >
              PARA PARCEIROS
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Contato")}
            >
              CONTATO
            </p>
            {user?.type === "Colaborador" && (
              <p className="argentum-extralight-25 text-primaria5 cursor-pointer">
                BUSCAR ESPAÇOS
              </p>
            )}
          </li>
        </section>
      )}
      {/* Web logado - Menu Aberto */}
      {user && (
        <section
          className={
            control
              ? "fixed bottom-0 z-[3000] bg-terciaria2 w-full min-h-screen visible hidden xl:flex"
              : "fixed bottom-0 z-50 bg-primaria4 w-0 h-0 invisible xl:hidden"
          }
        >
          <div className="flex flex-row items-center justify-between absolute top-0 w-full px-20 py-7">
            <ul className="flex flex-row gap-12 justify-start">
              <img
                src={logoOpen}
                alt="Logo Onworking/"
                className="w-40 h-auto cursor-pointer"
                onClick={() => menuC("Home")}
              />
            </ul>
            <div
              className="flex items-center justify-center gap-3 cursor-pointer"
              onClick={() => setControl(!control)}
            >
              <img src={closePurple} alt="menu" className="w-6 h-auto" />
              <p className="argentum-normal-25 text-primaria5">Menu</p>
            </div>
          </div>
          <li className="flex flex-col items-start justify-start mt-40 w-full px-[200px] pl-[450px] gap-12">
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Perfil")}
            >
              PERFIL
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Para empresas")}
            >
              PARA EMPRESAS
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Para parceiros")}
            >
              PARA PARCEIROS
            </p>
            <p
              className="argentum-extralight-25 text-primaria5 cursor-pointer"
              onClick={() => menuC("Contato")}
            >
              CONTATO
            </p>
            {user?.type === "Colaborador" && (
              <p className="argentum-extralight-25 text-primaria5 cursor-pointer">
                BUSCAR ESPAÇOS
              </p>
            )}
          </li>
        </section>
      )}
    </>
  );
};

export default Header;
