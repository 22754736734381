import React, { useEffect, useState } from "react";
import estrelaCheia from "../../assets/startFull.svg";
import fav1 from "../../assets/favorite1.svg";
import fav2 from "../../assets/favorite2.svg";
import green from "../../assets/green.svg";
// import DropDown from "../../components/DropDown";
import ButtonGradient from "../../components/ButtonGradientSquare";
import arrow from "../../assets/backArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import ListarCoworkings from "../../services/Colaborador/ListarCoworkings";
import close from "../../assets/close.svg";
import FavoriteCoworking from "../../services/Colaborador/FavoriteCoworking";
import Calendar from "react-calendar";
import "../../../node_modules/react-calendar/dist/Calendar.css";
import ModalAlert from "../../components/ModalAlert";
import SolicitarReserva from "../../services/Colaborador/SolicitarReserva";
import red from "../../assets/closeCoworking.svg";
import starPurple from "../../assets/star3.svg";
import bgReserva from "../../assets/bgReserva.jpg";
import fav3 from "../../assets/whiteHeart.svg";
import downArrow2 from "../../assets/downArrow2.svg";
import closeWhite from "../../assets/closeWhite.svg";
import InputHorario from "../../components/InputHorario";
import DropDown from "../../components/DropDown";

const ReservarEspaco = () => {
  const navigate = useNavigate();
  const [reserva, setReserva] = useState("hoje");
  const params = useParams();

  const [dados, setDados] = useState();
  const [load, setLoad] = useState(false);
  const [seeHorario, setSeeHorario] = useState(false);
  const [horarios, setHorarios] = useState({ inicio: "", fim: "" });
  const [future, setFuture] = useState({
    data: new Date(),
    set: false,
    funcionamento: false,
  });
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });
  const [choiceDate, setChoiceDate] = useState(false);
  const [tomorrow, setTomorrow] = useState(new Date());

  const choiceday = (day) => {
    let index = dados.funcionamento.findIndex(
      (x) => x.dia_codigo === day.getDay()
    );
    let funcionamento = dados.funcionamento[index].funcionamento;

    setFuture({ data: day, set: true, funcionamento: funcionamento });
    setChoiceDate(false);
    setHorarios({ inicio: "", fim: "" });
  };

  const [fotos, setFotos] = useState(false);

  const hours = [
    { value: "00:00", name: "00:00" },
    { value: "01:00", name: "01:00" },
    { value: "02:00", name: "02:00" },
    { value: "03:00", name: "03:00" },
    { value: "04:00", name: "04:00" },
    { value: "05:00", name: "05:00" },
    { value: "06:00", name: "06:00" },
    { value: "07:00", name: "07:00" },
    { value: "08:00", name: "08:00" },
    { value: "09:00", name: "09:00" },
    { value: "10:00", name: "10:00" },
    { value: "11:00", name: "11:00" },
    { value: "12:00", name: "12:00" },
    { value: "13:00", name: "13:00" },
    { value: "14:00", name: "14:00" },
    { value: "15:00", name: "15:00" },
    { value: "16:00", name: "16:00" },
    { value: "17:00", name: "17:00" },
    { value: "18:00", name: "18:00" },
    { value: "19:00", name: "19:00" },
    { value: "20:00", name: "20:00" },
    { value: "21:00", name: "21:00" },
    { value: "22:00", name: "22:00" },
    { value: "23:00", name: "23:00" },
    { value: "23:59", name: "23:59" },
  ];

  const atualizaHorario = () => {
    if (load && dados.tipo_reserva === "reserva") {
      return;
    }

    const hour = new Date();
    let minutes = hour.getMinutes();
    let now = "";
    if (minutes >= 0 && minutes <= 9) {
      now = hour.getHours().toString() + ":0" + hour.getMinutes().toString();
    } else {
      now = hour.getHours().toString() + ":" + hour.getMinutes().toString();
    }

    setHorarios({ fim: "", inicio: now });
  };

  const favorite = () => {
    FavoriteCoworking(dados.uuid)
      .then((resp) => {
        setDados({ ...dados, favorite: resp.data.status });
      })
      .catch((e) => {
        setDados({ ...dados, favorite: false });
      });
  };

  const [indexImg, setIndexImg] = useState(0);

  function replaceBadInputs(val, code) {
    if (!val) {
      return;
    }

    if (code.nativeEvent.inputType === "deleteContentBackward") {
      return val;
    }

    if (code.nativeEvent.inputType === "insertFromPaste") {
      return "";
    }

    let today = new Date();
    let fechamento = "";
    let abertura = "";
    if (reserva === "hoje") {
      today = today.getDay();
      let index = dados.funcionamento.findIndex((x) => x.dia_codigo === today);
      fechamento = dados.funcionamento[index].hora_fechamento;
      abertura = dados.funcionamento[index].hora_abertura;
    } else {
      today = future.data.getDay();
      let index = dados.funcionamento.findIndex((x) => x.dia_codigo === today);
      fechamento = dados.funcionamento[index].hora_fechamento;
      abertura = dados.funcionamento[index].hora_abertura;
    }

    if (val.length === 5) {
      if (parseInt(val.substring(0, 2)) < parseInt(abertura.substring(0, 2))) {
        setModal({
          text: "O horário de abertura do coworking foi excedido",
          alertType: "fail",
          active: true,
        });
        return "";
      }
      if (
        parseInt(val.substring(0, 2)) === parseInt(abertura.substring(0, 2))
      ) {
        if (
          parseInt(val.substring(3, 6)) < parseInt(abertura.substring(3, 6))
        ) {
          setModal({
            text: "O horário de abertura do coworking foi excedido",
            alertType: "fail",
            active: true,
          });
          return "";
        }
      }
      if (
        parseInt(val.substring(0, 2)) >
        parseInt(fechamento.substring(0, 2)) - 1
      ) {
        setModal({
          text: "O horário de fechamento do coworking foi excedido",
          alertType: "fail",
          active: true,
        });
        return "";
      } else {
        if (
          parseInt(val.substring(3, 6)) >
            parseInt(fechamento.substring(3, 6)) &&
          parseInt(val.substring(0, 2)) ===
            parseInt(fechamento.substring(0, 2)) - 1
        ) {
          setModal({
            text: "O horário de fechamento do coworking foi excedido",
            alertType: "fail",
            active: true,
          });
          return "";
        }
      }
    }

    val = val.replace(/[^\dh:]/, "");
    val = val.replace(/^([3-9])/, "0$1");
    val = val.replace(/^([2-9])[4-9]/, "$1");
    val = val.replace(/^\d[:h]/, "");
    val = val.replace(/^([01][0-9])[^:h]/, "$1");
    val = val.replace(/^(2[0-3])[^:h]/, "$1");
    val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
    val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
    val = val.replace(/^(\d{2})/, "$1:");
    val = val.replace(/^(\d{2}):(\d{2})^/, "$1:$2");
    val = val.replace(/^(\d{2}:):/, "$1");
    return val;
  }

  const setIndex = (action) => {
    if (action === "up") {
      if (indexImg !== dados.imagens.length - 1) {
        setIndexImg(indexImg + 1);
      } else {
        setIndexImg(0);
      }
    } else {
      if (indexImg !== 0) {
        setIndexImg(indexImg - 1);
      } else {
        setIndexImg(dados.imagens.length - 1);
      }
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0, "Smooth");

    const data = new Date();

    let newT = data.setDate(data.getDate() + 1);
    setTomorrow(new Date(newT));

    const intervalId = setInterval(() => {
      atualizaHorario();
    }, 120000);

    ListarCoworkings(params.id)
      .then((resp) => {
        setDados(resp.data.data);
        if (resp.data.data.aberto !== "disponivel") {
          setReserva("futuro");
        }
        atualizaHorario();
        setLoad(true);
      })
      .catch((e) => console.log(e));

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const changeReserva = (name) => {
    setReserva(name);
    setHorarios({ inicio: "", fim: "" });
  };

  const reservarToday = () => {
    const data = new Date();

    if (!horarios.inicio || !horarios.fim) {
      setModal({
        text: "Você deve selecionar os horários de inicio e fim da reserva!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (reserva === "hoje") {
      let inicio = {
        ano: data.getFullYear(),
        mes: data.getMonth() + 1,
        dia: data.getDate(),
        hora: horarios.inicio,
        minuto: horarios.inicio.substring(3, 6),
      };

      let fim = {
        ano: data.getFullYear(),
        mes: data.getMonth() + 1,
        dia: data.getDate(),
        hora: horarios.fim,
        minuto: horarios.fim.substring(3, 6),
      };

      let body = {
        inicio,
        fim,
        uuid: dados.uuid,
        tipo: reserva,
        tipo_checkin: dados.tipo_reserva,
      };
      SolicitarReserva(body)
        .then((resp) => {
          setModal({
            text: "Reserva realizada com sucesso!",
            alertType: "ok",
            active: true,
          });
        })
        .catch((e) => {
          setModal({
            text: e.response.data.message[0],
            alertType: "fail",
            active: true,
          });
        });
    }
    if (reserva === "futuro") {
      let inicio = {
        ano: future.data.getFullYear(),
        mes: future.data.getMonth(),
        dia: future.data.getDate(),
        hora: horarios.inicio,
        minuto: horarios.inicio.substring(3, 6),
      };

      let fim = {
        ano: future.data.getFullYear(),
        mes: future.data.getMonth(),
        dia: future.data.getDate(),
        hora: horarios.fim,
        minuto: horarios.fim.substring(3, 6),
      };

      let body = {
        inicio,
        fim,
        uuid: dados.uuid,
        tipo: reserva,
        tipo_checkin: dados.tipo_reserva,
      };
      SolicitarReserva(body)
        .then((resp) => {
          setModal({
            text: "Reserva solicitada com sucesso!",
            alertType: "ok",
            active: true,
          });
        })
        .catch((e) => {
          console.log(e);
          setModal({
            text: e.response.data.message[0],
            alertType: "fail",
            active: true,
          });
        });
    }
  };

  return (
    <>
      {load && (
        <section className="w-full min-h-screen lg:hidden bg-terciariafundo relative flex flex-col items-center justify-start transition-all">
          <div
            className="absolute top-4 left-5 z-40 flex flex-row gap-3"
            onClick={() => navigate("/buscar-espaco")}
          >
            <img src={arrow} alt="Voltar" />
            <p className="button medium text-terciaria2">VOLTAR</p>
          </div>
          <img
            src={bgReserva}
            alt="logo on working "
            className="absolute -top-[70px] z-2 w-full h-[250px] object-cover"
          />
          <div className="w-full -top-[70px] absolute z-5 min-h-[250px] bg-detalhes1 opacity-60"></div>
          <div className="z-20 px-4 bg-parceiros-lp flex flex-row gap-2 py-2 rounded-3xl h-auto mt-[115px] items-center justify-center">
            <img src={estrelaCheia} alt="estrela" className="w-5" />
            <p className="subtitle bold text-detalhes5">
              {dados.avaliacao_nota === -1
                ? "Sem avaliações"
                : dados.avaliacao_nota}
            </p>
          </div>
          <div className="w-full flex items-center justify-center h-[200px] sm:h-[300px] mt-4 relative">
            <img
              src={dados.imagens[0]}
              alt="imagem coworking"
              className="absolute w-11/12 mx-4 object-cover h-[200px] sm:h-[300px] rounded-md border-[8px] border-terciaria2"
            />
            <img
              src={downArrow2}
              alt="imagens"
              className="absolute right-2 rotate-[270deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
              onClick={() => setFotos(!fotos)}
            />
          </div>
          <div className="flex items-center justify-center flex-row gap-4 mt-6">
            {dados.imagens.map((img, count) => {
              if (count === 0) {
                return <i className="w-3 h-3 bg-primaria4  rounded-full"></i>;
              }
              return <i className="w-3 h-3 bg-primaria2  rounded-full"></i>;
            })}
          </div>
          {fotos && (
            <div
              className={
                fotos
                  ? "w-full h-full flex fixed z-[5000] top-0 items-center justify-center left-0 lg:items-center"
                  : "w-full h-full hidden z-[5000] top-0 items-center justify-center"
              }
            >
              <div className="w-full h-full fixed bg-primaria3 opacity-95 flex items-center justify-center" />
              <img
                src={closeWhite}
                alt="fechar imagens"
                className="z-[5000] right-4 absolute top-4 w-5 cursor-pointer"
                onClick={() => setFotos(false)}
              />
              <div className="flex flex-col relative w-full items-center justify-center">
                <img
                  src={dados.imagens[indexImg]}
                  alt="imagem"
                  className="w-full px-4 h-auto rounded-md"
                />
                <div className="flex flex-row mt-8 gap-8 justify-between w-full px-4">
                  <img
                    src={downArrow2}
                    alt="imagens"
                    className="rotate-[90deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
                    onClick={() => setIndex("down")}
                  />
                  <div className="flex flex-row z-[5000] gap-4 mt-4">
                    {dados.imagens.map((img, count) => {
                      if (count === indexImg) {
                        return (
                          <i className="w-3 h-3 bg-primaria4  rounded-full"></i>
                        );
                      }
                      return (
                        <i className="w-3 h-3 bg-primaria2  rounded-full"></i>
                      );
                    })}
                  </div>
                  <img
                    src={downArrow2}
                    alt="imagens"
                    className="rotate-[270deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
                    onClick={() => setIndex("up")}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center justify-center w-11/12">
            <div className="mt-10 flex w-full items-start justify-between flex-row">
              <div className="flex flex-col items-start justify-center gap-2">
                <p className="subtitle bold text-secundaria5">
                  {dados.nome_unidade}
                </p>
                <p className="button regular text-secundaria4 pr-8">
                  {dados.endereco_coworking.logradouro},{" "}
                  {dados.endereco_coworking.numero} -{" "}
                  {dados.endereco_coworking.municipio} -{" "}
                  {dados.endereco_coworking.estado}
                </p>
              </div>
              {dados.favorite ? (
                <div className="border-2 p-3 border-primaria4 rounded-lg">
                  <img src={fav2} alt="Favoritar" onClick={() => favorite()} />
                </div>
              ) : (
                <div className="border-2 p-3 rounded-lg">
                  <img src={fav1} alt="Favoritar" onClick={() => favorite()} />
                </div>
              )}
            </div>
            <div className="divisao-lp-line w-full my-6" />
            <p className="button medium text-start">
              {dados.descricao ? dados.descricao : "Espaço sem descrição"}
            </p>
            <div className="divisao-lp-line w-full my-6" />
            <h4 className="semi bold text-primaria4 self-start">
              Horário de Funcionamento
            </h4>
            <p
              className="button medium self-start mt-4 text-detalhes3 cursor-pointer"
              onClick={() => setSeeHorario(true)}
            >
              Ver horários
            </p>
            {seeHorario && (
              <div
                className={
                  seeHorario
                    ? "w-full h-full flex fixed z-[5000] top-0 items-center justify-center left-0 lg:items-center px-6"
                    : "w-full h-full hidden z-[5000] top-0 items-center justify-center"
                }
              >
                <div className="w-full h-full fixed bg-primaria3 opacity-95 flex items-center justify-center" />
                <div className="lg:w-6/12 w-full rounded-md white-box h-auto z-[300] px-6 flex flex-col items-center justify-center text-center py-6">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="textos semibold text-primaria4">Horários</p>
                    <img
                      src={close}
                      alt="Fechar horários"
                      className="w-4"
                      onClick={() => setSeeHorario(false)}
                    />
                  </div>
                  <div className="divisao-lp-line w-full my-4" />
                  <div className="flex flex-col items-start justify-center gap-2 w-full">
                    {dados.funcionamento.map((dia) => {
                      return (
                        <div className="flex flex-row justify-between items-center w-full">
                          <p className="textos regular text-secundaria5">
                            • {dia.dia}
                          </p>
                          <p className="textos regular text-secundaria5">
                            {dia.funcionamento ? (
                              <>
                                <p className="textos regular text-secundaria5">
                                  {dia.hora_abertura.substr(0, 5)} -{" "}
                                  {dia.hora_fechamento.substr(0, 5)}
                                </p>
                              </>
                            ) : (
                              <p className="textos regular text-secundaria5">
                                Fechado
                              </p>
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="divisao-lp-line w-full my-6" />
            {dados.tipo_reserva === "reserva" && (
              <div className="px-4 pt-8 pb-4 mb-6 w-full shadow-newShadow rounded-lg bg-primaria1 flex items-center justify-center flex-col">
                <div className="fade-vagas px-4 py-1 w-fit flex flex-row gap-2 rounded-xl">
                  {dados.aberto === "disponivel" && (
                    <>
                      <img src={green} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">
                        {dados.espacos_atual} VAGAS
                      </p>
                    </>
                  )}
                  {dados.aberto === "fechado" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">FECHADO</p>
                    </>
                  )}
                  {dados.aberto === "sem vagas" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">SEM VAGAS</p>
                    </>
                  )}
                </div>
                <h4 className="semi bold text-secundaria5 mt-4">
                  Solicitar agendamento
                </h4>
                <div className="flex flex-row w-full mt-4">
                  {dados.aberto === "disponivel" ? (
                    <div className="w-6/12 pr-3">
                      <button
                        className={
                          reserva === "hoje"
                            ? "w-full border-2 border-primaria3 p-2 rounded-md bg-primaria3 h-full regular text-terciaria2 flex items-center justify-center"
                            : "w-full border-2 border-primaria3 p-2 rounded-md h-full bg-terciaria2 regular text-primaria3  flex items-center justify-center"
                        }
                        onClick={() => changeReserva("hoje")}
                      >
                        HOJE
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      dados.aberto === "disponivel" ? "w-6/12 pl-3" : "w-full"
                    }
                  >
                    <button
                      className={
                        reserva === "futuro"
                          ? "w-full border-2 border-primaria3 p-2 rounded-md bg-primaria3 h-full regular text-terciaria2 flex items-center justify-center"
                          : "w-full border-2 border-primaria3 p-2 rounded-md h-full bg-terciaria2 regular text-primaria3  flex items-center justify-center"
                      }
                      onClick={() => changeReserva("futuro")}
                    >
                      FUTURO
                    </button>
                  </div>
                </div>
                <div className="divisao-lp-line w-full my-6" />
                {reserva === "futuro" ? (
                  <>
                    <div className="flex flex-col justify-start items-start w-full self-start">
                      <p className="textos medium">Data</p>
                      <p
                        className="button medium self-start mt-2 text-detalhes3"
                        onClick={() => setChoiceDate(true)}
                      >
                        Escolher data
                      </p>
                      <div className="w-full mt-2">
                        {choiceDate && (
                          <Calendar
                            value={future.data}
                            minDate={tomorrow}
                            onClickDay={(e) => choiceday(e)}
                          />
                        )}
                      </div>
                      {future.set && (
                        <p className="textos medium mt-2">
                          {future.data.getDate()}/{future.data.getMonth()}/
                          {future.data.getFullYear()}
                        </p>
                      )}
                    </div>
                    {future.funcionamento && (
                      <div className="flex flex-row justify-between w-full mt-5">
                        <div
                          className={
                            future.set
                              ? "flex flex-col justify-start items-start w-6/12 pr-2 gap-2"
                              : "flex flex-col justify-start items-start w-6/12 pr-2 gap-2 pointer-events-none"
                          }
                        >
                          <p className="textos medium">De</p>
                          <InputHorario
                            id="horario-entrada"
                            value={horarios.inicio}
                            onChange={(e) =>
                              setHorarios({
                                inicio: replaceBadInputs(e.target.value, e),
                                fim: "",
                              })
                            }
                            placer="De"
                            background="terciaria2"
                            name="horarioInicio"
                          />
                        </div>
                        <div
                          className={
                            horarios.inicio
                              ? "flex flex-col justify-start items-start w-6/12 pl-2 gap-2"
                              : "flex flex-col justify-start items-start w-6/12 pl-2 gap-2 pointer-events-none"
                          }
                        >
                          <p className="textos medium">Até</p>
                          <DropDown
                            options={hours.map((hour) => {
                              let today = new Date();
                              today = future.data.getDay();
                              let index = dados.funcionamento.findIndex(
                                (x) => x.dia_codigo === today
                              );

                              let fechamento =
                                dados.funcionamento[index].hora_fechamento;

                              if (
                                parseInt(hour?.value?.substring(0, 2)) >
                                parseInt(horarios?.inicio?.substring(0, 2))
                              ) {
                                if (
                                  horarios?.inicio?.substring(3, 6) === "00"
                                ) {
                                  if (
                                    parseInt(hour?.value?.substring(0, 2)) <=
                                    parseInt(fechamento?.substring(0, 2))
                                  ) {
                                    return {
                                      value: `${hour?.value?.substring(
                                        0,
                                        2
                                      )}:${horarios?.inicio?.substring(3, 5)}`,
                                      name: `${hour?.value?.substring(
                                        0,
                                        2
                                      )}:${horarios?.inicio?.substring(3, 5)}`,
                                    };
                                  }
                                  return false;
                                } else {
                                  if (
                                    parseInt(hour?.value?.substring(0, 2)) <=
                                    parseInt(fechamento?.substring(0, 2))
                                  ) {
                                    if (
                                      parseInt(hour?.value?.substring(0, 2)) ===
                                        parseInt(fechamento?.substring(0, 2)) &&
                                      parseInt(
                                        horarios?.inicio?.substring(3, 5)
                                      ) > parseInt(fechamento?.substring(3, 5))
                                    ) {
                                      return false;
                                    } else {
                                      return {
                                        value: `${hour?.value?.substring(
                                          0,
                                          2
                                        )}:${horarios?.inicio?.substring(
                                          3,
                                          5
                                        )}`,
                                        name: `${hour?.value?.substring(
                                          0,
                                          2
                                        )}:${horarios?.inicio?.substring(
                                          3,
                                          5
                                        )}`,
                                      };
                                    }
                                  }
                                  return false;
                                }
                              }
                              return false;
                            })}
                            background="terciaria2"
                            message="Horário"
                            onChange={(e) =>
                              setHorarios({ ...horarios, fim: e.target.value })
                            }
                            value={horarios.fim}
                          />
                        </div>
                      </div>
                    )}
                    {!future.funcionamento && future.set && (
                      <p className="textos medium mt-8 self-start">
                        Nenhum horário disponível para esta data, confira os
                        horários de funcionamento do espaço.
                      </p>
                    )}
                  </>
                ) : (
                  <div className="flex flex-row justify-between w-full mt-5">
                    <div className="flex flex-col justify-start items-start w-6/12 pr-2 gap-2">
                      <p className="textos medium">De</p>
                      <InputHorario
                        id="horario-entrada"
                        value={horarios.inicio}
                        onChange={(e) =>
                          setHorarios({
                            inicio: replaceBadInputs(e.target.value, e),
                            fim: "",
                          })
                        }
                        placer="De"
                        background="terciaria2"
                        name="horarioInicio"
                      />
                    </div>
                    <div
                      className={
                        horarios.inicio
                          ? "flex flex-col justify-start items-start w-6/12 pl-2 gap-2"
                          : "flex flex-col justify-start items-start w-6/12 pl-2 gap-2 pointer-events-none"
                      }
                    >
                      <p className="textos medium">Até</p>
                      <DropDown
                        options={hours.map((hour) => {
                          let today = new Date();
                          today = today.getDay();
                          let index = dados.funcionamento.findIndex(
                            (x) => x.dia_codigo === today
                          );

                          let fechamento =
                            dados.funcionamento[index].hora_fechamento;

                          if (
                            parseInt(hour?.value?.substring(0, 2)) >
                            parseInt(horarios?.inicio?.substring(0, 2))
                          ) {
                            if (horarios?.inicio?.substring(3, 6) === "00") {
                              if (
                                parseInt(hour?.value?.substring(0, 2)) <=
                                parseInt(fechamento?.substring(0, 2))
                              ) {
                                return {
                                  value: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                  name: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                };
                              }
                              return false;
                            } else {
                              if (
                                parseInt(hour?.value?.substring(0, 2)) <=
                                parseInt(fechamento?.substring(0, 2))
                              ) {
                                if (
                                  parseInt(hour?.value?.substring(0, 2)) ===
                                    parseInt(fechamento?.substring(0, 2)) &&
                                  parseInt(horarios?.inicio?.substring(3, 5)) >
                                    parseInt(fechamento?.substring(3, 5))
                                ) {
                                  return false;
                                } else {
                                  return {
                                    value: `${hour?.value?.substring(
                                      0,
                                      2
                                    )}:${horarios?.inicio?.substring(3, 5)}`,
                                    name: `${hour?.value?.substring(
                                      0,
                                      2
                                    )}:${horarios?.inicio?.substring(3, 5)}`,
                                  };
                                }
                              }
                              return false;
                            }
                          }
                          return false;
                        })}
                        background="terciaria2"
                        message="Horário"
                        onChange={(e) =>
                          setHorarios({ ...horarios, fim: e.target.value })
                        }
                        value={horarios.fim}
                      />
                    </div>
                  </div>
                )}

                <div className="w-full mt-[60px]">
                  <ButtonGradient
                    legend="SOLICITAR AGENDAMENTO"
                    func={() => reservarToday()}
                  />
                </div>
              </div>
            )}
            {dados.tipo_reserva === "disponibilidade" && (
              <div className="px-4 pt-8 pb-4 mb-6 w-full shadow-newShadow rounded-lg bg-primaria1 flex items-center justify-center flex-col">
                <div className="fade-vagas px-4 py-1 w-fit flex flex-row gap-2 rounded-xl">
                  {dados.aberto === "disponivel" && (
                    <>
                      <img src={green} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">
                        {dados.espacos_atual} VAGAS
                      </p>
                    </>
                  )}
                  {dados.aberto === "fechado" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">FECHADO</p>
                    </>
                  )}
                  {dados.aberto === "sem vagas" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">SEM VAGAS</p>
                    </>
                  )}
                </div>
                <h4 className="semi bold text-secundaria5 mt-4">
                  Realizar Check-in
                </h4>
                <div className="divisao-lp-line w-full my-6" />
                <div className="flex flex-row justify-between w-full mt-5">
                  <div className="flex flex-col justify-start items-start w-6/12 pr-2 gap-2">
                    <p className="textos medium">De</p>
                    <InputHorario
                      id="horario-entrada"
                      value={horarios.inicio}
                      onChange={(e) =>
                        setHorarios({
                          inicio: replaceBadInputs(e.target.value, e),
                          fim: "",
                        })
                      }
                      placer="De"
                      background="terciaria2"
                      name="horarioInicio"
                      block={true}
                    />
                  </div>
                  <div
                    className={
                      horarios.inicio
                        ? "flex flex-col justify-start items-start w-6/12 pl-2 gap-2"
                        : "flex flex-col justify-start items-start w-6/12 pl-2 gap-2 pointer-events-none"
                    }
                  >
                    <p className="textos medium">Até</p>
                    <DropDown
                      options={hours.map((hour) => {
                        let today = new Date();
                        today = today.getDay();
                        let index = dados.funcionamento.findIndex(
                          (x) => x.dia_codigo === today
                        );

                        let fechamento =
                          dados.funcionamento[index].hora_fechamento;

                        if (
                          parseInt(hour?.value?.substring(0, 2)) >
                          parseInt(horarios?.inicio?.substring(0, 2))
                        ) {
                          if (horarios?.inicio?.substring(3, 6) === "00") {
                            if (
                              parseInt(hour?.value?.substring(0, 2)) <=
                              parseInt(fechamento?.substring(0, 2))
                            ) {
                              return {
                                value: `${hour?.value?.substring(
                                  0,
                                  2
                                )}:${horarios?.inicio?.substring(3, 5)}`,
                                name: `${hour?.value?.substring(
                                  0,
                                  2
                                )}:${horarios?.inicio?.substring(3, 5)}`,
                              };
                            }
                            return false;
                          } else {
                            if (
                              parseInt(hour?.value?.substring(0, 2)) <=
                              parseInt(fechamento?.substring(0, 2))
                            ) {
                              if (
                                parseInt(hour?.value?.substring(0, 2)) ===
                                  parseInt(fechamento?.substring(0, 2)) &&
                                parseInt(horarios?.inicio?.substring(3, 5)) >
                                  parseInt(fechamento?.substring(3, 5))
                              ) {
                                return false;
                              } else {
                                return {
                                  value: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                  name: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                };
                              }
                            }
                            return false;
                          }
                        }
                        return false;
                      })}
                      background="terciaria2"
                      message="Horário"
                      onChange={(e) =>
                        setHorarios({ ...horarios, fim: e.target.value })
                      }
                      value={horarios.fim}
                    />
                  </div>
                </div>
                <div className="w-full mt-[60px]">
                  <ButtonGradient
                    legend="SOLICITAR AGENDAMENTO"
                    func={() => reservarToday()}
                  />
                </div>
              </div>
            )}
            <div className="divisao-lp-line w-full my-6" />
            <h4 className="semi bold text-primaria4 self-start">Facilities</h4>
            <div className="flex flex-row flex-wrap items-start justify-between w-full mt-4">
              {dados.facilities.length !== 0 ? (
                dados.facilities.map((facilidade) => {
                  return (
                    <p className="textos regular w-6/12 text-secundaria5">
                      • {facilidade}
                    </p>
                  );
                })
              ) : (
                <p className="textos regular w-full text-secundaria5">
                  Espaço sem facilidades
                </p>
              )}
            </div>
            <div className="divisao-lp-line w-full my-6" />
            <h4 className="semi bold text-primaria4 self-start">
              Cobrados á parte
            </h4>
            <div className="flex flex-row flex-wrap items-start justify-between w-full mt-4">
              {dados.itens.length !== 0 ? (
                dados.itens.map((iten) => {
                  return (
                    <p className="textos regular w-6/12 text-secundaria5">
                      • {iten}
                    </p>
                  );
                })
              ) : (
                <p className="textos regular w-6/12 text-secundaria5">Nenhum</p>
              )}
            </div>
            <div className="divisao-lp-line w-full my-6" />
            <h4 className="semi bold text-primaria4 self-start">Avaliações</h4>
            <div className="flex flex-col items-start justify-center w-full gap-3 mt-4 mb-8">
              {dados.avaliacoes_textos.length !== 0 ? (
                dados.avaliacoes_textos.map((avaliacao) => {
                  return (
                    <div className="w-full bg-terciaria2 rounded-md px-3 py-2 gap-2 flex items-start justify-center flex-col">
                      <div className="flex flex-row gap-2 items-center justify-center w-fit">
                        <p className="textos semibold pt-1">{avaliacao.nota}</p>
                        <img src={starPurple} alt="estrela" className="w-4" />
                      </div>
                      <p className="argentum-regular">{avaliacao.texto}</p>
                      <div className="flex flex-row gap-2 items-center justify-between text-primaria4 w-full">
                        <p className="textos semibold">{avaliacao.nome}</p>
                        <p className="argentum-regular">Avaliação verificada</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="textos regular w-6/12 text-secundaria5">
                  Nenhuma avaliação
                </p>
              )}
            </div>
          </div>
        </section>
      )}
      {load && (
        <section className="w-full min-h-screen lg:flex hidden bg-terciariafundo relative flex-col items-center justify-start pb-16 px-[50px] transition-all">
          <div
            className="absolute top-4 left-5 z-40 flex flex-row gap-3 cursor-pointer"
            onClick={() => navigate("/buscar-espaco")}
          >
            <img src={arrow} alt="Voltar" />
            <p className="button medium text-terciaria2 cursor-pointer">
              VOLTAR
            </p>
          </div>
          <img
            src={bgReserva}
            alt="logo on working "
            className="absolute z-2 w-full h-[345px] object-cover"
          />
          <div className="w-full absolute z-5 min-h-[345px] bg-detalhes1 opacity-60"></div>
          <div className="flex flex-row w-full">
            <div className="w-6/12 flex flex-col items-center justify-center self-start">
              <div className="z-20 px-4 bg-parceiros-lp flex flex-row gap-2 py-2 rounded-3xl h-auto mt-[80px] items-center justify-center">
                <img src={estrelaCheia} alt="estrela" className="w-5" />
                <p className="subtitle bold text-detalhes5">
                  {dados.avaliacao_nota === -1
                    ? "Sem avaliações"
                    : dados.avaliacao_nota}
                </p>
              </div>
              <div className="w-full flex items-center justify-center h-[345px] sm:h-[345px] mt-4 relative">
                <img
                  src={dados.imagens[0]}
                  alt="imagem coworking"
                  className="absolute w-full mx-4 object-cover h-[345px] sm:h-[345px] rounded-md border-[12px] border-terciaria2"
                />
                <img
                  src={downArrow2}
                  alt="imagens"
                  className="absolute -right-4 rotate-[270deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
                  onClick={() => setFotos(!fotos)}
                />
              </div>
              <div className="flex items-center justify-center flex-row gap-4 mt-6">
                {dados.imagens.map((img, count) => {
                  if (count === 0) {
                    return (
                      <i className="w-3 h-3 bg-primaria4  rounded-full"></i>
                    );
                  }
                  return <i className="w-3 h-3 bg-primaria2  rounded-full"></i>;
                })}
              </div>
              {fotos && (
                <div
                  className={
                    fotos
                      ? "w-full h-full flex fixed z-[5000] top-0 items-center justify-center flex-col left-0 lg:items-center px-6"
                      : "w-full h-full hidden z-[5000] top-0 items-center justify-center"
                  }
                >
                  <div className="w-full h-full fixed bg-primaria3 opacity-95 flex items-center justify-center" />
                  <img
                    src={closeWhite}
                    alt="fechar imagens"
                    className="z-[5000] right-[200px] absolute top-4 w-5 cursor-pointer"
                    onClick={() => setFotos(false)}
                  />
                  <div className="flex flex-row relative w-10/12 items-center justify-center gap-20">
                    <img
                      src={downArrow2}
                      alt="imagens"
                      className="rotate-[90deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
                      onClick={() => setIndex("down")}
                    />
                    <img
                      src={dados.imagens[indexImg]}
                      alt="imagem"
                      className="w-9/12 h-auto rounded-md"
                    />
                    <img
                      src={downArrow2}
                      alt="imagens"
                      className="rotate-[270deg] bg-terciariafundo p-2 rounded-full w-12 h-12 cursor-pointer"
                      onClick={() => setIndex("up")}
                    />
                  </div>
                  <div className="flex flex-row z-[5000] gap-4 mt-4">
                    {dados.imagens.map((img, count) => {
                      if (count === indexImg) {
                        return (
                          <i className="w-3 h-3 bg-primaria4  rounded-full"></i>
                        );
                      }
                      return (
                        <i className="w-3 h-3 bg-primaria2  rounded-full"></i>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="w-6/12 self-end flex flex-col items-start justify-center h-[400px] z-20 ml-8 relative">
              <div className="flex flex-row">
                <div className="flex flex-col items-start justify-center gap-2 pb-8">
                  <h4 className="semi bold text-terciaria2">
                    {dados.nome_unidade}
                  </h4>
                  <p className="button regular text-terciaria2 pr-8">
                    {dados.endereco_coworking.logradouro},{" "}
                    {dados.endereco_coworking.numero} -{" "}
                    {dados.endereco_coworking.municipio} -{" "}
                    {dados.endereco_coworking.estado}
                  </p>
                </div>
                {dados.favorite ? (
                  <div className="border-2 p-3 ml-6 border-primaria4 rounded-lg cursor-pointer h-fit">
                    <img
                      src={fav2}
                      alt="Favoritar"
                      onClick={() => favorite()}
                      className="w-7 cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="border-2 p-3 ml-6 rounded-lg border-terciaria2 cursor-pointer h-fit">
                    <img
                      src={fav3}
                      alt="Favoritar"
                      onClick={() => favorite()}
                      className="w-7 cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full relative">
            <div className="w-7/12 flex items-start flex-col justify-start self-start pr-8">
              <h4 className="semi bold text-primaria4 self-start mt-8">
                Descrição do espaço
              </h4>
              <p className="button medium text-start mt-4">
                {dados.descricao ? dados.descricao : "Espaço sem descrição"}
              </p>
              <div className="divisao-lp-line w-full my-6" />
              <h4 className="semi bold text-primaria4 self-start">
                Horário de Funcionamento
              </h4>
              <p
                className="button medium self-start mt-4 text-detalhes3 cursor-pointer"
                onClick={() => setSeeHorario(true)}
              >
                Ver horários
              </p>
              {seeHorario && (
                <div
                  className={
                    seeHorario
                      ? "w-full h-full flex fixed z-[5000] top-0 items-center justify-center left-0 lg:items-center px-6"
                      : "w-full h-full hidden z-[5000] top-0 items-center justify-center"
                  }
                >
                  <div className="w-full h-full fixed bg-primaria3 opacity-95 flex items-center justify-center" />
                  <div className="lg:w-5/12 max-w-[450px] w-full rounded-md white-box h-auto z-[300] px-6 flex flex-col items-center justify-center text-center py-6">
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="textos semibold text-primaria4">Horários</p>
                      <img
                        src={close}
                        alt="Fechar horários"
                        className="w-4 cursor-pointer"
                        onClick={() => setSeeHorario(false)}
                      />
                    </div>
                    <div className="divisao-lp-line w-full my-4" />
                    <div className="flex flex-col items-start justify-center gap-2 w-full">
                      {dados.funcionamento.map((dia) => {
                        return (
                          <div className="flex flex-row justify-between items-center w-full">
                            <p className="textos regular text-secundaria5">
                              • {dia.dia}
                            </p>
                            <p className="textos regular text-secundaria5">
                              {dia.funcionamento ? (
                                <>
                                  <p className="textos regular text-secundaria5">
                                    {dia.hora_abertura.substr(0, 5)} -{" "}
                                    {dia.hora_fechamento.substr(0, 5)}
                                  </p>
                                </>
                              ) : (
                                <p className="textos regular text-secundaria5">
                                  Fechado
                                </p>
                              )}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              <div className="divisao-lp-line w-full my-6" />
              <h4 className="semi bold text-primaria4 self-start">
                Facilities
              </h4>
              <div className="flex flex-row flex-wrap items-start justify-start w-full mt-4">
                {dados.facilities.length !== 0 ? (
                  dados.facilities.map((facilidade) => {
                    return (
                      <p className="py-2 pr-2 textos regular w-4/12 text-secundaria5">
                        • {facilidade}
                      </p>
                    );
                  })
                ) : (
                  <p className="py-2 pr-2 textos regular w-4/12 text-secundaria5">
                    Espaço sem facilidades
                  </p>
                )}
              </div>
              <div className="divisao-lp-line w-full my-6" />
              <h4 className="semi bold text-primaria4 self-start">
                Cobrados á parte
              </h4>
              <div className="flex flex-row flex-wrap items-start justify-start w-full mt-4">
                {dados.itens.length !== 0 ? (
                  dados.itens.map((iten) => {
                    return (
                      <p className="py-2 pr-2 textos regular w-4/12 text-secundaria5">
                        • {iten}
                      </p>
                    );
                  })
                ) : (
                  <p className="py-2 pr-2 textos regular w-4/12 text-secundaria5">
                    Nenhum
                  </p>
                )}
              </div>
            </div>
            <div className="w-5/12 flex items-start flex-col justify-center pl-4 -top-20 relative">
              <div className="px-4 max-w-[400px] pt-8 pb-4 mb-6 w-full shadow-newShadow rounded-lg bg-primaria1 flex items-center justify-center flex-col">
                <div className="fade-vagas px-4 py-1 w-fit flex flex-row gap-2 rounded-xl">
                  {dados.aberto === "disponivel" && (
                    <>
                      <img src={green} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">
                        {dados.espacos_atual} VAGAS
                      </p>
                    </>
                  )}
                  {dados.aberto === "fechado" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">FECHADO</p>
                    </>
                  )}
                  {dados.aberto === "sem vagas" && (
                    <>
                      <img src={red} alt="Espacos disponíveis" />
                      <p className="button medium text-terciaria2">SEM VAGAS</p>
                    </>
                  )}
                </div>

                {dados.tipo_reserva === "reserva" ? (
                  <h4 className="semi bold text-secundaria5 mt-4">
                    Solicitar agendamento
                  </h4>
                ) : (
                  <h4 className="semi bold text-secundaria5 mt-4">
                    Realizar Check-in
                  </h4>
                )}

                {dados.tipo_reserva === "reserva" && (
                  <div className="flex flex-row w-full mt-4">
                    {dados.aberto === "disponivel" ? (
                      <div className="w-6/12 pr-3">
                        <button
                          className={
                            reserva === "hoje"
                              ? "w-full border-2 border-primaria3 p-2 rounded-md bg-primaria3 h-full regular text-terciaria2 flex items-center justify-center"
                              : "w-full border-2 border-primaria3 p-2 rounded-md h-full bg-terciaria2 regular text-primaria3  flex items-center justify-center"
                          }
                          onClick={() => changeReserva("hoje")}
                        >
                          HOJE
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={
                        dados.aberto === "disponivel" ? "w-6/12 pl-3" : "w-full"
                      }
                    >
                      <button
                        className={
                          reserva === "futuro"
                            ? "w-full border-2 border-primaria3 p-2 rounded-md bg-primaria3 h-full regular text-terciaria2 flex items-center justify-center"
                            : "w-full border-2 border-primaria3 p-2 rounded-md h-full bg-terciaria2 regular text-primaria3  flex items-center justify-center"
                        }
                        onClick={() => changeReserva("futuro")}
                      >
                        FUTURO
                      </button>
                    </div>
                  </div>
                )}
                <div className="divisao-lp-line w-full my-6" />
                {reserva === "futuro" ? (
                  <>
                    <div className="flex flex-col justify-start items-start w-full self-start">
                      <p className="textos medium">Data</p>
                      <p
                        className="button medium self-start mt-2 text-detalhes3"
                        onClick={() => setChoiceDate(true)}
                      >
                        Escolher data
                      </p>
                      <div className="w-full mt-2">
                        {choiceDate && (
                          <Calendar
                            value={future.data}
                            minDate={tomorrow}
                            onClickDay={(e) => choiceday(e)}
                          />
                        )}
                      </div>
                      {future.set && (
                        <p className="textos medium mt-2">
                          {future.data.getDate()}/{future.data.getMonth()}/
                          {future.data.getFullYear()}
                        </p>
                      )}
                    </div>
                    {future.funcionamento && (
                      <div className="flex flex-row justify-between w-full mt-5">
                        <div
                          className={
                            future.set
                              ? "flex flex-col justify-start items-start w-6/12 pr-2 gap-2"
                              : "flex flex-col justify-start items-start w-6/12 pr-2 gap-2 pointer-events-none"
                          }
                        >
                          <p className="textos medium">De</p>
                          <InputHorario
                            id="horario-entrada"
                            value={horarios.inicio}
                            onChange={(e) =>
                              setHorarios({
                                inicio: replaceBadInputs(e.target.value, e),
                                fim: "",
                              })
                            }
                            placer="De"
                            background="terciaria2"
                            name="horarioInicio"
                          />
                        </div>
                        <div
                          className={
                            horarios.inicio
                              ? "flex flex-col justify-start items-start w-6/12 pl-2 gap-2"
                              : "flex flex-col justify-start items-start w-6/12 pl-2 gap-2 pointer-events-none"
                          }
                        >
                          <p className="textos medium">Até</p>
                          <DropDown
                            options={hours.map((hour) => {
                              let today = new Date();
                              today = future.data.getDay();
                              let index = dados.funcionamento.findIndex(
                                (x) => x.dia_codigo === today
                              );

                              let fechamento =
                                dados.funcionamento[index].hora_fechamento;

                              if (
                                parseInt(hour?.value?.substring(0, 2)) >
                                parseInt(horarios?.inicio?.substring(0, 2))
                              ) {
                                if (
                                  horarios?.inicio?.substring(3, 6) === "00"
                                ) {
                                  if (
                                    parseInt(hour?.value?.substring(0, 2)) <=
                                    parseInt(fechamento?.substring(0, 2))
                                  ) {
                                    return {
                                      value: `${hour?.value?.substring(
                                        0,
                                        2
                                      )}:${horarios?.inicio?.substring(3, 5)}`,
                                      name: `${hour?.value?.substring(
                                        0,
                                        2
                                      )}:${horarios?.inicio?.substring(3, 5)}`,
                                    };
                                  }
                                  return false;
                                } else {
                                  if (
                                    parseInt(hour?.value?.substring(0, 2)) <=
                                    parseInt(fechamento?.substring(0, 2))
                                  ) {
                                    if (
                                      parseInt(hour?.value?.substring(0, 2)) ===
                                        parseInt(fechamento?.substring(0, 2)) &&
                                      parseInt(
                                        horarios?.inicio?.substring(3, 5)
                                      ) > parseInt(fechamento?.substring(3, 5))
                                    ) {
                                      return false;
                                    } else {
                                      return {
                                        value: `${hour?.value?.substring(
                                          0,
                                          2
                                        )}:${horarios?.inicio?.substring(
                                          3,
                                          5
                                        )}`,
                                        name: `${hour?.value?.substring(
                                          0,
                                          2
                                        )}:${horarios?.inicio?.substring(
                                          3,
                                          5
                                        )}`,
                                      };
                                    }
                                  }
                                  return false;
                                }
                              }
                              return false;
                            })}
                            background="terciaria2"
                            message="Horário"
                            onChange={(e) =>
                              setHorarios({ ...horarios, fim: e.target.value })
                            }
                            value={horarios.fim}
                          />
                        </div>
                      </div>
                    )}
                    {!future.funcionamento && future.set && (
                      <p className="textos medium mt-8 self-start">
                        Nenhum horário disponível para esta data, confira os
                        horários de funcionamento do espaço.
                      </p>
                    )}
                  </>
                ) : (
                  <div className="flex flex-row justify-between w-full mt-5">
                    <div className="flex flex-col justify-start items-start w-6/12 pr-2 gap-2">
                      <p className="textos medium">De</p>
                      {/* <DropDown
                        options={hours.map((hour) => {
                          let today = new Date();
                          today = today.getDay();

                          let index = dados.funcionamento.findIndex(
                            (x) => x.dia_codigo === today
                          );

                          let abertura =
                            dados.funcionamento[index].hora_abertura;
                          let fechamento =
                            dados.funcionamento[index].hora_fechamento;

                          if (parseInt(hour.value.substring(0, 2)) >= hourNow) {
                            if (
                              parseInt(hour.value.substring(0, 2)) >
                                parseInt(abertura.substring(0, 2)) &&
                              parseInt(hour.value.substring(0, 2)) <
                                parseInt(fechamento.substring(0, 2))
                            ) {
                              return hour;
                            }
                            return false;
                          } else {
                            return false;
                          }
                        })}
                        background="terciaria2"
                        message="Horário"
                        onChange={(e) =>
                          setHorarios({ inicio: e.target.value, fim: "" })
                        }
                        value={horarios.inicio}
                      /> */}
                      {dados.tipo_reserva === "reserva" && (
                        <InputHorario
                          id="horario-entrada"
                          value={horarios.inicio}
                          onChange={(e) =>
                            setHorarios({
                              inicio: replaceBadInputs(e.target.value, e),
                              fim: "",
                            })
                          }
                          placer="De"
                          background="terciaria2"
                          name="horarioInicio"
                        />
                      )}
                      {dados.tipo_reserva === "disponibilidade" && (
                        <InputHorario
                          id="horario-entrada"
                          value={horarios.inicio}
                          onChange={(e) =>
                            setHorarios({
                              inicio: replaceBadInputs(e.target.value, e),
                              fim: "",
                            })
                          }
                          placer="De"
                          background="terciaria2"
                          name="horarioInicio"
                          block={true}
                        />
                      )}
                    </div>

                    <div
                      className={
                        horarios?.inicio?.length === 5
                          ? "flex flex-col justify-start items-start w-6/12 pl-2 gap-2"
                          : "flex flex-col justify-start items-start w-6/12 pl-2 gap-2 pointer-events-none"
                      }
                    >
                      <p className="textos medium">Até</p>
                      <DropDown
                        options={hours.map((hour) => {
                          let today = new Date();
                          today = today.getDay();
                          let index = dados.funcionamento.findIndex(
                            (x) => x.dia_codigo === today
                          );

                          let fechamento =
                            dados.funcionamento[index].hora_fechamento;

                          if (
                            parseInt(hour?.value?.substring(0, 2)) >
                            parseInt(horarios?.inicio?.substring(0, 2))
                          ) {
                            if (horarios?.inicio?.substring(3, 6) === "00") {
                              if (
                                parseInt(hour?.value?.substring(0, 2)) <=
                                parseInt(fechamento?.substring(0, 2))
                              ) {
                                return {
                                  value: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                  name: `${hour?.value?.substring(
                                    0,
                                    2
                                  )}:${horarios?.inicio?.substring(3, 5)}`,
                                };
                              }
                              return false;
                            } else {
                              if (
                                parseInt(hour?.value?.substring(0, 2)) <=
                                parseInt(fechamento?.substring(0, 2))
                              ) {
                                if (
                                  parseInt(hour?.value?.substring(0, 2)) ===
                                    parseInt(fechamento?.substring(0, 2)) &&
                                  parseInt(horarios?.inicio?.substring(3, 5)) >
                                    parseInt(fechamento?.substring(3, 5))
                                ) {
                                  return false;
                                } else {
                                  return {
                                    value: `${hour?.value?.substring(
                                      0,
                                      2
                                    )}:${horarios?.inicio?.substring(3, 5)}`,
                                    name: `${hour?.value?.substring(
                                      0,
                                      2
                                    )}:${horarios?.inicio?.substring(3, 5)}`,
                                  };
                                }
                              }
                              return false;
                            }
                          }
                          return false;
                        })}
                        background={
                          horarios?.inicio?.length === 5
                            ? "terciaria2"
                            : "secundaria3"
                        }
                        message="Horário"
                        onChange={(e) =>
                          setHorarios({ ...horarios, fim: e.target.value })
                        }
                        value={horarios.fim}
                      />
                    </div>
                  </div>
                )}

                <div className="w-full mt-[60px]">
                  <ButtonGradient
                    legend="SOLICITAR AGENDAMENTO"
                    func={() => reservarToday()}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-center max-w-[400px] gap-3 mt-4 w-full">
                <h4 className="semi bold text-primaria4 self-start">
                  Avaliações
                </h4>
                {dados.avaliacoes_textos.length !== 0 ? (
                  dados.avaliacoes_textos.map((avaliacao) => {
                    return (
                      <div className="w-full bg-terciaria2 rounded-md px-3 py-2 gap-5 mt-4 flex items-start justify-around flex-col">
                        <div className="flex flex-row gap-2 items-center justify-center w-fit">
                          <p className="textos semibold pt-1">
                            {avaliacao.nota}
                          </p>
                          <img src={starPurple} alt="estrela" className="w-4" />
                        </div>
                        <p className="text-ellipsis overflow-hidden argentum-regular max-h-[190px]">
                          {avaliacao.texto}
                        </p>
                        <div className="flex flex-row gap-2 items-center justify-between text-primaria4 w-full">
                          <p className="textos semibold">{avaliacao.nome}</p>
                          <p className="argentum-regular">
                            Avaliação verificada
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="textos regular w-6/12 text-secundaria5">
                    Nenhuma avaliação
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {!load && (
        <section className="min-h-screen bg-terciariafundo w-full"></section>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </>
  );
};

export default ReservarEspaco;
