import axiosInstance from "../AxiosInstance";

const BuscarCoworking = async (
  hoje,
  capacidade,
  avaliacoes,
  tipo,
  buscar,
  page
) => {
  return new Promise(function (resolve, reject) {
    if (!page) {
      axiosInstance
        .get(
          `colaboradores/listar-coworkings/?hoje=${hoje}&capacidade=${capacidade}&avaliacoes=${avaliacoes}&tipo=${tipo}&buscar=${buscar}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      axiosInstance
        .get(
          `colaboradores/listar-coworkings/?hoje=${hoje}&capacidade=${capacidade}&avaliacoes=${avaliacoes}&tipo=${tipo}&buscar=${buscar}&page=${page}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export default BuscarCoworking;
