import axiosInstance from "../AxiosInstance";

const CheckReserve = async (check, uuid, tipo_reserva) => {
  return new Promise(function (resolve, reject) {
    if (check === "check-in") {
      axiosInstance
        .post(`colaboradores/check-reserva/`, {
          check: "check-in",
          reserva_uuid: uuid,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      axiosInstance
        .post(`colaboradores/check-reserva/`, {
          check: "check-out",
          reserva_uuid: uuid,
          tipo_reserva: tipo_reserva,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export default CheckReserve;
