import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import maskDoc from "../../utils/maskDoc";
import ModalAlert from "../ModalAlert";
import validateCNPJ from "../../utils/validateCNPJ";
import checkValidate from "../../assets/check-validate.svg";

const EmpresaData = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    razao_social: "",
    cnpj: "",
  });

  const [step, setStep] = useState("preenchendo");

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();
    let cnpj = validateCNPJ(e.target.cnpj.value);
    if (inputs.cnpj === "" || inputs.razao_social === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    }

    if (!cnpj) {
      setModal({
        text: "CNPJ inválido!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      localStorage.setItem(
        "empresa-information-register",
        JSON.stringify(inputs)
      );
      setStep("preenchido");
    }
  };

  const handleFinish = () => {
    if (complete.telefone) {
      funcChangeStep("password", "", "data");
      setTimeout(() => {
        setStep("preenchendo");
      }, 200);

      return;
    } else {
      funcChangeStep("telefone", "", "data");
      setTimeout(() => {
        setStep("preenchendo");
      }, 200);
    }
    return;
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 lg:flex lg:items-start lg:justify-center lg:flex-col">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "preenchendo" && (
        <>
          <p className="body medium text-primaria4">Preencha seus dados</p>
          <p className="button regular text-secundaria4 lg:mb-4">
            Preencha a razão social e o CNPJ da sua empresa.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex flex-col mt-4"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <Input
              background="terciaria2"
              placer="Razão social"
              name="razao_social"
              value={inputs.razao_social}
              onChange={(e) =>
                setInputs({ ...inputs, razao_social: e.target.value })
              }
            />
            <Input
              background="terciaria2"
              placer="CNPJ"
              name="cnpj"
              value={inputs.cnpj}
              onChange={(e) =>
                setInputs({ ...inputs, cnpj: maskDoc(e.target.value, "cnpj") })
              }
              maxLength={18}
            />
            <div className="w-full flex items-center justify-center px-6 mt-8 h-10">
              <ButtonDark legend="CONFIRMAR" />
            </div>
          </form>
        </>
      )}
      {step === "preenchido" && (
        <div className="flex items-center justify-center flex-col gap-2">
          <img src={checkValidate} alt="E-mail validado com sucesso" />
          <p className="body medium text-primaria4">Dados registrados!</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2 text-center">
            Esses dados, serão úteis para a sua identidade na plataforma.
          </p>
          <div className="w-10/12 flex items-center justify-center h-11">
            <ButtonDark
              legend="CONTINUAR CADASTRO"
              func={() => handleFinish()}
              id="finaliza-preenchimento-dados"
            />
          </div>
        </div>
      )}
      {!complete.email && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("email", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default EmpresaData;
