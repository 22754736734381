import axiosInstance from "../AxiosInstance";

const FavoriteCoworking = async (uuid) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`colaboradores/favoritos/${uuid}/coworking`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default FavoriteCoworking;
