import axiosInstance from "../AxiosInstance";

const BuscarCoworkingProximos = async (
  hoje,
  capacidade,
  avaliacoes,
  latitude,
  longitude,
  tipo,
  page
) => {
  return new Promise(function (resolve, reject) {
    if (!page) {
      axiosInstance
        .get(
          `colaboradores/coworkings-proximos/?hoje=${hoje}&capacidade=${capacidade}&avaliacoes=${avaliacoes}&latitude=${latitude}&longitude=${longitude}&tipo=${tipo}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      axiosInstance
        .get(
          `colaboradores/coworkings-proximos/?hoje=${hoje}&capacidade=${capacidade}&avaliacoes=${avaliacoes}&latitude=${latitude}&longitude=${longitude}&tipo=${tipo}&page=${page}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export default BuscarCoworkingProximos;
