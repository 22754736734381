import axiosInstance from "../AxiosInstance";

const VerifyEmailCompany = async (email) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`auth/empresa/envia-token-cadastro-empresa/`, {
        email: email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default VerifyEmailCompany;
