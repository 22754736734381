import axiosInstance from "../AxiosInstance";

const VerifyEmailParceiro = async (email) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`auth/parceiro/envia-token-cadastro-parceiro/`, {
        email: email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default VerifyEmailParceiro;
