import React from "react";
import ButtonDark from "../ButtonDark";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import PasswordInput from "../passwordInput";
import RegisterColab from "../../services/Colaborador/RegisterColab";
import { useNavigate } from "react-router-dom";
import checkValidate from "../../assets/check-validate.svg";
const ColabPassword = (props) => {
  let { funcChangeStep, complete } = props;
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    senha: "",
    confirmarSenha: "",
  });

  const [pass, setPass] = useState(true);

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [step, setStep] = useState("validando");

  const funcConfirmBtn = (e) => {
    e.preventDefault();

    if (inputs.senha === "" || inputs.confirmarSenha === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    } else if (inputs.senha !== inputs.confirmarSenha) {
      setModal({
        text: "As senhas não conferem!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      const dadosColab = localStorage.getItem("colab-information-register")
        ? JSON.parse(localStorage.getItem("colab-information-register"))
        : null;

      const enderecoColab = localStorage.getItem("colab-address-register")
        ? JSON.parse(localStorage.getItem("colab-address-register"))
        : null;

      let cpf = dadosColab.cpf;
      cpf = cpf.replace(/[^\d]+/g, "");

      RegisterColab({
        email: dadosColab.email.toLowerCase(),
        password1: inputs.senha,
        password2: inputs.confirmarSenha,
        nome: dadosColab.name,
        cpf: cpf,
        telefone: dadosColab.telefone,
        endereco: {
          bairro: enderecoColab.bairro,
          cep: enderecoColab.cep,
          estado: enderecoColab.estado,
          logradouro: enderecoColab.logradouro,
          municipio: enderecoColab.municipio,
          numero: enderecoColab.numero,
        },
      })
        .then((response) => {
          setStep("validado");
          setModal({
            text: response.message,
            alertType: "ok",
            active: true,
          });
        })
        .catch((e) => {
          setModal({
            text: e.message,
            alertType: "fail",
            active: true,
          });
        });

      return;
    }
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "validando" && (
        <>
          <p className="body medium text-primaria4">Preencha seus dados</p>
          <p className="button regular text-secundaria4">
            Preencha seu nome completo e seu CPF
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-8 flex-wrap"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <div className="h-12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Senha"
                name="senha"
                value={inputs.senha}
                onChange={(e) =>
                  setInputs({ ...inputs, senha: e.target.value })
                }
                pass={pass}
                setPass={setPass}
              />
            </div>
            <div className="h-12 w-full">
              <PasswordInput
                background="terciaria2"
                placer="Confirmar Senha"
                name="confirmarSenha"
                value={inputs.confirmarSenha}
                pass={pass}
                setPass={setPass}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    confirmarSenha: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-full flex items-center justify-center px-6 mt-4 h-10">
              <ButtonDark legend="CONFIRMAR" />
            </div>
          </form>
          {!complete.password && (
            <p
              className="button bold self-center text-center mt-4 cursor-pointer"
              onClick={() => funcChangeStep("password", "", "")}
            >
              Voltar
            </p>
          )}
        </>
      )}
      {step === "validado" && (
        <div className="flex items-center justify-center flex-col gap-2 h-full pb-12">
          <img src={checkValidate} alt="E-mail validado com sucesso" />
          <p className="body medium text-primaria4">Cadastro realizado!</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2 text-center">
            Faça seu login e começe a usufruir de todos os seus benefícios!
          </p>
          <p
            className="button bold self-center text-center mt-4 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Acessar conta
          </p>
        </div>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default ColabPassword;
