import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ButtonGradient from "../../components/ButtonGradient";
import EmpresaData from "../../components/Register/EmpresaData";
import EmpresaEmail from "../../components/Register/EmpresaEmail";
import EmpresaPassword from "../../components/Register/EmpresaPassword";
import EmpresaPhone from "../../components/Register/EmpresaPhone";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";

const CadastroEmpresa = () => {
  const [toDo, setToDo] = useState("email");
  const [doing, setDoing] = useState("");
  const [complete, setComplete] = useState({
    email: false,
    data: false,
    telefone: false,
    password: false,
  });

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Cadastro empresa");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  const funcChangeStep = (todo, doing, comp) => {
    setToDo(todo);
    setDoing(doing);
    switch (comp) {
      case "email":
        setComplete({ ...complete, email: true });
        break;

      case "data":
        setComplete({ ...complete, data: true });
        break;

      case "telefone":
        setComplete({ ...complete, telefone: true });
        break;

      case "password":
        setComplete({ ...complete, password: true });
        break;

      default:
        setComplete({ ...complete });
        break;
    }
  };

  return (
    <section className="px-4 py-6 bg-terciariafundo transition-all lg:flex lg:flex-row lg:items-start lg:justify-center min-h-screen">
      <div className="lg:w-9/12 lg:rounded-2xl lg:py-12 lg:flex lg:flex-row lg:items-center lg:justify-center lg:px-4 lg:mb-16">
        <div className="lg:w-6/12 lg:bg-terciaria2 lg:p-8 lg:h-[500px] lg:rounded-xl">
          <p className="subtitle bold text-primaria5">Dados da empresa</p>
          <p className="textos regular text-secundaria4 mt-1">
            Valide os dados para que ninguém possa acessar ou criar uma conta no
            nome da sua empresa.
          </p>
          <div
            className={
              doing !== ""
                ? " bg-terciaria2 p-4 rounded-xl mt-6 opacity-30 lg:w-full pointer-events-none"
                : " bg-terciaria2 p-4 rounded-xl mt-6 opacity-100 lg:w-full"
            }
          >
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pb-5 gap-1">
              <div>
                <p
                  className={
                    complete.email
                      ? "body medium text-detalhes4"
                      : "body medium text-primaria4"
                  }
                >
                  Validar e-mail
                </p>
                {!complete.email && (
                  <p className="button regular text-secundaria4">
                    Ele será usado para acessar a sua conta.
                  </p>
                )}
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "email" && !complete.email && (
                  <ButtonGradient
                    legend="Validar"
                    func={() => funcChangeStep("", "email", "")}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pt-5 pb-5 gap-1">
              <div>
                <p
                  className={
                    complete.data
                      ? "body medium text-detalhes4"
                      : "body medium text-primaria4"
                  }
                >
                  Confirme a sua identidade
                </p>
                {!complete.data && (
                  <p className="button regular text-secundaria4">
                    Ninguém poderá criar uma conta no seu nome.
                  </p>
                )}
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "data" && !complete.data && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "data", "")}
                  />
                )}
                {complete.data && (
                  <p
                    className="button medium flex items-center justify-center text-secundaria5 cursor-pointer"
                    onClick={() => funcChangeStep("", "data", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pt-5 pb-5 gap-8">
              <div>
                <p
                  className={
                    complete.telefone
                      ? "body medium text-detalhes4"
                      : "body medium text-primaria4"
                  }
                >
                  Telefone
                </p>
                {!complete.telefone && (
                  <p className="button regular text-secundaria4">
                    Será utilizado a seu favor na plataforma
                  </p>
                )}
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "telefone" && !complete.telefone && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "telefone", "")}
                  />
                )}
                {complete.telefone && (
                  <p
                    className="button medium flex items-center justify-center text-secundaria5 cursor-pointer"
                    onClick={() => funcChangeStep("", "telefone", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pt-5 pb-5 gap-8">
              <div>
                <p
                  className={
                    complete.password
                      ? "body medium text-detalhes4"
                      : "body medium text-primaria4"
                  }
                >
                  Criar senha
                </p>
                {!complete.password && (
                  <p className="button regular text-secundaria4">
                    Ele será usado por segurança.
                  </p>
                )}
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "password" && !complete.password && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "password", "")}
                  />
                )}
                {complete.password && (
                  <p
                    className="button medium flex items-center justify-center text-secundaria5 cursor-pointer"
                    onClick={() => funcChangeStep("", "passoword", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            doing === "email"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-3 lg:w-6/12 lg:mt-0 lg:ml-4"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <EmpresaEmail funcChangeStep={funcChangeStep} complete={complete} />
        </div>
        <div
          className={
            doing === "data"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-3 lg:w-6/12 lg:mt-0 lg:ml-4"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <EmpresaData funcChangeStep={funcChangeStep} complete={complete} />
        </div>
        <div
          className={
            doing === "telefone"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-6 lg:w-6/12 lg:ml-4 lg:mt-0"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <EmpresaPhone funcChangeStep={funcChangeStep} complete={complete} />
        </div>
        <div
          className={
            doing === "password"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-6 lg:w-6/12 lg:ml-4 lg:mt-0"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <EmpresaPassword
            funcChangeStep={funcChangeStep}
            complete={complete}
          />
        </div>
      </div>
    </section>
  );
};

export default CadastroEmpresa;
