import axiosInstance from "../AxiosInstance";

const FilterColabs = async (user, page, search, status) => {
  return new Promise(function (resolve, reject) {
    if (!page) {
      if (status) {
        axiosInstance
          .get(
            `/empresas/listar-colaborador-filtro/?search=${search}&status=${status}`
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject({
              message: error.response.data,
              status: 400,
            });
          });
      } else {
        axiosInstance
          .get(`/empresas/listar-colaborador-filtro/?search=${search}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject({
              message: error.response.data,
              status: 400,
            });
          });
      }
    } else {
      if (status) {
        axiosInstance
          .get(
            `/empresas/listar-colaborador-filtro/?search=${search}&page=${page}&status=${status}`
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject({
              message: error.response.data,
              status: 400,
            });
          });
      } else {
        axiosInstance
          .get(
            `/empresas/listar-colaborador-filtro/?search=${search}&page=${page}`
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject({
              message: error.response.data,
              status: 400,
            });
          });
      }
    }
  });
};

export default FilterColabs;
