import React, { useContext } from "react";
// import Login from "../screens/login2";
import { Routes, Route } from "react-router-dom";
import AuthContext from "../hooks/AuthContext";
import AlterarSenha from "../screens/AlterarSenha";
import Colaboradores from "../screens/Empresa/Colaboradores";
import PerfilColab from "../screens/Colaborador/PerfilColab";
import CancelPlan from "../screens/Empresa/CancelPlan";
import PerfilEmpresa from "../screens/Empresa/PerfilEmpresa";
import AdicionarColaboradores from "../screens/Empresa/AdicionarColaboradores";
import Reservas from "../screens/Colaborador/Reservas";
import ReservasParceiro from "../screens/Coworking/Reservas";
import BuscarEspaco from "../screens/Colaborador/BuscarEspaco";
import PerfilParceiro from "../screens/Coworking/PerfilParceiro";
import Token from "../screens/Coworking/Token";
import Solicitacoes from "../screens/Coworking/Solicitacoes";
import Relatorios from "../screens/Coworking/Relatorios";
import Espacos from "../screens/Coworking/Espacos";
import EditarEspaco from "../screens/Coworking/EditarEspaco";
import ReservarEspaco from "../screens/Colaborador/ReservarEspaco";

const PrivateRoutes = () => {
  const { user } = useContext(AuthContext);

  return user?.type ? (
    <>
      <Routes>
        {user?.type === "Colaborador" && (
          <>
            <Route path="/colaborador/perfil" element={<PerfilColab />} />
            <Route
              path="/colaborador/alterar-senha"
              element={<AlterarSenha />}
            />
            <Route path="/colaborador/reservas" element={<Reservas />} />
            <Route path="/buscar-espaco" element={<BuscarEspaco />} />
            <Route path="/parceiro/espaco/:id" element={<ReservarEspaco />} />
          </>
        )}
        {user?.type === "Empresa" && (
          <>
            <Route path="/empresa/perfil" element={<PerfilEmpresa />}></Route>
            <Route
              path="/empresa/colaboradores"
              element={<Colaboradores />}
            ></Route>
            <Route
              path="/empresa/perfil/cancelar-plano"
              element={<CancelPlan />}
            />
            <Route path="/empresa/alterar-senha" element={<AlterarSenha />} />
            <Route
              path="/empresa/adicionar-colaboradores"
              element={<AdicionarColaboradores />}
            />
            <Route path="/parceiro/espaco/:id" element={<ReservarEspaco />} />
          </>
        )}
        {user.type === "Coworking" && (
          <>
            <Route path="/parceiro/perfil" element={<PerfilParceiro />} />
            <Route path="/parceiro/token" element={<Token />} />
            <Route path="/parceiro/solicitacoes" element={<Solicitacoes />} />
            <Route path="/parceiro/reservas" element={<ReservasParceiro />} />
            <Route path="/parceiro/relatorios" element={<Relatorios />} />
            <Route path="/parceiro/espacos" element={<Espacos />} />
            <Route path="/parceiro/editar-espaco" element={<EditarEspaco />} />
            <Route path="/parceiro/espaco/:id" element={<ReservarEspaco />} />
            <Route path="/parceiro/alterar-senha" element={<AlterarSenha />} />
          </>
        )}
      </Routes>
    </>
  ) : (
    <></>
  );
};

export default PrivateRoutes;
