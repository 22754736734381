import React, { useEffect, useState } from "react";
import ListarReservas from "../../services/Parceiro/ListarReservas";
import getParamsFromUrl from "../../utils/getParamsFromUrl";
import calendar from "../../assets/calendarreservas.svg";
import CancelarReserva from "../../services/Parceiro/CancelarReserva";

const Reservas = () => {
  const [nextPage, setNextPage] = useState("");
  const [load, setLoad] = useState(false);
  const [reservas, setReservas] = useState([]);
  const [now, setNow] = useState();

  useEffect(() => {
    GetReservas();
    // eslint-disable-next-line
  }, []);

  const addItens = async (arr, itens) => {
    return new Promise(function (resolve, reject) {
      itens.map((iten) => {
        let newDate = new Date(iten.hora_inicio);
        iten.hora_inicio = newDate;
        let newDate2 = new Date(iten.hora_fim);
        iten.hora_fim = newDate2;
        arr.push(iten);
        return true;
      });
      resolve(arr);
    });
  };

  const GetReservas = () => {
    let newDate = new Date();
    ListarReservas()
      .then((resp) => {
        let resultados = resp.data.results;
        let newArr = [];
        if (resp.data.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }
        addItens(newArr, resultados).then((resp) => {
          setReservas(resp);
          setNow(newDate);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSeeMore = () => {
    ListarReservas(nextPage)
      .then((resp) => {
        if (resp?.data?.next) {
          setNextPage(getParamsFromUrl("page", resp?.data?.next));
        } else {
          setNextPage("");
        }
        let resultados = resp.data.results;
        let newArr = reservas;
        addItens(newArr, resultados).then((resp) => {
          setReservas(resp);
          setNextPage(resp?.data?.next);
          setLoad(true);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = (uuid, hora) => {
    let newDate = new Date();
    let tipo = hora - newDate <= 0 ? "Atraso" : "Cancelar";
    if (tipo === "Atraso") {
      CancelarReserva({ uuid: uuid, tipo: "Atraso" })
        .then((resp) => {
          GetReservas();
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (tipo === "Cancelar") {
      CancelarReserva({ uuid: uuid, tipo: "Cancelar" })
        .then((resp) => {
          GetReservas();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <section className="p-6 lg:p-4 flex items-center justify-start bg-terciariafundo min-h-screen flex-col">
      <div className="bg-terciariafundo rounded-xl shadow-shadowMin pt-4 pb-12 px-4 w-full lg:w-10/12 my-4 flex items-center justify-center flex-col">
        <div className="flex flex-row gap-3 items-center justify-start pb-2 border-b border-secundaria3 border-opacity-25 w-full">
          <img src={calendar} alt="check-in" />
          <p className="textos semibold text-secundaria4 pt-1">RESERVAS</p>
        </div>
        <div className="lg:py-8 py-4 flex lg:flex-row flex-col items-center justify-center lg:flex-wrap gap-8 w-full">
          {load &&
            reservas.map((reserva) => {
              return (
                <div
                  key={reserva.uuid}
                  className="bg-terciaria2 gap-4 lg:border border-secundaria2 rounded-t-lg rounded-b-lg border flex flex-col py-4 lg:w-5/12 lg:px-0 px-4"
                >
                  <p className="textos semibold text-secundaria4 lg:pl-6">
                    Dados da reserva
                  </p>
                  <div className="w-full flex items-center justify-center flex-col gap-4 lg:flex-row lg:flex-wrap">
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {reserva.nome_colaborador}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg lg:w-5/12 text-ellipsis">
                      <p className="textos regular text-secundaria3 text-ellipsis overflow-hidden">
                        {reserva.email}
                      </p>
                    </div>
                    <div className="w-full border border-secundaria2 bg-terciaria2 px-4 py-2 flex items-center justify-center rounded-lg lg:w-5/12">
                      <p className="textos regular text-secundaria3">
                        {reserva.data_agendamento}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full lg:w-5/12">
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {reserva.hora_inicio.getHours()}:
                          {reserva.hora_inicio.getMinutes()}h
                        </p>
                      </div>
                      <p className="textos regular text-secundaria3">às</p>
                      <div className="w-5/12 border border-secundaria2 bg-terciaria2 px-2 py-2 flex items-center justify-center rounded-lg">
                        <p className="textos regular text-secundaria3">
                          {reserva.hora_fim.getHours()}:
                          {reserva.hora_fim.getMinutes()}h
                        </p>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 mt-8 flex items-center flex-col gap-4 text-center">
                      <div className="w-full border-2 rounded-md px-2 py-2 border-primaria4">
                        {reserva.status === "Confirmada" && (
                          <p className="button medium text-primaria4 select-none">
                            CHECK-IN NÃO REALIZADO
                          </p>
                        )}
                        {reserva.status === "Ativa" && (
                          <p className="button medium text-primaria4 select-none">
                            RESERVA ATIVA
                          </p>
                        )}
                      </div>
                      {reserva.hora_inicio - now <= 0 ? (
                        <p
                          className="textos semibold text-secundaria4 cursor-pointer"
                          onClick={() =>
                            handleCancel(reserva.uuid, reserva.hora_inicio)
                          }
                        >
                          Cancelar por atraso
                        </p>
                      ) : (
                        <p
                          className="textos semibold text-secundaria4 cursor-pointer"
                          onClick={() =>
                            handleCancel(reserva.uuid, reserva.hora_inicio)
                          }
                        >
                          Cancelar reserva
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {load && reservas.length === 0 && (
            <p className="textos semibold text-secundaria4 mt-8">
              Não há reservas pendentes.
            </p>
          )}
          {nextPage && (
            <p
              className="textos semibold text-secundaria4 mt-2"
              onClick={() => handleSeeMore()}
            >
              Ver mais
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Reservas;
