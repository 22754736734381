import React from "react";
import ButtonDark from "../ButtonDark";
import Input from "../Input";
import downArrow2 from "../../assets/downArrow2.svg";
import { useState } from "react";
import ModalAlert from "../ModalAlert";
import checkValidate from "../../assets/check-validate.svg";

const EmpresaPhone = (props) => {
  let { funcChangeStep, complete } = props;

  const [inputs, setInputs] = useState({
    telefone: "",
  });

  const [step, setStep] = useState("preenchendo");

  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const funcConfirmBtn = (e) => {
    e.preventDefault();

    if (inputs.telefone === "") {
      setModal({
        text: "Preencha todos os campos!",
        alertType: "fail",
        active: true,
      });
      return;
    } else if (inputs.telefone.length < 10 || inputs.telefone.length > 11) {
      setModal({
        text: "Telefone inválido, tente novamente!",
        alertType: "fail",
        active: true,
      });
      return;
    } else {
      localStorage.setItem("empresa-telefone", JSON.stringify(inputs));
      setStep("preenchido");
      return;
    }
  };

  const handleFinish = () => {
    funcChangeStep("password", "", "telefone");
    setTimeout(() => {
      setStep("preenchendo");
    }, 200);
  };

  return (
    <div className="px-4 py-8 bg-terciaria2 rounded-xl relative lg:h-full lg:px-16 flex flex-col justify-center">
      <div className="rounded-full w-12 h-12 shadow-xl flex items-center justify-center p-4 absolute -top-2 left-[50%] translate-x-[-50%] lg:-left-2 lg:top-[50%] lg:-rotate-90 translate-y-[-50%] bg-terciaria2">
        <img src={downArrow2} alt="" />
      </div>
      {step === "preenchendo" && (
        <>
          <p className="body medium text-primaria4">
            Digite seu telefone (DDD + Número)
          </p>
          <p className="button regular text-secundaria4 pt-2 pb-2">
            Este será usado para contato direto com a unidade do coworking.
          </p>
          <form
            className="w-full px-1 items-center justify-center gap-4 flex mt-4 flex-wrap"
            onSubmit={(e) => funcConfirmBtn(e)}
          >
            <Input
              background="terciaria2"
              placer="Telefone"
              name="telefone"
              value={inputs.telefone}
              onChange={(e) =>
                setInputs({ ...inputs, telefone: e.target.value })
              }
              tipo={"number"}
              maxLength={9}
            />
            <div className="w-full flex items-center justify-center px-6 mt-4 h-10">
              <ButtonDark legend="CONFIRMAR" />
            </div>
          </form>
        </>
      )}
      {step === "preenchido" && (
        <div className="flex items-center justify-center flex-col gap-2">
          <img src={checkValidate} alt="E-mail validado com sucesso" />
          <p className="body medium text-primaria4">Dados registrados!</p>
          <p className="button regular text-secundaria4 lg:mb-4 mb-6 mt-2 text-center">
            Esses dados, serão úteis para a sua identidade na plataforma.
          </p>
          <div className="w-10/12 flex items-center justify-center h-11">
            <ButtonDark
              legend="CONTINUAR CADASTRO"
              func={handleFinish}
              id="finaliza-preenchimento-dados"
            />
          </div>
        </div>
      )}
      {!complete.address && (
        <p
          className="button bold self-center text-center mt-4 cursor-pointer"
          onClick={() => funcChangeStep("address", "", "")}
        >
          Voltar
        </p>
      )}
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default EmpresaPhone;
