import axiosInstance from "../AxiosInstance";

const VerifyEmailColab = async (email, cpf) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`auth/colaborador/envia-token-cadastro-colaborador/`, {
        email: email,
        cpf: cpf,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default VerifyEmailColab;
