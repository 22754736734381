import axiosInstance from "../AxiosInstance";

const AddColabs = async (colaboradores, empresa_uuid) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`empresas/adicionar-colaboradores-empresa/`, {
        colaboradores: colaboradores,
        empresa: empresa_uuid,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({
          message: error.response.data,
          status: 400,
        });
      });
  });
};

export default AddColabs;
