import React from "react";

const InputFixed = (props) => {
  let { placer, background, tipo, name, value, onChange, maxLength, id } =
    props;

  const bckg = background ? "bg-" + background : "bg-terciaria1";
  const edt = "pointer-events-none ";
  const classe =
    "w-full relative justify-center rounded-lg border-secundaria2 border h-10 pl-5 pr-4 items-center placeholder:text-secundaria3 text-ellipsis text-secundaria3 " +
    edt +
    bckg;

  return (
    <div className="w-full relative">
      <input
        id={id}
        className={classe}
        placeholder={placer}
        type={tipo ? tipo : "text"}
        name={name}
        onChange={onChange}
        value={value}
        maxLength={maxLength ? maxLength : ""}
      ></input>
    </div>
  );
};

export default InputFixed;
