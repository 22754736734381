import React, { useState, useEffect, useContext } from "react";
import principal from "../../assets/principal.mp4";
import coworking from "../../assets/Coworking.svg";
import empresas from "../../assets/Empresas.svg";
import pessoas from "../../assets/Pessoas.svg";
import coworkingBig from "../../assets/CoworkingNewB.svg";
import empresasBig from "../../assets/empresasNewB.svg";
import pessoasBig from "../../assets/pessoasNewB.svg";
import Replace from "../../assets/Replace.svg";
import empresaImage from "../../assets/empresaImage.svg";
import colabImage from "../../assets/colabImage.svg";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";
import FormEmpresa from "../../services/LadingPage/FormEmpresa";
import ModalAlert from "../../components/ModalAlert";
import noticia1 from "../../assets/forbesMulheres.jpg";
import noticia2 from "../../assets/noticia2.jpg";
import noticia4 from "../../assets/noticia4.jpg";
import noticia5 from "../../assets/noticia5.jpg";
import noticia3 from "../../assets/noticia3.webp";
import noticia6 from "../../assets/noticia6.webp";
import purple from "../../assets/purple.svg";
import { HashLink as Link } from "react-router-hash-link";

const Home = () => {
  const [modal, setModal] = useState({
    text: "",
    alertType: "",
    active: false,
  });

  const [notice, setNotice] = useState(0);

  const changeNotice = (action) => {
    if (action === "up") {
      if (notice !== noticias.length - 1) {
        setNotice(notice + 1);
      } else {
        setNotice(0);
      }
    } else {
      if (notice !== 0) {
        setNotice(notice - 1);
      } else {
        setNotice(noticias.length - 1);
      }
    }
  };

  const noticias = [
    {
      img_url: noticia1,
      noticia_url:
        "https://forbes.com.br/forbes-mulher/2023/03/4-dicas-para-mulheres-tirarem-o-melhor-do-trabalho-hibrido/",
      titulo: "4 dicas para mulheres tirarem o melhor do trabalho híbrido",
      resumo:
        "Mulheres apontam a possibilidade de trabalho híbrido como um dos principais motivos para permanecer em uma empresa. Veja como ter mais sucesso nessa modalidade.",
      index: 0,
    },
    {
      img_url: noticia2,
      noticia_url:
        "https://forbes.com.br/carreira/2023/03/por-que-ter-flexibilidade-e-algo-nao-negociavel-para-os-trabalhadores/",
      titulo:
        "Trabalho flexível é inegociável para profissionais, mostra pesquisa",
      resumo:
        "Pesquisa mostra que dois terços dos trabalhadores buscariam outro emprego se a possibilidade de trabalhar de casa fosse retirada deles",
      index: 1,
    },
    {
      img_url: noticia3,
      noticia_url:
        "https://vocerh.abril.com.br/futurodotrabalho/liderancas-sao-resistentes-ao-trabalho-remoto/",
      titulo: "Maioria acha que lideranças são resistentes ao trabalho remoto",
      resumo:
        "Segundo estudo da Mercer Brasil, 61% dos RHs entrevistados dizem que os gestores não aceitam bem os modelos flexíveis",
      index: 2,
    },
    {
      img_url: noticia4,
      noticia_url:
        "https://forbes.com.br/carreira/2023/02/trabalho-flexivel-e-essencial-para-atrair-talentos-diz-pesquisa/",
      titulo:
        "Trabalho flexível é essencial para atrair talentos, diz pesquisa",
      resumo:
        "Levantamento da consultoria Robert Half mostra que trabalho híbrido é determinante para atrair e reter profissionais",
      index: 3,
    },
    {
      img_url: noticia5,
      noticia_url:
        "https://vocerh.abril.com.br/politicasepraticas/mercado-pago-adota-hibrido-flexivel-funcionario-escolhe/",
      titulo: "Mercado Pago adota híbrido flexível: funcionário escolhe",
      resumo:
        "Profissionais do administrativo decidem a frequência da ida ao escritório e podem atuar por 90 dias a partir de outro país",
      index: 4,
    },
    {
      img_url: noticia6,
      noticia_url:
        "https://vocerh.abril.com.br/politicasepraticas/profissionais-repensam-o-sentido-do-trabalho-e-buscam-flexibilidade/",
      titulo:
        "Profissionais repensam o sentido do trabalho e buscam flexibilidade",
      resumo:
        "A rotina de dedicação exaustiva à empresa deu lugar à busca por mais flexibilidade, desenvolvimento e propósito. Saiba como atender a esses anseios",
      index: 5,
    },
  ];

  const [change, setChange] = useState("colaborador");
  let { changeMenuLogged } = useContext(LoggedHeaderContext);
  const [inputs, setInputs] = useState({
    nome_empresa: "",
    email: "",
    telefone: "",
    colaboradores: "",
    visitas: "",
  });

  const [calc, setCalc] = useState({
    mes: {
      tempo_deslocamento: 0,
      km_deslocamento: 0,
      taxa_carbono: 0,
      custo_espaco: 0,
    },
    ano: {
      tempo_deslocamento: 0,
      km_deslocamento: 0,
      taxa_carbono: 0,
      custo_espaco: 0,
    },
  });

  const [colabs, setColabs] = useState("");

  const sendForm = (e) => {
    e.preventDefault();
    FormEmpresa({
      nome_empresa: e.target.nome_empresa.value,
      email: e.target.email.value,
      telefone: e.target.telefone.value,
      colaboradores: e.target.colaboradores.value,
      visitas: e.target.visitas.value,
    })
      .then((resp) => {
        setModal({
          text: "Formulário enviado com sucesso!",
          alertType: "ok",
          active: true,
        });
        setInputs({
          nome_empresa: "",
          email: "",
          telefone: "",
          colaboradores: "",
          visitas: "",
        });
      })
      .catch((e) => {
        setModal({
          text: e.response.data.message,
          alertType: "fail",
          active: true,
        });
      });
  };

  const changeColabs = (valor) => {
    let num = "";

    if (isNaN(valor)) {
      setColabs("");
      return;
    }

    num = parseInt(valor);

    if (isNaN(num)) {
      setColabs("");
      return;
    }

    if (num <= 9) {
      setCalc({
        mes: {
          custo_espaco: num * 556,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 556 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num > 9 && num <= 49) {
      setCalc({
        mes: {
          custo_espaco: num * 772,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 772 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num >= 50 && num <= 249) {
      setCalc({
        mes: {
          custo_espaco: num * 939,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 939 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    } else if (num > 249) {
      setCalc({
        mes: {
          custo_espaco: num * 1221,
          taxa_carbono: num * 0.5,
          km_deslocamento: num * 5,
          tempo_deslocamento: (num * 120) / 60,
        },
        ano: {
          custo_espaco: num * 1221 * 12,
          taxa_carbono: num * 0.5 * 12,
          km_deslocamento: num * 5 * 12,
          tempo_deslocamento: ((num * 120) / 60) * 12,
        },
      });
    }
    setColabs(num);
  };

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Home");
    }, [1]);

    window.scrollTo(0, 0, "Smooth");

    navigator.geolocation.getCurrentPosition(function (position) {});

    changeColabs(10);

    let video = document.getElementById("principal-video");
    video.muted = true;
    video.play();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="">
      <section className="relative flex">
        <div className="bg-decal-empresas">
          <video muted autoplay loop playsInline id="principal-video">
            <source src={principal} type="video/mp4" />
          </video>
        </div>
        <p className="argentum-medium absolute bottom-[230px] lg:bottom-[150px] left-4 lg:left-0 text-terciaria2 text-start w-8/12 lg:w-[610px] lg:px-20">
          Espaços compartilhados a qualquer momento e em qualquer lugar
        </p>
        <div className="w-full absolute bottom-[55px] flex gap-10 items-start lg:items-center lg:justify-between lg:flex-row justify-center lg:px-20 flex-col">
          <p className="argentum-caption left-4 text-terciaria2 text-start pl-4 lg:pl-0 w-10/12 lg:w-[691px]">
            Uma assinatura flexível para sua equipe trabalhar de qualquer lugar,
            de acordo com a sua necessidade!
          </p>
          <Link to="/empresas#planos" className="">
            <div className="div-lp flex items-center justify-center px-8 py-4 cursor-pointer self-center">
              <p className="argentum-normal text-terciaria2">
                Confira nossos planos de assinatura
              </p>
            </div>
          </Link>
        </div>
      </section>
      <section className="bg-terciaria2 py-12 lg:py-20 px-4 flex flex-col gap-4 lg:px-20">
        <p className="argentum-medium text-primaria5">
          OTIMIZE SUA FORMA DE TRABALHAR
        </p>
        <p className="argentum-caption text-secundaria4 leading-loose lg:w-9/12">
          Oferecemos acesso à centenas de espaços inspiradores e compartilhados
          através de uma assinatura, trabalhar onde e com quem você quiser já é
          uma realidade!
        </p>
      </section>
      <section>
        <div className="flex items-center justify-center flex-col lg:flex-row overflow-hidden h-auto">
          <img
            src={coworking}
            alt="coworking"
            className="cover-img lg:hidden w-full"
          />
          <img
            src={empresas}
            alt="empresas"
            className="cover-img lg:hidden w-full"
          />
          <img
            src={pessoas}
            alt="coworking"
            className="cover-img lg:hidden w-full"
          />
          <div className="lg:flex items-center justify-center hidden w-4/12 max-h-[330px] cursor-pointer transition-all relative hover:scale-125 overflow-hidden">
            <img
              src={coworkingBig}
              alt="coworking"
              className="cover-img lg:flex hidden w-full "
            />
            <p className="argentum-medium text-terciaria2 absolute">
              COWORKING & CAFÉ
            </p>
          </div>
          <div className="lg:flex items-center justify-center hidden w-4/12 flex-grow-0 max-h-[330px] cursor-pointer transition-all relative hover:scale-125 overflow-hidden">
            <img
              src={empresasBig}
              alt="empresas"
              className="cover-img lg:flex hidden w-full"
            />
            <p className="argentum-medium text-terciaria2 absolute">EMPRESAS</p>
          </div>
          <div className="lg:flex items-center justify-center hidden w-4/12 max-h-[330px] cursor-pointer transition-all relative hover:scale-125 overflow-hidden">
            <img
              src={pessoasBig}
              alt="coworking"
              className="cover-img lg:flex hidden w-full"
            />
            <p className="argentum-medium text-terciaria2 absolute">PESSOAS</p>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8">
        <p className="argentum-medium text-primaria5 lg:px-20 px-4 lg:w-12">
          COMO FUNCIONA?
        </p>
        <div className="flex flex-row items-center justify-center gap-2 pt-8 lg:hidden">
          <div
            className={
              change === "empresas"
                ? "bg-primaria4 w-fit px-4 py-3 rounded-lg"
                : "bg-detalhes2 w-fit px-4 py-3 rounded-lg"
            }
            onClick={() => setChange("empresas")}
          >
            <p className="argentum-normal text-terciaria2">Para empresa</p>
          </div>
          <img src={Replace} alt="alternar" />
          <div
            className={
              change === "colaborador"
                ? "bg-primaria4 w-fit px-4 py-3 rounded-lg"
                : "bg-detalhes2 w-fit px-4 py-3 rounded-lg"
            }
            onClick={() => setChange("colaborador")}
          >
            <p className="argentum-normal text-terciaria2">Para colaborador</p>
          </div>
        </div>
        {change === "colaborador" && (
          <div className="flex flex-col px-4 py-10 h-[450px] lg:hidden">
            <p className="argentum-normal-600">Receba o convite</p>
            <p className="argentum-caption-400 mt-3">
              A empresa cadastra o colaborador no beneficio e o colaborador
              recebe um e-mail para ativação.
            </p>
            <p className="argentum-normal-600 mt-8">Agende quando quiser</p>
            <p className="argentum-caption-400 mt-3">
              Com diversos espaços compartilhados e flexíveis espalhados por
              cada m² quadrado desse país, o colaborador escolhe o melhor
              conforme sua necessidade, quando e onde quiser.
            </p>
            <p className="argentum-normal-600 mt-8">
              Acompanha e gerencie seu plano
            </p>
            <p className="argentum-caption-400 mt-3">
              O colaborador terá acesso a agenda, histórico de visitações,
              avaliações realizadas e a realizar e acompanhar toda suas
              atividades em nossa plataforma.
            </p>
          </div>
        )}
        {change === "empresas" && (
          <div className="flex flex-col px-4 py-10 h-[450px] lg:hidden">
            <p className="argentum-normal-600">Contrata o benefício</p>
            <p className="argentum-caption-400 mt-3">
              A empresa escolhe o plano e a quantidade de colaboradores conforme
              suas necessidades.
            </p>
            <p className="argentum-normal-600 mt-8">
              Disponibiliza para os colaboradores
            </p>
            <p className="argentum-caption-400 mt-3">
              A empresa cadastra, disponibiliza e gerencia os planos para seus
              colaboradores
            </p>
            <p className="argentum-normal-600 mt-8">
              Acompanha todos os indicadores
            </p>
            <p className="argentum-caption-400 mt-3">
              A empresa companha o nível de satisfação, avaliação sobre os
              espaços e demais gestão das necessidades de forma prática,
              assertiva e eficiente.
            </p>
          </div>
        )}
        <div className="items-center justify-between py-6 hidden lg:flex">
          <div className="self-start w-6/12 pr-10">
            <img
              src={empresaImage}
              alt="empresa-imagem"
              className="w-full h-auto"
            />
          </div>
          <div className="items-start justify-start w-6/12 px-20 flex-col ">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Para sua empresa
            </p>
            <div className="flex flex-col gap-3 pl-3">
              <p className="argentum-normal-600">Contrata o benefício</p>
              <p className="argentum-caption-400">
                A empresa escolhe o plano e a quantidade de colaboradores
                conforme suas necessidades.
              </p>
              <p className="argentum-normal-600 mt-8">
                Disponibiliza para os colaboradores
              </p>
              <p className="argentum-caption-400">
                A empresa cadastra, disponibiliza e gerencia os planos para seus
                colaboradores
              </p>
              <p className="argentum-normal-600 mt-8">
                Acompanha todos os indicadores
              </p>
              <p className="argentum-caption-400">
                A empresa companha o nível de satisfação, avaliação sobre os
                espaços e demais gestão das necessidades de forma prática,
                assertiva e eficiente.
              </p>
            </div>
          </div>
        </div>
        <div className="items-center justify-between py-6 hidden lg:flex">
          <div className="flex items-start justify-start w-6/12 px-20 flex-col ">
            <p className="argentum-normal-25 text-primaria5 mb-4">
              Para colaboradores
            </p>
            <div className="flex flex-col gap-3 pl-3">
              <p className="argentum-normal-600">Receba o convite</p>
              <p className="argentum-caption-400">
                A empresa cadastra o colaborador no beneficio e o colaborador
                recebe um e-mail para ativação.
              </p>
              <p className="argentum-normal-600 mt-8">Agende quando quiser</p>
              <p className="argentum-caption-400">
                Com diversos espaços compartilhados e flexíveis espalhados por
                cada m² quadrado desse país, o colaborador escolhe o melhor
                conforme sua necessidade, quando e onde quiser.
              </p>
              <p className="argentum-normal-600 mt-8">
                Acompanha e gerencie seu plano
              </p>
              <p className="argentum-caption-400">
                O colaborador terá acesso a agenda, histórico de visitações,
                avaliações realizadas e a realizar e acompanhar toda suas
                atividades em nossa plataforma.
              </p>
            </div>
          </div>
          <div className="self-start w-6/12 pl-10">
            <img src={colabImage} alt="empresa-imagem" className="w-full" />
          </div>
        </div>
      </section>
      <section className="bg-primaria5 py-8 px-4 flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-between lg:flex-row lg:px-20">
          <div className="w-full flex flex-col lg:w-6/12 lg:pr-[35px] mt-8">
            <p className="argentum-medium-20 text-terciaria2 ">
              Simule os benefícios que serão gerados pela sua empresa e para a
              sua empresa!
            </p>
            <p className="argentum-extralight-2 text-terciaria2 mt-8 text-start pr-5">
              Descubra quanto você pode economizar com a ON! Use nossa
              calculadora interativa para estimar seus benefícios financeiros,
              desde economias mensais até redução de custos anuais. Saiba como
              nossa plataforma pode ajudar a impulsionar sua eficiência,
              aumentar sua produtividade e reduzir seus gastos. Experimente
              agora e veja o que podemos fazer por você!
            </p>
          </div>
          <div className="flex flex-col w-full lg:w-6/12 self-center">
            <p className="argentum-caption-low text-terciaria2 mt-8 text-center">
              Digite a quantidade de colaboradores da sua empresa
            </p>
            <input
              className="w-full mt-3 bg-terciaria2 h-12 rounded-md text-center"
              placeholder="Digite a quantidade de colaboradores da sua empresa"
              onChange={(e) => changeColabs(e.target.value)}
              value={colabs}
            ></input>
          </div>
        </div>
        <div className="flex flex-row pt-32 items-center justify-between lg:px-20 gap-4">
          <div className="flex flex-col w-6/12 gap-4">
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Tempo de deslocamento
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              KM por deslocamento
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Taxa de Carbono
            </p>
            <p className="argentum-normal px-4 w-full text-center flex items-center justify-center h-14 bg-primaria2lp rounded-lg  text-terciaria2">
              Custo de espaço
            </p>
          </div>
          <div className="flex flex-col gap-4 w-3/12">
            <div className="h-auto relative">
              <p className="w-full argentum-normal absolute h-14 -top-[70px] text-terciaria2 flex items-center justify-center bg-primaria2lp z-[20] rounded-lg">
                Mês
              </p>
              <p className="w-full argentum-normal h-14 flex items-center justify-center z-[21] bg-terciaria2 rounded-lg">
                {calc.mes.tempo_deslocamento} Horas
              </p>
            </div>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.mes.km_deslocamento} KM
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.mes.taxa_carbono}
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              R$ {calc.mes.custo_espaco.toLocaleString("pt-BR")}
            </p>
          </div>
          <div className="flex flex-col gap-4 w-3/12">
            <div className="h-auto relative">
              <p className="w-full argentum-normal absolute h-14 -top-[70px] text-terciaria2 flex items-center justify-center bg-primaria2lp z-[20] rounded-lg">
                Ano
              </p>
              <p className="w-full argentum-normal h-14 flex items-center justify-center z-[21] bg-terciaria2 rounded-lg">
                {calc.ano.tempo_deslocamento} Horas
              </p>
            </div>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.ano.km_deslocamento} KM
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              {calc.ano.taxa_carbono}
            </p>
            <p className="w-full argentum-normal h-14 flex items-center justify-center bg-terciaria2 rounded-lg">
              R$ {calc.ano.custo_espaco.toLocaleString("pt-BR")}
            </p>
          </div>
        </div>
      </section>
      <section className="bg-terciaria2 py-8 hidden lg:flex items-center justify-center min-h-screen">
        <div className="px-20 flex flex-row items-center justify-center w-full">
          <img
            src={noticias[notice].img_url}
            alt="Imagem de um prédio"
            id="foto-escritorio"
            className="xl:w-[650px] w-[450px] rounded-lg h-[500px] object-cover"
          />
          <div className="flex items-start justify-start xl:ml-20 ml-10 my-20 flex-col gap-8 h-[500px] relative">
            <p className="argentum-medium-30 text-primaria5">
              Fique por dentro!
            </p>
            <p className="argentum-medium">{noticias[notice].titulo}</p>
            <p className="argentum-extralight-3 text-secundaria4 leading-loose w-10/12">
              {noticias[notice].resumo}
            </p>
            <div className="flex flex-row items-center justify-between xl:gap-[105px] gap-[70px] absolute bottom-0">
              <div className="flex flex-row gap-10">
                <img
                  src={purple}
                  className="rotate-180 cursor-pointer"
                  onClick={() => changeNotice("down")}
                  alt="Noticia anterior"
                />
                <img
                  src={purple}
                  className="cursor-pointer"
                  onClick={() => changeNotice("up")}
                  alt="Proxima noticia"
                />
              </div>
              <a
                href={noticias[notice].noticia_url}
                target="_blank"
                className="border-2 text-primaria4 argentum-extralight-3 border-primaria4 px-5 py-2 rounded-md cursor-pointer hover:bg-primaria4 hover:text-terciaria2 transition-all"
                rel="noreferrer"
              >
                Ver mais
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="flex lg:hidden">
        <div className="w-full flex items-start justify-center px-4 py-8 gap-4 relative">
          <div className="w-6/12 relative rounded-lg">
            <img
              src={noticias[notice].img_url}
              alt="imagem escritorio"
              className="w-full rounded-lg h-[340px] object-cover object-right"
            />
          </div>
          <div className="flex items-start flex-col justify-start gap-5 w-6/12 h-[380px] relative">
            <p className="argentum-normal-400 text-primaria5">
              Fique por dentro!
            </p>
            <p className="argentum-normal-400">{noticias[notice].titulo}</p>
            <p className="argentum-extralight-4 text-secundaria4 leading-loose w-full">
              {noticias[notice].resumo}
            </p>
            <div className="flex flex-row gap-4 absolute bottom-0">
              <img
                src={purple}
                className="rotate-180 cursor-pointer w-8"
                onClick={() => changeNotice("down")}
                alt="Noticia anterior"
              />
              <img
                src={purple}
                className="cursor-pointer w-8"
                onClick={() => changeNotice("up")}
                alt="Proxima noticia"
              />
              <a
                href={noticias[notice].noticia_url}
                target="_blank"
                className="text-primaria4 argentum-extralight-3 border-primaria4 py-2 rounded-md cursor-pointer transition-all"
                rel="noreferrer"
              >
                Ver mais
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-decal-2 lg:flex-row flex-col-reverse flex items-start justify-center py-8 w-full lg:px-8 px-3">
        <div className="w-full items-center justify-center text-center lg:hidden">
          <p className="argentum-caption-16 text-terciaria2 mt-12 lg:hidden text-center">
            contato@onworkingpass.com
          </p>
        </div>
        <div className="flex items-center justify-center lg:w-6/12 mt-8 lg:mt-0 w-full">
          <form
            className="bg-terciaria2 lg:w-8/12 max-w-[600px] w-full px-8 py-12 gap-6 flex items-center justify-center flex-col rounded-md"
            onSubmit={(e) => sendForm(e)}
          >
            <input
              placeholder="Nome empresa"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="nome_empresa"
              value={inputs.nome_empresa}
              onChange={(e) =>
                setInputs({ ...inputs, nome_empresa: e.target.value })
              }
            />
            <input
              placeholder="Email"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="email"
              value={inputs.email}
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            />
            <input
              placeholder="Telefone"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="telefone"
              value={inputs.telefone}
              onChange={(e) =>
                setInputs({ ...inputs, telefone: e.target.value })
              }
            />
            <input
              placeholder="Quantidade de colaboradores"
              className="w-full py-4 pl-4 bg-secundaria1 rounded-md placeholder:text-secundaria4"
              name="colaboradores"
              value={inputs.colaboradores}
              onChange={(e) =>
                setInputs({ ...inputs, colaboradores: e.target.value })
              }
            />
            <textarea
              className="w-full bg-secundaria1 rounded-md placeholder:text-secundaria4 pl-4 pt-3 resize-none min-h-[150px]"
              placeholder="Quantidade de visitas por mês"
              name="visitas"
              value={inputs.visitas}
              onChange={(e) =>
                setInputs({ ...inputs, visitas: e.target.value })
              }
            />
            <button
              className="w-full h-14 argentum-normal text-terciaria2 rounded-sm"
              id="send-lp-form"
            >
              ENVIAR
            </button>
          </form>
        </div>
        <div className="lg:ml-20 lg:w-6/12 w-full flex items-start justify-start flex-col lg:gap-[40px] gap-12 py-8">
          <p className="argentum-medium text-detalhes4">Fale conosco</p>
          <p className="argentum-medium-36 text-terciaria2 lg:w-[450px]">
            Empresas Líderes sabem como ser produtivas
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[520px]">
            Conte com um parceiro que pode te oferecer muito mais do que um
            benefício - um apoio completo para aumentar a extensão da sua
            empresa.
          </p>
          <p className="argentum-caption-16 text-terciaria2 lg:w-[520px] mt-12 hidden lg:flex">
            contato@onworkingpass.com
          </p>
        </div>
      </section>
      {modal.active && (
        <ModalAlert
          text={modal.text}
          alertType={modal.alertType}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default Home;
