import React from "react";

const TextArea = (props) => {
  let { placer, background, id, onChange, value } = props;
  const bckg = background ? "bg-" + background : "bg-terciaria1";
  const classe =
    "w-full justify-center resize-none h-full rounded-lg shadow-3xl pl-5 pt-2 items-center placeholder:text-secundaria3 text-secundaria3 " +
    bckg;

  return (
    <textarea
      className={classe}
      placeholder={placer}
      id={id}
      onChange={onChange}
      value={value}
    ></textarea>
  );
};

export default TextArea;
