import axiosInstance from "../AxiosInstance";

const GetProfileCompany = async (id) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`empresas/info/?user=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({
          message: error.response.data,
          status: 400,
        });
      });
  });
};

export default GetProfileCompany;
