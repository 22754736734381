import axiosInstance from "../AxiosInstance";

const CancelarReserva = async (body) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("parceiros/cancelar-reserva", body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default CancelarReserva;
