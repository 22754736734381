import axiosInstance from "../AxiosInstance";

const ListarCoworking = async (uuid) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`colaboradores/coworking/${uuid}/info`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default ListarCoworking;
