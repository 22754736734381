import axiosInstance from "../AxiosInstance";

const GetItensCobrados = async () => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .get(`parceiros/listar-itens-cobrados`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default GetItensCobrados;
