import axiosInstance from "../AxiosInstance";

const ListarHistorico = async (page, filtro) => {
  return new Promise(function (resolve, reject) {
    if (!page) {
      if (!filtro) {
        axiosInstance
          .get(`parceiros/listar-historico`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axiosInstance
          .get(`parceiros/listar-historico/?filtro=${filtro}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    }
    if (page) {
      if (!filtro) {
        axiosInstance
          .get(`parceiros/listar-historico/?page=${page}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axiosInstance
          .get(`parceiros/listar-historico/?page=${page}&filtro=${filtro}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    }
  });
};

export default ListarHistorico;
