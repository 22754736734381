import React from "react";

const DropDownFiltro = (props) => {
  let { options, message, id, onChange, value, pointer } = props;

  let select = pointer ? " pointer-events-none" : " pointer-events-auto";
  let bckg = pointer ? "bg-detalhes6 " : "bg-terciariafundo ";
  const classe =
    "w-full justify-center h-10 pl-5 px-5 items-center dropdown placeholder:text-secundaria3 text-secundaria3 " +
    bckg +
    select;

  return options ? (
    <select className={classe} id={id} onChange={onChange} value={value}>
      <option value="" disabled selected>
        {message}
      </option>
      {options.map((option) => {
        return <option value={option.value}>{option.name}</option>;
      })}
    </select>
  ) : (
    <></>
  );
};

export default DropDownFiltro;
