import React, { useEffect } from "react";
import close from "../assets/closeModal.svg";

const ModalAlert = (props) => {
  let { text, alertType, setModal, margin, id } = props;
  const colorDict = {
    ok: "#249b11",
    fail: "#ce1010",
  };

  let style = {
    background: colorDict[alertType],
    margin: margin,
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setModal(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [setModal]);

  const closeModal = () => {
    setModal(false);
    return;
  };

  return (
    <div
      className="w-full max-w-[350px] md:max-w-[450px] p-3 rounded-md flex items-center justify-between gap-3 mt-2 mb-2 fixed right-0 top-[80px] z-[1000]"
      style={style}
      id={id}
    >
      <p className="textos semibold text-secundaria1" id="modal-text">
        {text}
      </p>
      <img
        src={close}
        className="w-6 h-auto cursor-pointer"
        onClick={closeModal}
        alt=""
      />
    </div>
  );
};

export default ModalAlert;
