import React, { useContext, useEffect } from "react";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";

const Privacidade = () => {
  const { changeMenuLogged } = useContext(LoggedHeaderContext);
  useEffect(() => {
    window.scrollTo(0, 0, "Smooth");

    setTimeout(() => {
      changeMenuLogged("Privacidade");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  return (
    <section className="w-full px-[80px] flex flex-col items-center justify-start gap-8 pb-12 bg-terciaria2">
      <p className="argentum-medium pt-8">PRIVACIDADE</p>
      <div className="w-full text-justify">
        <p className="argentum-caption">
          Bem-vindo ao ON Working pass, uma plataforma social, acessível pelo
          site portal.onworkingpass.com (“Site”) e/ou pelo aplicativo ONpass,
          que oferece acesso aos principais espaços de trabalho compartilhado do
          Brasil (“Serviços de Coworking e espaços de Cafeterias”) e que torna
          possível a conexão de pessoas baseadas em informações relevantes e
          interesses em comum de natureza acadêmica e/ou profissional. A ON
          Working é de titularidade exclusiva e é operada pela ON Working LTDA.
          Sua privacidade é muito importante para nós, e por isso nos
          comprometemos a respeitá-la e a preservá-la. Por esse motivo,
          preparamos a esta política de privacidade (“Política de Privacidade”),
          com o objetivo de esclarecer quais informações suas nós coletamos,
          como e em quais circunstâncias nós as utilizamos e/ou compartilhamos,
          de forma automatizada ou não, quais são os mecanismos de proteção de
          dados e informações adotados pelo ON Working pass, seja no ONpass App,
          seja no Site e da forma como o Visitante ou Usuário poderá atualizar,
          gerenciar ou excluir estas informações. Ao visitar, cadastrar-se,
          criar conta, contratar planos, fazer downloads, usar informações,
          ferramentas, recursos, serviços e funcionalidades do Site e/ou do
          ONpass App, você concorda com todas as condições desta Política de
          Privacidade, e declara que o faz sem qualquer indução, coação ou
          obrigação imposta por terceiros, sendo o exercício de tais condutas
          sua opção e exercício de seu livre arbítrio. As condições desta
          Política de Privacidade aplicam-se a você a partir do momento em que
          você navega pelo Site (“Visitante”), e em caso de contratação de Plano
          para utilização dos Serviços de espaços compartilhados dos nossos
          parceiros de Coworkings e/ou Cafeterias no Site ou no ONpass App
          (“Usuário”). Esta Política de Privacidade integra os Termos e
          Condições de Uso do ON Working pass. Esta Política de Privacidade foi
          elaborada em conformidade com a Lei Federal nº. 12.965 de 23 de abril
          de 2014 (Marco Civil da Internet), com a Lei Federal nº. 13.709, de 14
          de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) e com o
          Regulamento UE nº. 2016/679, de 27 de abril de 2016 (Regulamento Geral
          Europeu de Proteção de Dados Pessoais – RGDP).
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          1. Direitos dos Visitantes e/ou Usuários
        </p>
        <div>
          <p className="argentum-caption indent-12">
            A ON Working pass se compromete a cumprir as normas previstas na
            LGPD, em respeito aos seguintes princípios:
          </p>
          <div className="flex flex-col gap-4 py-4 pl-[100px]">
            <p className="argentum-caption">
              1. Os dados dos usuários serão processados de forma lítica, legal
              e transparente (licitude, lealdade e transparência);
            </p>
            <p className="argentum-caption">
              2. Os dados pessoais dos usuários serão coletados apenas para
              finalidades determinadas, explícitas e legítimas, não podendo ser
              tratados posteriormente de forma incompatível com essas
              finalidades (limitação de finalidades);
            </p>
            <p className="argentum-caption">
              3. Os dados pessoais do usuário serão coletados de forma adequada,
              pertinente e limitada às necessidades do objetivo para os quais
              eles são processados (minimização de dados);
            </p>
            <p className="argentum-caption">
              4. Os dados pessoais do usuário serão exatos e atualizados sempre
              que necessário, de maneira que os dados inexatos sejam apagados ou
              retificados quando possível (exatidão);
            </p>
            <p className="argentum-caption">
              5. Os dados pessoais do usuário serão conservados de uma forma que
              permita a identificação dos titulares dos dados apenas durante o
              período necessário para as finalidades para as quais são tratados
              (limitação de conservação);
            </p>
            <p className="argentum-caption">
              6. Os dados pessoais do usuário serão tratados de forma segura,
              protegidos do tratamento não autorizado ou ilícito e contra a sua
              perda, destruição ou danificação acidental, adotando as medidas
              técnicas ou organizativas adequadas (integridade e
              confidencialidade).
            </p>
          </div>
          <p className="argentum-caption indent-12">
            Os Visitantes e/ou Usuários possuem os seguintes direitos,
            conferidos pela Lei Geral de Proteção de Dados e pelo RGPD:
          </p>
          <div className="flex flex-col gap-4 py-4 pl-[100px]">
            <p className="argentum-caption">
              1. Direito de confirmação e acesso: é o direito do usuário,
              visitante e/ou membro de obter do site a confirmação de que os
              dados pessoais que lhe digam respeito são ou não objeto de
              tratamento e, se for esse o caso, o direito de acessar os seus
              dados pessoais;
            </p>
            <p className="argentum-caption">
              2. Direito de retificação: é o direito do usuário de obter do
              site, sem demora injustificada, a retificação dos dados pessoais
              inexatos que lhe digam respeito;
            </p>
            <p className="argentum-caption">
              3. Direito à eliminação dos dados (direito ao esquecimento): é o
              direito do usuário de ter os seus dados apagados no site;
            </p>
            <p className="argentum-caption">
              4. Direito à limitação do tratamento de dados: é o direito do
              usuário de limitar o tratamento dos seus dados pessoais, podendo
              obtê-la quando contesta a exatidão dos dados, quando o tratamento
              for ilícito, quando o site não precisar mais dos dados para as
              finalidades propostas e quanto tiver se oposto ao tratamento de
              dados e em caso de tratamento de dados desnecessários;
            </p>
            <p className="argentum-caption">
              5. Direito de Oposição: é o direito do usuário de, a qualquer
              momento, se opor por motivos relacionados com a sua situação
              particular, ao tratamento dos dados pessoais que lhe digam
              respeito, podendo se opor ainda ao uso de seus dados pessoais para
              definição de perfil de marketing (profiling);
            </p>
            <p className="argentum-caption">
              6. Direito de portabilidade dos dados: é o direito do usuário de
              receber os dados pessoais que lhe digam respeito e que tenha
              fornecido ao site, num formato estruturado, de uso corrente e de
              leitura automática, e o direito de transmitir estes dados a outro
              site;
            </p>
            <p className="argentum-caption">
              7. Direito de não ser submetido a decisões automatizadas: é o
              direito do usuário de não ficar sujeito a nenhuma decisão tomada
              exclusivamente com base no tratamento automatizado, incluindo a
              definição de perfis (profiling), que produza efeitos na sua esfera
              jurídica ou que o afete significativamente de forma similar.
            </p>
          </div>
          <p className="argentum-caption indent-12">
            O Usuário poderá exercer os seus direitos por meio de comunicação
            escrita enviada ao site com o assunto “LGPD – Esclarecimentos”,
            especificando:
          </p>
          <div className="flex flex-col gap-4 py-4 pl-[100px]">
            <p className="argentum-caption">
              1. O Usuário poderá exercer os seus direitos por meio de
              comunicação escrita enviada ao site com o assunto “LGPD –
              Esclarecimentos”, especificando:
            </p>
            <p className="argentum-caption">
              2. Direito que deseja exercer junto ao site;
            </p>
            <p className="argentum-caption">
              3. Data do pedido e assinatura do usuário;
            </p>
            <p className="argentum-caption">
              4. Todo documento que possa demonstrar ou justificar o exercício
              do seu direito.
            </p>
            <p className="argentum-caption">
              5. Direito de Oposição: é o direito do usuário de, a qualquer
              momento, se opor por motivos relacionados com a sua situação
              particular, ao tratamento dos dados pessoais que lhe digam
              respeito, podendo se opor ainda ao uso de seus dados pessoais para
              definição de perfil de marketing (profiling);
            </p>
            <p className="argentum-caption">
              6. Direito de portabilidade dos dados: é o direito do usuário de
              receber os dados pessoais que lhe digam respeito e que tenha
              fornecido ao site, num formato estruturado, de uso corrente e de
              leitura automática, e o direito de transmitir estes dados a outro
              site;
            </p>
            <p className="argentum-caption">
              7. Direito de não ser submetido a decisões automatizadas: é o
              direito do usuário de não ficar sujeito a nenhuma decisão tomada
              exclusivamente com base no tratamento automatizado, incluindo a
              definição de perfis (profiling), que produza efeitos na sua esfera
              jurídica ou que o afete significativamente de forma similar.
            </p>
          </div>
          <p className="argentum-caption indent-12">
            O pedido deverá ser enviado ao email{" "}
            <span className="font-black">contato@onworkingpass.com</span> ou
            pelo correio, ao seguinte endereço:{" "}
            <span className="font-black">
              {" "}
              Rod. Virgílio Várzea, 4570, bloco A303 - Canasvieiras,
              Florianópolis -SC, 88054-605{" "}
            </span>{" "}
            O usuário será informado em caso de retificação ou eliminação dos
            seus dados.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          2. Tipos de informações coletadas
        </p>
        <p className="argentum-caption indent-12">
          “Informações Pessoais”, para os fins desta Política de Privacidade,
          são aquelas que identificam as pessoas a quem pertencem, tais como
          nome, fotografias, informações de cartão de crédito, nome de usuário e
          endereço de e-mail. Ao visitar o Site, criar uma conta para utilização
          do ONpass App (“Conta”) e/ou o seu empregador contratar o plano no
          Site para utilização dos Serviços de espaços compartilhados de
          Coworking e Cafeteria (“Plano”), bem como durante a utilização dos
          Serviços de Coworking e espaços de Cafeteria, você pode nos fornecer
          Informações Pessoais. Durante a utilização do site, a fim de
          resguardar e proteger os direitos de terceiros, você deverá fornecer
          somente seus dados pessoais, e não os de terceiros. A ON Working pass
          somente coletará as Informações Pessoais caso elas sejam fornecidas
          voluntariamente pelos Visitantes e/ou Usuário. Quando você nos fornece
          Informações Pessoais, está nos autorizando a usá-las de acordo com os
          termos desta Política de Privacidade. Caso você opte por não fornecer
          Informações Pessoais, advertimos que você encontrará algumas
          restrições de acesso, como, por exemplo, a impossibilidade de criar
          Contas e de contratar Planos.
        </p>
        <p className="argentum-caption indent-12">
          Ao utilizar os Serviços de Coworking e os Serviços de Cafeterias, você
          poderá, ainda, enviar, postar e/ou exibir, publicamente ou em
          mensagens privadas, tanto para outros Usuários como para o ON Working
          pass, comentários, dados, textos, imagens, informações, nomes,
          imagens, gráficos, fotos, perfis, clipes de áudio e vídeo, sons, obras
          musicais, obras de autoria, aplicações, links e outros conteúdos ou
          materiais ("Conteúdo"). Você reconhece que o compartilhamento dos
          Conteúdos no Site e/ou no ONpass App é de sua exclusiva
          responsabilidade, não podendo ser o ON Working pass responsabilizado
          pela utilização dos Conteúdos por outros Visitantes e/ou Usuários aos
          quais você tenha dado acesso. Em atendimento às disposições do artigo
          15, caput e parágrafos, da Lei Federal nº. 12.965/2014 (Marco Civil da
          Internet), os registros de acesso do usuário serão coletados e
          armazenados por, pelo menos, seis meses.
        </p>
        • Fundamento jurídico para o tratamento de dados pessoais
        <p className="argentum-caption indent-12">
          Ao utilizar os serviços do site, o usuário está consentindo com a
          presente Política de Privacidade.{" "}
          <span className="font-black">
            Ao enviar, postar e/ou exibir qualquer Conteúdo através do ONpass
            App, você concede ao ON Working pass uma licença não exclusiva,
            transferível, gratuita e sem limitações quanto ao território ou de
            outra natureza, para armazenar, utilizar, copiar, reproduzir,
            editar, publicar, executar, modificar e/ou distribuir o Conteúdo,
            inclusive comercialmente, desde que para finalidades relacionadas à
            operação, desenvolvimento, promoção e melhoramento do ON Working
            pass ou de outras plataformas de propriedade do ON Working.
          </span>{" "}
          O usuário tem o direito de retirar o seu consentimento a qualquer
          momento, não comprometendo a licitude do tratamento de seus dados
          pessoais antes da retirada. A retirada do consentimento poderá ser
          feita pelo email{" "}
          <span className="font-black">contato@onworkingpass.com</span> ou pelo
          correio, ao seguinte endereço:{" "}
          <span className="font-black">
            Rod. Virgilio Varzea, 4570, Bloco A303 - Canasvieiras, Florianópolis
            - SC, 88054-605.
          </span>
          <p className="argentum-caption indent-12">
            O consentimento dos relativamente ou absolutamente incapazes,
            especialmente menores de 16 (dezesseis) anos, apenas será feito,
            respectivamente, se devidamente assistidos ou representados.{" "}
            <span className="font-black">
              Poderão ainda ser coletados dados pessoais necessários para a
              execução e cumprimento dos serviços contratados pelo usuário no
              site, de acordo com o artigo 7º, V, da LGPD.
            </span>{" "}
            O tratamento de dados pessoais sem o consentimento do usuário apenas
            será realizado em razão de interesse legítimo ou para as hipóteses
            previstas em lei, ou seja, dentre elas, as seguintes:
          </p>
        </p>
        <div className="flex flex-col gap-4 py-4 text-justify pl-[100px]">
          <p className="argentum-caption">
            1. Para cumprimento de obrigação legal ou regulatória pelo
            controlador;
          </p>
          <p className="argentum-caption">
            2. Para a realização de estudos de órgãos de pesquisa, garantida,
            sempre que possível, a anonimização dos dados pessoais;
          </p>
          <p className="argentum-caption">
            3. Quando necessário para a execução do contrato ou de procedimentos
            preliminares relacionados a contrato do qual seja parte o usuário, a
            pedido do titular dos dados;
          </p>
          <p className="argentum-caption">
            4. Para o exercício regular do direito em processo judicial,
            administrativo ou arbitral, este último nos termos da Lei nº. 9.307,
            de 23 de setembro de 1996 (Lei de Arbitragem);
          </p>
          <p className="argentum-caption">
            5. Para a proteção da vida ou da incolumidade física do titular dos
            dados ou de terceiro;
          </p>
          <p className="argentum-caption">
            6. Para a tutela da saúde, em procedimento realizado por
            profissionais da área de saúde ou por entidades sanitárias;
          </p>
          <p className="argentum-caption">
            7. Quando necessário para atender aos interesses legítimos do
            controlador ou de terceiro, exceto no caso de prevalecerem direitos
            e liberdades fundamentais do titular dos dados que exijam a proteção
            de dados pessoais;
          </p>
          <p className="argentum-caption">
            8. Para a proteção do crédito, inclusive quanto ao disposto na
            legislação pertinente.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          3. Informações Analíticas coletadas
        </p>
        <p className="argentum-caption indent-12">
          Nós poderemos, ainda, coletar informações registradas por nossos
          servidores de forma automática sobre o seu computador, tablet,
          smartphone e/ou outro dispositivo (“Dispositivo”), incluindo, sem
          limitação, certas informações do arquivo de log, como o seu pedido
          web, o endereço de Internet Protocol ("IP"), o tipo de navegador, a
          referência/saída páginas e as URLs, os números de cliques, os nomes de
          domínio, as páginas, as páginas visitadas, e outras informações.
        </p>
        <p className="argentum-caption indent-12">
          Poderemos também usar “cookies” – blocos de informações que são
          armazenados pelo navegador no disco rígido de seu Dispositivo – e
          outras tecnologias semelhantes e relacionadas, como pixels, web
          beacons e outras configurações de armazenamento local para coletar
          informações sobre como você usa o Site e/ou o ONpass App, como, por
          exemplo, as áreas que você visitou e os serviços que utilizou, o seu
          tempo de permanência, os links visitados, entre outras informações. A
          maioria dos navegadores é inicialmente ajustada para aceitar
          “cookies”. Se você preferir, poderá ajustar o seu para recusá-los ou
          para alertá-lo quando eles estiverem sendo enviados. Advertimos, no
          entanto, que a recusa à utilização de “cookies” poderá resultar na
          impossibilidade de visitar certas áreas e/ou de utilizar certas
          funcionalidades do Site ou do ONpass App, bem como de receber
          informações personalizadas.
        </p>
        <p className="argentum-caption indent-12">
          Especificamente nos casos em que você estiver utilizando dispositivos
          móveis para acessar o Site ou o ONpass App, poderemos acessar,
          coletar, monitorar dados armazenados no seu dispositivo móvel, e/ou
          armazenar remotamente um ou mais identificadores de dispositivo
          (“Identificador de Dispositivo”). Identificadores de Dispositivo são
          pequenos arquivos de dados ou estruturas de dados semelhantes
          armazenados ou associados a um dispositivo móvel e que o identificam.
          Um Identificador de Dispositivo pode fornecer informações para nós
          sobre como você navega e usa o ONpass App e pode nos ajudar a fornecer
          relatórios, conteúdos personalizados ou anúncios direcionados a você.
          Se você preferir, você poderá ajustar o seu dispositivo móvel para
          desativar os Identificadores de Dispositivo. Advertimos, no entanto,
          que algumas características do ONpass App podem não funcionar
          corretamente se o uso ou disponibilidade dos Identificadores de
          Dispositivo estiver prejudicado ou desativado. Todas as informações
          destacadas nessa seção serão doravante definidas como “Informações
          Analíticas”.
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8">
          4. Como coletamos suas informações
        </p>
        <p className="argentum-caption indent-12">
          Nós coletamos todas as Informações Analíticas destacadas acima para
          melhor adequar o Site, o ONpass App e os nossos Serviços e de
          parceiros de espaços compartilhados de Coworking e Cafeterias aos
          interesses e necessidades dos Visitantes e Usuários, com a finalidade
          de facilitar, agilizar e cumprir os compromissos estabelecidos com os
          usuários e a fazer cumprir as solicitações realizadas por meio do
          preenchimento de formulários. As Informações Pessoais que coletamos
          serão usadas para o fim específico para o qual foram coletadas. Por
          exemplo, informações de seu cartão de crédito serão utilizadas
          exclusivamente para efetuar as cobranças devidas e o seu nome será
          usado para a manutenção do registro de sua Conta e/ou Plano no ON
          Working. Suas Informações Pessoais também poderão ser usadas para
          enviarmos informações, ofertas e comunicados relacionados ao ON
          Working pass ou a outros produtos e serviços desenvolvidos pelo ON
          Working ou seus parceiros que acreditarmos que possam ser de seu
          interesse. Além dos usos destacados acima das informações que
          coletamos, nós podemos utilizar as informações coletadas para as
          seguintes finalidades:
        </p>
        <div className="flex flex-col gap-4 py-4 pl-[100px]">
          <p className="argentum-caption">
            • ajudar você a acessar de forma eficiente suas informações depois
            da criação de sua Conta, de modo a evitar que você tenha que digitar
            novamente suas informações durante visita ao Site ou acesso à sua
            Conta;
          </p>
          <p className="argentum-caption">
            • fornecer conteúdo personalizado e informações para você e outras
            pessoas, que podem incluir anúncios on-line ou outras formas de
            comercialização;
          </p>
          <p className="argentum-caption">
            • fornecer, melhorar, testar e monitorar a eficácia e a qualidade do
            Site, do ONpass App e dos Serviços de Coworking e Serviços de
            Cafeterias;
          </p>
          <p className="argentum-caption">
            • desenvolver e testar novos serviços, produtos e recursos;
          </p>
          <p className="argentum-caption">
            • monitorar indicadores, como o número total de visitantes, tráfego
            e padrões demográficos;
          </p>
          <p className="argentum-caption">
            diagnosticar ou corrigir problemas técnicos e de tecnologia;
          </p>
          <p className="argentum-caption">
            permitir que outros Usuários possam realizar concursos, ofertas
            especiais ou outros eventos ou atividades ("Eventos").
          </p>
        </div>
        <p className="argentum-caption indent-12">
          O site recolhe os dados do usuário para que seja realizada definição
          de perfis (profiling), ou seja, tratamento automatizado de dados
          pessoais que consista em utilizar estes dados para avaliar certos
          aspectos pessoais do usuário, principalmente para analisar ou prever
          características relacionadas ao seu desempenho profissional, a sua
          situação econômica, saúde, preferências pessoais, interesses,
          fiabilidade, comportamento, localização ou deslocamento. Os dados de
          cadastro serão utilizados para permitir o acesso do usuário a
          determinados conteúdos do site, exclusivos para usuários cadastrados.
        </p>
        <p className="argentum-caption indent-12">
          A coleta de dados relacionados ou necessários à execução de um
          contrato de prestação de serviços eventualmente firmado com o usuário
          terá a finalidade de conferir às partes segurança jurídica, além de
          facilitar e viabilizar a conclusão do negócio. O tratamento de dados
          pessoais para finalidades não previstas nessa Política de Privacidade
          somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em
          qualquer caso, os direitos e obrigações aqui previstos permanecerão
          aplicáveis.
        </p>
      </div>
      <div
        className="w-full flex flex-col items-start justify-center text-justify gap-3"
        id="privacidade-2"
      >
        <p className="argentum-medium w-full pt-8">
          5. Como utilizamos suas informações
        </p>
        <p className="argentum-caption indent-12">
          As suas Informações Pessoais serão utilizadas estritamente dentro dos
          limites previstos nesta Política de Privacidade. Nós não iremos
          compartilhar, nem comercializar as suas Informações Pessoais com
          terceiros que não façam parte do ON Working, suas afiliadas,
          controladas e/ou controladoras (“Afiliadas”) sem o seu consentimento.
          Nós poderemos compartilhar as suas Informações Pessoais e Informações
          Analíticas com as Afiliadas do ON Working, bem como com todos os
          funcionários e empregados que delas necessitem para exercer seus
          trabalhos. O compartilhamento das Informações Pessoais e das
          Informações Analíticas com essas pessoas se dará estritamente na
          medida do necessário para alcançar o propósito a que tal
          compartilhamento se destina. Eventualmente, poderemos fornecer as
          informações que coletarmos no Site e /ou no ONpass App, inclusive
          Informações Pessoais, a empresas terceirizadas, patrocinadores ou
          parceiros do ON Working (em conjunto, “Parceiros”) para processá-las
          ou analisá-las, de modo a gerar dados estatísticos de caráter
          informativo, estratégico ou comercial ou para promover campanhas
          publicitárias para públicos-alvo específicos, por exemplo. Destacamos
          que as suas Informações Pessoais nunca serão fornecidas aos Parceiros
          de forma individualizada, sendo sempre fornecidas de modo que não seja
          possível a identificação pessoal de qualquer Visitante ou Usuário
          individualmente. Se, por qualquer motivo, entendermos ser necessário o
          compartilhamento de Informações Pessoais suas com terceiros de forma
          individualizada, você será contatado para que obtenhamos a sua
          autorização prévia e expressa.
        </p>
        <p className="argentum-caption indent-12">
          <span className="font-black">
            Você nos autoriza desde já, entretanto, a compartilhar suas
            informações, inclusive Informações Pessoais individualizadas, sem
            que seja necessária a sua autorização específica, com todos nossos
            Parceiros que delas precisem para entregar um produto ou serviço
            contratado por você, em especial, mas sem limitação, com os nossos
            Coworkings e Cafeterias Parceiras, para possibilitar a adequada
            prestação dos Serviços de Coworking e Serviços de Cafeterias.
          </span>{" "}
          Em todo caso, os Parceiros que receberem Informações Pessoais
          individualizadas não estão autorizados por nós a compartilhar tais
          informações ou usá-las de qualquer maneira diversa das dispostas nesta
          Política de Privacidade. A ON Working poderá, ainda, armazenar, em
          conformidade com a legislação aplicável, suas Informações Pessoais,
          inclusive após o término do seu relacionamento com o ON Working pass.
          Poderemos também excluir essas informações, conforme permitido pela
          legislação.
        </p>
        <p className="argentum-caption indent-12">
          Caso você esteja localizado em jurisdição cujas leis que regem a
          coleta, guarda, armazenamento e tratamento de informações sejam
          diferentes das leis do Brasil, note que, em observância à legislação
          aplicável, as Informações Pessoais que você fornecer estarão sujeitas
          ao tratamento dado pela legislação brasileira. Na hipótese de venda ou
          cessão dos direitos relativos ao ON Working pass ou em caso de venda,
          cisão, fusão ou incorporação do ON Working e/ou de suas Afiliadas, as
          suas Informações Pessoais e Informações Analíticas poderão ser
          transferidas. Essa transferência não implicará qualquer prejuízo à
          proteção de suas Informações Pessoais e Informações Analíticas, sendo
          que o comprador ou cessionário estará obrigado a honrar os
          compromissos que assumimos na presente Política de Privacidade. Por
          fim, saiba que poderemos revelar as suas Informações Pessoais se assim
          for exigido por lei ou por determinação judicial ou administrativa. A
          transferência apenas poderá ser feita para outro país ou território em
          questão ou a organização internacional em causa assegurem um nível de
          proteção adequado aos dados do usuário. Caso não haja nível de
          proteção adequado, o site se compromete a garantir a proteção dos seus
          dados de acordo com as regras mais rigorosas, por meio de cláusulas
          contratuais específicas para determinada transferência,
          cláusulas-padrão contratuais, normas corporativas globais ou selos,
          certificados e códigos de conduta regularmente emitidos.
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8 font-black">
          6. Prazo de conservação de dados pessoais
        </p>
        <p className="argentum-caption indent-12">
          Os dados pessoais do usuário serão conservados enquanto for
          necessário, de acordo com o legítimo interesse da empresa, exceto se o
          usuário solicitar a sua supressão antes do final do prazo. Os dados
          pessoais do usuário apenas poderão ser conservados após o término do
          seu tratamento nas seguintes hipóteses:
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="argentum-caption">
          1. Para o cumprimento de obrigação legal ou regulatória pelo
          controlador;
        </p>
        <p className="argentum-caption">
          2. Para estudo por órgão de pesquisa, garantida, sempre que possível,
          a anonimização dos dados pessoais;
        </p>
        <p className="argentum-caption">
          3. Para a transferência de terceiro, desde que respeitados os
          requisitos de tratamento dos dados dispostos na legislação;
        </p>
        <p className="argentum-caption">
          4. Para uso exclusivo do controlador, vedado seu acesso por terceiro,
          e desde que anonimizados os dados.
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8 font-black">
          7. Do tratamento dos dados pessoais
        </p>
        • Do encarregado de proteção dos dados (“Data Protection Officer”)
        <p className="argentum-caption indent-12">
          O encarregado de proteção dos dados é o profissional encarregado de
          informar, aconselhar e controlar o responsável pelo tratamento dos
          dados, bem como os trabalhadores que tratem os dados, a respeito das
          obrigações do site nos termos da LGPD, RGDP e de outras disposições de
          proteção de dados presentes na legislação nacional e internacional, em
          cooperação com a autoridade de controle competente. Neste site, o
          encarregado de proteção de dados pessoais é{" "}
          <span className="font-black">Vinicius Eduardo Gallo Hora</span>,
          representada pelo setor de tecnologia, que poderá ser contactado pelo
          e-mail: <span className="font-black">contato@onworkingpass.com</span>
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8 font-black">
          8. Responsabilidade do ON Working
        </p>
        <p className="argentum-caption indent-12">
          Quando suas Informações Pessoais forem solicitadas, seja para criação
          de Conta, para contratação de Plano ou para qualquer outra finalidade,
          bem como nos casos em que suas Informações Analíticas forem coletadas,
          elas são enviadas diretamente ao ON Working para serem utilizadas para
          os propósitos descritos nesta Política de Privacidade. Entretanto,
          destacamos que pode haver, tanto no Site como no ONpass App, links ou
          imagens de publicidade e/ou propaganda de terceiros que levem você a
          endereços que solicitem suas Informações Pessoais e que coletem outras
          informações, inclusive Informações Analíticas. A ON Working esclarece
          (e você reconhece) que, como nestes casos as informações não são
          enviadas ao ON Working, ele não tem qualquer controle e/ou
          responsabilidade sobre a utilização, coleta, guarda, armazenamento e
          tratamento dessas informações por terceiros. De maneira análoga, a ON
          Working também não possui qualquer responsabilidade sobre as
          informações coletadas em sites de terceiros em que o ON Working possua
          link, promoção, anúncio ou imagem de publicidade e/ou propaganda.
        </p>
        <p className="argentum-caption indent-12">
          <span className="font-black">
            Caso seja comprovada a culpa do ON Workig pass por prejuízos
            sofridos pelos Visitantes e/ou Usuários em decorrência de
            descumprimento das obrigações relacionadas a utilização, coleta,
            guarda, armazenamento e a tratamento de Informações Pessoais, a
            responsabilidade da ON working não excederá, em nenhuma hipótese, a
            soma total dos valores efetivamente pagos pelo Visitante e/ou
            Usuário a ON Working como contraprestação pela disponibilização dos
            Serviços de Coworking e/ou dos Serviços de Cafeterias, ou ao valor
            máximo definido nos Termos e Condições de Uso da ON Working.
          </span>
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8 font-black">
          9. Segurança das Informações
        </p>
        <p className="argentum-caption indent-12">
          Todas as Informações Pessoais fornecidas por você são armazenadas em
          um banco de dados reservado e com acesso restrito a pessoas
          devidamente habilitadas a tratar as Informações Pessoais, que são
          obrigadas contratualmente a preservar o sigilo e a confidencialidade
          das informações e não as utilizar inadequadamente. A ON Working pass
          toma todas as precauções possíveis para manter as suas Informações
          Pessoais protegidas, se comprometendo a aplicar as medidas técnicas e
          organizativas aptas a proteger os dados pessoais de acesso não
          autorizados e de situações de destruição, perda, alteração,
          comunicação ou difusão de tais dados. Todas as Informações Pessoais
          estão sujeitas a medidas de segurança para impedir acesso, uso ou
          divulgação não autorizados, como a exigência de senha, utilização da
          criptografia SSL (Secure Sockets Layer), sistemas de autenticação
          dupla para assegurar que somente pessoas autorizadas possam
          acessá-las, entre outras, todas que levem em consideração: a técnica
          adequada, os custos de aplicação, a natureza, o âmbito, o contexto e a
          finalidade do tratamento e os riscos para os direitos e liberdades do
          usuário. A ON Working pass declara, ainda, adotar práticas em
          conformidade com as disposições e obrigações a ele atribuíveis com
          relação à proteção da privacidade das Informações Pessoais,
          especialmente com as disposições do Marco Civil da Internet (Lei
          12.965/2015) e do seu Regulamento (Decreto 8.771/2016), bem como a
          LGPD.
        </p>
        <p className="argentum-caption indent-12">
          Destacamos, no entanto, que nenhum método eletrônico de armazenamento
          ou de transmissão pela Internet é isento de falhas. Portanto, embora
          nos comprometamos a utilizar todas as medidas possíveis para proteger
          as informações fornecidas por você, não nos responsabilizaremos por
          eventuais falhas de sistema ou de segurança que venham a afetar o
          acesso não autorizado às Informações Pessoais e às Informações
          Analíticas. O site se exime da responsabilidade por culpa exclusiva de
          terceiro, como em caso de ataque de hackers ou crackers, ou culpa
          exclusiva do usuário, como no caso em que ele mesmo transfere seus
          dados a terceiro. O site se compromete, ainda, a comunicar o usuário
          em prazo adequado caso ocorra algum tipo de violação da segurança de
          seus dados pessoais que possa lhe causar um alto risco para seus
          direitos e liberdades pessoais. A violação de dados pessoais é uma
          violação de segurança que provoque, de modo acidental ou ilícito, a
          destruição, a perda, a alteração, a divulgação ou o acesso não
          autorizados a dados pessoais transmitidos, conservados ou sujeitos a
          qualquer tipo de tratamento. Por fim, o site se compromete a tratar os
          dados pessoais do usuário com confidencialidade, dentro dos limites
          legais.
        </p>
      </div>
      <div className="w-full flex flex-col items-start justify-center text-justify gap-3">
        <p className="argentum-medium w-full pt-8 font-black">
          10. Modificação da Política de Privacidade
        </p>
        <p className="argentum-caption indent-12">
          A ON Working pass poderá modificar esta Política de Privacidade a
          qualquer tempo, mediante concessão de período de prévio aviso com
          tempo razoável aos Visitantes e/ou Usuários. Esta Política de
          Privacidade poderá ser atualizada em decorrência de eventual
          atualização normativa, razão pela qual se convida os Visitantes e/ou
          Usuários a consultar periodicamente esta seção. A utilização do Site
          ou do ONpas App após o fim desse período de prévio será considerada
          como uma aceitação da Política de Privacidade, com as alterações
          incorporadas. Se você não concordar ou não estiver satisfeito com as
          mudanças na Política de Privacidade, você deverá cancelar sua Conta
          e/ou Plano e deixar de utilizar o Site e/ou o ONpass App.
        </p>
        <p className="argentum-caption indent-12">
          <span className="font-black">
            Para a solução das controvérsias decorrentes do presente
            instrumento, serão aplicadas as normas decorrentes da LGPD.
            Eventuais litígios deverão ser apresentados no foro da comarca em
            que se encontra a sede do editor do site.
          </span>
        </p>
        <p className="argentum-caption py-12">
          <span className="font-black">
            Última atualização: fevereiro de 2023
          </span>
        </p>
      </div>
    </section>
  );
};

export default Privacidade;
