import React, { useContext, useEffect } from "react";
import empresa from "../assets/building.svg";
import coworking from "../assets/people.svg";
import colab from "../assets/profilecircle.svg";
import { useNavigate } from "react-router-dom";
import LoggedHeaderContext from "../hooks/LoogedHeaderContext";

const EscolherCadastro = () => {
  const navigate = useNavigate();

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  useEffect(() => {
    setTimeout(() => {
      changeMenuLogged("Cadastro2");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  return (
    <section className="min-h-screen bg-terciariafundo flex items-start justify-center lg:px-[200px] px-4">
      <div className="w-full flex items-start justify-center flex-col gap-7 max-w-[400px] mt-12">
        <p className="textos semibold self-center">Faça seu cadastro</p>
        <div
          onClick={() => navigate("/colaborador/cadastro")}
          className="w-full bg-terciaria2 h-auto border border-detalhes2 border-opacity-40 px-2 py-4 rounded-md cursor-pointer flex flex-row items-center justify-center"
        >
          <div className="flex flex-row items-center justify-center gap-4 relative">
            <img src={colab} alt="Colaboradores" className="w-12" />
            <p className="textos semibold text-primaria5">COLABORADOR</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/empresa/cadastro")}
          className="w-full bg-terciaria2 h-auto border border-detalhes2 border-opacity-40 px-2 py-4 rounded-md cursor-pointer flex flex-row items-center justify-center"
        >
          <div className="flex flex-row items-center justify-center gap-4">
            <img src={empresa} alt="Colaboradores" className="w-12" />
            <p className="textos semibold text-primaria5">EMPRESA</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/parceiro/cadastro")}
          className="w-full bg-terciaria2 h-auto border border-detalhes2 border-opacity-40 px-2 py-4 rounded-md cursor-pointer flex flex-row items-center justify-center"
        >
          <div className="flex flex-row items-center justify-center gap-4">
            <img src={coworking} alt="Colaboradores" className="w-12" />
            <p className="textos semibold text-primaria5">PARCEIRO</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EscolherCadastro;
