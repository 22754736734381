import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ButtonGradient from "../../components/ButtonGradient";
import ParceiroRazaoSocial from "../../components/Register/ParceiroRazaoSocial";
import ParceiroEspaco from "../../components/Register/ParceiroEspaco";
import ParceiroResponsavel from "../../components/Register/ParceiroResponsavel";
import ParceiroPassword from "../../components/Register/ParceiroPassword";
import LoggedHeaderContext from "../../hooks/LoogedHeaderContext";

const CadastroEmpresa = () => {
  const [toDo, setToDo] = useState("razao_social");
  const [doing, setDoing] = useState("");
  const [complete, setComplete] = useState({
    razao_social: false,
    espaco: false,
    responsavel: false,
    password: false,
  });

  let { changeMenuLogged } = useContext(LoggedHeaderContext);

  useEffect(() => {
    window.scrollTo(0, 0, "Smooth");

    setTimeout(() => {
      changeMenuLogged("Cadastro parceiro");
    }, [1]);
    // eslint-disable-next-line
  }, []);

  const funcChangeStep = (todo, doing, comp) => {
    setToDo(todo);
    setDoing(doing);
    switch (comp) {
      case "razao_social":
        setComplete({ ...complete, razao_social: true });
        break;

      case "espaco":
        setComplete({ ...complete, espaco: true });
        break;

      case "responsavel":
        setComplete({ ...complete, responsavel: true });
        break;

      case "password":
        setComplete({ ...complete, password: true });
        break;

      default:
        setComplete({ ...complete });
        break;
    }
  };

  return (
    <section className="px-4 py-6 bg-terciariafundo transition-all lg:flex lg:flex-row lg:items-start lg:justify-center min-h-screen">
      <div className="lg:w-9/12 lg:rounded-2xl lg:py-12 lg:flex lg:flex-row lg:items-center lg:justify-center lg:px-4 lg:mb-16">
        <div className="lg:w-6/12 lg:bg-terciaria2 lg:p-8 lg:h-[500px] lg:rounded-xl">
          <p className="subtitle bold text-primaria5">Pré-cadastro parceiro</p>
          <p className="textos regular text-secundaria4 mt-1">
            Será realizado para que possa ser verificado, a veracidade dos seus
            dados.
          </p>
          <div
            className={
              "bg-terciaria2 p-4 rounded-xl mt-6 opacity-100 lg:w-full"
            }
          >
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pb-5 gap-1">
              <div>
                <p
                  className={
                    doing === "razao_social"
                      ? complete.razao_social
                        ? "body medium text-detalhes4"
                        : "body medium text-primaria4"
                      : doing !== ""
                      ? complete.razao_social
                        ? "body medium text-detalhes4 opacity-30"
                        : "body medium text-primaria4 opacity-30"
                      : complete.razao_social
                      ? "body medium text-detalhes4 opacity-100"
                      : "body medium text-primaria4 opacity-100"
                  }
                >
                  Dados da razão social
                </p>
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "razao_social" && !complete.razao_social && (
                  <ButtonGradient
                    legend="Inserir"
                    func={() => funcChangeStep("", "razao_social", "")}
                  />
                )}
              </div>
              {complete.razao_social && (
                <p
                  className={
                    doing === ""
                      ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                      : doing === "razao_social"
                      ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                      : "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-30 pointer-events-none"
                  }
                  onClick={() => funcChangeStep("", "razao_social", "")}
                >
                  editar dados
                </p>
              )}
            </div>
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pt-5 pb-5 gap-1">
              <div>
                <p
                  className={
                    doing === "espaco"
                      ? complete.espaco
                        ? "body medium text-detalhes4"
                        : "body medium text-primaria4"
                      : doing !== ""
                      ? complete.espaco
                        ? "body medium text-detalhes4 opacity-30"
                        : "body medium text-primaria4 opacity-30"
                      : complete.espaco
                      ? "body medium text-detalhes4 opacity-100"
                      : "body medium text-primaria4 opacity-100"
                  }
                >
                  Dados do seu espaço
                </p>
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "espaco" && !complete.espaco && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "espaco", "")}
                  />
                )}
                {complete.espaco && (
                  <p
                    className={
                      doing === ""
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : doing === "espaco"
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-30 pointer-events-none"
                    }
                    onClick={() => funcChangeStep("", "espaco", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-b border-b-secundaria2 pt-5 pb-5 gap-8">
              <div>
                <p
                  className={
                    doing === "responsavel"
                      ? complete.responsavel
                        ? "body medium text-detalhes4"
                        : "body medium text-primaria4"
                      : doing !== ""
                      ? complete.responsavel
                        ? "body medium text-detalhes4 opacity-30"
                        : "body medium text-primaria4 opacity-30"
                      : complete.responsavel
                      ? "body medium text-detalhes4 opacity-100"
                      : "body medium text-primaria4 opacity-100"
                  }
                >
                  Dados do responsável
                </p>
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "responsavel" && !complete.responsavel && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "responsavel", "")}
                  />
                )}
                {complete.responsavel && (
                  <p
                    className={
                      doing === ""
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : doing === "responsavel"
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-30 pointer-events-none"
                    }
                    onClick={() => funcChangeStep("", "responsavel", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pt-5 pb-5 gap-8">
              <div>
                <p
                  className={
                    doing === "password"
                      ? complete.password
                        ? "body medium text-detalhes4"
                        : "body medium text-primaria4"
                      : doing !== ""
                      ? complete.password
                        ? "body medium text-detalhes4 opacity-30"
                        : "body medium text-primaria4 opacity-30"
                      : complete.password
                      ? "body medium text-detalhes4 opacity-100"
                      : "body medium text-primaria4 opacity-100"
                  }
                >
                  Criar conta
                </p>
              </div>

              <div className="min-w-24 max-w-24 h-8 flex">
                {toDo === "password" && !complete.password && (
                  <ButtonGradient
                    legend="Preencher"
                    func={() => funcChangeStep("", "password", "")}
                  />
                )}
                {complete.password && (
                  <p
                    className={
                      doing === ""
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : doing === "password"
                        ? "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-100"
                        : "button medium flex items-center justify-center text-secundaria5 cursor-pointer opacity-30 pointer-events-none"
                    }
                    onClick={() => funcChangeStep("", "password", "")}
                  >
                    editar dados
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            doing === "razao_social"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-3 lg:w-6/12 lg:mt-0 lg:ml-4"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <ParceiroRazaoSocial
            funcChangeStep={funcChangeStep}
            complete={complete}
          />
        </div>
        <div
          className={
            doing === "espaco"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-3 lg:w-6/12 lg:mt-0 lg:ml-4"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <ParceiroEspaco funcChangeStep={funcChangeStep} complete={complete} />
        </div>
        <div
          className={
            doing === "responsavel"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-6 lg:w-6/12 lg:ml-4 lg:mt-0"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <ParceiroResponsavel
            funcChangeStep={funcChangeStep}
            complete={complete}
          />
        </div>
        <div
          className={
            doing === "password"
              ? "h-auto lg:h-[500px] opacity-100 transition-all mt-6 lg:w-6/12 lg:ml-4 lg:mt-0"
              : "max-h-0 opacity-0 transition-all lg:w-0"
          }
        >
          <ParceiroPassword
            funcChangeStep={funcChangeStep}
            complete={complete}
          />
        </div>
      </div>
    </section>
  );
};

export default CadastroEmpresa;
