import axiosInstance from "../AxiosInstance";

const ParceiroCadastraCapa = async (body, cnpj) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post(`parceiros/cadastra-capa-coworking/?cnpj=${cnpj}`, body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default ParceiroCadastraCapa;
