import axiosInstance from "../AxiosInstance";

const FormParceiro = async (body) => {
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("parceiros/formulario-home", body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default FormParceiro;
